define("clientui/pods/account/login/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "clientui/superclasses/authenticated-route"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  /*
    This is a special route that simply redirects to the same path on the OBT
    but after ensuring that the user is properly logged in, and permitted to access the OBT
    and that all necessary profile fields are completed
   */
  let AccountLoginRoute = (_dec = Ember.inject.service('keycloak-session'), _dec2 = Ember.inject.service('system'), (_class = class AccountLoginRoute extends _authenticatedRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
    }

    model() {
      let guid = this.session.subject;
      let email = null;
      let firstName = null;
      let surname = null;

      if (this.session.authenticated) {
        email = this.session.tokenParsed.email;
        firstName = this.session.tokenParsed.given_name;
        surname = this.session.tokenParsed.family_name;
      }

      let clientportal = this.system.clientportal;
      let clientportalId = null;

      if (clientportal) {
        clientportalId = clientportal.get('tradeClientProfileId');
      }

      return this.store.query('client', {
        'tcpid': clientportalId,
        'guid': guid,
        'email': email,
        'kcfirstname': firstName,
        'kcsurname': surname,
        'testmultierror': 'false',
        'pno': -1
      }).then(clients => {
        return clients.get('firstObject');
      }, errors => {
        console.log(errors);
        throw errors;
      });
    }

    afterModel(client
    /* , transition */
    ) {
      let obtPathName = window.location.pathname;
      this.system.sendAnalytics('account.login');
      let clientportal = this.system.get('clientportal'); // console.log('dbId: ' + client.get('dbId'));
      // console.log('obtPermitted: ' + client.get('clientProfile.obtPermitted'));
      // console.log('hasMissingObtFields: ' + client.get('hasMissingObtFields'));

      if (client.get('dbId') === 0) {
        console.log("transitioning to newprofile from login in afterModel as dbId is " + client.get('dbId'));
        this.transitionTo('newprofile');
      } else if (client.get('clientProfile.obtPermitted') && client.get('hasMissingObtFields')) {
        console.log("transitioning to myprofile from login in afterModel as hasMissingObtFields is true");
        this.transitionTo('myprofile'); //Change to 'required fields' editor when written
      } else if (clientportal && clientportal.hasObt && client.get('clientProfile.obtPermitted')) {
        console.log("Transitioning to " + clientportal.obtUrl + obtPathName);
        window.location.replace(clientportal.obtUrl + obtPathName);
      } else {
        console.log("transitioning to application from login in afterModel as dbId is " + client.get('dbId'));
        this.transitionTo('application');
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AccountLoginRoute;
});