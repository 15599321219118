define("clientui/pods/components/pages/downloadabledocuments/page-downloadabledocuments-archive/component", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@glimmer/component"], function (_exports, _applyDecoratedDescriptor2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let DownloadabledocumentsArchive = (_dec = Ember._action, (_class = class DownloadabledocumentsArchive extends _component.default {
    constructor(owner, args) {
      super(owner, args);

      if (this.args.downloadableDocument.archived) {
        this.savebuttonlabel = "UN-ARCHIVE";
      } else {
        this.savebuttonlabel = "ARCHIVE";
      }
    }

    save() {
      this.args.downloadableDocument.validate().then(() => {
        this.args.downloadableDocument.set('archived', !this.args.downloadableDocument.archived);

        if (this.args.downloadableDocument.get("isValid")) {
          let promises = {};
          Ember.RSVP.hash(promises).then(item => {
            Object.keys(item).forEach(key => {
              this.args.downloadableDocument.set(key, item[key]);
            });
            this.args.onSave(this.args.downloadableDocument).then(() => {
              // Promise
              this.args.onClose();
            }, errors => {
              console.log(errors);
              this.servererrors = errors;
            });
          }, errors => {
            console.log(errors);
            this.servererrors = errors;
          });
        }
      });
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "save", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  _exports.default = DownloadabledocumentsArchive;
});