define("clientui/pods/components/sections/page-myprofilenew-contact/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let PageMyprofilenewContact = (_dec = (0, _component.classNames)('card-columns', 'contact-panel'), _dec2 = Ember.computed.filter('client.contactDetails', function (item) {
    return item.get('contactDetailType') === 'EMAIL';
  }), _dec3 = Ember.computed.sort('emailAddresses', 'sortDefinition'), _dec4 = Ember.computed.filter('client.contactDetails', function (item) {
    return item.get('contactDetailType') === 'APPROV';
  }), _dec5 = Ember.computed.sort('approvalEmailAddresses', 'sortDefinition'), _dec6 = Ember.computed.filter('client.contactDetails', function (item) {
    return item.get('contactDetailType') === 'MOBILE';
  }), _dec7 = Ember.computed.sort('mobiles', 'sortDefinition'), _dec8 = Ember.computed.filter('client.contactDetails', function (item) {
    return item.get('contactDetailType') === 'PHONE';
  }), _dec9 = Ember.computed.sort('phones', 'sortDefinition'), _dec10 = Ember.computed.sort('client.addresses', 'addressSortDefinition'), _dec11 = Ember.computed('client.clientProfile.{emergencyContactName,emergencyContactPhone,emergencyContactEmail}'), classic(_class = _dec(_class = (_class2 = class PageMyprofilenewContact extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "emailAddresses", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "sortedEmailAddresses", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "approvalEmailAddresses", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "sortedApprovalEmailAddresses", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "mobiles", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "sortedMobiles", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "phones", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "sortedPhones", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "sortedAddresses", _descriptor9, this);
      (0, _defineProperty2.default)(this, "sortDefinition", null);
    }

    get hasEmergencyContact() {
      let result = false;

      if (this.get('client.clientProfile.emergencyContactName') || this.get('client.clientProfile.emergencyContactPhone') || this.get('client.clientProfile.emergencyContactEmail')) {
        result = true;
      }

      return result;
    }

    init() {
      super.init(...arguments);
      this.set('sortDefinition', ['defaultContactDetail:desc', 'description', 'value']);
      this.set('addressSortDefinition', ['defaultContactDetail:desc', 'description', 'id']);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "emailAddresses", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sortedEmailAddresses", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "approvalEmailAddresses", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sortedApprovalEmailAddresses", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "mobiles", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sortedMobiles", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "phones", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sortedPhones", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sortedAddresses", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "hasEmergencyContact", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "hasEmergencyContact"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = PageMyprofilenewContact;
});