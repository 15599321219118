define("clientui/pods/travelrequests/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vY6lNVcl",
    "block": "{\"symbols\":[],\"statements\":[[8,\"layout/page-header\",[],[[\"@clientportal\",\"@client\",\"@showBanner\",\"@pageTitle\"],[[34,0],[34,1],false,\"Selective Profile Suite\"]],null],[2,\"\\n\"],[10,\"section\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[14,1,\"content\"],[12],[2,\"\\n    \"],[8,\"layout/content-heading\",[],[[\"@portalDisplayName\",\"@firstName\",\"@surname\",\"@email\",\"@position\",\"@tradeclient\"],[[34,0,[\"portalDisplayName\"]],[34,1,[\"firstName\"]],[34,1,[\"surname\"]],[34,1,[\"mainEmail\"]],[34,1,[\"position\"]],[34,1,[\"owner\",\"selectDisplayText\"]]]],null],[2,\"\\n\\n\"],[2,\"    \"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"clientportal\",\"client\",\"-outlet\",\"component\"]}",
    "moduleName": "clientui/pods/travelrequests/template.hbs"
  });

  _exports.default = _default;
});