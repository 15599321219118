define("clientui/pods/mis/route", ["exports", "clientui/superclasses/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MisRoute extends _authenticatedRoute.default {}

  _exports.default = MisRoute;

  window.__CLASSIC_OWN_CLASSES__.set(MisRoute, true);
});