define("clientui/serializers/quotecontainer", ["exports", "clientui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      // createdBy:                     { serialize: 'id', deserialize: 'records' },
      quoteElements: {
        embedded: 'always'
      }
    }
  });

  _exports.default = _default;
});