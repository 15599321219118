define("clientui/pods/travelrequests/manage/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ManageRoute = (_dec = Ember.inject.service('keycloak-session'), _dec2 = Ember.inject.service('system'), _dec3 = Ember._action, classic(_class = (_class2 = class ManageRoute extends Ember.Route {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
      (0, _defineProperty2.default)(this, "dialogTitle", 'New Traveller Profile');
      (0, _defineProperty2.default)(this, "enableDebugLogging", true);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
    }

    model() {
      // Unload any enquiryContainers before retrieving the fresh list.
      // This is due to an enquiry container being created when you enter the New Request route which is then displayed on the manage route even when it has not been saved.
      this.store.unloadAll('enquirycontainer');
      let promises = [];
      promises['client'] = this.modelFor('travelrequests'); // Give me whatever model is returned from the travelrequests route

      promises['travelrequests'] = this.store.query('enquirycontainer', {}); // This will only return the required enquirylist applicable to the logged in client.

      promises['approvalrequests'] = this.store.query('enquirycontainer', {
        'approveronly': true
      }); // This will only return the required enquirylist applicable to the logged in client.

      return Ember.RSVP.hash(promises);
    }

    setupController(controller, promises) {
      let client = promises['client'];

      if (client) {
        controller.set('client', client);
      }

      controller.set('clientportal', this.system.clientportal);
      let travelrequests = promises['travelrequests'];
      console.log("travelrequests", travelrequests);

      if (travelrequests) {
        controller.set('travelrequests', travelrequests);
      }

      let approvalrequests = promises['approvalrequests'];
      console.log("approvalrequests", approvalrequests);

      if (approvalrequests) {
        controller.set('approvalrequests', approvalrequests);
      }
    }

    selectAction(itemId, action) {
      if (itemId > 0) {
        switch (action) {
          case 'view':
            this.transitionTo('travelrequests.travelrequest', itemId);
            break;

          default:
            this.transitionTo("travelrequests.travelrequest", itemId);
        }
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "selectAction", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "selectAction"), _class2.prototype)), _class2)) || _class);
  _exports.default = ManageRoute;
});