define("clientui/models/person", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model", "clientui/models/contact"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model, _contact) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  let Person = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('dateonly'), _dec7 = (0, _model.attr)('boolean'), _dec8 = (0, _model.attr)('string'), (_class = class Person extends _contact.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "title", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "firstName", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "middleName", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "surname", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "position", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "dateOfBirth", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "nameisAsPerPassport", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "displayName", _descriptor8, this);
    }

    get selectDisplayText() {
      return `${this.displayName}`;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "title", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "firstName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "middleName", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "surname", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "position", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dateOfBirth", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "nameisAsPerPassport", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "displayName", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Person;
});