define("clientui/helpers/upload-path", ["exports", "clientui/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.uploadPath = uploadPath;

  function uploadPath(_ref) {
    let [clientId, docId] = _ref;
    let result = null;
    console.log(clientId);
    console.log(docId);

    if (!Ember.isBlank(clientId) && Number(docId) > 0) {
      let host = _environment.default.APP.JBOSS_URL;
      let path = "/v1/clients/" + clientId + "/documents/" + docId;
      result = host + path;
      console.log(result);
    }

    return result;
  }

  var _default = Ember.Helper.helper(uploadPath);

  _exports.default = _default;
});