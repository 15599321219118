define("clientui/pods/components/pages/page-customeraccounts/page-customeraccounts-accounts-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0Zp4O3H7",
    "block": "{\"symbols\":[\"@accounts\",\"@dataNavigationAction\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n    \"],[8,\"ember-model-table/models-table-paginated\",[],[[\"@data\",\"@columns\",\"@dataNavigationAction\",\"@preventDownload\"],[[32,1],[32,0,[\"accountColumns\"]],[32,2],true]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "clientui/pods/components/pages/page-customeraccounts/page-customeraccounts-accounts-list/template.hbs"
  });

  _exports.default = _default;
});