define("clientui/pods/customeraccounts/statements/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Cw5+0J3J",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"Statements\"],null]],[2,\"\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"page-title\",\"-outlet\",\"component\"]}",
    "moduleName": "clientui/pods/customeraccounts/statements/template.hbs"
  });

  _exports.default = _default;
});