define("clientui/pods/components/pages/downloadabledocuments/page-downloadabledocuments-delete/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  let DownloadabledocumentsDelete = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class DownloadabledocumentsDelete extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _initializerDefineProperty2.default)(this, "deletableForAll", _descriptor, this);

      if (this.args.downloadableDocument.recipients.length > 1) {
        this.deletableForAll = true;
      }
    }

    delete() {
      let emberAction = {
        'removeRecipient': "XXX"
      };

      if (this.args.downloadableDocument.get("deleteForAll")) {
        emberAction = {
          'deleteDoc': "XXX"
        };
      }

      this.args.downloadableDocument.set('emberAction', emberAction);
      this.args.downloadableDocument.validate().then(() => {
        if (this.args.downloadableDocument.get("isValid")) {
          let promises = {};
          Ember.RSVP.hash(promises).then(item => {
            Object.keys(item).forEach(key => {
              this.args.downloadableDocument.set(key, item[key]);
            });
            this.args.onDelete(this.args.downloadableDocument).then(() => {
              // Promise
              this.args.onClose();
            }, errors => {
              console.log(errors);
              this.servererrors = errors;
            });
          }, errors => {
            console.log(errors);
            this.servererrors = errors;
          });
        }
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "deletableForAll", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "delete", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype)), _class));
  _exports.default = DownloadabledocumentsDelete;
});