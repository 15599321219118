define("clientui/pods/components/widgets/subscription-card/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "jquery"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;

  let SubscriptionCard = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class SubscriptionCard extends _component.default {
    //calculated element Ids
    get elementId() {
      return Ember.guidFor(this);
    }

    get headingid() {
      return 'heading_' + this.elementId;
    }

    get panelid() {
      return 'panel_' + this.elementId;
    }

    constructor(owner, args) {
      var _this$args$open;

      super(owner, args);
      (0, _initializerDefineProperty2.default)(this, "open_internal", _descriptor, this);
      this.open_internal = (_this$args$open = this.args.open) !== null && _this$args$open !== void 0 ? _this$args$open : false;
    }

    //current state
    listener(e) {
      this.open_internal = e.type === 'show';
    }

    jqElement(e) {
      // eslint-disable-next-line ember/no-jquery
      return (0, _jquery.default)(e);
    }

    registerListener(element) {
      this.jqElement(element).on('show.bs.collapse', this.listener);
      this.jqElement(element).on('hide.bs.collapse', this.listener);

      if (this.args.open) {
        this.jqElement(element).collapse('show');
      }
    }

    unregisterListener(element) {
      this.jqElement(element).off('show.bs.collapse', this.listener);
      this.jqElement(element).off('hide.bs.collapse', this.listener);
    }

    get badgestatus() {
      if (this.args.subscribed === true) {
        return 'Subscribed';
      } else {
        return 'NOT Subscribed';
      }
    }

    get badgeclass() {
      if (this.args.subscribed === true) {
        return 'btn-success';
      } else {
        return 'btn-warning';
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "open_internal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "listener", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "listener"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "registerListener", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "registerListener"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "unregisterListener", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "unregisterListener"), _class.prototype)), _class));
  _exports.default = SubscriptionCard;
});