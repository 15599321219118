define("clientui/pods/components/sections/page-myprofilenew-subscriptions/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PageMyprofilenewSubscriptions extends _component.default {
    get subscriptions() {
      let list = {};
      this.args.client.get('marketingGroups').forEach(element => {
        list[element.get('id')] = true;
      });
      return list;
    }

  }

  _exports.default = PageMyprofilenewSubscriptions;

  window.__CLASSIC_OWN_CLASSES__.set(PageMyprofilenewSubscriptions, true);
});