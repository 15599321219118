define("clientui/pods/components/pages/page-managedprofiles-index/component", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor"], function (_exports, _applyDecoratedDescriptor2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let PageManagedprofilesIndex = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, classic(_class = (_class2 = class PageManagedprofilesIndex extends Ember.Component {
    setAction(item) {
      this.onSelect(item);
    }

    onAddAction() {
      this.onAdd();
    }

    onMyprofile() {
      this.onMyProfile();
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "setAction", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "setAction"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "onAddAction", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "onAddAction"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "onMyprofile", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "onMyprofile"), _class2.prototype)), _class2)) || _class);
  _exports.default = PageManagedprofilesIndex;
});