define("clientui/pods/components/pages/page-manage-requests/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let PageManageRequests = (_dec = Ember.inject.service, _dec2 = Ember.computed('enquirycontainer'), _dec3 = Ember._action, _dec4 = Ember._action, classic(_class = (_class2 = class PageManageRequests extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor, this);
      (0, _defineProperty2.default)(this, "comment", '');
    }

    get leadPax() {
      return this.get('enquirycontainer.quoteClients.firstObject');
    }

    didReceiveAttrs() {
      console.log('****Page Manage Request Did Receive Attrs ****');
      this.store.query('approver', {
        'tcpid': this.get('clientportal.tradeClientProfileId'),
        'newprofile': 'true',
        'pno': -1
      }).then(data => {
        data.forEach(item => {
          if (Number(Math.abs(item.id)) === this.get('enquirycontainer.travelRequests.firstObject.requestedApproverId')) {
            this.set('requestedApprover', item);
          }
        });
      });
    }

    approveRequest() {
      console.log('Travel Request Approved Saving in Component');
      this.onSave(this.enquirycontainer.id, true, this.comment);
    }

    declineRequest() {
      console.log('Travel Request Declined Saving in Component');
      this.onSave(this.enquirycontainer.id, false, this.comment);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "leadPax", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "leadPax"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "approveRequest", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "approveRequest"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "declineRequest", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "declineRequest"), _class2.prototype)), _class2)) || _class);
  _exports.default = PageManageRequests;
});