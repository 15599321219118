define("clientui/pods/components/ember-model-table/page-select/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  /**
   * Select-dropdown with a list of options. Used as page-size select and for select-filters.
   *
   * @class ModelsTableSelect
   * @namespace Components
   * @extends Ember.Component
   */
  let SelectComponent = (_dec = (0, _component.tagName)('select'), _dec2 = Ember.computed.empty('options'), _dec3 = Ember.computed.alias('themeInstance.input'), _dec4 = Ember.computed.alias('themeInstance.select'), _dec(_class = (_class2 = class SelectComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "disabled", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "themeInputClass", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "themeSelectClass", _descriptor3, this);
      (0, _defineProperty2.default)(this, "type", '');
      (0, _initializerDefineProperty2.default)(this, "cssPropertyName", _descriptor4, this);
      (0, _defineProperty2.default)(this, "themeInstance", null);
    }

    change(e) {
      if (e) {
        e.stopPropagation();
      }

      let val = this.element.querySelector('option:checked').value;

      if (this.type === 'number') {
        val = +val;
      }

      Ember.set(this, 'value', val);

      if (typeof this.onchange !== 'undefined') {
        this.onchange(val);
      }

      return false;
    }

    click(e) {
      if (e) {
        e.stopPropagation();
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "disabled", [_component.attribute, _dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "themeInputClass", [_component.className, _dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "themeSelectClass", [_component.className, _dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "cssPropertyName", [_component.className], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  })), _class2)) || _class);
  _exports.default = SelectComponent;
});