define("clientui/models/clientprofilepermission", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let Clientprofilepermission = (_dec = (0, _model.attr)('boolean'), _dec2 = (0, _model.attr)('number'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('number'), _dec6 = (0, _model.attr)('number'), _dec7 = (0, _model.attr)('datetime'), _dec8 = (0, _model.attr)('datetime'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = Ember.computed('clientDisplayName'), _dec15 = Ember.computed('clientDisplayName', 'tradeClientName'), _dec16 = Ember.computed('clientDisplayName', 'position'), classic(_class = (_class2 = class Clientprofilepermission extends _model.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "active", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "clientId", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "clientCuiId", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "clientDisplayName", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "clientTradeClientId", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "clientDefaultAccountId", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "grantDateTime", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "revokeDateTime", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "grantor", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "revoker", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "emailAddress", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "tradeClientName", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "position", _descriptor13, this);
    }

    get selectDisplayText() {
      return `${this.clientDisplayName}`;
    }

    get selectDisplayTextLong() {
      let result = `${this.clientDisplayName}`;

      if (!Ember.isBlank(this.tradeClientName)) {
        result += " (" + this.tradeClientName + ")";
      }

      console.log(result);
      return result;
    }

    get positionSelectDisplayText() {
      let result = `${this.clientDisplayName}`;

      if (!Ember.isBlank(this.position)) {
        result += " (" + this.position + ")";
      }

      console.log(result);
      return result;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "active", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "clientId", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "clientCuiId", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "clientDisplayName", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "clientTradeClientId", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "clientDefaultAccountId", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "grantDateTime", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "revokeDateTime", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "grantor", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "revoker", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "emailAddress", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "tradeClientName", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "position", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "selectDisplayText", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "selectDisplayText"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "selectDisplayTextLong", [_dec15], Object.getOwnPropertyDescriptor(_class2.prototype, "selectDisplayTextLong"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "positionSelectDisplayText", [_dec16], Object.getOwnPropertyDescriptor(_class2.prototype, "positionSelectDisplayText"), _class2.prototype)), _class2)) || _class);
  _exports.default = Clientprofilepermission;
});