define("clientui/pods/components/widgets/address-line-item-value/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let AddressLineItemValue = (_dec = (0, _component.tagName)(''), classic(_class = _dec(_class = class AddressLineItemValue extends Ember.Component {}) || _class) || _class);
  _exports.default = AddressLineItemValue;
});