define("clientui/pods/components/layout/page-logos/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pOc+O8+d",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,2]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,1,\"logos\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"inner\"],[12],[2,\"\\n        \"],[10,\"img\"],[15,\"src\",[34,0]],[14,\"alt\",\"Bottom Banner\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"bottomBannerImageUrl\",\"if\",\"isTopWindow\"]}",
    "moduleName": "clientui/pods/components/layout/page-logos/template.hbs"
  });

  _exports.default = _default;
});