define("clientui/models/profiledocument", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model", "moment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let Profiledocument = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.belongsTo)('country', {
    inverse: null
  }), _dec5 = (0, _model.attr)('dateonly'), _dec6 = (0, _model.attr)('dateonly'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.belongsTo)('country', {
    inverse: null
  }), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('boolean'), _dec16 = Ember.computed('documentType', 'referenceNumber'), _dec17 = Ember.computed('description', 'documentType', 'referenceNumber', 'country'), _dec18 = Ember.computed('altTitle', 'altFirstName', 'altMiddleName', 'altSurname'), _dec19 = Ember.computed('referenceNumber', 'country', 'issueDate', 'expiryDate', 'altDisplayName'), classic(_class = (_class2 = class Profiledocument extends _model.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "documentType", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "description", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "referenceNumber", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "country", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "issueDate", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "expiryDate", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "altTitle", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "altFirstName", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "altMiddleName", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "altSurname", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "citizenship", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "carMake", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "carModel", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "carColour", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "fileUploaded", _descriptor15, this);
    }

    get selectDisplayText() {
      return `${this.documentType} ${this.referenceNumber}`;
    }

    get displayDescription() {
      let result = this.description;

      if (!result) {
        if (this.documentType === 'CARREG') {
          result = this.referenceNumber;
        } else {
          result = this.get('country.selectDisplayText');
        }
      }

      return result;
    }

    get altDisplayName() {
      let s = this.altTitle;

      if (this.altFirstName) {
        if (s) {
          s += " ";
        }

        s += this.altFirstName;
      }

      if (this.altMiddleName) {
        if (s) {
          s += " ";
        }

        s += this.altMiddleName;
      }

      if (this.altSurname) {
        if (s) {
          s += " ";
        }

        s += this.altSurname;
      }

      return s;
    }

    get displayText() {
      let s = this.referenceNumber;

      if (this.get('country.id')) {
        s += "(" + this.country.get('name') + ")";
      }

      if (this.issueDate) {
        s += " Issued: " + (0, _moment.default)(this.issueDate).format("DD-MMM-YYYY");
      }

      if (this.expiryDate) {
        s += " Expires: " + (0, _moment.default)(this.expiryDate).format("DD-MMM-YYYY");
      }

      if (this.altDisplayName) {
        s += " AltName: " + this.altDisplayName;
      }

      return s;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "documentType", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "description", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "referenceNumber", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "country", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "issueDate", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "expiryDate", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "altTitle", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "altFirstName", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "altMiddleName", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "altSurname", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "citizenship", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "carMake", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "carModel", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "carColour", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "fileUploaded", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "selectDisplayText", [_dec16], Object.getOwnPropertyDescriptor(_class2.prototype, "selectDisplayText"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "displayDescription", [_dec17], Object.getOwnPropertyDescriptor(_class2.prototype, "displayDescription"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "altDisplayName", [_dec18], Object.getOwnPropertyDescriptor(_class2.prototype, "altDisplayName"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "displayText", [_dec19], Object.getOwnPropertyDescriptor(_class2.prototype, "displayText"), _class2.prototype)), _class2)) || _class);
  _exports.default = Profiledocument;
});