define("clientui/pods/coordinator/route", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "clientui/superclasses/search-route"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _searchRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  let CoordinatorRoute = (_dec = Ember._action, _dec2 = Ember._action, (_class = class CoordinatorRoute extends _searchRoute.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "queryParams", {
        accountid: {
          refreshModel: true
        },
        tradeclientid: {
          refreshModel: true
        },
        clientroletype: {
          refreshModel: true
        },
        searchfield: {
          refreshModel: true
        },
        pageno: {
          refreshModel: false
        },
        psz: {
          refreshModel: false
        },
        sort: {
          refreshModel: false
        },
        sortdir: {
          refreshModel: false
        }
      });
    }

    model(params) {
      if (this.controller) {
        this.controller.set('noneFound', false);
      }

      let promises = {};
      let clientPortal = this.modelFor('application');
      promises['application'] = clientPortal;
      let session = this.session;

      if (session.get('authenticated') === true) {
        let guid = session.get('subject'); //Use query instead of find so that we can pass through the tcpid and so verify that the client is logging in to the correct site.
        //The server will return a redirect error if the two parameters do not match up.

        promises['client'] = this.store.query('client', {
          'guid': guid,
          'tcpid': clientPortal.get('tradeClientProfileId'),
          'pno': -1
        }).then(clients => {
          return clients.get('firstObject');
        }, errors => {
          console.log(errors);
          throw errors;
        });
      } //load lists for search form


      promises['tradeclients'] = this.system.queryAll('tradeclient');
      promises['accounts'] = this.system.queryAll('account');
      promises['clientroletypes'] = this.system.enum('clientroletype');
      promises['clientroles'] = this.search('clientrole', params);
      return Ember.RSVP.hash(promises);
    }

    setupController(controller, promises) {
      let client = promises['client'];

      if (client) {
        controller.set('client', client);
      }

      let clientportal = promises['application']; // console.log(clientportal);

      controller.set('clientportal', clientportal);
      controller.set('tradeclients', promises['tradeclients']);
      controller.set('accounts', promises['accounts']);
      controller.set('clientroletypes', promises['clientroletypes']);
      controller.set('clientroles', promises['clientroles']);

      if (promises['clientroles'].get('length') === 0 && this.filterValid === true) {
        controller.set('noneFound', true);
      }
    }

    formSubmit(formParams) {
      this.tidyFormParams(formParams);
      this.transitionTo('coordinator', {
        queryParams: formParams
      });
    }

    editClientRole(id) {
      this.transitionTo('coordinator.edit', id);
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "formSubmit", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "formSubmit"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "editClientRole", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "editClientRole"), _class.prototype)), _class));
  _exports.default = CoordinatorRoute;
});