define("clientui/pods/components/sections/page-myprofilenew-subscriptions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BQ/vD3Gs",
    "block": "{\"symbols\":[\"mg\",\"@inverse\",\"@clientportal\"],\"statements\":[[10,\"div\"],[14,1,\"marketinggroup-accordion\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,3,[\"offeredMarketingGroups\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"widgets/subscription-card\",[],[[\"@marketinggroup\",\"@subscribed\",\"@dataparent\",\"@inverse\"],[[32,1],[30,[36,0],[[32,0,[\"subscriptions\"]],[32,1,[\"id\"]]],null],\"marketinggroup-accordion\",[32,2]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"get\",\"-track-array\",\"each\"]}",
    "moduleName": "clientui/pods/components/sections/page-myprofilenew-subscriptions/template.hbs"
  });

  _exports.default = _default;
});