define("clientui/models/client", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model", "clientui/models/modelwithdependentrelationships"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model, _modelwithdependentrelationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34;

  let Client = (_dec = (0, _model.attr)('number'), _dec2 = (0, _model.attr)('datetime'), _dec3 = (0, _model.attr)('datetime'), _dec4 = (0, _model.attr)('number'), _dec5 = (0, _model.attr)('dateonly'), _dec6 = (0, _model.hasMany)('contact-detail'), _dec7 = (0, _model.hasMany)('address'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('boolean'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('string'), _dec16 = (0, _model.attr)('string'), _dec17 = (0, _model.belongsTo)('gender'), _dec18 = (0, _model.belongsTo)('genderidentity'), _dec19 = (0, _model.attr)('dateonly'), _dec20 = (0, _model.attr)('boolean'), _dec21 = (0, _model.attr)('string'), _dec22 = (0, _model.hasMany)('noteitem'), _dec23 = (0, _model.attr)('string'), _dec24 = (0, _model.attr)('string'), _dec25 = (0, _model.belongsTo)('tradeclient', {
    inverse: null
  }), _dec26 = (0, _model.attr)('boolean'), _dec27 = (0, _model.attr)('boolean'), _dec28 = (0, _model.belongsTo)('clientprofile'), _dec29 = (0, _model.hasMany)('marketinggroup'), _dec30 = (0, _model.attr)('string', {
    defaultValue: 'CL'
  }), _dec31 = (0, _model.attr)('number'), _dec32 = (0, _model.attr)('boolean'), _dec33 = (0, _model.attr)('boolean'), _dec34 = (0, _model.attr)(), (_class = class Client extends _modelwithdependentrelationships.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "dbId", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "createdOn", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "updatedOn", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "version", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "deleteAfter", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "contactDetails", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "addresses", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "mainPhone", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "mainEmail", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "mainMobile", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "closed", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "title", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "firstName", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "middleName", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "surname", _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, "position", _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, "gender", _descriptor17, this);
      (0, _initializerDefineProperty2.default)(this, "genderIdentity", _descriptor18, this);
      (0, _initializerDefineProperty2.default)(this, "dateOfBirth", _descriptor19, this);
      (0, _initializerDefineProperty2.default)(this, "nameisAsPerPassport", _descriptor20, this);
      (0, _initializerDefineProperty2.default)(this, "displayName", _descriptor21, this);
      (0, _initializerDefineProperty2.default)(this, "notes", _descriptor22, this);
      (0, _initializerDefineProperty2.default)(this, "paxType", _descriptor23, this);
      (0, _initializerDefineProperty2.default)(this, "paxTypeLabel", _descriptor24, this);
      (0, _initializerDefineProperty2.default)(this, "owner", _descriptor25, this);
      (0, _initializerDefineProperty2.default)(this, "fromPnr", _descriptor26, this);
      (0, _initializerDefineProperty2.default)(this, "allowMarketingContact", _descriptor27, this);
      (0, _initializerDefineProperty2.default)(this, "clientProfile", _descriptor28, this);
      (0, _initializerDefineProperty2.default)(this, "marketingGroups", _descriptor29, this);
      (0, _initializerDefineProperty2.default)(this, "contactType", _descriptor30, this);
      (0, _initializerDefineProperty2.default)(this, "misEid", _descriptor31, this);
      (0, _initializerDefineProperty2.default)(this, "requiresArranger", _descriptor32, this);
      (0, _initializerDefineProperty2.default)(this, "requiresApprover", _descriptor33, this);
      (0, _initializerDefineProperty2.default)(this, "emberAction", _descriptor34, this);
    }

    //used to send paired reversed/reversing invoice
    //Computed Fields
    get selectDisplayText() {
      return `${this.firstName} ${this.surname}`;
    }

    get missingObtFields() {
      let missingFields = [];
      let objectFields = ['mainEmail', 'title', 'firstName', 'surname', 'gender', 'clientProfile.defaultAccount.name', 'clientProfile.nationality.name'];
      let fieldNames = ['Email Address', 'Title', 'First Name', 'Surname', 'Gender', 'Default Account', 'Nationality', 'At least one Approver'];

      if (this.requiresApprover === true) {
        objectFields.push('clientProfile.approvers');
      }

      for (let i = 0; i < objectFields.length; i++) {
        if (Ember.isBlank(Ember.get(this, objectFields[i]))) {
          missingFields.push(fieldNames[i]);
        }
      }

      return missingFields;
    }

    get hasMissingObtFields() {
      return this.missingObtFields.length > 0;
    }

    get missingObtFieldsForm() {
      //Show all missing fields calculated above, but also add in Mobile Number, and DOB if they are not set
      //The CBT can wok without these, so we don't want their absence to trigger a popul form, but if one is being triggered
      //then we will show these additional fields also, if they are missing in the profile
      let missingFields = this.missingObtFields;

      if (Ember.isBlank(this.mainMobile)) {
        missingFields.push('Mobile Phone Number');
      }

      if (Ember.isBlank(this.dateOfBirth)) {
        missingFields.push('Date of Birth');
      }

      return missingFields;
    }

    get missingTravelRequestFields() {
      let missingFields = [];
      let objectFields = ['mainEmail', 'mainMobile', 'title', 'firstName', 'surname', 'gender', 'dateOfBirth', 'clientProfile.defaultAccount.name', 'clientProfile.nationality.name', 'clientProfile.passports.firstObject.country.name', 'clientProfile.passports.firstObject.referenceNumber', 'clientProfile.passports.firstObject.issueDate', 'clientProfile.passports.firstObject.expiryDate'];
      let fieldNames = ['Email Address', 'Mobile Phone Number', 'Title', 'First Name', 'Surname', 'Gender', 'Date of Birth', 'Default Account', 'Nationality', 'Passport Country Of Issue', 'Passport Number', 'Passport Date Of Issue', 'Passport Date Of Expiry'];

      if (this.requiresApprover === true) {
        objectFields.push('clientProfile.approvers');
      }

      for (let i = 0; i < objectFields.length; i++) {
        if (Ember.isBlank(Ember.get(this, objectFields[i]))) {
          missingFields.push(fieldNames[i]);
        }
      }

      return missingFields;
    }

    get hasMissingTravelRequestFields() {
      return this.missingTravelRequestFields.length > 0;
    }

    get missingEvolviSsoFields() {
      let missingFields = [];
      let objectFields = ['mainEmail', 'title', 'firstName', 'surname', 'clientProfile.defaultAccount.name'];
      let fieldNames = ['Email Address', 'Title', 'First Name', 'Surname', 'Default Account'];

      for (let i = 0; i < objectFields.length; i++) {
        if (Ember.isBlank(Ember.get(this, objectFields[i]))) {
          missingFields.push(fieldNames[i]);
        }
      }

      return missingFields;
    }

    get hasMissingEvolviSsoFields() {
      return this.missingEvolviSsoFields.length > 0;
    }

    get hasCustomerAccounts() {
      return this.clientProfile && this.clientProfile.get('accountsRole');
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dbId", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "createdOn", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updatedOn", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "version", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "deleteAfter", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "contactDetails", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "addresses", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "mainPhone", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "mainEmail", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "mainMobile", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "closed", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "title", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "firstName", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "middleName", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "surname", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "position", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "gender", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "genderIdentity", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dateOfBirth", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "nameisAsPerPassport", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "displayName", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "notes", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "paxType", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "paxTypeLabel", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "owner", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fromPnr", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "allowMarketingContact", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "clientProfile", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "marketingGroups", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "contactType", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "misEid", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "requiresArranger", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "requiresApprover", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "emberAction", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Client;
});