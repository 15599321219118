define("clientui/pods/components/dialogs/form-profile-edit-obt/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let FormProfileEditObt = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('system'), _dec3 = Ember.computed('client.corporateTradeClientSelection', 'accounts.[]'), _dec4 = Ember.computed('visibleAccounts.[]'), _dec5 = Ember.computed('tradeclients.[]'), _dec6 = Ember.computed.sort('countries', 'dialcodeSortDefinition'), _dec7 = Ember.computed('clientportal.homeCountryCode'), _dec8 = Ember.computed.sort('countries', 'countrySortDefinition'), _dec9 = Ember.computed('clientportal.homeCountryCode'), _dec10 = Ember.computed('clientportal.tradeClientCaption'), _dec11 = Ember.computed('clientportal.accountCaption'), _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, classic(_class = (_class2 = class FormProfileEditObt extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
      (0, _defineProperty2.default)(this, "needsTitle", false);
      (0, _defineProperty2.default)(this, "needsFirstName", false);
      (0, _defineProperty2.default)(this, "needsSurname", false);
      (0, _defineProperty2.default)(this, "needsEmail", false);
      (0, _defineProperty2.default)(this, "needsMobile", false);
      (0, _defineProperty2.default)(this, "needsGender", false);
      (0, _defineProperty2.default)(this, "needsDateOfBirth", false);
      (0, _defineProperty2.default)(this, "needsDefaultAccount", false);
      (0, _defineProperty2.default)(this, "needsApprover", false);
      (0, _initializerDefineProperty2.default)(this, "sortedDialCodes", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "sortedCountries", _descriptor4, this);
    }

    init() {
      super.init(...arguments);
    }

    didReceiveAttrs() {
      let client = this.client;
      this.missingFields.forEach(field => {
        switch (field) {
          case 'Email Address':
            this.needsEmail = true;

            if (Ember.isPresent(this.get('client.mainEmail'))) {
              client.set('x-email1-display', client.get('mainEmail'));
            }

            break;

          case 'Mobile Phone Number':
            this.needsMobile = true;
            this.set('countries', this.system.queryAll('country'));

            if (Ember.isPresent(this.get('client.mainMobile'))) {
              client.set('businessMobile', client.get('mainMobile'));
            }

            break;

          case 'Title':
            this.needsTitle = true;
            this.set('titles', this.store.query('title', {
              'newprofile': 'true',
              'pno': -1
            }));
            break;

          case 'First Name':
            this.needsFirstName = true;
            break;

          case 'Surname':
            this.needsSurname = true;
            break;

          case 'Gender':
            this.needsGender = true;
            this.set('genders', this.store.query('gender', {
              'newprofile': 'true',
              'pno': -1
            }));

            if (Ember.isPresent(this.get('client.gender.id'))) {
              this.client.set('genderSelection', this.get('client.gender.id'));
            }

            break;

          case 'Date of Birth':
            this.needsDateOfBirth = true;
            break;

          case 'Default Account':
            this.needsDefaultAccount = true;
            this.set('tradeclients', this.system.queryAll('tradeclient'));
            this.set('accounts', this.system.queryAll('account'));

            if (Ember.isPresent(this.get('client.owner.id'))) {
              this.client.set('corporateTradeClientSelection', this.get('client.owner.id'));
            }

            if (Ember.isPresent(this.get('client.clientProfile.defaultAccount.id'))) {
              console.log("Setting defaultAccountSelection to " + this.get('client.clientProfile.defaultAccount.id'));
              this.client.set('defaultAccountSelection', this.get('client.clientProfile.defaultAccount.id'));
            }

            break;

          case 'Nationality':
            this.needsNationality = true;

            if (!this.needsMobile) {
              //This may already be loaded by needsMobile
              this.set('countries', this.system.queryAll('country'));
            }

            if (Ember.isPresent(this.get('client.clientProfile.nationality.id'))) {
              this.client.set('nationalitySelection', this.get('client.clientProfile.nationality.id'));
            }

            break;

          case 'At least one Approver':
            this.needsApprover = true;
            this.set('approvers', this.store.query('approver', {
              'tcpid': this.get('clientportal.tradeClientProfileId'),
              'excludesuper': 'true',
              'pno': -1
            }));
            break;

          default:
            break;
        }
      });
      this.set('genderManuallyUpdated', false);
    }

    get visibleAccounts() {
      let ownerId = Number(this.get('client.corporateTradeClientSelection'));

      if (ownerId > 0) {
        //console.log("ownerId " + ownerId + " " + (typeof ownerId));
        //console.log(this.get('accounts'));
        let list = this.accounts.filter(item => item.get('accountContactId') === ownerId); //console.log("Original List" + this.get('accounts').length);
        //console.log("Filtered List" + list.length);

        return list;
      } else {
        return this.accounts;
      }
    }

    get onlyOneAccount() {
      let result = false;

      if (this.visibleAccounts) {
        result = this.visibleAccounts.length == 1;

        if (result) {
          Ember.run.next(this, function () {
            let account = this.visibleAccounts.objectAt(0);
            this.client.set('defaultAccountCaption', account.get('selectDisplayText'));
            this.client.set('defaultAccountSelection', account.get('id'));
          });
        } else {
          Ember.run.next(this, function () {
            this.client.set('defaultAccountCaption', null);
          });
        }
      }

      return result;
    }

    get onlyOneTradeClient() {
      let result = false;

      if (this.tradeclients) {
        result = this.tradeclients.length == 1;

        if (result) {
          Ember.run.next(this, function () {
            let tradeClient = this.tradeclients.objectAt(0);
            this.client.set('corporateTradeClientCaption', tradeClient.get('selectDisplayText'));
            this.client.set('corporateTradeClientSelection', tradeClient.get('id'));
          });
        } else {
          Ember.run.next(this, function () {
            this.client.set('corporateTradeClientCaption', null);
          });
        }
      }

      return result;
    }

    get dialcodeSortDefinition() {
      return ['priority' + this.get('clientportal.homeCountryCode'), 'dialCode'];
    }

    get countrySortDefinition() {
      return ['priority' + this.get('clientportal.homeCountryCode'), 'name'];
    }

    get tradeClientCaption() {
      let result = this.get('clientportal.tradeClientCaption');

      if (Ember.isBlank(result)) {
        result = "Trade Client";
      }

      return result;
    }

    get accountCaption() {
      let result = this.get('clientportal.accountCaption');

      if (Ember.isBlank(result)) {
        result = "Account";
      }

      return result;
    }

    save() {
      this.system.set('saving', true);
      let client = this.client;
      client.validate().then(() => {
        if (client.get("isValid")) {
          let fieldName = null;
          let selectionId = 0;
          let model = null;
          let promises = {};

          if (this.needsGender) {
            if (Ember.isPresent(this.get('client.genderSelection'))) {
              fieldName = 'gender';
              model = 'gender';
              selectionId = this.get('client.genderSelection');
              promises[fieldName] = this.store.peekRecord(model, selectionId);
            }
          }

          if (this.needsNationality) {
            if (Ember.isPresent(this.get('client.nationalitySelection'))) {
              fieldName = 'clientProfile.nationality';
              model = 'country';
              selectionId = this.get('client.nationalitySelection');
              promises[fieldName] = this.store.peekRecord(model, selectionId);
            }
          }

          if (this.needsMobile) {
            if (client.get('businessMobile')) {
              let cd = this.store.createRecord('contact-detail');
              cd.set('contactDetailType', 'MOBILE');
              cd.set('description', 'Business');
              cd.set('value', client.get('businessMobile'));
              cd.set('defaultContactDetail', true);
              client.get('contactDetails').pushObject(cd);
            }
          }

          if (this.needsEmail) {
            if (client.get('x-email1-display')) {
              let cd = this.store.createRecord('contact-detail');
              cd.set('contactDetailType', 'EMAIL');
              cd.set('description', 'Primary');
              cd.set('value', client.get('x-email1-display'));
              cd.set('defaultContactDetail', true);
              client.get('contactDetails').pushObject(cd);
            }
          }

          if (this.needsDefaultAccount) {
            if (Ember.isPresent(this.get('client.corporateTradeClientSelection'))) {
              fieldName = 'owner';
              model = 'tradeclient';
              selectionId = this.get('client.corporateTradeClientSelection');
              promises[fieldName] = this.store.peekRecord(model, selectionId);
            }

            if (Ember.isPresent(this.get('client.defaultAccountSelection'))) {
              fieldName = 'clientProfile.defaultAccount';
              model = 'account';
              selectionId = this.get('client.defaultAccountSelection');
              promises[fieldName] = this.store.peekRecord(model, selectionId);
            }
          }

          if (this.needsApprover) {
            let approvers = [];

            if (client.get('approver1')) {
              let permission = this.store.createRecord('clientprofilepermission');
              permission.set('clientId', Math.abs(client.get('approver1')));
              approvers.pushObject(permission);
              console.log("Approver1 added");
            }

            if (client.get('approver2')) {
              let permission = this.store.createRecord('clientprofilepermission');
              permission.set('clientId', Math.abs(client.get('approver2')));
              approvers.pushObject(permission);
              console.log("Approver2 added");
            }

            if (client.get('approver3')) {
              let permission = this.store.createRecord('clientprofilepermission');
              permission.set('clientId', Math.abs(client.get('approver3')));
              approvers.pushObject(permission);
              console.log("Approver3 added");
            }

            if (approvers && approvers.length) {
              promises["clientProfile.approvers"] = approvers;
            }
          }

          Ember.RSVP.hash(promises).then(item => {
            Object.keys(item).forEach(key => {
              client.set(key, item[key]);
            });
            this.onSave(client).then(() => {
              // Promise
              console.log('clientPersonal Promise Saved Successfully in Component');
              this.onClose(); //this.onRefreshRoute();

              this.system.set('saving', false);
            }, errors => {
              console.log('clientPersonal Promise Failed to Save in Component');
              console.log(errors);
              this.set('servererrors', errors);
              this.system.set('saving', false);
            });
          }, errors => {
            console.log('clientPersonal Promise Failed to Save in Component - unable to find one of gender/paxType');
            console.log(errors);
            this.set('servererrors', errors);
            this.system.set('saving', false);
          });
        } else {
          this.system.set('saving', false);
        }
      }, () => {
        this.system.set('saving', false);
      });
    }

    back() {
      //Rollback any changes
      this.client.rollback();

      this.client._content._internalModel._record.rollbackAttributes(); //Close dialog


      this.onClose();
      this.onRollback();
    }

    updateTitle(newTitle) {
      if (newTitle) {
        if (this.needsGender) {
          if (newTitle.get('defaultGenderCode')) {
            if (!this.client.get('genderSelection') || this.genderManuallyUpdated === false) {
              console.log("Setting genderSelection to " + newTitle.get('defaultGenderCode'));
              this.client.set('genderSelection', newTitle.get('defaultGenderCode'));
            }
          }
        }
      }
    }

    updateGender() {
      //console.log("genderManuallyUpdated");
      this.set('genderManuallyUpdated', true);
    } // updatePaxType() {
    //   //console.log("paxTypeManuallyUpdated");
    //   this.set('paxTypeManuallyUpdated', true);
    //   this.client.validate('dateOfBirth');
    // },
    //If Account is manually set then set tradeClient to match


    updateAccount(newAccount) {
      if (newAccount) {
        if (newAccount.get('accountContactId') > 0) {
          //console.log("setting corporateTradeClientSelection to " + newAccount.get('accountContactId'));
          this.client.set('corporateTradeClientSelection', newAccount.get('accountContactId')); //this.filterVisibleAccounts(newAccount.get('accountContactId'));
        } else {
          //console.log("setting corporateTradeClientSelection to Null");
          this.client.set('corporateTradeClientSelection', null); //this.filterVisibleAccounts(null); //show all
        }
      }
    } //If tradeClient is manually set then limit accounts list to accounts within that trade client


    updateOwner(newOwner) {
      if (newOwner) {
        let currentAccountId = this.get('client.defaultAccountSelection');
        let keepCurrent = false;

        if (currentAccountId) {
          let currentAccount = this.store.peekRecord('account', currentAccountId);

          if (currentAccount) {
            if (currentAccount.get('accountContactId')) {
              if (currentAccount.get('accountContactId') === newOwner.get('id')) {
                keepCurrent = true; //console.log("current account is valid = keep it");
              }
            }
          }
        }

        if (!keepCurrent) {
          //console.log("resettng defaultAccountSelection")
          this.client.set('defaultAccountSelection', '');
        }

        if (newOwner.get('customCaption1')) {
          //console.log("found TC Custom Field " + newOwner.get('customCaption1'));
          this.client.set('customRegex6', newOwner.get('customRegex1'));
        }

        if (newOwner.get('customCaption2')) {
          //console.log("found TC Custom Field " + newOwner.get('customCaption2'));
          this.client.set('customRegex7', newOwner.get('customRegex2'));
        }
      } else {
        this.set('client.corporateTradeClientSelection', 0);
      }

      this.set('currentOwner', newOwner);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "visibleAccounts", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "visibleAccounts"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "onlyOneAccount", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "onlyOneAccount"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "onlyOneTradeClient", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "onlyOneTradeClient"), _class2.prototype), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sortedDialCodes", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "dialcodeSortDefinition", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "dialcodeSortDefinition"), _class2.prototype), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sortedCountries", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "countrySortDefinition", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "countrySortDefinition"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "tradeClientCaption", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "tradeClientCaption"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "accountCaption", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "accountCaption"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "save", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "back", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "back"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "updateTitle", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "updateTitle"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "updateGender", [_dec15], Object.getOwnPropertyDescriptor(_class2.prototype, "updateGender"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "updateAccount", [_dec16], Object.getOwnPropertyDescriptor(_class2.prototype, "updateAccount"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "updateOwner", [_dec17], Object.getOwnPropertyDescriptor(_class2.prototype, "updateOwner"), _class2.prototype)), _class2)) || _class);
  _exports.default = FormProfileEditObt;
});