define("clientui/pods/components/widgets/list-item/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@glimmer/component"], function (_exports, _defineProperty2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ListItem extends _component.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "inputId", 'textInput-' + Ember.guidFor(this));
    }

    get labelClass() {
      if (this.args.labelClass) {
        return this.args.labelClass;
      } else {
        return 'list-item-label';
      }
    }

    get col1Width() {
      var _this$args$col1Width;

      return (_this$args$col1Width = this.args.col1Width) !== null && _this$args$col1Width !== void 0 ? _this$args$col1Width : 4; //default value
    }

    get nullvaluelabel() {
      var _this$args$nullvaluel;

      return (_this$args$nullvaluel = this.args.nullvaluelabel) !== null && _this$args$nullvaluel !== void 0 ? _this$args$nullvaluel : 'Unknown'; //default value
    }

    get col1Class() {
      return 'col-' + this.col1Width;
    }

    get col2Class() {
      return 'col-' + (12 - Number(this.col1Width));
    }

  }

  _exports.default = ListItem;

  window.__CLASSIC_OWN_CLASSES__.set(ListItem, true);
});