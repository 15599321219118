define("clientui/pods/components/widgets/open-in-lightbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9LS9YheJ",
    "block": "{\"symbols\":[],\"statements\":[[8,\"light-box\",[],[[\"@href\",\"@data-lightbox\",\"@data-class\"],[[34,0],[34,1],[34,2]]],null]],\"hasEval\":false,\"upvars\":[\"lightboxUrl\",\"lightboxId\",\"lightboxClass\"]}",
    "moduleName": "clientui/pods/components/widgets/open-in-lightbox/template.hbs"
  });

  _exports.default = _default;
});