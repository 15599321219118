define("clientui/pods/components/widgets/subscription-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e7VJsd+z",
    "block": "{\"symbols\":[\"@inverse\",\"@marketinggroup\",\"@dataparent\"],\"statements\":[[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card-header py-0 d-flex justify-content-between align-items-center\"],[15,1,[32,0,[\"headingid\"]]],[12],[2,\"\\n    \"],[10,\"h5\"],[14,0,\"mb-0\"],[12],[2,\"\\n      \"],[10,\"button\"],[15,0,[30,[36,1],[\"btn btn-link \",[30,[36,0],[[32,1],\"text-info\"],null]],null]],[14,\"data-toggle\",\"collapse\"],[15,\"data-target\",[30,[36,1],[\"#\",[32,0,[\"panelid\"]]],null]],[14,\"aria-expanded\",\"false\"],[15,\"aria-controls\",[32,0,[\"panelid\"]]],[14,4,\"button\"],[12],[2,\"\\n        \"],[1,[32,2,[\"title\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"span\"],[15,0,[30,[36,1],[\"badge \",[32,0,[\"badgeclass\"]],\" badge-pill\"],null]],[12],[2,\"\\n      \"],[1,[32,0,[\"badgestatus\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[15,1,[32,0,[\"panelid\"]]],[14,0,\"collapse\"],[15,\"aria-labelledby\",[32,0,[\"headingid\"]]],[15,\"data-parent\",[30,[36,1],[\"#\",[32,3]],null]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n      \"],[1,[32,2,[\"description\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\"]}",
    "moduleName": "clientui/pods/components/widgets/subscription-card/template.hbs"
  });

  _exports.default = _default;
});