define("clientui/pods/components/widgets/server-errors/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I+jTSkCJ",
    "block": "{\"symbols\":[\"err\"],\"statements\":[[6,[37,5],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"validation\"],[12],[2,\"\\n\"],[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"      Something went wrong on the server-side of this transaction. Please click Cancel, refresh the page and try again.\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[10,\"ul\"],[14,0,\"list-group\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2,[\"errors\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"li\"],[14,0,\"list-group-item\"],[12],[2,\"\\n          \"],[1,[32,1,[\"title\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"hideHeader\",\"unless\",\"servererrors\",\"-track-array\",\"each\",\"if\"]}",
    "moduleName": "clientui/pods/components/widgets/server-errors/template.hbs"
  });

  _exports.default = _default;
});