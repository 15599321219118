define("clientui/pods/components/pages/downloadabledocuments/page-downloadabledocuments-searchlist/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4IwIDozM",
    "block": "{\"symbols\":[\"@downloadabledocuments\",\"@dataNavigationAction\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"card-header\"],[12],[2,\"\\n        Documents\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n        \"],[8,\"ember-model-table/models-table-searchresults\",[],[[\"@data\",\"@columns\",\"@selectAction\",\"@dataNavigationAction\"],[[32,1],[32,0,[\"columns\"]],[32,0,[\"selectAction\"]],[32,2]]],null],[2,\"\\n     \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "clientui/pods/components/pages/downloadabledocuments/page-downloadabledocuments-searchlist/template.hbs"
  });

  _exports.default = _default;
});