define("clientui/validators/dateofbirth-v-paxtype", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateDateofbirthVPaxtype;

  function validateDateofbirthVPaxtype() {
    let paxTypeKey = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return (key, newValue, oldValue, changes, content) => {
      let paxType = Ember.get(changes, paxTypeKey) || Ember.get(content, paxTypeKey);

      if (paxType && newValue) {
        console.log(paxType);
        console.log(newValue);
        let dateOfBirth = (0, _moment.default)(newValue);
        console.log(dateOfBirth);
        let age = (0, _moment.default)().diff(dateOfBirth, 'years');
        console.log(age);

        if (dateOfBirth.isValid()) {
          if (age >= 12 && paxType === 'ADT' || age >= 2 && age < 12 && paxType === 'CHD' || age < 2 && paxType === 'INF') {
            return true;
          } else {
            return 'Date of Birth is inconsistent with Passenger Type selection. Current age is ' + age;
          }
        } else {
          return "Date of Birth is not a valid date";
        }
      } else {
        return true;
      }
    };
  }
});