define("clientui/pods/customeraccounts/receipts/search/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "clientui/superclasses/search-route"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _searchRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  let _SearchRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('system'), _dec3 = Ember._action, _dec4 = Ember._action, (_class = class _SearchRoute extends _searchRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "fetch", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
      (0, _defineProperty2.default)(this, "queryParams", {
        id: {
          refreshModel: true
        },
        accountid: {
          refreshModel: true
        },
        datefrom: {
          refreshModel: true
        },
        dateto: {
          refreshModel: true
        },
        amountfrom: {
          refreshModel: true
        },
        amountto: {
          refreshModel: true
        },
        receipttype: {
          refreshModel: true
        },
        pageno: {
          refreshModel: false
        },
        psz: {
          refreshModel: false
        },
        sort: {
          refreshModel: false
        },
        sortdir: {
          refreshModel: false
        }
      });
    }

    model(params
    /*, transition*/
    ) {
      if (this.controller) {
        this.controller.set('noneFound', false);
      }

      let promises = {};
      promises['accounts'] = this.store.query('account', {
        accountsscope: true,
        pno: -1
      });
      promises['receipts'] = this.search('receipt', params);
      return Ember.RSVP.hash(promises);
    }

    setupController(controller, promises) {
      let model = promises['receipts'];

      if (model.get('length') === 0 && this.filterValid === true) {
        controller.set('noneFound', true);
      }

      controller.set('accounts', promises['accounts']);
      controller.set('receipts', model);
      controller.set('receiptTypes', this.system.enum('receipttype'));
    }

    formSubmit(formParams) {
      this.tidyFormParams(formParams);
      this.transitionTo('customeraccounts.receipts.search', {
        queryParams: formParams
      });
    }

    tidyFormParams(params) {
      for (var p in params) {
        if (Object.prototype.hasOwnProperty.call(params, p)) {
          if (Ember.isBlank(params[p])) {
            params[p] = null;
          }
        }
      }

      if (this.queryParams['pageno']) {
        //If using pageno
        params['pageno'] = 1; //reset page number to 1 for new search
      }

      if (this.queryParams['pno']) {
        //If using pbo
        params['pno'] = 0; //reset page number to 0 for new search
      }

      if (params['showAll']) {
        params['showAll'] = null;
      }
    }

    clearSelectedItems() {
      this.controller.set('selectedItems', Ember.A([]));
    }

    fetchAccounts() {
      return this.fetch.fetch('get', 'cors', 'accounts/' + "?accountsscope=true").then(response => {
        if (response.ok) {
          return response.json().then(accounts => {
            return accounts.pageablelist;
          });
        } else {
          return null;
        }
      }, errors => {
        throw errors;
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "formSubmit", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "formSubmit"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "clearSelectedItems", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "clearSelectedItems"), _class.prototype)), _class));

  _exports.default = _SearchRoute;
});