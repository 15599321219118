define("clientui/pods/downloadabledocuments/downloadabledocument/index/route", ["exports", "clientui/superclasses/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IndexRoute extends _authenticatedRoute.default {
    beforeModel() {
      this.replaceWith('downloadabledocuments.search');
    }

  }

  _exports.default = IndexRoute;

  window.__CLASSIC_OWN_CLASSES__.set(IndexRoute, true);
});