define("clientui/pods/downloadabledocuments/downloadabledocument/delete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BMtLw/6+",
    "block": "{\"symbols\":[\"closeDialog\"],\"statements\":[[8,\"widgets/dialog-box-route\",[],[[\"@title\",\"@historyBack\"],[\"Delete Document\",false]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"pages/downloadabledocuments/page-downloadabledocuments-delete\",[],[[\"@downloadableDocument\",\"@onDelete\",\"@onRollback\",\"@onClose\"],[[30,[36,0],[[32,0,[\"model\"]],[32,0,[\"validation\"]]],null],[30,[36,1],[\"delete\"],null],[30,[36,1],[\"back\"],null],[30,[36,2],[[32,1]],null]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"changeset\",\"route-action\",\"fn\"]}",
    "moduleName": "clientui/pods/downloadabledocuments/downloadabledocument/delete/template.hbs"
  });

  _exports.default = _default;
});