define("clientui/pods/components/widgets/dialog-box-route/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vg+0geTP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"modal-dialog\",[],[[\"@translucentOverlay\",\"@targetAttachment\",\"@containerClass\",\"@overlayClass\",\"@wrapperClass\"],[true,\"none\",\"centered-scrolling-container forcewide\",\"centered-scrolling-overlay\",\"centered-scrolling-wrapper\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n      \"],[10,\"div\"],[15,0,[30,[36,1],[\"card-header text-light \",[35,0]],null]],[12],[2,\"\\n        \"],[10,\"h4\"],[14,0,\"modal-title\"],[12],[2,\"\\n          \"],[1,[34,2]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n      \"],[18,1,[[30,[36,3],[[32,0],\"closeDialog\"],null]]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"headerClass\",\"concat\",\"title\",\"action\",\"isShowingDialog\",\"if\"]}",
    "moduleName": "clientui/pods/components/widgets/dialog-box-route/template.hbs"
  });

  _exports.default = _default;
});