define("clientui/superclasses/search-route", ["exports", "clientui/superclasses/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SearchRoute extends _authenticatedRoute.default {
    //Return list of queryparams which should be ignored when deciding if a search should be performed.
    //This method can be extended as follows:
    //return super(...arguments).concat(['excludewithoutprofile']);
    ignorableParams() {
      return ['pno', 'pageno', 'psz', 'sort', 'sortdir']; //default values.
    } //Return list of params that should be considered when deciding if filter is valid, but should NOT be sent to host


    unSendableParams() {
      return ['showAll']; //default values
    } //This should be called from the model hook:
    //    return this.search('xxmodeltype', params);
    //This method filters blank parameter values from the query and flags whether a valid filter set has been found
    //If the filter set is valid, then the search is performed, otherwise and empty array is returned.
    //If params results in a valid filter set then additionalParams are appended to filter


    search(modelType, params, additionalParams) {
      let filter = {};
      let filterValid = false;
      let ignorableParams = this.ignorableParams();
      let unSendableParams = this.unSendableParams();
      let removableParams = []; // console.log(params);
      // console.log(ignorableParams);

      for (var p in params) {
        // console.log('Param:"' + p + '" "' + params[p] + '"');
        if (Object.prototype.hasOwnProperty.call(params, p)) {
          if (!Ember.isBlank(params[p])) {
            //exclude nulls, undefined and empty strings/arrays. Note Zero is a valid value
            if (!unSendableParams.includes(p)) {
              //filter is valid if any parameter EXCEPT the ignorable ones has a value set
              filter[p] = params[p];
            } //console.log('Model Param:' + p  + params[p]);


            if (!ignorableParams.includes(p)) {
              //filter is valid if any parameter EXCEPT the ignorable ones has a value set
              filterValid = true;
            }
          } else {
            if (params[p]) {
              // console.log("adding " + p + " to removableParams as it is blank but not empty '" + params[p] + "'");
              removableParams.push(p);
            }
          }
        }
      }

      removableParams.forEach(p => params[p] = null);
      this.filterValid = filterValid;

      if (filterValid) {
        //Avoid doing a search-all on entering the page
        if (additionalParams) {
          // console.log("AdditionalParams", additionalParams);
          for (var ap in additionalParams) {
            if (Object.prototype.hasOwnProperty.call(additionalParams, ap)) {
              if (!Ember.isBlank(additionalParams[ap])) {
                //exclude nulls, undefined and empty strings/arrays. Note Zero is a valid value
                filter[ap] = additionalParams[ap]; // console.log("Added additional param " + ap);
              }
            }
          }
        }

        return this.store.query(modelType, filter);
      } else {
        return [];
      }
    }

    tidyFormParams(params) {
      console.log("tidyFormParams before", params);

      for (var p in params) {
        if (Object.prototype.hasOwnProperty.call(params, p)) {
          // console.log('Param:"' + p + '" "' + params[p] + '" isBlank ' + (isBlank(params[p]) ? 'true' : 'false') + ' isEmpty ' + (isEmpty(params[p]) ? 'true' : 'false'));
          if (Ember.isBlank(params[p])) {
            params[p] = null;
          }
        }
      }

      if (params['pageno']) {
        params['pageno'] = 1; //reset page number to 1 for new search
      }

      if (params['pno']) {
        params['pno'] = 0; //reset page number to 0 for new search
      }

      if (params['showAll']) {
        params['showAll'] = null;
      }

      console.log("tidyFormParams after", params);
    }

  }

  _exports.default = SearchRoute;

  window.__CLASSIC_OWN_CLASSES__.set(SearchRoute, true);
});