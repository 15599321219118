define("clientui/pods/customeraccounts/receipts/search/controller", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "moment"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let SearchController = (_dec = Ember.computed('id', 'accountid', 'datefrom', 'dateto', 'amountfrom', 'amountto', 'receipttype', 'trigger'), _dec2 = Ember._action, _dec3 = Ember._action, classic(_class = (_class2 = class SearchController extends Ember.Controller {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "queryParams", ['id', 'accountid', 'datefrom', 'dateto', 'amountfrom', 'amountto', 'receipttype', 'pageno', 'psz', 'sort', 'sortdir']);
      (0, _defineProperty2.default)(this, "id", null);
      (0, _defineProperty2.default)(this, "accountid", null);
      (0, _defineProperty2.default)(this, "datefrom", (0, _moment.default)().subtract(30, 'days').format('YYYY-MM-DD'));
      (0, _defineProperty2.default)(this, "dateto", null);
      (0, _defineProperty2.default)(this, "amountfrom", null);
      (0, _defineProperty2.default)(this, "amountto", null);
      (0, _defineProperty2.default)(this, "receipttype", null);
      (0, _defineProperty2.default)(this, "pageno", 1);
      (0, _defineProperty2.default)(this, "psz", 10);
      (0, _defineProperty2.default)(this, "sort", null);
      (0, _defineProperty2.default)(this, "sortdir", null);
      (0, _defineProperty2.default)(this, "trigger", 0);
      (0, _defineProperty2.default)(this, "noneFound", false);
    }

    get formParams() {
      let f = Ember.Object.create();
      ['id', 'accountid', 'datefrom', 'dateto', 'amountfrom', 'amountto', 'receipttype', 'excludedAccounts'].forEach(s => {
        f.set(s, this.get(s));
      });
      return f;
    }

    clearForm() {
      this.set('id', null);
      this.set('accountid', null);
      this.set('datefrom', null);
      this.set('dateto', null);
      this.set('amountfrom', null);
      this.set('amountto', null);
      this.set('receipttype', null);
      this.incrementProperty('trigger');
    }

    dataNavigation(navData) {
      //Update page level variables to match whatever has happened in the table.
      console.log("Search Controller dataNavigation navData", navData);
      this.set('pageno', navData.currentPageNumber);
      this.set('psz', navData.pageSize);
      this.set('sort', navData.sortField);
      this.set('sortdir', navData.sortDir);
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "formParams", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "formParams"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "clearForm", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "clearForm"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "dataNavigation", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "dataNavigation"), _class2.prototype)), _class2)) || _class);
  _exports.default = SearchController;
});