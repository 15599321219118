define("clientui/pods/components/pages/faq-accordion/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yoMIF+bN",
    "block": "{\"symbols\":[\"@text\",\"@html\",\"@title\"],\"statements\":[[10,\"div\"],[14,0,\"row justify-content-left\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-11\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"card-header text-white bg-primary mb-3\"],[15,\"onclick\",[32,0,[\"expand\"]]],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"nav-item dropdown\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"expanded\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            v -\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            > -\\n\"]],\"parameters\":[]}]]],[2,\"          \"],[1,[32,3]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"],[6,[37,0],[[32,0,[\"expanded\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n\"],[6,[37,0],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"              \"],[2,[32,1]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"              \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "clientui/pods/components/pages/faq-accordion/template.hbs"
  });

  _exports.default = _default;
});