define("clientui/pods/customeraccounts/clientinvoices/search/route", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "clientui/superclasses/search-route"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _searchRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let CustomeraccountsClientinvoicesSearchRoute = (_dec = Ember._action, (_class = class CustomeraccountsClientinvoicesSearchRoute extends _searchRoute.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "queryParams", {
        identifier: {
          refreshModel: true
        },
        accountid: {
          refreshModel: true
        },
        bookingid: {
          refreshModel: true
        },
        datefrom: {
          refreshModel: true
        },
        dateto: {
          refreshModel: true
        },
        hasoutstandingbalance: {
          refreshModel: true
        },
        pageno: {
          refreshModel: false
        },
        psz: {
          refreshModel: false
        },
        sort: {
          refreshModel: false
        },
        sortdir: {
          refreshModel: false
        }
      });
    }

    ignorableParams() {
      return super.ignorableParams(...arguments).concat(['hasoutstandingbalance']);
    }

    model(params
    /*, transition*/
    ) {
      if (this.controller) {
        this.controller.set('noneFound', false);
      }

      let promises = {};
      promises['clientinvoices'] = this.search('clientinvoice', params);
      promises['accounts'] = this.store.query('account', {
        accountsscope: true,
        pno: -1
      });
      return Ember.RSVP.hash(promises);
    }

    setupController(controller, promises) {
      let model = promises['clientinvoices'];

      if (model.get('length') === 0 && this.filterValid === true) {
        controller.set('noneFound', true);
      }

      controller.set('clientinvoices', model);
      controller.set('accounts', promises['accounts']);
    }

    formSubmit(formParams) {
      console.log("route.formSubmit", formParams);
      this.tidyFormParams(formParams);
      this.transitionTo('customeraccounts.clientinvoices.search', {
        queryParams: formParams
      });
    }

    tidyFormParams(params) {
      for (var p in params) {
        if (Object.prototype.hasOwnProperty.call(params, p)) {
          if (Ember.isBlank(params[p])) {
            params[p] = null;
          }
        }
      }

      if (this.queryParams['pageno']) {
        //If using pageno
        params['pageno'] = 1; //reset page number to 1 for new search
      }

      if (this.queryParams['pno']) {
        //If using pbo
        params['pno'] = 0; //reset page number to 0 for new search
      }

      if (params['showAll']) {
        params['showAll'] = null;
      }
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "formSubmit", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "formSubmit"), _class.prototype)), _class));
  _exports.default = CustomeraccountsClientinvoicesSearchRoute;
});