define("clientui/pods/managedprofiles/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pbSmcXON",
    "block": "{\"symbols\":[],\"statements\":[[8,\"pages/page-managedprofiles-index\",[],[[\"@managedclientlist\",\"@onSelect\",\"@onAdd\",\"@onMyProfile\"],[[32,0,[\"managedclientlist\"]],[30,[36,0],[\"selectClient\"],null],[30,[36,0],[\"addClient\"],null],[30,[36,0],[\"myProfile\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"route-action\"]}",
    "moduleName": "clientui/pods/managedprofiles/index/template.hbs"
  });

  _exports.default = _default;
});