define("clientui/pods/components/dialogs/evolvi-sso/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DBvDXUtl",
    "block": "{\"symbols\":[\"&attrs\",\"@ssoUrl\"],\"statements\":[[11,\"div\"],[16,0,[30,[36,0],[\"ld-over-full-inverse \",[32,0,[\"spinClass\"]]],null]],[17,1],[4,[38,1],[[32,0,[\"didInsertComponent\"]],[32,2]],null],[4,[38,2],[[32,0,[\"didUpdateComponent\"]],[32,2]],null],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ld ld-ring ld-spin-fast loadingfont\"],[12],[13],[2,\"\\n  \"],[10,\"p\"],[14,0,\"card-text\"],[12],[2,\"\\n    \"],[1,[32,0,[\"message\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[14,0,\"card-text validation\"],[12],[2,\"\\n    \"],[1,[32,0,[\"errorMessage\"]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"row forcewide mt-3\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n\"],[6,[37,3],[[32,0,[\"buttonText\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"widgets/form-submit-button-edit\",[],[[\"@onSubmit\",\"@inverse\",\"@savebuttonlabel\"],[[32,0,[\"close\"]],false,[32,0,[\"buttonText\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"concat\",\"did-insert\",\"did-update\",\"if\"]}",
    "moduleName": "clientui/pods/components/dialogs/evolvi-sso/template.hbs"
  });

  _exports.default = _default;
});