define("clientui/serializers/clientportal", ["exports", "clientui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      links: {
        serialize: 'id',
        deserialize: 'records'
      },
      offeredMarketingGroups: {
        serialize: 'id',
        deserialize: 'records'
      },
      travelPolicies: {
        embedded: 'always'
      },
      defaultTravelPolicy: {
        serialize: 'id',
        deserialize: 'records'
      }
    }
  });

  _exports.default = _default;
});