define("clientui/mappings/profiledocumenttypes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
  Map BOSS profile document type codes to words
  */
  var _default = {
    'PASSPORT': 'Passport',
    'DRIVLIC': 'Driving Licence',
    'VISA': 'Visa',
    'CARREG': 'Car Registration'
  };
  _exports.default = _default;
});