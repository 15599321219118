define("clientui/pods/cookieconsent/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "clientui/superclasses/unauthenticated-route"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _unauthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  let CookieconsentRoute = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class CookieconsentRoute extends _unauthenticatedRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
    }

    setupController(controller) {
      // console.log("Starting Cookie-Consent route");
      let status = this.system.cookieConsentStatus();
      controller.set('consentStatus', status);
      controller.set('isShowingDialog', true);
    }

    allowall() {
      let status = this.system.allowAllCookies();
      this.controller.set('consentStatus', status);
      this.transitionTo('application');
    }

    savechanges(consentStatus) {
      // console.log("Saving Changes in cookieconsent route", consentStatus);
      let status = this.system.updateCookieConsentStatus(consentStatus);
      this.controller.set('consentStatus', status);
      this.transitionTo('application');
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "allowall", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "allowall"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "savechanges", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "savechanges"), _class.prototype)), _class));
  _exports.default = CookieconsentRoute;
});