define("clientui/pods/customeraccounts/receipts/receipt/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "clientui/superclasses/authenticated-route"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  let ReceiptRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, (_class = class ReceiptRoute extends _authenticatedRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "fetch", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "toast", _descriptor2, this);
    }

    fetchData(receiptId) {
      return this.fetch.fetch('get', 'cors', 'receipts/' + receiptId).then(response => {
        if (response.ok) {
          return response.json().then(receipt => {
            return receipt;
          });
        } else {
          return null;
        }
      }, errors => {
        throw errors;
      });
    }

    model(params) {
      let promises = {};
      promises['receipt'] = this.store.findRecord('receipt', params.receipt_id);
      return Ember.RSVP.hash(promises);
    }

    setupController(controller, _ref) {
      let {
        receipt
      } = _ref;
      controller.set('receipt', receipt);
    }

    downloadReceipt(id) {
      console.log('Downloading Receipt...');
      return this.fetch.fetchAndDownloadFile("receipts/" + id + "/download").then(() => {}, () => {
        this.toast.error('Unable To Download Receipt');
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toast", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "downloadReceipt", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "downloadReceipt"), _class.prototype)), _class));
  _exports.default = ReceiptRoute;
});