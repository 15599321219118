define("clientui/services/emt-helper", ["exports", "clientui/services/emt-helper-base"], function (_exports, _emtHelperBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let EmtHelperService =
  /*
    This version is suitable for use on regular (non-server-paginated) tables
  */
  classic(_class = class EmtHelperService extends _emtHelperBase.default {
    _buildColInternal(propertyName, title, filterPropertyName, className, componentName, defaultOptions, userOptions) {
      let result = super._buildColInternal(propertyName, title, filterPropertyName, className, componentName, defaultOptions, userOptions);

      if (filterPropertyName) {
        result['sortedBy'] = filterPropertyName;
      } //by default sort by filterPropertyName, but allow this to be overrodden in userOptions


      return result;
    }

    buildTotalledMoneyIncZeroCol(moneyPropertyName, title, filterPropertyName, className, componentName, userOptions) {
      let defaultOptions = {
        //Set defaults for money displays
        component: "ember-model-table/cell-money-inc-zero",
        className: "text-right",
        componentForFooterCell: "ember-model-table/total-money-inc-zero"
      };
      return this._buildColInternal(moneyPropertyName, title, filterPropertyName, className, componentName, defaultOptions, userOptions);
    }

    buildTotalledMoneyCol(moneyPropertyName, title, filterPropertyName, className, componentName, userOptions) {
      let defaultOptions = {
        //Set defaults for money displays
        component: "ember-model-table/cell-money",
        className: "text-right",
        componentForFooterCell: "ember-model-table/total-money-inc-zero"
      };
      return this._buildColInternal(moneyPropertyName, title, filterPropertyName, className, componentName, defaultOptions, userOptions);
    }

  }) || _class;

  _exports.default = EmtHelperService;
});