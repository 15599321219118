define("clientui/pods/subscribe/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "clientui/superclasses/unauthenticated-route"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _unauthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  //Note that this is NOT an authenticated route
  let SubscribeRoute = (_dec = Ember.inject.service('keycloak-session'), (_class = class SubscribeRoute extends _unauthenticatedRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
    }

    model(params) {
      let promises = {};
      let clientPortal = this.modelFor('application');
      promises['application'] = clientPortal;
      let session = this.session;

      if (session.get('authenticated') === true) {
        let guid = session.get('subject'); //Use query instead of find so that we can pass through the tcpid and so verify that the client is logging in to the correct site.
        //The server will return a redirect error if the two parameters do not match up.

        promises['client'] = this.store.query('client', {
          'guid': guid,
          'tcpid': clientPortal.get('tradeClientProfileId'),
          'pno': -1
        }).then(clients => {
          return clients.get('firstObject');
        }, errors => {
          console.log(errors); //throw errors; do not throw an error as client does not have to be logged in to use this page
        });
      }

      let listId = params.listid;
      promises['listid'] = listId;
      return Ember.RSVP.hash(promises);
    }

    setupController(controller, promises) {
      let client = promises['client'];

      if (client) {
        controller.set('client', client);
      }

      let clientportal = promises['application'];
      console.log(clientportal);
      controller.set('clientportal', clientportal);
      let listId = promises['listid'];

      if (listId) {
        listId = listId.toUpperCase();
      }

      console.log(listId);
      controller.set('listId', listId);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SubscribeRoute;
});