define("clientui/pods/customeraccounts/bookings/route", ["exports", "clientui/superclasses/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BookingsRoute extends _authenticatedRoute.default {}

  _exports.default = BookingsRoute;

  window.__CLASSIC_OWN_CLASSES__.set(BookingsRoute, true);
});