define("clientui/pods/managedprofiles/profile/delete/route", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "clientui/superclasses2/this-route-edit"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _thisRouteEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let ManagedprofilesProfileDeleteRoute = (_dec = Ember._action, (_class = class ManagedprofilesProfileDeleteRoute extends _thisRouteEdit.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _defineProperty2.default)(this, "routeLeaf", 'delete');
      (0, _defineProperty2.default)(this, "dialogTitle", 'Delete Traveller Profile');
      (0, _defineProperty2.default)(this, "enableDebugLogging", true);
      (0, _defineProperty2.default)(this, "paramName", 'client_id');
      (0, _defineProperty2.default)(this, "modelType", 'client');
      Object.assign(this, this.controllerFor('managedprofiles').adminSettings);
    }

    setupController(controller, model) {
      controller.set('clientportal', this.system.clientportal);
      super.setupController(controller, model);
    }

    deleteAction(thisModel) {
      let emberAction = {
        'delete': "XXX"
      }; //Value field has no meaning

      thisModel.set('emberAction', emberAction);
      return this.save(thisModel);
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "deleteAction", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "deleteAction"), _class.prototype)), _class));
  _exports.default = ManagedprofilesProfileDeleteRoute;
});