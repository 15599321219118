define("clientui/adapters/application", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/adapter/rest", "clientui/config/environment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _rest, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  let ApplicationAdapter = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class ApplicationAdapter extends _rest.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "host", _environment.default.APP.JBOSS_URL);
      (0, _defineProperty2.default)(this, "namespace", 'v1');
      (0, _initializerDefineProperty2.default)(this, "keycloakSession", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
    }

    get headers() {
      let authorization = `Bearer ${this.keycloakSession.token}`;
      let wtcRef = this.system.getWtcRefHeaderValue();
      let wtcRef2 = this.system.getWtcRef2HeaderValue();
      return {
        "X-WTC": wtcRef,
        "X-WTC2": wtcRef2,
        'Authorization': `${authorization}`
      };
    }
    /**
     * Will overload the adapter method to ensure that the call to the secured back end is made only after the session token has been updated.
     *
     * @method ajax
     * @param url {String}
     * @param type {String}
     * @param hash {Object}
     * @private
     */


    ajax(url, type, hash) {
      //let self = this;
      //let ajax = this.super(url, type, hash);
      return this.keycloakSession.updateToken().then(() => {
        /* We have a valid token - call the super method */
        //ajax.apply(self, [url, type, hash]),
        return super.ajax(url, type, hash);
      }, reason => {
        // console.warn(`Keycloak adapter mixin :: ajax :: rejected :: ${reason}`);
        // throw reason;

        /* WARNING:
         * This code is normally triggered from the model() hook, which is called before the transition is complete
         * Therefore the location.href (and all the properties of the router service) have not yet been updated to 
         * the target URL. 
         * Effectively, if this code is triggered, the user will be redirected back to the page they have just left, 
         * not the target page of the link they clicked on.
         */
        let thisUrl = location.href;
        console.log(`Keycloak adapter :: ajax :: update token failed reason='${reason}'. Will login with redirect='${thisUrl}'`);
        return this.keycloakSession.login(thisUrl);
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "keycloakSession", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ApplicationAdapter;
});