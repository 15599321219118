define("clientui/pods/components/dialogs/form-download-statement/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zoL4rhMI",
    "block": "{\"symbols\":[\"option\",\"@inverse\"],\"statements\":[[10,\"form\"],[14,0,\"form\"],[12],[2,\"\\n  \"],[10,\"fieldset\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-4\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-form-label\"],[12],[2,\"\\n          \"],[10,\"label\"],[14,\"for\",\"statementType\"],[14,0,\"\"],[12],[2,\"\\n            File Format\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-8\"],[12],[2,\"\\n        \"],[8,\"power-select\",[],[[\"@id\",\"@options\",\"@selected\",\"@onChange\",\"@searchEnabled\",\"@allowClear\"],[\"statementType\",[32,0,[\"options\"]],[32,0,[\"fileType\"]],[32,0,[\"setFileType\"]],false,\"false\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[32,1]],[2,\"\\n        \"]],\"parameters\":[1]}]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \\n\\n    \"],[10,\"div\"],[14,0,\"row forcewide\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n        \"],[8,\"widgets/form-submit-button-edit\",[],[[\"@onSubmit\",\"@savebuttonlabel\",\"@onBack\",\"@backbuttonlabel\",\"@inverse\"],[[32,0,[\"downloadStatement\"]],\"Download\",[32,0,[\"back\"]],\"Back\",[32,2]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n          \"],[8,\"widgets/server-errors\",[],[[\"@servererrors\"],[[32,0,[\"servererrors\"]]]],null],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "clientui/pods/components/dialogs/form-download-statement/template.hbs"
  });

  _exports.default = _default;
});