define("clientui/pods/components/widgets/contactdetail-line-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LQlTrErE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[30,[36,4],[[35,0],[35,3]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"row border-bottom border-top\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-4\"],[12],[2,\"\\n      \"],[10,\"small\"],[12],[2,\"\\n        \"],[10,\"strong\"],[12],[2,\"\\n          \"],[1,[34,1]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-8\"],[12],[2,\"\\n\"],[6,[37,2],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"p\"],[14,0,\"card-text\"],[12],[2,\"\\n          \"],[1,[34,0]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"value\",\"description\",\"if\",\"force\",\"or\"]}",
    "moduleName": "clientui/pods/components/widgets/contactdetail-line-item/template.hbs"
  });

  _exports.default = _default;
});