define("clientui/pods/components/widgets/form-submit-button-edit/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;

  let FormSubmitButtonEdit = (_dec = Ember.inject.service('keycloak-session'), _dec2 = Ember.inject.service('system'), _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class FormSubmitButtonEdit extends _component.default {
    get backbuttonlabel() {
      var _this$args$backbutton;

      return (_this$args$backbutton = this.args.backbuttonlabel) !== null && _this$args$backbutton !== void 0 ? _this$args$backbutton : "Cancel";
    }

    get savebuttonlabel() {
      var _this$args$savebutton;

      return (_this$args$savebutton = this.args.savebuttonlabel) !== null && _this$args$savebutton !== void 0 ? _this$args$savebutton : "Save";
    }

    constructor(owner, args) {
      var _this$args$disabled;

      super(owner, args);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "disabled", _descriptor3, this);
      this.disabled = (_this$args$disabled = this.args.disabled) !== null && _this$args$disabled !== void 0 ? _this$args$disabled : false;
    }

    get internalsavedisabled() {
      return this.args.savedisabled || this.system.saving;
    }

    get internalbackdisabled() {
      return this.args.backdisabled || this.system.saving;
    } // willDestroyElement() {
    //   cancel(this._debounced);
    //   super.willDestroyElement(...arguments);
    // }


    Back() {
      this.disabled = true;
      this.args.onBack();
      this._debounced = Ember.run.later(this, () => {
        this.disabled = false;
      }, 500);
    }

    Submit() {
      this.disabled = true;
      this.args.onSubmit();
      this._debounced = Ember.run.later(this, () => {
        this.disabled = false;
      }, 500);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "disabled", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "Back", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "Back"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "Submit", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "Submit"), _class.prototype)), _class));
  _exports.default = FormSubmitButtonEdit;
});