define("clientui/pods/components/dialogs/form-profile-edit-gdpr/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "clientui/mappings/helptext"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _helptext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;

  let FormProfileEditGdpr = (_dec = Ember.inject.service('system'), _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class FormProfileEditGdpr extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _defineProperty2.default)(this, "helpTextMap", _helptext.default);
      (0, _initializerDefineProperty2.default)(this, "servererrors", _descriptor3, this);
      this.system.set('saving', false);
    }

    get gdprText() {
      let source = this.helpTextMap['gdpr'];
      let text = null;

      if (typeof source === 'function') {
        //functions always take clientportal as the param
        text = source(this.system.clientportal);
      } else {
        text = source;
      }

      return Ember.String.htmlSafe(text);
    }

    get additionalConditionsText() {
      let text = this.args.clientportal.get('additionalConditions').replace(/(?:\r\n|\r|\n)/g, '<br />'); //convert line breaks to HTML <br />

      return Ember.String.htmlSafe(text);
    }

    save() {
      this.system.set('saving', true);
      let client = this.args.client;
      client.validate().then(() => {
        if (client.get("isValid")) {
          // let fieldName = null;
          // let selectionId = 0;
          // let model = null;
          let promises = {};
          Ember.RSVP.hash(promises).then(item => {
            Object.keys(item).forEach(key => {
              client.set(key, item[key]);
            });
            this.args.onSave(client).then(() => {
              // Promise
              console.log('clientPersonal Promise Saved Successfully in Component');
              this.args.onClose(); //this.onRefreshRoute();

              this.system.set('saving', false);
            }, errors => {
              console.log('clientPersonal Promise Failed to Save in Component');
              console.log(errors);
              this.servererrors = errors;
              this.system.set('saving', false);
            });
          }, errors => {
            console.log('clientPersonal Promise Failed to Save in Component - unable to find one of gender/paxType');
            console.log(errors);
            this.servererrors = errors;
            this.system.set('saving', false);
          });
        } else {
          this.system.set('saving', false);
        }
      }, () => {
        this.system.set('saving', false);
      });
    }

    back() {
      //Rollback any changes
      this.args.client.rollback();

      this.args.client._content._internalModel._record.rollbackAttributes(); //Close dialog


      this.args.onClose();
      this.args.onRollback();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "servererrors", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "save", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "back", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "back"), _class.prototype)), _class));
  _exports.default = FormProfileEditGdpr;
});