define("clientui/pods/contact/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "clientui/superclasses/authenticated-route", "clientui/config/environment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _authenticatedRoute, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  //Note that this is NOT an authenticated route
  let ContactRoute = (_dec = Ember.inject.service('keycloak-session'), (_class = class ContactRoute extends _authenticatedRoute.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
      (0, _defineProperty2.default)(this, "accountmanagers", null);
      (0, _defineProperty2.default)(this, "companySettings", null);
      (0, _defineProperty2.default)(this, "company", null);
      this.accountmanagers = [{
        // item 0 is an 'unknown' account manager
        name: '',
        email: 'accmgt@selective-travel.co.uk',
        phone: '+44-28-9068-9669',
        mobile: ''
      }, {
        // item 1
        name: 'David Fingland',
        email: 'accmgt@selective-travel.co.uk',
        phone: '+44-28-9038-9000',
        mobile: '+44-7720-593-698'
      }, {
        // item 2
        name: 'Lydia Smith',
        email: 'accmgt@selective-travel.co.uk',
        phone: '+44-28-9068-9669',
        mobile: ''
      }, {
        // item 3
        name: 'Stewart Bell',
        email: 'accmgt@selective-travel.co.uk',
        phone: '',
        mobile: '+44-771-1377049'
      }, {
        // item 4
        name: 'David Conlon',
        email: 'david.conlon@selective-travel.com',
        phone: '+353-1-4167044',
        mobile: '+353-86-0412524'
      }, {
        // item 5
        name: 'Stephen Staerke',
        email: 'accmgt@selective-travel.co.uk',
        phone: '+44-28-9038-9000',
        mobile: '+44-7548-216866'
      }, {
        // item 6
        name: 'Antoine Zimny',
        email: 'accmgt@selective-travel.co.uk',
        phone: '+44-28-9038-9000',
        mobile: '+44-7815-559086'
      }, {
        // item 7
        name: 'Collette Brennan',
        email: 'collette.brennan@selective-travel.com',
        phone: '+353-1-4081925',
        mobile: ''
      }, {
        // item 8 - Irish gov
        name: '',
        email: 'accmgt@selective-travel.com',
        phone: '+353-1-416-7089',
        mobile: ''
      }, {
        // item 9 - Irish account management
        name: '',
        email: 'accmgt.ie@selective-travel.com',
        phone: '+353-1-416-7089',
        mobile: ''
      }];
      this.companySettings = {
        WTC: {
          accountsemail: 'accounts.ie@selective-travel.com',
          accountsphone: '+353-1-4167076',
          headofcontract: 'Keith Graham',
          hocemail: 'keith@selective-travel.com',
          hocphone: '+353-1-416-7081',
          // hocmobile: '+44-7720-593-699',
          helpdesk: '+353-1-416-7090',
          securehelpdesk: '+353-1-416-7090'
        },
        TSM: {
          accountsemail: 'accounts@selective-travel.co.uk',
          accountsphone: '+44-28-9068-9683',
          headofcontract: 'Keith Graham',
          hocemail: 'keith@selective-travel.co.uk',
          hocphone: '+44-28-9096-2007',
          hocmobile: '+44-7720-593-699',
          helpdesk: '+44-28-9068-9674',
          securehelpdesk: '+44-28-9560-7015'
        }
      }, this.company = this.companySettings[_environment.default.APP.CLIENTCODE];
    }

    model(params) {
      let promises = {};
      let clientPortal = this.modelFor('application');
      promises['application'] = clientPortal;
      let session = this.session;

      if (session.get('authenticated') === true) {
        let guid = session.get('subject'); //Use query instead of find so that we can pass through the tcpid and so verify that the client is logging in to the correct site.
        //The server will return a redirect error if the two parameters do not match up.

        promises['client'] = this.store.query('client', {
          'guid': guid,
          'tcpid': clientPortal.get('tradeClientProfileId'),
          'pno': -1
        }).then(clients => {
          return clients.get('firstObject');
        }, errors => {
          console.log(errors); //throw errors; do not throw an error as client does not have to be logged in to use this page
        });
      }

      let param = params.accmanid;
      let accountManagerId = 0;
      let hideHeadOfContract = false;
      let hideOnlineHelpdesk = false;
      let showSecureOnlineHelpdesk = false;
      console.log("param", param, param.includes('-'));

      if (param && param.includes('-')) {
        //param is in the form accountManagerid-hideHeadOfContract-hideOnlineHelpdesk
        //hideHeadOfContract and hideOnlineHelpdesk are represented as 0 (no) or 1 (yes)
        //If these fields are neither 0 or 1, or missing, then the related block will be shown
        let p = param.split('-');
        console.log("p", p);

        if (p.length > 2) {
          console.log("p[2]", p[2]);
          hideOnlineHelpdesk = p[2] === '1';
          showSecureOnlineHelpdesk = p[2] === '2';
        }

        if (p.length > 1) {
          console.log("p[1]", p[1]);
          hideHeadOfContract = p[1] === '1';
        }

        if (p.length > 0) {
          accountManagerId = p[0];
        }
      } else {
        accountManagerId = param;
      }

      let accountManager = this.accountmanagers[accountManagerId];

      if (!accountManager) {
        accountManager = this.accountmanagers[0];
      }

      promises['accountmanager'] = accountManager;
      promises['hideHeadOfContract'] = hideHeadOfContract;
      promises['hideOnlineHelpdesk'] = hideOnlineHelpdesk;
      promises['showSecureOnlineHelpdesk'] = showSecureOnlineHelpdesk;
      return Ember.RSVP.hash(promises);
    }

    setupController(controller, promises) {
      let client = promises['client'];

      if (client) {
        controller.set('client', client);
      }

      let clientportal = promises['application'];
      console.log(clientportal);
      controller.set('clientportal', clientportal);
      let accountManager = promises['accountmanager'];
      console.log(accountManager);
      controller.set('accountmanager', accountManager);
      controller.set('company', this.company);
      let hideHeadOfContract = promises['hideHeadOfContract'];

      if (hideHeadOfContract && hideHeadOfContract === true) {
        controller.set('hideHeadOfContract', hideHeadOfContract);
      }

      let hideOnlineHelpdesk = promises['hideOnlineHelpdesk'];

      if (hideOnlineHelpdesk && hideOnlineHelpdesk === true) {
        controller.set('hideOnlineHelpdesk', hideOnlineHelpdesk);
      }

      let showSecureOnlineHelpdesk = promises['showSecureOnlineHelpdesk'];

      if (showSecureOnlineHelpdesk && showSecureOnlineHelpdesk === true) {
        controller.set('showSecureOnlineHelpdesk', showSecureOnlineHelpdesk);
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ContactRoute;
});