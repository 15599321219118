define("clientui/pods/customeraccounts/bookings/search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0g3MBZw4",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"Search Bookings\"],null]],[2,\"\\n\"],[8,\"pages/page-customeraccounts/page-customeraccounts-booking-search-list\",[],[[\"@accounts\",\"@bookings\",\"@formParams\",\"@queryParams\",\"@onSubmit\",\"@onClearForm\"],[[32,0,[\"accounts\"]],[32,0,[\"bookings\"]],[32,0,[\"formParams\"]],[32,0,[\"queryParams\"]],[30,[36,1],[\"formSubmit\"],null],[30,[36,2],[[32,0,[\"clearForm\"]]],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"page-title\",\"route-action\",\"fn\"]}",
    "moduleName": "clientui/pods/customeraccounts/bookings/search/template.hbs"
  });

  _exports.default = _default;
});