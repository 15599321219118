define("clientui/services/evolvisso", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-data", "fetch", "clientui/config/environment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberData, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let EvolvissoService = (_dec = Ember.inject.service('keycloak-session'), _dec2 = Ember.inject.service('evolvissofix'), _dec3 = Ember.inject.service, classic(_class = (_class2 = class EvolvissoService extends Ember.Service {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "evolvissofix", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor3, this);
      (0, _defineProperty2.default)(this, "testing", false);
    }

    getEvolviSSO() {
      console.log("Starting getEvolviSSO");

      if (this.testing) {
        console.log("RETURNING TEST DATA");
        return _emberData.default.PromiseArray.create({
          promise: this.sampleData
        });
      } else {
        let bearerToken = this.session.get('token');
        let options = {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + bearerToken,
            "X-WTC": this.system.getWtcRefHeaderValue(),
            "X-WTC2": this.system.getWtcRef2HeaderValue()
          }
        };
        let url = _environment.default.APP.JBOSS_URL + '/v1/evolvilogin'; // console.log(url);
        // console.log(bearerToken);

        return _emberData.default.PromiseObject.create({
          promise: (0, _fetch.default)(url, options).then(response => {
            if (response.status == 200) {
              return response.json().then(ssoJson => {
                if (ssoJson.redirectUrl) {
                  console.log(ssoJson.redirectUrl);
                  return ssoJson.redirectUrl;
                } else {
                  throw "SSO Response does not contain a redirectUrl";
                }
              });
            } else {
              throw "Unable to retrieve SSO URL (Status " + response.status + ")";
            }
          }, errors => {
            console.log("Error loading Evolvi Login URL");
            console.log(errors);
            throw errors;
          })
        });
      }
    }

    async fixupRedirectUrl(url) {
      //open the redirect URL three times to make sure that the cookies are reset
      let counter = 1;
      console.log("Starting fixupRedirectUrl (polyfill)");
      let status = await this.fetchOnce(url);
      console.log("Attempt #" + counter + " returned " + status);

      while (counter < 3) {
        counter++;
        status = await this.fetchOnce(url); //should get 302 now

        console.log("Attempt #" + counter + " returned " + status);
      }
    }

    async fetchOnce(url) {
      //returns promise which resolves to a status number
      //This is the same code as in evolvissofix, but there is an import of the fetch polyful at the top of this wile which is not present in the other.
      //Without this polyfill, no fetch command can work in IE11
      //The polyfill does not handle the redirect and mode options and so this will throw some CORS-related errors in the console, but it will actually request the pages and hence the cookies will be processed
      return await (0, _fetch.default)(url, {
        method: 'GET',
        credentials: 'include',
        //redirect: 'follow',
        //mode: 'no-cors',
        headers: {//'Content-Type': 'application/json;charset=UTF-8',
          //'Authorization': 'Bearer ' + this.session.token,
        }
      }).then(response => {
        //console.log(response);
        return response.status;
      }).catch(() => {
        //console.log(error);
        return 999;
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "evolvissofix", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = EvolvissoService;
});