define("clientui/models/approver", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let Approver = (_dec = (0, _model.attr)('number'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('number'), _dec5 = (0, _model.attr)('number'), _dec6 = (0, _model.attr)('string'), _dec7 = Ember.computed('clientDisplayName'), _dec8 = Ember.computed('clientDisplayName', 'tradeClientName'), classic(_class = (_class2 = class Approver extends _model.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "clientId", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "clientDisplayName", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "tradeClientName", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "clientTradeClientId", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "clientDefaultAccountId", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "clientScope", _descriptor6, this);
    }

    get selectDisplayText() {
      return `${this.clientDisplayName}`;
    }

    get selectDisplayTextLong() {
      let result = `${this.clientDisplayName}`;

      if (!Ember.isBlank(this.tradeClientName)) {
        result += " (" + this.tradeClientName + ")";
      }

      console.log(result);
      return result;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "clientId", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "clientDisplayName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "tradeClientName", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "clientTradeClientId", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "clientDefaultAccountId", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "clientScope", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "selectDisplayText", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "selectDisplayText"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "selectDisplayTextLong", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "selectDisplayTextLong"), _class2.prototype)), _class2)) || _class);
  _exports.default = Approver;
});