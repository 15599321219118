define("clientui/pods/components/inputs/bs-datetimepicker/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "moment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  let BsDatetimepickerComponent = (_dec = Ember.inject.service('system'), _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class BsDatetimepickerComponent extends _component.default {
    constructor(owner, args) {
      super(owner, args); //Do not remove this line - the call to parseArgsDate is necessary

      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "dropdown", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "center", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "timeOnly", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "dateOnly", _descriptor5, this);
      (0, _defineProperty2.default)(this, "months", ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]);
      (0, _defineProperty2.default)(this, "hours", Array(...Array(24)).map((_, i) => `${i.toString().padStart(2, '0')}`));
      (0, _defineProperty2.default)(this, "minutes", Array(...Array(60)).map((_, i) => `${i.toString().padStart(2, '0')}`));
      (0, _defineProperty2.default)(this, "seconds", Array(...Array(60)).map((_, i) => `${i.toString().padStart(2, '0')}`));
      console.log('BsDatetimepickerComponent: constructor ', this.printDate(this.args.date), "withTime", this.args.withTime);
      this.parseArgsDate();
    } //for debugging


    printDate(mmt) {
      if (mmt) {
        return this.asMoment(mmt).format(this.format);
      } else {
        return "null";
      }
    }

    get alignLeft() {
      var _this$args$alignLeft;

      return (_this$args$alignLeft = this.args.alignLeft) !== null && _this$args$alignLeft !== void 0 ? _this$args$alignLeft : false;
    }

    get placeholder() {
      var _this$args$placeholde, _this$args$label;

      return (_this$args$placeholde = this.args.placeholder) !== null && _this$args$placeholde !== void 0 ? _this$args$placeholde : (_this$args$label = this.args.label) !== null && _this$args$label !== void 0 ? _this$args$label : null;
    }

    get label() {
      var _this$args$label2;

      return (_this$args$label2 = this.args.label) !== null && _this$args$label2 !== void 0 ? _this$args$label2 : null;
    }

    get disabled() {
      var _this$args$disabled;

      return (_this$args$disabled = this.args.disabled) !== null && _this$args$disabled !== void 0 ? _this$args$disabled : false;
    }

    get withTime() {
      var _this$args$withTime;

      return (_this$args$withTime = this.args.withTime) !== null && _this$args$withTime !== void 0 ? _this$args$withTime : false;
    }

    get showTime() {
      return this.withTime === true && !Ember.isNone(this.mergedDateTime);
    }

    get allowClear() {
      var _this$args$allowClear;

      return (_this$args$allowClear = this.args.allowClear) !== null && _this$args$allowClear !== void 0 ? _this$args$allowClear : false;
    }

    get showClear() {
      return this.allowClear === true && !Ember.isNone(this.mergedDateTime);
    }

    get startYear() {
      var _this$args$startYear;

      return (_this$args$startYear = this.args.startYear) !== null && _this$args$startYear !== void 0 ? _this$args$startYear : this.system.currentYear - 10;
    }

    get endYear() {
      var _this$args$endYear;

      return (_this$args$endYear = this.args.endYear) !== null && _this$args$endYear !== void 0 ? _this$args$endYear : this.system.currentYear + 10;
    }

    get years() {
      let startYear = Number(this.startYear);
      let endYear = Number(this.endYear);
      let selectedYear = this.dateOnly ? this.dateOnly.year() : this.system.currentYear;
      let years = [];

      if (startYear === 0 && endYear === 0) {
        startYear = this.system.currentYear - 10;
        endYear = this.system.currentYear + 10;
      } else if (startYear === 0) {
        startYear = endYear - 10;
      } else if (endYear === 0) {
        endYear = startYear + 10;
      }

      if (startYear > endYear) {
        //list in descending order
        if (selectedYear > startYear) {
          startYear = selectedYear;
        }

        if (selectedYear !== 0 && selectedYear < endYear) {
          endYear = selectedYear;
        }

        for (let year = startYear; year >= endYear; year--) {
          years.push(year.toString());
        }
      } else {
        //list in ascending order
        if (selectedYear !== 0 && selectedYear < startYear) {
          startYear = selectedYear;
        }

        if (selectedYear > endYear) {
          endYear = selectedYear;
        }

        for (let year = startYear; year <= endYear; year++) {
          years.push(year.toString());
        }
      }

      return years;
    }

    parseArgsDate() {
      let momentDate;

      if (this.args.date) {
        momentDate = this.asMoment(this.args.date);
        this.dateOnly = new _moment.default({
          'year': momentDate.get('year'),
          'month': momentDate.get('month'),
          'date': momentDate.get('date'),
          'hour': 0,
          'minute': 0,
          'second': 0
        });
        this.timeOnly = new _moment.default({
          'hour': momentDate.get('hour'),
          'minute': momentDate.get('minute'),
          'second': momentDate.get('second')
        });
      } else {
        // console.log("Setting dateOnly and timeOnly to null");
        this.dateOnly = null;
        this.timeOnly = null;
      }
    }

    get triggerId() {
      let s = this.args.id;

      if (Ember.isBlank(s)) {
        s = Ember.guidFor(this);
      }

      s += '-trigger';
      return s;
    }

    get inputClasses() {
      if (this.alignLeft === true) {
        return 'form-control';
      } else {
        return 'form-control number-format';
      }
    }

    get format() {
      let result = this.system.dateFormat;

      if (this.withTime === true) {
        result = this.system.dateTimeFormat;
      }

      return result;
    } //DropDown Actions


    onInit(dropdown) {
      this.dropdown = dropdown;
    }

    onCenterChange(event) {
      //reactive action
      this.center = event.date;
    }

    changeCenter(unit, calendar, val) {
      //proactive action
      let newCenter = new _moment.default(calendar.center).set(unit, val);
      calendar.actions.changeCenter(newCenter);
    }

    onSelect(event) {
      if (event.moment) {
        this.dateOnly = event.moment;

        if (this.dropdown && !this.withTime) {
          this.dropdown.actions.close();
        }

        this.updateCaller();
      } else {
        console.log("moment is invalid in onSelect");
      }
    }

    onClear() {
      if (this.allowClear === true) {
        this.dateOnly = null;
        this.timeOnly = null;

        if (this.dropdown) {
          this.dropdown.actions.close();
        }

        this.updateCaller();
      }
    }

    changeTime(unit, calendar, val) {
      let timeOnly = this.timeOnly;

      if (Ember.isNone(timeOnly)) {
        timeOnly = new _moment.default("00000", "Hmmss");
      }

      timeOnly = new _moment.default(timeOnly).set(unit, Number(val)); //Need to create new moment to trigger computed values
      // console.log('Setting timeOnly to ' + timeOnly.format("HH:mm:ss") + ' in changeTime');

      this.timeOnly = timeOnly;
      this.updateCaller();
    }

    focusOutAction() {
      if (typeof this.args.onFocusOut !== 'undefined') {
        this.args.onFocusOut(...arguments);
      }
    }

    asMoment(d) {
      let m = null;

      if (d) {
        if (!_moment.default.isMoment(d)) {
          m = new _moment.default(d); //convert from date/string etc. per moment.js library
        } else {
          m = d;
        }
      }

      return m;
    } // Check to see if this.args.date has changed.


    argsHaveChanged() {
      let oldDate = this.prevArgDate;
      let newDate = this.args.date;
      let result = false;

      if (oldDate == null && newDate) {
        result = true;
      } else if (oldDate && newDate == null) {
        result = true;
      } else if (oldDate && newDate && !this.asMoment(oldDate).isSame(this.asMoment(newDate), "second")) {
        result = true;
      }

      this.prevArgDate = newDate;
      return result;
    }

    get mergedDateTime() {
      //dateOnly contains the date part of the result (moment object)
      //timeOnly contains the time part of the result (moment object)
      //Check for changes in this.args.date which may have triggered a call to this getter
      if (this.argsHaveChanged(this.args.date)) {
        this.parseArgsDate();
      }

      let dateOnly = this.dateOnly;
      let timeOnly = this.timeOnly;
      let result = null; // console.log("mergedDateTime date & time", this.printDate(dateOnly), timeOnly ? timeOnly.format("HHmmss") : null, "args.date", this.printDate(this.args.date));

      if (this.allowClear === true && Ember.isNone(dateOnly) && Ember.isNone(timeOnly)) {
        // console.log("mergedDateTime: returning null");
        result = null;
      } else {
        if (Ember.isNone(dateOnly)) {
          //null or undefined
          // console.log("mergedDateTime: date is null or undefined");
          dateOnly = new _moment.default();
        }

        if (this.withTime === true) {
          if (Ember.isNone(timeOnly)) {
            //null or undefined
            // console.log("mergedDateTime: date is null or undefined");
            timeOnly = new _moment.default("00000", "Hmmss");
          } //It is necessary to create a new Moment object here as Ember does not 'notice' and changes 
          //to the fields within the moment object. We take the date part from 'dateOnly' and the time from 'timeOnly'


          result = new _moment.default({
            'year': dateOnly.get('year'),
            'month': dateOnly.get('month'),
            'date': dateOnly.get('date'),
            'hour': timeOnly.get('hour'),
            'minute': timeOnly.get('minute'),
            'second': timeOnly.get('second')
          });
        } else {
          result = new _moment.default(dateOnly);
        }
      } // console.log("mergedDateTime returning", this.printDate(result));


      return result;
    }

    updateCaller() {
      //Notify caller
      if (typeof this.args.updateDate !== 'undefined') {
        this.args.updateDate(this.mergedDateTime);
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dropdown", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "center", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "timeOnly", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dateOnly", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onInit", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onInit"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onCenterChange", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onCenterChange"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "changeCenter", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "changeCenter"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onSelect", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onSelect"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onClear", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onClear"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "changeTime", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "changeTime"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "focusOutAction", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "focusOutAction"), _class.prototype)), _class));
  _exports.default = BsDatetimepickerComponent;
});