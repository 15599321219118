define("clientui/pods/coordinator/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qpspctej",
    "block": "{\"symbols\":[\"closeDialog\"],\"statements\":[[8,\"widgets/dialog-box-route\",[],[[\"@title\",\"@inverse\",\"@historyBack\"],[\"Update User Roles and Permissions\",true,false]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"dialogs/form-coordinator-edit-roles\",[],[[\"@clientrole\",\"@client\",\"@clientportal\",\"@inverse\",\"@scopes\",\"@clientroletypes\",\"@onSave\",\"@onRollback\",\"@onClose\"],[[30,[36,0],[[32,0,[\"clientrole\"]],[32,0,[\"validation\"]]],null],[32,0,[\"client\"]],[32,0,[\"clientportal\"]],true,[32,0,[\"scopes\"]],[32,0,[\"clientroletypes\"]],[30,[36,1],[\"save\"],null],[30,[36,1],[\"back\"],null],[30,[36,2],[[32,1]],null]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"changeset\",\"route-action\",\"fn\"]}",
    "moduleName": "clientui/pods/coordinator/edit/template.hbs"
  });

  _exports.default = _default;
});