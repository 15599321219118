define("clientui/pods/technology/route", ["exports", "clientui/superclasses/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //Note that this is NOT an authenticated route
  class TechnologyRoute extends _authenticatedRoute.default {}

  _exports.default = TechnologyRoute;

  window.__CLASSIC_OWN_CLASSES__.set(TechnologyRoute, true);
});