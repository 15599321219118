define("clientui/pods/customeraccounts/statements/search/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "clientui/superclasses/authenticated-route"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let CustomeraccountsStatementsSearchRoute = (_dec = Ember.inject.service, (_class = class CustomeraccountsStatementsSearchRoute extends _authenticatedRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "fetch", _descriptor, this);
    }

    model() {
      let promises = {};
      let clientPortal = this.modelFor('application');
      promises['clientPortal'] = clientPortal;
      promises['accounts'] = this.store.query('account', {
        accountsscope: true,
        pno: -1
      });
      let session = this.session;

      if (session.get('authenticated') === true) {
        let guid = session.get('subject'); //Use query instead of find so that we can pass through the tcpid and so verify that the client is logging in to the correct site.
        //The server will return a redirect error if the two parameters do not match up.

        promises['client'] = this.store.query('client', {
          'guid': guid,
          'tcpid': clientPortal.get('tradeClientProfileId'),
          'pno': -1
        }).then(clients => {
          return clients.get('firstObject');
        }, errors => {
          console.log(errors);
        });
      }

      return Ember.RSVP.hash(promises);
    }

    setupController(controller, promises) {
      super.setupController(controller, promises);
      this.customerAccountsController = this.controllerFor('customeraccounts');
      controller.set('clientPortal', promises['clientPortal']);
      controller.set('accounts', promises['accounts']);
      controller.set('client', promises['client']);
    }

    fetchAccounts() {
      return this.fetch.fetch('get', 'cors', 'accounts/' + "?accountsscope=true").then(response => {
        if (response.ok) {
          return response.json().then(accounts => {
            return accounts.pageablelist;
          });
        } else {
          return null;
        }
      }, errors => {
        throw errors;
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CustomeraccountsStatementsSearchRoute;
});