define("clientui/serializers/faqitem", ["exports", "@babel/runtime/helpers/esm/defineProperty", "clientui/serializers/pageablelist"], function (_exports, _defineProperty2, _pageablelist) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let Serializer = classic(_class = class Serializer extends _pageablelist.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "attrs", {
        faqCategory: {
          serialize: 'id',
          deserialize: 'records'
        } // tradeClientProfile: { serialize: 'id', deserialize: 'records' },

      });
    }

  }) || _class;

  _exports.default = Serializer;
});