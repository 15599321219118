define("clientui/pods/components/pages/page-technology/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  let PagesPageTechnologyComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, (_class = class PagesPageTechnologyComponent extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _initializerDefineProperty2.default)(this, "accountsRow", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "trackingRow", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "misRow", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "visaRow", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "carHireRow", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "ferryRow", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "carParkRow", _descriptor7, this);
      this.processArgs();
    }

    processArgs() {
      let row = 1;
      let cp = this.args.clientportal;
      let client = this.args.client;

      if (client && client.clientProfile && client.clientProfile.get('accountsRole') === true) {
        this.accountsRow = row++;
      }

      if (cp.hasTracking) {
        this.trackingRow = row++;
      }

      if (cp.hasMis) {
        this.misRow = row++;
      }

      if (cp.hasVisa) {
        this.visaRow = row++;
      }

      if (cp.hasCarHire) {
        this.carHireRow = row++;
      }

      if (cp.hasFerry) {
        this.ferryRow = row++;
      }

      if (cp.carParkingLink) {
        this.carParkRow = row++;
      }
    }

    didUpdateComponent() {
      this.processArgs();
    }

    get nolinks() {
      return !this.args.clientportal.hasTechnologyLink;
    }

    get clientLoggedInWithMis() {
      // if (this.args.client) {
      //   console.log("this.args.client is truthy");
      //   if (this.args.client.get('clientProfile')) {
      //     console.log("this.args.client.clientprofile is truthy");
      //     if (this.args.client.get('clientProfile.misRole')) {
      //       console.log("this.args.client.profile.misRole is truthy");
      //       if (this.args.client.get('clientProfile.misEid')) {
      //         console.log("this.args.client.profile.misEid is truthy");
      //       }
      //       else {
      //         console.log("this.args.client.clientProfile.misEid is falsey");
      //       }
      //     }
      //     else {
      //       console.log("this.args.client.clientProfile.misRole is falsey");
      //     }
      //   }
      //   else {
      //     console.log("this.args.client.clientProfile is falsey");
      //   }
      // }
      // else {
      //   console.log("this.args.client is falsey");
      // }
      return this.args.client && this.args.client.get('clientProfile') && this.args.client.get('clientProfile.misRole') && this.args.client.get('misEid') || this.args.clientportal.misUrl === "<DEMO>";
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "accountsRow", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "trackingRow", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "misRow", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "visaRow", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "carHireRow", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ferryRow", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "carParkRow", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "didUpdateComponent", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "didUpdateComponent"), _class.prototype)), _class));
  _exports.default = PagesPageTechnologyComponent;
});