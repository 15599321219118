define("clientui/pods/components/widgets/view-edit-delete-icons/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  let ViewEditDeleteIcons = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class ViewEditDeleteIcons extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "show", _descriptor, this);
    }

    handleMouseEnter() {
      this.show = 'in';
    }

    handleMouseLeave() {
      this.show = '';
    }

    get viewIcon() {
      var _this$args$viewIcon;

      return (_this$args$viewIcon = this.args.viewIcon) !== null && _this$args$viewIcon !== void 0 ? _this$args$viewIcon : 'view';
    }

    get editIcon() {
      var _this$args$editIcon;

      return (_this$args$editIcon = this.args.editIcon) !== null && _this$args$editIcon !== void 0 ? _this$args$editIcon : 'edit';
    }

    get deleteIcon() {
      var _this$args$deleteIcon;

      return (_this$args$deleteIcon = this.args.deleteIcon) !== null && _this$args$deleteIcon !== void 0 ? _this$args$deleteIcon : 'delete';
    }

    get viewTooltip() {
      var _this$args$viewToolti;

      return (_this$args$viewToolti = this.args.viewTooltip) !== null && _this$args$viewToolti !== void 0 ? _this$args$viewToolti : 'View';
    }

    get editTooltip() {
      var _this$args$editToolti;

      return (_this$args$editToolti = this.args.editTooltip) !== null && _this$args$editToolti !== void 0 ? _this$args$editToolti : 'Edit';
    }

    get deleteTooltip() {
      var _this$args$deleteTool;

      return (_this$args$deleteTool = this.args.deleteTooltip) !== null && _this$args$deleteTool !== void 0 ? _this$args$deleteTool : 'Delete';
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "show", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleMouseEnter", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleMouseEnter"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleMouseLeave", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleMouseLeave"), _class.prototype)), _class));
  _exports.default = ViewEditDeleteIcons;
});