define("clientui/pods/myprofile-admin/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ikqhFn/L",
    "block": "{\"symbols\":[],\"statements\":[[8,\"layout/page-header\",[],[[\"@clientportal\",\"@client\",\"@showBanner\"],[[34,0],[34,1],false]],null],[2,\"\\n\"],[10,\"section\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[14,1,\"content\"],[12],[2,\"\\n    \"],[8,\"layout/content-heading\",[],[[\"@portalDisplayName\",\"@firstName\",\"@surname\",\"@email\"],[[34,0,[\"portalDisplayName\"]],[34,2],[34,3],[34,4]]],null],[2,\"\\n    \"],[10,\"h3\"],[12],[2,\"\\n      Admin - MultipleMatchingClientProfiles\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      There is more than one profile relating to your email address. Please contact our online support desk who will resolve this for you.\\n    \"],[13],[2,\"\\n    \"],[1,[30,[36,6],[[30,[36,5],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"clientportal\",\"client\",\"firstName\",\"surname\",\"email\",\"-outlet\",\"component\"]}",
    "moduleName": "clientui/pods/myprofile-admin/template.hbs"
  });

  _exports.default = _default;
});