define("clientui/pods/managedprofiles/newprofile/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "clientui/superclasses/this-route-new", "clientui/mixins/parent-managedclient-mixin", "clientui/mixins/child-newclient-mixin", "clientui/validations/newtraveller"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _thisRouteNew, _parentManagedclientMixin, _childNewclientMixin, _newtraveller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let NewprofileRoute = (_dec = Ember.inject.service('keycloak-session'), _dec2 = Ember.inject.service('system'), _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, classic(_class = (_class2 = class NewprofileRoute extends _thisRouteNew.default.extend(_parentManagedclientMixin.default, _childNewclientMixin.default) {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "NewTravellerValidations", _newtraveller.default);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
      (0, _defineProperty2.default)(this, "routeLeaf", 'new');
      (0, _defineProperty2.default)(this, "dialogTitle", 'New Traveller Profile');
      (0, _defineProperty2.default)(this, "enableDebugLogging", true);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
      (0, _defineProperty2.default)(this, "fields", null);
    }

    model() {
      let promises = this.modelFor('managedprofiles'); //promises['managedClient'] = this.store.find('client', params.guid);

      return Ember.RSVP.hash(promises); // //Look for existing profile for this keycloak login
      // this.set('guid', this.get('session.subject'));
      // this.set('email', this.get('session.tokenParsed.email'));
      // this.set('firstName', this.get('session.tokenParsed.given_name'));
      // this.set('surname', this.get('session.tokenParsed.family_name'));
      // this.set('clientportal', this.get('system.clientportal'));
      // return this.store.query('client', {
      //   'tcpid': (this.get('clientportal.tradeClientProfileId')),
      //   'guid': (this.get('guid')),
      //   'email': (this.get('email')),
      //   'kcfirstname': (this.get('firstName')),
      //   'kcsurname': (this.get('surname')),
      //   'testmultierror': 'false',
      //   'pno': -1
      // }).then((clients) =>{
      //   return clients.get('firstObject');
      // }, (errors) => {
      //   console.log(errors);
      //   throw errors;
      // });
    }

    setupController(controller, promises) {
      controller.set('model', undefined); //Reset form

      let client = promises['client'];

      if (client) {
        controller.set('client', client);
      } // let managedClient = promises['managedClient'];
      // if (managedClient) {
      //   controller.set('managedClient', managedClient);
      //   this.set("managedClientId", managedClient.get('id'));
      // }


      let clientportal = promises['application'];
      controller.set('clientportal', clientportal);
      controller.set('system', this.system);
      let fields = {
        firstname: null,
        surname: null,
        email: null
      };
      controller.set('fields', fields);
      controller.set('NewTravellerValidations', this.NewTravellerValidations); // if (serverclient.get('dbId') > 0) {
      //   //This is a previously persisted client profile so jump to myprofile page
      //   this.transitionTo('myprofile');
      // }
      // else {
      //   //Start fresh - remove serverclient from store
      //   serverclient.unloadRecord();
      //   //Call superclass
      //   this._super(...arguments);
      //   //Now the controller is populated with a new empty Client object by the superclass
      //   let client = controller.get('model');
      //   client.set('x-email1', this.get('email'));
      //   client.set('firstName', this.get('firstName'));
      //   client.set('surname', this.get('surname'));
      //   controller.set('clientportal', this.get('system.clientportal'));
      // }
    }

    doNothing() {
      console.log('donothing');
    }

    startProfile(fields) {
      fields.execute();
      return this.store.query('client', {
        creatingclient: 'true',
        firstname: fields.get('firstname'),
        surname: fields.get('surname'),
        email: fields.get('email')
      }).then(clients => {
        let newClient = clients.get('firstObject');
        this.set('newClient', newClient);
        console.log(newClient);
        return newClient;
      }, errors => {
        console.log(errors);
        throw errors;
      });
    }

    backProfile() {
      this.transitionTo('managedprofiles');
    }

    parseNewClient() {
      let newClient = this.newClient;

      if (Number(newClient.get('dbId')) !== 0) {
        console.log("transitioning to managedprofiles.profile as dbId is " + newClient.get('dbId'));
        this.transitionTo('managedprofiles.profile', newClient.get('id'));
      } else {
        //let keycloakUserId = newClient.get('id');
        //Start fresh - remove newClient from store
        if (newClient.get('clientProfile')) {
          //Also remove newClient's clientProfile if it exists.
          //We can't remove it directly as it is a proxy, not a model, so we get the corresponding model as cp2 and unload that
          let clientProfile = newClient.get('clientProfile');
          let cp2 = this.store.peekRecord('clientprofile', clientProfile.get('id'));
          cp2.unloadRecord();
          newClient.set('clientProfile', undefined);
          clientProfile = undefined;
        }

        newClient.unloadRecord();
        this.set('newClient', undefined);
        newClient = undefined;
        let model = this.store.createRecord(this.modelType);
        let clientProfile = this.store.createRecord('clientprofile');
        model.set('clientProfile', clientProfile);
        model.set('x-email1', this.get('controller.fields.email'));
        model.set('firstName', this.get('controller.fields.firstname'));
        model.set('surname', this.get('controller.fields.surname'));
        model.set('arranger1', -1 * Number(this.get('controller.client.dbId'))); //Arranger id

        let controller = this.controller;
        controller.set('model', model);
        controller.set('validation', this.get(this.validationName));
        controller.set('title', this.dialogTitle);
        controller.set('clientportal', this.get('system.clientportal'));
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "doNothing", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "doNothing"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "startProfile", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "startProfile"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "backProfile", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "backProfile"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "parseNewClient", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "parseNewClient"), _class2.prototype)), _class2)) || _class);
  _exports.default = NewprofileRoute;
});