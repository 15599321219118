define("clientui/pods/components/pages/page-customeraccounts/page-customeraccounts-accounts-list/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let PageCustomeraccountsAccountsList = (_dec = Ember.inject.service('emtsp-helper'), (_class = class PageCustomeraccountsAccountsList extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "emthelper", _descriptor, this);
    }

    get accountColumns() {
      let columns = [];
      columns.push(this.emthelper.buildStringCol("name", "Account"));
      columns.push(this.emthelper.buildStringCol("fopAndLimit", "Credit Limit/Days", null, 'text-right'));
      return columns;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "emthelper", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PageCustomeraccountsAccountsList;
});