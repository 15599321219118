define("clientui/pods/components/widgets/link-card/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember-decorators/component"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let LinkCard = (_dec = (0, _component.classNames)('card', 'linkcard', 'm-2', 'text-primary'), _dec2 = (0, _component.classNameBindings)('isActive:bg-primary:bg-white', 'isActive:text-light:text-primary'), _dec3 = Ember.computed('img-url'), classic(_class = _dec(_class = _dec2(_class = (_class2 = class LinkCard extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "isActive", false);
    }

    get safeImgUrl() {
      return Ember.String.htmlSafe("background-image: url('" + this['img-url'] + "');");
    }

    mouseEnter() {
      this.set('isActive', true);
    }

    mouseLeave() {
      this.set('isActive', false);
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "safeImgUrl", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "safeImgUrl"), _class2.prototype)), _class2)) || _class) || _class) || _class);
  _exports.default = LinkCard;
});