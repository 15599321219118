define("clientui/validations/quotetraveller", ["exports", "clientui/validators/presence-if-required"], function (_exports, _presenceIfRequired) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    selectedPassport: [(0, _presenceIfRequired.default)('validatePassportSelection')],
    firstName: [(0, _presenceIfRequired.default)('guest')],
    surname: [(0, _presenceIfRequired.default)('guest')],
    nameisAsPerPassport: [(0, _presenceIfRequired.default)('guest')],
    paxType: [(0, _presenceIfRequired.default)('guest')],
    dateOfBirth: [(0, _presenceIfRequired.default)('guest')],
    gender: [(0, _presenceIfRequired.default)('guest')],
    emailAddress: [(0, _presenceIfRequired.default)('guest')],
    mobilePhone: [(0, _presenceIfRequired.default)('guest')],
    passportNumber: [(0, _presenceIfRequired.default)('guest')],
    passportCountryOfIssue: [(0, _presenceIfRequired.default)('guest')],
    passportDateOfIssue: [(0, _presenceIfRequired.default)('guest')],
    passportDateOfExpiry: [(0, _presenceIfRequired.default)('guest')]
  };
  _exports.default = _default;
});