define("clientui/validations/newclient", ["exports", "ember-changeset-validations/validators", "clientui/validators/dateofbirth-v-paxtype", "clientui/validators/presence-if-required", "clientui/validators/regex-if-present"], function (_exports, _validators, _dateofbirthVPaxtype, _presenceIfRequired, _regexIfPresent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    title: (0, _validators.validatePresence)(true),
    firstName: (0, _validators.validatePresence)(true),
    surname: (0, _validators.validatePresence)(true),
    dateOfBirth: [(0, _dateofbirthVPaxtype.default)('x-paxtype'), (0, _presenceIfRequired.default)('requiresDateOfBirth')],
    nationalitySelection: (0, _presenceIfRequired.default)('requiresNationality'),
    corporateTradeClientSelection: (0, _validators.validatePresence)(true),
    defaultAccountSelection: (0, _validators.validatePresence)(true),
    // "x-email1": [
    //             validatePresence(true),
    //             validateFormat({ allowBlank: false, type: 'email'})
    //             ],
    // businessMobile: [ validateLength({max: 50}),
    //                   validatePresence(true)
    //                 ],
    businessMobile: (0, _validators.validateLength)({
      max: 50
    }),
    businessLandline: (0, _validators.validateLength)({
      max: 50
    }),
    personalEmail: [(0, _validators.validateFormat)({
      allowBlank: true,
      type: 'email'
    }), (0, _validators.validateLength)({
      max: 50
    })],
    personalMobile: (0, _validators.validateLength)({
      max: 50
    }),
    personalLandline: (0, _validators.validateLength)({
      max: 50
    }),
    emergencyContactPhone: (0, _validators.validateLength)({
      max: 30
    }),
    emergencyContactEmail: [(0, _validators.validateFormat)({
      allowBlank: true,
      type: 'email'
    }), (0, _validators.validateLength)({
      max: 100
    })],
    customValue1: (0, _regexIfPresent.default)('customRegex1', 'requiresCustomFields', false),
    customValue2: (0, _regexIfPresent.default)('customRegex2', 'requiresCustomFields', false),
    customValue3: (0, _regexIfPresent.default)('customRegex3', 'requiresCustomFields', false),
    customValue4: (0, _regexIfPresent.default)('customRegex4', 'requiresCustomFields', false),
    customValue5: (0, _regexIfPresent.default)('customRegex5', 'requiresCustomFields', false),
    customValue6: (0, _regexIfPresent.default)('customRegex6', 'requiresCustomFields', false),
    customValue7: (0, _regexIfPresent.default)('customRegex7', 'requiresCustomFields', false) // passportIssuedate: validateDate({before: 'now'}),  //Validatedate is not part of ember-changeset-validations for now
    // passportExpirydate: validateDate({after: 'now'})

  };
  _exports.default = _default;
});