define("clientui/pods/components/pages/faq-category-accordion/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sBPWQ7L7",
    "block": "{\"symbols\":[\"item\",\"@category\",\"@dashdata\"],\"statements\":[[10,\"div\"],[14,0,\"card-header text-white bg-secondary mb-2\"],[15,\"onclick\",[32,0,[\"expand\"]]],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"expanded\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    v -\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    > -\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[1,[32,2,[\"label\"]]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,1],[[32,0,[\"expanded\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1,[\"faqCategory\",\"id\"]],[32,2,[\"id\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"pages/faq-accordion\",[],[[\"@title\",\"@text\",\"@html\"],[[32,1,[\"title\"]],[32,1,[\"itemText\"]],[32,1,[\"html\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "clientui/pods/components/pages/faq-category-accordion/template.hbs"
  });

  _exports.default = _default;
});