define("clientui/pods/components/widgets/file-uploader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service('keycloak-session'),
    system: Ember.inject.service('system'),
    mimetypes: "image/*",
    name: "photos",
    heading: "Upload Images",
    uploadPath: "",
    uploadFile: (0, _emberConcurrency.task)(function* (file) {
      // console.log("File Name: "+ file.get('name'));
      // console.log("File Size: "+ file.get('size'));
      try {
        yield this.session.updateToken().then(() => {
          file.upload(this.uploadPath, {
            withCredentials: true,
            headers: {
              'Authorization': `Bearer ${this.get('session.token')}`,
              "X-WTC": this.system.getWtcRefHeaderValue(),
              "X-WTC2": this.system.getWtcRef2HeaderValue()
            }
          }).then(response => {
            console.log(response); //trigger action on completion

            this.onUploadFile();
          });
        });
      } catch (e) {//photo.rollback();
      }
    }).maxConcurrency(3).enqueue(),
    actions: {
      uploadFile(file) {
        this.uploadFile.perform(file);
      }

    }
  });

  _exports.default = _default;
});