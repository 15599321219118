define("clientui/pods/components/widgets/contact-detail-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "clURHkwx",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,5],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"li\"],[14,0,\"list-group-item\"],[12],[2,\"\\n    \"],[1,[34,1]],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-sm btn-primary float-right\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"addNew\"],null],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-plus-circle\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"ul\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"widgets/contact-detail-item\",[],[[\"@item\",\"@onEdit\"],[[32,1],[30,[36,0],[[32,0],\"edit\"],null]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"caption\",\"list\",\"-track-array\",\"each\",\"if\"]}",
    "moduleName": "clientui/pods/components/widgets/contact-detail-list/template.hbs"
  });

  _exports.default = _default;
});