define("clientui/pods/components/widgets/link-item-v2/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let LinkItemV2 = (_dec = Ember.inject.service('system'), _dec2 = Ember.inject.service('store'), _dec3 = Ember._tracked, (_class = class LinkItemV2 extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "dynamicDisplay", _descriptor3, this);

      if (this.args.link.dynamicDisplayModel) {
        let params = {};

        if (this.args.link.dynamicDisplayModelParams) {
          // Params should be in the format key:val~key:val
          let keyValues = this.args.link.dynamicDisplayModelParams.split("~");

          for (let i = 0; i < keyValues.length; i++) {
            let key = keyValues[i].split(":")[0];
            let val = keyValues[i].split(":")[1];
            params[key] = val;
          }
        }

        this.store.query(this.args.link.dynamicDisplayModel, params).then(records => {
          if (records) {
            this.dynamicDisplay = "To Approve " + records.get('length');
          }
        });
      }
    }

    get externalHRef() {
      let result = this.args.link.externalHRef;

      if (!Ember.isBlank(result)) {
        result = this.system.replacePlaceholderUrls(result);
      }

      return result;
    }

    get tileClasses() {
      let link = this.args.link;
      let cls = "mt-1 col "; //Size can be 1, 2 or 3

      switch (link.displaySize) {
        case 1:
          cls += "col-md-2 col-sm-12 col-6 ";
          break;

        case 2:
          cls += "col-md-3 col-sm-3 col-6 ";
          break;

        case 3:
          cls += "col-md-4 col-sm-4 col-6 ";
          break;
      }

      cls += "text-center home-box ";

      if (link.rowNumber > 0) {
        cls += "bg-purple" + link.rowNumber + " ";
      }

      if (link.firstItem) {
        cls += "first-item ";
      }

      if (link.lastItem) {
        cls += "last-item ";
      } else {
        cls += "br ";
      }

      return cls;
    }

    get rowClass() {
      return "row" + this.args.link.rowNumber;
    }

    getInternalPath() {
      let path = this.args.link.internalRoute;

      if (path) {
        path = path.replace('.', '/'); //convert all dots to slashes for matching purposes

        if (!path.startsWith('/')) {
          path = '/' + path;
        }
      }

      return path;
    }

    getFoundPath(path) {
      let foundPath = this.system.pathsWithParam.find(parampath => {
        return path.startsWith(parampath);
      });
      return foundPath;
    }

    get internalLinkRoute() {
      let path = this.getInternalPath();

      if (path === '/') {
        //console.log("internalLinkRoute = application");
        return 'application';
      } else {
        let foundPath = this.getFoundPath(path);

        if (foundPath) {
          // console.log("FoundPath: " + foundPath);
          let param = path.substring(foundPath.length);
          let internalPath = path.substring(1, foundPath.length - 1).replace('/', '.');

          if (param) {
            //console.log("internalLinkRoute = " + internalPath);
            return internalPath;
          } else {
            //console.log("Problem: internalLinkRoute = " + this.link.internalRoute);
            return this.args.link.internalRoute; //We can't figure this out so return what was configured, without modification
          }
        } else {
          let internalPath = this.args.link.internalRoute.replace('/', '.'); //There are no params so return original route, sanitised for slashes & dots
          //console.log("internalLinkRoute = " + internalPath);

          return internalPath;
        }
      }
    }

    get internalLinkParam() {
      //console.log("starting internalLinkParam for " + this.link.internalRoute);
      let path = this.getInternalPath(); //console.log("InternalLinkParam: path: " + path);

      if (path === '/') {
        //console.log("internalLinkRoute = application -> no param");
        return null;
      } else {
        let foundPath = this.getFoundPath(path);

        if (foundPath) {
          //console.log("FoundPath: " + foundPath);
          let param = path.substring(foundPath.length);

          if (param) {
            //console.log("internalLinkRoute Param = " + param);
            return param;
          } else {
            //console.log("Problem: There should be a param but there is none");
            return null;
          }
        } else {
          //There are no params
          //console.log("InternalLinkParam: path: " + path + " -> There are no params");
          return null;
        }
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dynamicDisplay", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class));
  _exports.default = LinkItemV2;
});