define("clientui/pods/components/ember-model-table/cell-reconciled-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oP94LnPc",
    "block": "{\"symbols\":[],\"statements\":[[8,\"widgets/icon-for\",[],[[\"@target\",\"@tooltip\"],[[34,0,[\"reconciledIcon\"]],[34,0,[\"reconciledTooltip\"]]]],null]],\"hasEval\":false,\"upvars\":[\"record\"]}",
    "moduleName": "clientui/pods/components/ember-model-table/cell-reconciled-status/template.hbs"
  });

  _exports.default = _default;
});