define("clientui/pods/blogs/route", ["exports", "clientui/superclasses/unauthenticated-route"], function (_exports, _unauthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //Note that this is NOT an authenticated route
  class BlogsRoute extends _unauthenticatedRoute.default {}

  _exports.default = BlogsRoute;

  window.__CLASSIC_OWN_CLASSES__.set(BlogsRoute, true);
});