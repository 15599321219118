define("clientui/pods/components/pages/faq-display/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PagesFaqDisplayComponent extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      this.faqData = this.args.dashdata;
      this.categories = Ember.A([]);

      if (this.faqData) {
        this.faqData.forEach(faqItem => {
          faqItem.faqCategory.expanded = false;
          this.categories.addObject(faqItem.faqCategory);
        });
      }

      this.categories = this.categories.uniqBy('id');
    }

  }

  _exports.default = PagesFaqDisplayComponent;

  window.__CLASSIC_HAS_CONSTRUCTOR__.set(PagesFaqDisplayComponent, true);

  window.__CLASSIC_OWN_CLASSES__.set(PagesFaqDisplayComponent, true);
});