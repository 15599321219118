define("clientui/pods/components/pages/downloadabledocuments/page-downloadabledocuments-searchform/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "moment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let PageDownloadabledocumentsSearchform = (_dec = Ember.inject.service('system'), _dec2 = Ember.inject.service('form-date-conversion'), _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class PageDownloadabledocumentsSearchform extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "dateConversion", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "selectedAccount", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "selectedDocType", _descriptor4, this);
    }

    get momentfrom() {
      let moment = null;

      if (this.args.formParams.get('datefrom')) {
        moment = new _moment.default(this.args.formParams.get('datefrom'), this.system.isoDateFormat);
      }

      return moment;
    }

    get momentto() {
      let moment = null;

      if (this.args.formParams.get('dateto')) {
        moment = new _moment.default(this.args.formParams.get('dateto'), this.system.isoDateFormat);
      }

      return moment;
    }

    setAccounts(selected) {
      this.selectedAccount = selected;

      if (selected !== null) {
        if (Ember.isArray(selected)) {
          let arrayOfIds = selected.map(function (a) {
            return a.id;
          });
          this.args.formParams.set('accountid', arrayOfIds.join(","));
        } else {
          this.args.formParams.set('accountid', selected.id);
        }
      } else {
        this.args.formParams.set('accountid', null);
      }
    }

    setDocType(selected) {
      this.selectedDocType = selected;
      Ember.set(this.args.formParams, 'documenttype', selected ? selected.id : null);
    }

    setDateFrom(momentDate) {
      this.args.formParams.set('datefrom', this.dateConversion.getIsoDate(momentDate));
    }

    setDateTo(momentDate) {
      this.args.formParams.set('dateto', this.dateConversion.getIsoDate(momentDate));
    }

    formSubmit() {
      this.args.onSubmit(this.args.formParams);
    }

    clearForm() {
      this.selectedAccount = null;
      this.selectedDocType = null;
      this.args.onClearForm();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dateConversion", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedAccount", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedDocType", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setAccounts", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setAccounts"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setDocType", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setDocType"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setDateFrom", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setDateFrom"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setDateTo", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "setDateTo"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "formSubmit", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "formSubmit"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "clearForm", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "clearForm"), _class.prototype)), _class));
  _exports.default = PageDownloadabledocumentsSearchform;
});