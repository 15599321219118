define("clientui/pods/components/widgets/icon-for/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EE0+beZK",
    "block": "{\"symbols\":[\"@tooltip\",\"&attrs\"],\"statements\":[[2,\"\\n\"],[11,\"i\"],[16,0,[31,[\"\\n  \",[30,[36,0],[[32,0,[\"hasOnClick\"]],\" a \"],null],\"\\n  \"]]],[17,2],[4,[38,1],[\"click\",[32,0,[\"onClick\"]]],null],[12],[2,\"\\n  \"],[10,\"i\"],[15,0,[31,[\"fa fa-\",[32,0,[\"targetWithDefault\"]]]]],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"attach-tooltip\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,1]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "moduleName": "clientui/pods/components/widgets/icon-for/template.hbs"
  });

  _exports.default = _default;
});