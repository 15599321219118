define("clientui/pods/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uDm0XC2z",
    "block": "{\"symbols\":[\"row\",\"link\"],\"statements\":[[8,\"layout/page-header\",[],[[\"@clientportal\",\"@client\",\"@showBanner\"],[[32,0,[\"clientportal\"]],[32,0,[\"client\"]],true]],null],[2,\"\\n\"],[10,\"section\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"rows\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"widgets/link-item-v2\",[],[[\"@link\",\"@clientportal\"],[[32,2],[32,0,[\"clientportal\"]]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "moduleName": "clientui/pods/index/template.hbs"
  });

  _exports.default = _default;
});