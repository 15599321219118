define("clientui/pods/components/widgets/icon-for/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@glimmer/component", "clientui/mappings/icons"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _component, _icons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let IconFor = (_dec = Ember._action, (_class = class IconFor extends _component.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "icons", _icons.default);
    }

    //load external map
    get targetWithDefault() {
      let result = this.icons[this.args.target];

      if (result === undefined || result === null) {
        result = this.icons['default'];
      }

      return result.icon;
    }

    get content() {
      if (this.material) {
        return this.targetWithDefault['icon'];
      } else {
        return null;
      }
    }

    get hasOnClick() {
      return typeof this.args.onClick === "function";
    }

    onClick(event) {
      if (this.hasOnClick) {
        this.args.onClick(event);
      }
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "onClick", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype)), _class));
  _exports.default = IconFor;
});