define("clientui/pods/components/layout/menu-bar/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let MenuBar = (_dec = (0, _component.classNames)('mainmenu'), _dec2 = Ember.inject.service('keycloak-session'), _dec3 = Ember.inject.service('system'), _dec4 = Ember.computed, _dec5 = Ember._action, classic(_class = _dec(_class = (_class2 = class MenuBar extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
    }

    get loggedIn() {
      return this.get('session.authenticated');
    }

    logout() {
      this.system.logout();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "loggedIn", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "loggedIn"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "logout", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "logout"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = MenuBar;
});