define("clientui/pods/components/inputs/validated-customfield/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ONZ0hsz8",
    "block": "{\"symbols\":[\"@label\",\"@propertyName\",\"@maxlength\",\"@changeset\",\"@helpkey\",\"@manualHelpText\",\"@isSelection\"],\"statements\":[[6,[37,0],[[32,7]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"inputs/validated-power-select\",[],[[\"@label\",\"@propertyName\",\"@changeset\",\"@options\",\"@searchEnabled\",\"@selectDisplayText\",\"@searchField\",\"@allowClear\",\"@helpkey\",\"@manualHelpText\"],[[32,1],[32,2],[32,4],[32,0,[\"selectionOptions\"]],true,\"id\",\"id\",true,[32,5],[32,6]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"inputs/validated-input\",[],[[\"@label\",\"@propertyName\",\"@maxlength\",\"@changeset\",\"@helpkey\",\"@manualHelpText\"],[[32,1],[32,2],[32,3],[32,4],[32,5],[32,6]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "clientui/pods/components/inputs/validated-customfield/template.hbs"
  });

  _exports.default = _default;
});