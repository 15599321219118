define("clientui/components/models-table/themes/ember-paper/row-sorting-cell", ["exports", "ember-models-table/components/models-table/themes/ember-paper/row-sorting-cell"], function (_exports, _rowSortingCell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _rowSortingCell.default;
    }
  });
});