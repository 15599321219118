define("clientui/pods/blogs/index/controller", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let IndexController = (_dec = Ember.computed('data.[]', 'pno'), _dec2 = Ember.computed('data.[]'), _dec3 = Ember.computed('pno'), _dec4 = Ember.computed('maxPage', 'pno'), _dec5 = Ember._action, _dec6 = Ember._action, classic(_class = (_class2 = class IndexController extends Ember.Controller {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "queryParams", ['pno']);
      (0, _defineProperty2.default)(this, "pno", 0);
      (0, _defineProperty2.default)(this, "pageSize", 4);
      (0, _defineProperty2.default)(this, "data", null);
    }

    init() {
      super.init(...arguments);
      this.set('data', []);
    }

    get currentPage() {
      let start = this.pno * this.pageSize;
      let end = (this.pno + 1) * this.pageSize;

      if (end > this.data.length) {
        end = this.data.length;
      }

      return this.data.slice(start, end);
    }

    get maxPage() {
      return Math.floor(this.data.length / this.pageSize) - 1;
    }

    get hasPrev() {
      return this.pno > 0;
    }

    get hasNext() {
      return this.pno < this.maxPage;
    }

    nextPage() {
      if (this.hasNext) {
        this.set('pno', this.pno + 1);
        console.log("page number incremented to " + this.pno);
      }
    }

    prevPage() {
      if (this.hasPrev) {
        this.set('pno', this.pno - 1);
        console.log("page number decremented to " + this.pno);
      }
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "currentPage", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "currentPage"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "maxPage", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "maxPage"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "hasPrev", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "hasPrev"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "hasNext", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "hasNext"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "nextPage", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "nextPage"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "prevPage", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "prevPage"), _class2.prototype)), _class2)) || _class);
  _exports.default = IndexController;
});