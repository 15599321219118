define("clientui/pods/components/widgets/link-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b0EWOaoM",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"card-header small px-2 link-item-header\"],[12],[2,\"\\n  \"],[1,[34,0]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"card-body p-0\"],[12],[2,\"\\n\"],[2,\"  \"],[10,\"div\"],[14,0,\"link-item-image\"],[15,5,[34,1]],[12],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"card-footer text-right small\"],[12],[2,\"\\n  more info ››\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"caption\",\"safeImgUrl\"]}",
    "moduleName": "clientui/pods/components/widgets/link-card/template.hbs"
  });

  _exports.default = _default;
});