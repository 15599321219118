define("clientui/pods/managedprofiles/profile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RiD07t4H",
    "block": "{\"symbols\":[],\"statements\":[[8,\"widgets/managedpage-subtitle\",[],[[\"@managedClient\",\"@onBack\",\"@onDelete\",\"@onUndelete\"],[[32,0,[\"managedClient\"]],[30,[36,0],[\"back\"],null],[30,[36,0],[\"delete\"],null],[30,[36,0],[\"undelete\"],null]]],null],[2,\"\\n\"],[8,\"pages/page-myprofile-new\",[],[[\"@client\",\"@dateFormat\",\"@dateTimeFormat\",\"@clientportal\",\"@loggedinclient\",\"@inverse\",\"@onEditPersonal\",\"@onEditContact\",\"@onEditProfessional\",\"@onEditDocuments\",\"@onEditProfileNotes\",\"@onEditSubscriptions\",\"@onEditPreferences\",\"@onEditPermissions\"],[[32,0,[\"managedClient\"]],[32,0,[\"system\",\"dateFormat\"]],[32,0,[\"system\",\"dateTimeFormat\"]],[32,0,[\"clientportal\"]],[32,0,[\"client\"]],true,[30,[36,0],[\"editPersonal\"],null],[30,[36,0],[\"editContact\"],null],[30,[36,0],[\"editProfessional\"],null],[30,[36,0],[\"editDocuments\"],null],[30,[36,0],[\"editProfileNotes\"],null],[30,[36,0],[\"editSubscriptions\"],null],[30,[36,0],[\"editPreferences\"],null],[30,[36,0],[\"editPermissions\"],null]]],null],[2,\"\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"route-action\",\"-outlet\",\"component\"]}",
    "moduleName": "clientui/pods/managedprofiles/profile/template.hbs"
  });

  _exports.default = _default;
});