define("clientui/transforms/dateonly", ["exports", "@ember-data/serializer/transform", "moment"], function (_exports, _transform, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let Dateonly =
  /**
   The `DS.DateTimeTransform` class is used to serialize and deserialize
   date attributes on Ember Data record objects. This transform is used
   when `date` is passed as the type parameter to the
   [DS.attr](../../data#method_attr) function. It uses the [`ISO 8601`](https://en.wikipedia.org/wiki/ISO_8601)
   standard.
  
   ```app/models/score.js
   import DS from 'ember-data';
  
   export default DS.Model.extend({
      value: DS.attr('number'),
      player: DS.belongsTo('player'),
      date: DS.attr('date')
    });
   ```
  
   @class DateTransform
   @extends DS.Transform
   @namespace DS
   */
  classic(_class = class Dateonly extends _transform.default {
    deserialize(serialized) {
      let fullDate = null;
      let type = typeof serialized;

      if (type === "string") {
        let offset = serialized.indexOf('+');

        if (offset === -1) {
          offset = serialized.indexOf('-', 10);
        } // this is a phantom specific bug fix in which +0000 is not supported


        if (offset !== -1 && serialized.length - 5 === offset) {
          offset += 3;
          serialized = serialized.slice(0, offset) + ':' + serialized.slice(offset);
        } else if (offset === -1) {
          //Timezone not supplied - apply local timezone
          //When the time zone offset is absent, date-only forms are interpreted as a UTC time and date-time forms are interpreted as local time
          //Therefore, we make this a date-time form and we get the date as in the local timezone
          if (!serialized.includes('T')) {
            serialized = serialized + 'T00:00:00';
          }
        }

        fullDate = new Date(serialized);
      } else if (type === "number") {
        fullDate = new Date(serialized);
      } else if (type === "moment") {
        fullDate = serialized.toDate();
      } else if (serialized === null || serialized === undefined) {
        // if the value is null return null
        // if the value is not present in the data return undefined
        return serialized;
      } else {
        return null;
      }

      if (fullDate) {
        let d = new Date(fullDate.getFullYear(), fullDate.getMonth(), fullDate.getDate());
        return d;
      } else {
        return null;
      }
    }

    serialize(date) {
      if (date instanceof Date && !isNaN(date)) {
        let dateString = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
        return dateString;
      } else if (date instanceof _moment.default) {
        let dateString = date.format('Y-MM-DD');
        return dateString;
      } else {
        return null;
      }
    }

  }) || _class;

  _exports.default = Dateonly;
});