define("clientui/superclasses/sublist-route-new", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor"], function (_exports, _applyDecoratedDescriptor2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let SublistRouteNewRoute = (_dec = Ember._action, _dec2 = Ember._action, classic(_class = (_class2 = class SublistRouteNewRoute extends Ember.Route {
    // routePrefix = '';
    // routePlural = '';
    // routeSingular = '';
    // routeLeaf = '';
    // paramName = '';
    // parentModelName = '';
    // modelType = '';
    // parentModelFieldName = '';
    // parentModelFieldNamePrefix = '';
    // validationName = '';
    // dialogTitle = '';
    // enableDebugLogging = true;
    newPreSaveHook() {//(thisModel, parentModel) {
    }

    init() {
      super.init(...arguments);
      this.log('Route: ' + this.buildRoute() + '->' + this.routeLeaf);
    }

    setupController(controller, parentModel) {
      let model = this.store.createRecord(this.modelType);
      controller.set('parentModel', parentModel);
      controller.set('model', model);
      controller.set('validation', this.get(this.validationName));
      controller.set('title', this.dialogTitle);
    }

    log(s) {
      if (this.enableDebugLogging) {
        console.log(s);
      }
    }

    buildRoute() {
      let newRoute = '';

      if (this.routePrefix) {
        newRoute += this.routePrefix + '.';
      }

      if (this.routePlural) {
        newRoute += this.routePlural + '.';
      }

      if (this.routeSingular) {
        newRoute += this.routeSingular;
      }

      return newRoute;
    }

    save(changeset) {
      changeset = changeset.execute();
      let parentModel = this.get('controller.parentModel');
      this.log(parentModel);
      let newModel = changeset._content;
      this.newPreSaveHook(newModel, parentModel);
      parentModel.get(this.parentModelFieldNamePrefix + this.parentModelFieldName).pushObject(newModel);
      return parentModel.save().then(() => {
        this.replaceWith(this.buildRoute(), this.get('controller.parentModel.id'));
      }, errors => {
        this.log('Edit' + this.routeLeaf + ' ' + this.parentModelName + '.save returned FAILURE');
        this.log(errors);
        throw errors;
      });
    }

    back() {
      this.log('Rollback Clicked');
      this.replaceWith(this.buildRoute(), this.get('controller.parentModel.id'));
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "save", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "back", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "back"), _class2.prototype)), _class2)) || _class);
  _exports.default = SublistRouteNewRoute;
});