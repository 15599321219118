define("clientui/models/gender", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  let Gender = (_dec = (0, _model.attr)('string'), _dec2 = Ember.computed('label'), (_class = class Gender extends _model.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "label", _descriptor, this);
    }

    get selectDisplayText() {
      return `${this.label}`;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "label", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectDisplayText", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "selectDisplayText"), _class.prototype)), _class));
  _exports.default = Gender;
});