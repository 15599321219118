define("clientui/pods/components/dialogs/form-download-statement/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let DialogsFormDownloadStatementComponent = (_dec = Ember.inject.service('goto-previous-page'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class DialogsFormDownloadStatementComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "gotoPrevPage", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "fetch", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor3, this);
      (0, _defineProperty2.default)(this, "options", ['CSV', 'PDF']);
      (0, _initializerDefineProperty2.default)(this, "fileType", _descriptor4, this);
      this.options = ['CSV', 'PDF'];
    }

    downloadStatement() {
      return this.fetch.fetchAndDownloadFile("statementservice/" + this.args.accountId + "/generatestatement/" + this.fileType, null, null).then(() => {
        this.back();
      }, errors => {
        console.log("errors in promise", errors);
        this.toast.error(errors);
      });
    }

    setFileType(selected) {
      this.fileType = selected;
    }

    back() {
      this.router.replaceWith('customeraccounts.statements.statement', this.args.accountId);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "gotoPrevPage", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetch", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fileType", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'CSV';
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "downloadStatement", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "downloadStatement"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setFileType", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setFileType"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "back", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "back"), _class.prototype)), _class));
  _exports.default = DialogsFormDownloadStatementComponent;
});