define("clientui/pods/components/pages/page-myprofile-new/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "jquery"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  let PageMyprofileNew = (_dec = Ember.inject.service('system'), _dec2 = Ember._action, _dec3 = Ember._action, (_class = class PageMyprofileNew extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
    }

    get hasPermissions() {
      return this.args.clientportal.get('usingApprovers') === true || this.args.clientportal.get('usingArrangers') === true || this.args.clientportal.get('obtUrl');
    }

    get mayEditPermissions() {
      let mayEditApprovers = this.system.mayEditApprovers(this.inverse);
      let mayEditArrangers = this.system.mayEditArrangers(this.inverse);
      let mayEditPermissions = this.system.mayEditPermissions(this.inverse, this.args.client.get('clientProfile.travelArranger'));
      return mayEditApprovers || mayEditArrangers || mayEditPermissions;
    }

    expandAll() {
      // eslint-disable-next-line ember/no-jquery
      (0, _jquery.default)(".collapsible-section.collapse").collapse('show');
    }

    closeAll() {
      // eslint-disable-next-line ember/no-jquery
      (0, _jquery.default)(".collapsible-section.collapse").collapse('hide');
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "expandAll", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "expandAll"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "closeAll", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "closeAll"), _class.prototype)), _class));
  _exports.default = PageMyprofileNew;
});