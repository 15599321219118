define("clientui/initializers/emt-theme-wtc", ["exports", "clientui/themes/emt-theme-wtc"], function (_exports, _emtThemeWtc) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(application) {
    application.register('emt-theme:emt-theme-wtc', _emtThemeWtc.default, {
      singleton: false
    });
  }

  var _default = {
    name: 'emt-theme-wtc',
    after: 'emt-themes',
    initialize
  };
  _exports.default = _default;
});