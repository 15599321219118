define("clientui/pods/components/widgets/document-item/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember-decorators/component", "clientui/mappings/profiledocumenttypes"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _component, _profiledocumenttypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let DocumentItem = (_dec = (0, _component.classNameBindings)('show:bg-light', 'item.current:bg-primary', 'item.current:text-white'), _dec2 = Ember._action, classic(_class = _dec(_class = (_class2 = class DocumentItem extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "profileDocumentTypes", _profiledocumenttypes.default);
      (0, _defineProperty2.default)(this, "show", false);
    }

    mouseEnter() {
      if (!this.get('item.current')) {
        this.set('show', true);
      }
    }

    mouseLeave() {
      this.set('show', false);
    }

    edit(item) {
      this.set('show', false); //Allow item.current flag to take over

      this.onEdit(item);
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "edit", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "edit"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = DocumentItem;
});