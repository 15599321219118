define("clientui/pods/customeraccounts/route", ["exports", "clientui/superclasses/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CustomeraccountsRoute extends _authenticatedRoute.default {
    model() {
      let promises = {};
      let clientPortal = this.modelFor('application');
      promises['application'] = clientPortal;
      let session = this.session;

      if (session.get('authenticated') === true) {
        let guid = session.get('subject'); //Use query instead of find so that we can pass through the tcpid and so verify that the client is logging in to the correct site.
        //The server will return a redirect error if the two parameters do not match up.

        promises['client'] = this.store.query('client', {
          'guid': guid,
          'tcpid': clientPortal.get('tradeClientProfileId'),
          'pno': -1
        }).then(clients => {
          return clients.get('firstObject');
        }, errors => {
          console.log(errors); //throw errors; do not throw an error as client does not have to be logged in to use this page
        });
      }

      return Ember.RSVP.hash(promises);
    }

    afterModel(promises
    /* , transition */
    ) {
      let client = promises['client'];

      if (client) {
        if (client.clientProfile) {
          if (client.clientProfile.get('accountsRole') != true) {
            this.transitionTo('application');
          }
        } else {
          this.transitionTo('application');
        }
      } else {
        this.transitionTo('application');
      }
    }

    setupController(controller, promises) {
      let client = promises['client'];

      if (client) {
        controller.set('client', client);
      }

      let clientportal = promises['application'];
      controller.set('clientportal', clientportal);
    }

  }

  _exports.default = CustomeraccountsRoute;

  window.__CLASSIC_OWN_CLASSES__.set(CustomeraccountsRoute, true);
});