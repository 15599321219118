define("clientui/pods/components/inputs/validated-ps-object/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "validated-changeset"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _validatedChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2;

  let ValidatedPsObject = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class ValidatedPsObject extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "currentItem", _descriptor2, this);
      console.log('ValidatedPsObject: constructor ' + this.args.propertyName, this.args.changeset.get(this.args.propertyName), "option count", this.args.options ? this.args.options.length : 0, "allowClear", this.args.allowClear);

      if (!(0, _validatedChangeset.isChangeset)(this.args.changeset)) {
        console.log('Argument Passed to ValidatedPsObject is not a changeset for ' + this.args.propertyName);
      }

      this.currentItem = this.args.changeset.get(this.args.propertyName);
    }

    get isValidated() {
      let result = true;

      for (var key in this.args.changeset.error) {
        if (key === this.args.propertyName) {
          console.log('The changeset.error has a error for ' + key, this.args.changeset.error); //['key']);

          result = false;
        }
      }

      return result;
    }

    get inputId() {
      let idName = Ember.guidFor(this) + "-input"; // console.log("inputId", idName);

      return idName;
    }

    get noMargin() {
      var _this$args$noMargin;

      return (_this$args$noMargin = this.args.noMargin) !== null && _this$args$noMargin !== void 0 ? _this$args$noMargin : false;
    }

    get calculatedPlaceHolder() {
      let result = this.args.placeholder;

      if (!result) {
        result = this.args.label;
      }

      return result;
    }

    setAction(item) {
      // console.log('ValidatedPsObject: Setting ' + this.args.propertyName + ' to ' + (item ? item.id : "null"));
      this.args.changeset.set(this.args.propertyName, item);
      this.currentItem = item;

      if (typeof this.args.onUpdate !== 'undefined') {
        // console.log('Calling onUpdate');
        this.args.onUpdate(item);
      }
    }

    validateProperty(changeset, property) {
      return changeset.validate(property);
    }

    handleFocus(select, e) {
      if (this.focusComesFromOutside(e)) {
        select.actions.open();
      }
    }

    focusComesFromOutside(e) {
      let blurredEl = e.relatedTarget;

      if (Ember.isBlank(blurredEl)) {
        return false;
      }

      return !blurredEl.classList.contains('ember-power-select-search-input');
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "currentItem", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setAction", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setAction"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "validateProperty", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "validateProperty"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleFocus", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "handleFocus"), _class.prototype)), _class));
  _exports.default = ValidatedPsObject;
});