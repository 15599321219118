define("clientui/pods/components/inputs/validated-checkbox/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "validated-changeset"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _validatedChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  let ValidatedCheckbox = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class ValidatedCheckbox extends _component.default {
    get type() {
      var _this$args$type;

      return (_this$args$type = this.args.type) !== null && _this$args$type !== void 0 ? _this$args$type : 'checkbox';
    }

    constructor(owner, args) {
      super(owner, args);
      (0, _initializerDefineProperty2.default)(this, "value", _descriptor, this);
      console.log('ValidatedCheckbox: constructor ' + this.args.propertyName, this.args.changeset.get(this.args.propertyName));

      if (!(0, _validatedChangeset.isChangeset)(this.args.changeset)) {
        console.log('Argument Passed to ValidatedCheckbox is not a changeset for ' + this.args.propertyName);
      }

      this.value = this.args.changeset.get(this.args.propertyName); //set tracked value to trigger update
    }

    get currentValue() {
      return Ember.get(this.args.changeset, this.args.propertyName);
    }

    set currentValue(x) {//ignore x as currentValue is a calculated property
    }

    get isValidated() {
      let result = true;

      for (var key in this.args.changeset.error) {
        if (key === this.args.propertyName) {
          console.log('The changeset.error has a error for ' + key, this.args.changeset.error); //['key']);

          result = false;
        }
      }

      return result;
    }

    get inputId() {
      let idName = Ember.guidFor(this) + "-input";
      console.log("inputId", idName);
      return idName;
    }

    get calculatedPlaceHolder() {
      let result = this.args.placeholder;

      if (!result) {
        result = this.args.label;
      }

      return result;
    }

    get nullvaluelabel() {
      var _this$args$nullvaluel;

      return (_this$args$nullvaluel = this.args.nullvaluelabel) !== null && _this$args$nullvaluel !== void 0 ? _this$args$nullvaluel : 'N/A';
    }

    get nullable() {
      var _this$args$nullable;

      return (_this$args$nullable = this.args.nullable) !== null && _this$args$nullable !== void 0 ? _this$args$nullable : false;
    }

    get nullablevalue() {
      let result = '0';

      if (this.value === true) {
        result = '2';
      } else if (this.value === false) {
        result = '1';
      }

      return result;
    }

    set nullablevalue(value) {
      switch (Number(value)) {
        case 2:
          this.args.changeset.set(this.args.propertyName, true);
          console.log("value set to true");
          break;

        case 1:
          this.args.changeset.set(this.args.propertyName, false);
          console.log("value set to false");
          break;

        case 0:
          this.args.changeset.set(this.args.propertyName, null);
          console.log("value set to null");
          break;

        default:
          console.log("Unknown value " + value);
          break;
      }

      this.value = this.args.changeset.get(this.args.propertyName); //set tracked value to trigger update
    }

    validateProperty() {
      console.log('validateProperty: ' + this.args.propertyName);
      return this.args.changeset.validate(this.args.propertyName);
    }

    setAction(e) {
      this.args.changeset.set(this.args.propertyName, e.target.checked);

      if (typeof this.args.onUpdate !== 'undefined') {
        // console.log('Calling onUpdate');
        this.args.onUpdate(e.target.checked);
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "value", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "validateProperty", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "validateProperty"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setAction", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setAction"), _class.prototype)), _class));
  _exports.default = ValidatedCheckbox;
});