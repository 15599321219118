define("clientui/services/goto-previous-page", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let GotoPreviousPageService = (_dec = Ember.inject.service('router'), (_class = class GotoPreviousPageService extends Ember.Service {
    constructor(owner, args) {
      super(owner, args);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor, this);
      (0, _defineProperty2.default)(this, "ENABLED", true);

      if (this.ENABLED) {
        this.router.on('routeWillChange', this, 'routeWillChange');
        this.router.on('routeDidChange', this, 'routeDidChange'); //console.log("GotoPreviousPageService initialised");
      }
    }

    initialise() {
      //called from application route init(). This will trigger the init() method on this service
      if (this.ENABLED) {//console.log("GotoPreviousPageService initialise() called");
      }
    }

    routeWillChange() {
      if (this.ENABLED) {
        // console.log("GotoPreviousPageService: routeWillChange");
        // console.log("GotoPreviousPageService: transition", transition);
        // console.log("GotoPreviousPageService: router.currentRouteName", this.router.currentRouteName);
        if (this.router.currentRouteName && !this.router.currentRouteName.endsWith(".loading") && !this.router.currentRouteName.endsWith(".error")) {
          this.pendingRoute = this.router.currentRouteName; // console.log("GotoPreviousPageService routeWillChange: pendingRoute", this.pendingRoute);

          this.pendingParams = this.buildPendingParams(this.router.currentRoute); // console.log("GotoPreviousPageService routeWillChange: pendingParams: ", this.pendingParams);
        }
      }
    }

    buildPendingParams(routeInfo) {
      let pendingParams = [];

      if (routeInfo) {
        while (!Ember.isEmpty(routeInfo.params)) {
          Object.values(routeInfo.params).forEach(param => {
            // starts from last part of route so each time add to beginning of array to maintain param order
            pendingParams.unshift(param);
          });

          if (routeInfo.parent) {
            routeInfo = routeInfo.parent;
          } else {
            break;
          }
        }
      }

      return pendingParams;
    }

    routeDidChange() {
      if (this.ENABLED) {
        // console.log("GotoPreviousPageService: routeDidChange");
        // console.log("GotoPreviousPageService: transition", transition);
        // console.log("GotoPreviousPageService: router.currentRouteName", this.router.currentRouteName);
        if (this.pendingRoute) {
          if (this.router.currentRouteName != this.pendingRoute) {
            this.previousRoute = this.pendingRoute;
            this.previousParams = this.pendingParams; // console.log("GotoPreviousPageService: routeDidChange: previousRoute", this.previousRoute, ", previousParams", this.previousParams);
          }
        }
      }
    }

    transitionToPreviousPage(defaultRoute) {
      // console.log("GotoPreviousPageService: transitionToPreviousPage previousRoute", this.previousRoute, defaultRoute, defaultRouteParam);
      if (this.previousRoute) {
        this.router.transitionTo(this.previousRoute, ...this.previousParams);
      } else {
        if (defaultRoute) {
          for (var _len = arguments.length, defaultParams = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
            defaultParams[_key - 1] = arguments[_key];
          }

          if (defaultParams && defaultParams.length) {
            this.router.transitionTo(defaultRoute, ...defaultParams);
          } else {
            this.router.transitionTo(defaultRoute);
          }
        } else {
          window.history.back();
        }
      }
    }

    replaceWithPreviousPage(defaultRoute) {
      // console.log("GotoPreviousPageService: replaceWithPreviousPage previousRoute", this.previousRoute, defaultRoute, defaultRouteParam);
      if (this.previousRoute) {
        this.router.replaceWith(this.previousRoute, ...this.previousParams);
      } else {
        if (defaultRoute) {
          for (var _len2 = arguments.length, defaultParams = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
            defaultParams[_key2 - 1] = arguments[_key2];
          }

          if (defaultParams && defaultParams.length) {
            this.router.replaceWith(defaultRoute, ...defaultParams);
          } else {
            this.router.replaceWith(defaultRoute);
          }
        } else {
          window.history.back();
        }
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = GotoPreviousPageService;
});