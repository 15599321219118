define("clientui/pods/managedprofiles/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ManagedProfilesController extends Ember.Controller {
    get adminSettings() {
      return {
        routePlural: 'managedprofiles',
        routeSingular: 'profile',
        parentModelName: 'client'
      };
    }

  }

  _exports.default = ManagedProfilesController;

  window.__CLASSIC_OWN_CLASSES__.set(ManagedProfilesController, true);
});