define("clientui/pods/components/widgets/search-form-pills/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8fv2U7f7",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[32,0,[\"displayParam\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"badge rounded-pill bg-dark text-light\"],[12],[2,\"\\n\"],[6,[37,2],[[32,0,[\"isDate\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[[32,0,[\"paramLabel\"]],\": \"],null]],[2,\" \"],[1,[30,[36,1],[[32,0,[\"displayValue\"]]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[30,[36,0],[[32,0,[\"paramLabel\"]],\": \"],null]],[2,\" \"],[1,[32,0,[\"displayValue\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"boss-date\",\"if\"]}",
    "moduleName": "clientui/pods/components/widgets/search-form-pills/template.hbs"
  });

  _exports.default = _default;
});