define("clientui/pods/components/pages/page-customeraccounts/page-customeraccounts-statements-landingpage/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;

  let PagesPageCustomeraccountsPageCustomeraccountsStatementsLandingpageComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class PagesPageCustomeraccountsPageCustomeraccountsStatementsLandingpageComponent extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "selectedAccount", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "onlyOneAccount", _descriptor3, this);

      if (this.args.accounts) {
        if (this.args.accounts.length > 0) {
          this.selectedAccount = this.args.client.clientProfile.get('accountsDefaultAccount');

          if (this.args.accounts.length == 1) {
            this.onlyOneAccount = true;
          } else {
            this.onlyOneAccount = false;
          }
        }
      }
    }

    setAccount(selected) {
      this.selectedAccount = selected;
    }

    goToAccountStatement() {
      if (this.selectedAccount) {
        this.router.transitionTo('customeraccounts.statements.statement', Ember.get(this.selectedAccount, 'id'));
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedAccount", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onlyOneAccount", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setAccount", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "setAccount"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "goToAccountStatement", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "goToAccountStatement"), _class.prototype)), _class));
  _exports.default = PagesPageCustomeraccountsPageCustomeraccountsStatementsLandingpageComponent;
});