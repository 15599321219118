define("clientui/pods/components/pages/page-customeraccounts/page-customeraccounts-booking/bookingitem-expanded/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "moment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let BookingitemExpanded = (_dec = Ember.inject.service('emt-helper'), (_class = class BookingitemExpanded extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "emthelper", _descriptor, this);
    }

    get invoiceLinesColumns() {
      return [this.emthelper.buildStringCol("quantity", "Quantity"), this.emthelper.buildStringCol("description", "Description"), this.emthelper.buildTotalledMoneyIncZeroCol("fcPrice", "Unit Cost"), this.emthelper.buildTotalledMoneyIncZeroCol("grossAmount", "Total Amount")];
    }

    get travelTime() {
      let result = "None";
      let mins = this.args.record.travelTimeInMinsPerPax;

      if (mins > 0) {
        let duration = _moment.default.duration(mins, "minutes");

        result = Math.floor(duration.asHours()) + _moment.default.utc(duration.asMilliseconds()).format(":mm") + " hours";
      }

      return result;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "emthelper", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BookingitemExpanded;
});