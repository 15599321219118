define("clientui/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SsYLFysg",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"Clientui\"],null]],[2,\"\\n\\n\"],[8,\"welcome-page\",[],[[],[]],null],[2,\"\\n\"],[2,\"\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"page-title\",\"-outlet\",\"component\"]}",
    "moduleName": "clientui/templates/application.hbs"
  });

  _exports.default = _default;
});