define("clientui/pods/components/ember-model-table/cell-checkbox/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let CellCheckbox = (_dec = (0, _component.classNames)('text-center'), classic(_class = _dec(_class = class CellCheckbox extends Ember.Component {}) || _class) || _class);
  _exports.default = CellCheckbox;
});