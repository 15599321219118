define("clientui/validators/regex-if-present", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateRegexIfPresent;

  function validateRegexIfPresent() {
    let regexKey = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    let requiresFlagKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    let allowBlank = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    return (key, newValue, oldValue, changes, content) => {
      let regexString = Ember.get(changes, regexKey) || Ember.get(content, regexKey);
      let requiresFlag = true;

      if (!Ember.isBlank(requiresFlagKey)) {
        requiresFlag = Ember.get(changes, requiresFlagKey) || Ember.get(content, requiresFlagKey);
      }

      if (requiresFlag === true) {
        if (allowBlank && Ember.isBlank(newValue)) {
          return true;
        } else if (!Ember.isBlank(regexString)) {
          //wrap in ^..$
          let regexString2 = '^' + regexString + '$';
          let regex = new RegExp(regexString2);
          let result = regex.test(newValue); //console.log("validating '" + newValue + "' against '" + regexString2 + "' returns " + result);

          if (result) {
            return true;
          } else {
            return "Invalid format entered"; //"Value does not match required expression " + regexString;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    };
  }
});