define("clientui/pods/customeraccounts/statements/statement/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "clientui/superclasses/authenticated-route"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let CustomeraccountsStatementsStatementRoute = (_dec = Ember.inject.service, (_class = class CustomeraccountsStatementsStatementRoute extends _authenticatedRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "fetch", _descriptor, this);
    }

    model(params) {
      let promises = {};
      let accountId = params.account_id;
      promises['statement'] = this.fetchData(accountId);
      promises['accountId'] = accountId;
      promises['account'] = this.store.findRecord('account', accountId);
      return Ember.RSVP.hash(promises);
    }

    setupController(controller, promises) {
      controller.set('statement', promises['statement']);
      controller.set('accountId', promises['accountId']);
      controller.set('account', promises['account']);
    }

    fetchData(accountId) {
      return this.fetch.fetch('get', 'cors', 'statementobjects/' + accountId).then(response => {
        if (response.ok) {
          return response.json().then(statement => {
            return statement;
          });
        } else {
          return null;
        }
      }, errors => {
        throw errors;
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CustomeraccountsStatementsStatementRoute;
});