define("clientui/mixins/child-clientgdpr-mixin", ["exports", "clientui/validations/clientgdpr"], function (_exports, _clientgdpr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ClientNameValidations: _clientgdpr.default,
    validationName: 'ClientNameValidations',
    paramName: '',
    modelType: 'client',

    newPreSaveHook() {//(changeset) {
    },

    editPreSaveHook() {//(changeset) {
    }

  });

  _exports.default = _default;
});