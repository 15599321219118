define("clientui/serializers/client", ["exports", "clientui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'cuiId',
    attrs: {
      contactDetails: {
        embedded: 'always'
      },
      addresses: {
        embedded: 'always'
      },
      createdOn: {
        serialize: false
      },
      updatedOn: {
        serialize: false
      },
      contactType: {
        serialize: false
      },
      mainPhone: {
        serialize: false
      },
      mainEmail: {
        serialize: false
      },
      mainMobile: {
        serialize: false
      },
      gender: {
        serialize: 'id',
        deserialize: 'records'
      },
      genderIdentity: {
        serialize: 'id',
        deserialize: 'records'
      },
      notes: {
        embedded: 'always'
      },
      owner: {
        serialize: 'id',
        deserialize: 'records'
      },
      clientProfile: {
        embedded: 'always'
      },
      marketingGroups: {
        serialize: 'id',
        deserialize: 'records'
      }
    }
  });

  _exports.default = _default;
});