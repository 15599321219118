define("clientui/pods/page/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "clientui/superclasses/unauthenticated-route", "clientui/config/environment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _unauthenticatedRoute, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  //Note that this is NOT an authenticated route
  let PageRoute = (_dec = Ember.inject.service('system'), _dec2 = Ember.inject.service('keycloak-session'), _dec3 = Ember.inject.service('router'), _dec4 = Ember._tracked, _dec5 = Ember._action, (_class = class PageRoute extends _unauthenticatedRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "thisLink", _descriptor4, this);
      (0, _defineProperty2.default)(this, "transitionIn", null);
    }

    beforeModel(transition) {
      this.transitionIn = transition;
    }

    model(params) {
      this.linkid = params.link_id;
      let promises = {};
      let clientPortal = this.modelFor('application');
      promises['application'] = clientPortal;
      let session = this.session;

      if (session.get('authenticated') === true) {
        let guid = session.get('subject'); //Use query instead of find so that we can pass through the tcpid and so verify that the client is logging in to the correct site.
        //The server will return a redirect error if the two parameters do not match up.

        promises['client'] = this.store.query('client', {
          'guid': guid,
          'tcpid': clientPortal.get('tradeClientProfileId'),
          'pno': -1
        }).then(clients => {
          return clients.get('firstObject');
        }, errors => {
          console.log(errors); //throw errors; do not throw an error as client does not have to be logged in to use this page
        });
      }

      return Ember.RSVP.hash(promises);
    }

    setupController(controller, promises) {
      console.log("Page " + this.linkid + ": setupController");
      let client = promises['client']; //Note client may be null

      let clientportal = promises['application'];
      controller.set('link', null); //clear any previous link from controller

      let thisLink = clientportal.get('links').findBy('id', this.linkid);

      if (!thisLink) {
        thisLink = clientportal.get('links').findBy('linkName', this.linkid);
      }

      console.log(thisLink);

      if (!thisLink) {
        console.log("Page " + this.linkid + ": does not exist - returning to homepage");
        this.transitionTo('application'); //return to homepage if invalid link chosen
      } else if (!this.system.isLinkPermitted(thisLink, client)) {
        console.log("Page " + this.linkid + ": Not permitted - returning to homepage");
        this.transitionTo('application'); //return to homepage if invalid link chosen
      } else {
        this.thisLink = thisLink;

        if (!Ember.isBlank(thisLink.get('internalRoute'))) {
          console.log("Page " + this.linkid + ": Transitioning to " + thisLink.get('internalRoute'));
          this.transitionTo(thisLink.get('internalRoute'));
        } else if (Ember.isBlank(thisLink.get('pageHtml'))) {
          console.log("Page " + this.linkid + ": pageHtml is blank - transitioning to application"); //HTML is missing so return to homepage

          this.transitionTo('application');
        } else {
          if (thisLink.authRequired && this.session.get('authenticated') !== true) {
            //trigger auth
            this.session.checkTransition(this.transitionIn);
          } else {
            console.log("Page " + this.linkid + ": pageHtml is not blank - attempting to display");
            this.link = thisLink;
            controller.set('link', thisLink);

            if (client) {
              controller.set('client', client);
            }

            controller.set('clientportal', clientportal);
            controller.set('system', this.system);
          }
        }
      }
    }

    didTransition() {
      let thisLink = this.thisLink;
      let title = this.routeName.replace('.', '/');
      let pageName = thisLink.linkName ? thisLink.linkName : thisLink.id;
      let friendlyUrl = window.location.origin + '/' + title + '/' + pageName;
      let host = window.location.host;

      if (window._paq) {
        window._paq.push(['setCustomUrl', friendlyUrl]);

        window._paq.push(['setDocumentTitle', pageName]);

        window._paq.push(['deleteCustomVariables', 'page']);

        window._paq.push(["setCustomDimension", 1, _environment.default.APP.CLIENTCODE]);

        window._paq.push(["setCustomDimension", 2, host]);

        let guid = this.session.get('subject');

        if (guid) {
          window._paq.push(['setUserId', guid]);
        }

        window._paq.push(['trackPageView']);
      }

      return true;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "thisLink", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "didTransition", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "didTransition"), _class.prototype)), _class));
  _exports.default = PageRoute;
});