define("clientui/pods/components/ember-model-table/cell-money/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zB61pJCH",
    "block": "{\"symbols\":[\"@column\",\"@record\",\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"nowrap\"],[17,3],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,2],[32,1,[\"propertyName\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,2],[[30,[36,0],[[32,2],[32,1,[\"propertyName\"]]],null]],[[\"currency\"],[[30,[36,1],[[32,1,[\"currency\"]],[32,1,[\"currency\"]],[30,[36,1],[[32,1,[\"currencyProperty\"]],[30,[36,0],[[32,2],[32,1,[\"currencyProperty\"]]],null]],null]],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"get\",\"if\",\"boss-money\"]}",
    "moduleName": "clientui/pods/components/ember-model-table/cell-money/template.hbs"
  });

  _exports.default = _default;
});