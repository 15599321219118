define("clientui/pods/components/inputs/validated-customfield/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ValidatedCustomfield extends _component.default {
    constructor(owner, args) {
      super(owner, args); // console.log("validated-customfield.didReceiveAttrs");
      // If we are to display a selectionbox, and the current value is not on the list then set the field on the changeset to null

      if (!Ember.isBlank(this.args.regex) && this.args.isSelection === true && this.args.changeset != null && !Ember.isBlank(this.args.changeset.get(this.args.propertyName))) {
        let array = this.args.regex.split('|');

        if (!array.includes(this.args.changeset.get(this.args.propertyName))) {
          Ember.run.next(this, function () {
            this.args.changeset.set(this.args.propertyName, null);
          });
        }
      }
    }

    get selectionOptions() {
      // console.log('regex=' + this.regex + ' isSelection=' + this.isSelection);
      let list = [];

      if (!Ember.isBlank(this.args.regex) && this.args.isSelection === true) {
        let array = this.args.regex.split('|');
        array.forEach(x => {
          list.push({
            'id': x
          });
        }); // console.log(list);
      }

      return list;
    }

  }

  _exports.default = ValidatedCustomfield;

  window.__CLASSIC_HAS_CONSTRUCTOR__.set(ValidatedCustomfield, true);

  window.__CLASSIC_OWN_CLASSES__.set(ValidatedCustomfield, true);
});