define("clientui/superclasses/sublist-route-del", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor"], function (_exports, _applyDecoratedDescriptor2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let SublistRouteDelRoute = (_dec = Ember._action, _dec2 = Ember._action, classic(_class = (_class2 = class SublistRouteDelRoute extends Ember.Route {
    // routePrefix = '';
    // routePlural = '';
    // routeSingular = '';
    // routeLeaf = '';
    // paramName = '';
    // parentModelName = '';
    // modelType = '';
    // parentModelFieldName = '';
    // parentModelFieldNamePrefix = '';
    // validationName = '';
    // dialogTitle = '';
    // enableDebugLogging = true;
    delPreSaveHook() {//(thisModel, parentModel) {
    }

    init() {
      super.init(...arguments);
      this.log('Route: ' + this.buildRoute() + '->' + this.routeLeaf);
    }

    model(params) {
      this.log('Route: ' + this.routePrefix + '->' + this.routePlural + '->' + this.routeSingular + '->' + this.routeLeaf + ' Getting model for ' + this.modelType + ' id ' + params[this.paramName]);
      this.log(params);
      return this.store.peekRecord(this.modelType, params[this.paramName]);
    }

    setupController(controller, model) {
      this.log('Route: ' + this.routePrefix + '->' + this.routePlural + '->' + this.routeSingular + '->' + this.routeLeaf + ' setupcontroller');
      let parentmodel = this.modelFor(this.routePrefix + '.' + this.routePlural + '.' + this.routeSingular);
      controller.set('parentModel', parentmodel);
      controller.set('model', model);
      controller.set('validation', this.get(this.validationName));
      controller.set('title', this.dialogTitle);
    }

    log(s) {
      if (this.enableDebugLogging) {
        console.log(s);
      }
    }

    buildRoute() {
      let newRoute = '';

      if (this.routePrefix) {
        newRoute += this.routePrefix + '.';
      }

      if (this.routePlural) {
        newRoute += this.routePlural + '.';
      }

      if (this.routeSingular) {
        newRoute += this.routeSingular;
      }

      return newRoute;
    }

    delete() {
      let parentModel = this.get('controller.parentModel');
      let thisModel = this.get('controller.model');
      this.delPreSaveHook(thisModel, parentModel);
      parentModel.get(this.parentModelFieldNamePrefix + this.parentModelFieldName).removeObject(thisModel);
      return parentModel.save().then(returnItem => {
        this.log(returnItem);
        this.log('unloading deleted ' + this.modelType + ' id ' + thisModel.get('id'));
        thisModel.unloadRecord(); //Remove ghost record from Ember Store

        this.replaceWith(this.buildRoute(), this.get('controller.parentModel.id'));
      }, errors => {
        this.log('Del' + this.routeLeaf + ' ' + this.parentModelName + '.save returned FAILURE');
        this.log(errors);
        throw errors;
      });
    }

    back() {
      this.log('Rollback Clicked');
      this.replaceWith(this.buildRoute(), this.get('controller.parentModel.id'));
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "delete", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "delete"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "back", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "back"), _class2.prototype)), _class2)) || _class);
  _exports.default = SublistRouteDelRoute;
});