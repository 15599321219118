define("clientui/pods/components/widgets/pop-over/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OHlsdBpP",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[4,[38,0],[[32,0,[\"setupPopover\"]]],null],[12],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\"]}",
    "moduleName": "clientui/pods/components/widgets/pop-over/template.hbs"
  });

  _exports.default = _default;
});