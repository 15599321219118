define("clientui/pods/components/sections/page-coordinator-searchlist/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V6PMbC+J",
    "block": "{\"symbols\":[\"&attrs\",\"@clientRoles\",\"@onDataNavigation\"],\"statements\":[[11,\"div\"],[24,0,\"col-12 mt-3\"],[17,1],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"card-header\"],[12],[2,\"\\n      User Role Search Results\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n      \"],[8,\"ember-model-table/models-table-searchresults\",[],[[\"@data\",\"@columns\",\"@dataNavigationAction\",\"@selectAction\"],[[32,2],[32,0,[\"columns\"]],[32,3],[32,0,[\"selectAction\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "clientui/pods/components/sections/page-coordinator-searchlist/template.hbs"
  });

  _exports.default = _default;
});