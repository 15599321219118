define("clientui/pods/downloadabledocuments/downloadabledocument/archive/route", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "clientui/superclasses/authenticated-route"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  let ArchiveRoute = (_dec = Ember._action, _dec2 = Ember._action, (_class = class ArchiveRoute extends _authenticatedRoute.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _defineProperty2.default)(this, "routeLeaf", 'edit');
      (0, _defineProperty2.default)(this, "enableDebugLogging", true);
      (0, _defineProperty2.default)(this, "paramName", 'downloadabledocument_id');
      (0, _defineProperty2.default)(this, "modelType", 'downloadabledocument');
      Object.assign(this, this.controllerFor('downloadabledocuments').downloadabledocumentSettings);
    }

    editPreSaveHook() {}

    editPostSaveHook() {}

    model() {
      return this.modelFor(this.routePlural + '.' + this.routeSingular);
    }

    setupController(controller, model) {
      if (model.model) {
        model = model.model; //extract model from hash
      }

      controller.set('model', model);

      if (this.validation) {
        controller.set('validation', this.validation);
      }

      if (model.archived) {
        controller.set('title', 'Un-Archive Document');
      } else {
        controller.set('title', 'Archive Document');
      }

      this.system.set('saving', false);
      this.thisController = controller;
    }

    log() {
      if (this.enableDebugLogging) {
        console.log(...arguments);
      }
    }

    back() {
      this.replaceWith('downloadabledocuments.search');
    }

    save(changeset) {
      this.system.set('saving', true);
      this.editPreSaveHook(changeset);
      return changeset.save().then(response => {
        this.editPostSaveHook(response);
        this.system.set('saving', false);
        this.replaceWith('downloadabledocuments.search');
      }, errors => {
        this.log('Archive ' + this.modelType + '.save returned FAILURE');
        this.log(errors);
        this.system.set('saving', false);
        throw errors;
      });
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "back", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "back"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "save", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  _exports.default = ArchiveRoute;
});