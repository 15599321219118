define("clientui/mappings/adapters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   *This is a simple map which identifies which adapter should be used for each model.
   *If a model is not mentioned here, it uses the application adapter
   */
  var _default = {
    //model_name : adapter_name
    'clientportal': 'unauthenticated',
    'faqitem': 'unauthenticated',
    'faqcategory': 'unauthenticated'
  };
  _exports.default = _default;
});