define("clientui/pods/managedprofiles/newprofile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TXC4LWyB",
    "block": "{\"symbols\":[],\"statements\":[[8,\"widgets/managedpage-subtitle\",[],[[\"@managedClient\",\"@onBack\"],[[34,7],[30,[36,5],[\"back\"],null]]],null],[2,\"\\n\\n\"],[6,[37,8],[[35,7]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"widgets/card-basic\",[],[[\"@header\",\"@inverse\",\"@title\"],[[34,0],true,\"Please complete the following steps\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"wizards/wizard-create-profile\",[],[[\"@client\",\"@clientportal\",\"@inverse\",\"@onSave\",\"@onRollback\",\"@onClose\"],[[30,[36,3],[[35,7],[35,6]],null],[34,4],true,[30,[36,5],[\"save\"],null],[30,[36,5],[\"back\"],null],[30,[36,5],[\"doNothing\"],null]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"widgets/card-basic\",[],[[\"@header\",\"@inverse\",\"@title\"],[[34,0],true,\"Enter Name and Email for New Traveller Profile\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"wizards/wizard-start-profile\",[],[[\"@fields\",\"@clientportal\",\"@inverse\",\"@onSave\",\"@onRollback\",\"@onClose\"],[[30,[36,3],[[35,2],[35,1]],null],[34,4],true,[30,[36,5],[\"startProfile\"],null],[30,[36,5],[\"backProfile\"],null],[30,[36,5],[\"parseNewClient\"],null]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"title\",\"NewTravellerValidations\",\"fields\",\"changeset\",\"clientportal\",\"route-action\",\"validation\",\"model\",\"if\"]}",
    "moduleName": "clientui/pods/managedprofiles/newprofile/template.hbs"
  });

  _exports.default = _default;
});