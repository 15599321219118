define("clientui/pods/components/dialogs/form-profile-edit-professional/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _class2, _descriptor, _descriptor2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let FormProfileEditProfessional = (_dec = Ember.inject.service('system'), _dec2 = Ember.inject.service, _dec3 = Ember.computed('client.corporateTradeClientSelection', 'accounts.[]'), _dec4 = Ember.computed('visibleAccounts.[]'), _dec5 = Ember.computed('tradeclients.[]'), _dec6 = Ember.computed('clientportal.{customCaption1,customCaption2,customCaption3,customCaption4,customCaption5}', 'client.owner.{customCaption1,customCaption2}'), _dec7 = Ember.computed('clientportal.tradeClientCaption'), _dec8 = Ember.computed('clientportal.accountCaption'), _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, classic(_class = (_class2 = class FormProfileEditProfessional extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _defineProperty2.default)(this, "currentOwner", null);
    }

    init() {
      super.init(...arguments);
      console.log('Init Client Professional Form');
    }

    didReceiveAttrs() {
      this.set('tradeclients', this.store.query('tradeclient', {
        updatingprofile: 'true',
        pno: -1
      }));
      this.set('accounts', this.store.query('account', {
        updatingprofile: 'true',
        pno: -1
      }));
      this.set('tiers', this.system.enum('clientprofiletier'));

      if (Ember.isPresent(this.get('client.owner.id'))) {
        this.client.set('corporateTradeClientSelection', this.get('client.owner.id'));
      }

      this.set('currentOwner', this.get('client.owner'));

      if (Ember.isPresent(this.get('client.clientProfile.defaultAccount.id'))) {
        console.log("Setting defaultAccountSelection to " + this.get('client.clientProfile.defaultAccount.id'));
        this.client.set('defaultAccountSelection', this.get('client.clientProfile.defaultAccount.id'));
      }

      this.client.set('tier', this.get('client.clientProfile.tier'));
      this.client.set('customValue1', this.client.get('clientProfile.customValue1'));
      this.client.set('customValue2', this.client.get('clientProfile.customValue2'));
      this.client.set('customValue3', this.client.get('clientProfile.customValue3'));
      this.client.set('customValue4', this.client.get('clientProfile.customValue4'));
      this.client.set('customValue5', this.client.get('clientProfile.customValue5'));
      this.client.set('customValue6', this.client.get('clientProfile.customValue6'));
      this.client.set('customValue7', this.client.get('clientProfile.customValue7'));

      if (this.get('clientportal.customCaption1')) {
        this.client.set('customRegex1', this.get('clientportal.customRegex1'));
      }

      if (this.get('clientportal.customCaption2')) {
        this.client.set('customRegex2', this.get('clientportal.customRegex2'));
      }

      if (this.get('clientportal.customCaption3')) {
        this.client.set('customRegex3', this.get('clientportal.customRegex3'));
      }

      if (this.get('clientportal.customCaption4')) {
        this.client.set('customRegex4', this.get('clientportal.customRegex4'));
      }

      if (this.get('clientportal.customCaption5')) {
        this.client.set('customRegex5', this.get('clientportal.customRegex5'));
      }

      if (this.get('client.owner.customCaption1')) {
        this.client.set('customRegex6', this.get('client.owner.customRegex1'));
      }

      if (this.get('client.owner.customCaption2')) {
        this.client.set('customRegex7', this.get('client.owner.customRegex2'));
      }

      this.system.set('saving', false);
    }

    get visibleAccounts() {
      let ownerId = Number(this.get('client.corporateTradeClientSelection'));

      if (ownerId > 0) {
        //console.log("ownerId " + ownerId + " " + (typeof ownerId));
        //console.log(this.get('accounts'));
        let list = this.accounts.filter(item => item.get('accountContactId') === ownerId); //console.log("Original List" + this.get('accounts').length);
        //console.log("Filtered List" + list.length);

        return list;
      } else {
        return this.accounts;
      }
    }

    get onlyOneAccount() {
      let result = false;

      if (this.visibleAccounts) {
        result = this.visibleAccounts.length == 1;

        if (result) {
          Ember.run.next(this, function () {
            let account = this.visibleAccounts.objectAt(0);
            this.client.set('defaultAccountCaption', account.get('selectDisplayText'));
            this.client.set('defaultAccountSelection', account.get('id'));
          });
        } else {
          Ember.run.next(this, function () {
            this.client.set('defaultAccountCaption', null);
          });
        }
      }

      return result;
    }

    get onlyOneTradeClient() {
      let result = false;

      if (this.tradeclients) {
        result = this.tradeclients.length == 1;

        if (result) {
          Ember.run.next(this, function () {
            let tradeClient = this.tradeclients.objectAt(0);
            this.client.set('corporateTradeClientCaption', tradeClient.get('selectDisplayText'));
            this.client.set('corporateTradeClientSelection', tradeClient.get('id'));
          });
        } else {
          Ember.run.next(this, function () {
            this.client.set('corporateTradeClientCaption', null);
          });
        }
      }

      return result;
    }

    get hasCustomFields() {
      let result = false;

      if (!Ember.isBlank(this.get('clientportal.customCaption1')) || !Ember.isBlank(this.get('clientportal.customCaption2')) || !Ember.isBlank(this.get('clientportal.customCaption3')) || !Ember.isBlank(this.get('clientportal.customCaption4')) || !Ember.isBlank(this.get('clientportal.customCaption5')) || !Ember.isBlank(this.get('client.owner.customCaption1')) || !Ember.isBlank(this.get('client.owner.customCaption2'))) {
        result = true;
      }

      return result;
    }

    get tradeClientCaption() {
      let result = this.get('clientportal.tradeClientCaption');

      if (Ember.isBlank(result)) {
        result = "Trade Client";
      }

      return result + ' *';
    }

    get accountCaption() {
      let result = this.get('clientportal.accountCaption');

      if (Ember.isBlank(result)) {
        result = "Account";
      }

      return result + ' *';
    }

    save() {
      this.system.set('saving', true);
      let client = this.client;
      client.validate().then(() => {
        if (client.get("isValid")) {
          this.client.get('clientProfile').set('customValue1', this.client.get('customValue1'));
          this.client.get('clientProfile').set('customValue2', this.client.get('customValue2'));
          this.client.get('clientProfile').set('customValue3', this.client.get('customValue3'));
          this.client.get('clientProfile').set('customValue4', this.client.get('customValue4'));
          this.client.get('clientProfile').set('customValue5', this.client.get('customValue5'));
          this.client.get('clientProfile').set('customValue6', this.client.get('customValue6'));
          this.client.get('clientProfile').set('customValue7', this.client.get('customValue7'));
          this.client.get('clientProfile').set('tier', this.client.get('tier'));
          let fieldName = null;
          let selectionId = 0;
          let model = null;
          let promises = {};

          if (Ember.isPresent(this.get('client.corporateTradeClientSelection'))) {
            fieldName = 'owner';
            model = 'tradeclient';
            selectionId = this.get('client.corporateTradeClientSelection');
            promises[fieldName] = this.store.peekRecord(model, selectionId);
          }

          if (Ember.isPresent(this.get('client.defaultAccountSelection'))) {
            fieldName = 'clientProfile.defaultAccount';
            model = 'account';
            selectionId = this.get('client.defaultAccountSelection');
            promises[fieldName] = this.store.peekRecord(model, selectionId);
          }

          Ember.RSVP.hash(promises).then(item => {
            Object.keys(item).forEach(key => {
              client.set(key, item[key]);
            });
            this.onSave(client).then(() => {
              // Promise
              console.log('clientProfessional Promise Saved Successfully in Component');
              this.onClose(); //this.onRefreshRoute();

              this.system.set('saving', false);
            }, errors => {
              console.log('clientProfessional Promise Failed to Save in Component');
              console.log(errors);
              this.set('servererrors', errors);
              this.system.set('saving', false);
            });
          }, errors => {
            console.log('clientProfessional Promise Failed to Save in Component - unable to find one of gender/paxType');
            console.log(errors);
            this.set('servererrors', errors);
            this.system.set('saving', false);
          });
        } else {
          this.system.set('saving', false);
        }
      }, () => {
        this.system.set('saving', false);
      });
    }

    back() {
      //Rollback any changes
      this.client.rollback();

      this.client._content._internalModel._record.rollbackAttributes(); //Close dialog


      this.onClose();
      this.onRollback();
    } //If Account is manually set then set tradeClient to match


    updateAccount(newAccount) {
      if (newAccount) {// if (newAccount.get('accountContactId') > 0) {
        //   //console.log("setting corporateTradeClientSelection to " + newAccount.get('accountContactId'));
        //   this.client.set('corporateTradeClientSelection', newAccount.get('accountContactId'));
        //   //this.filterVisibleAccounts(newAccount.get('accountContactId'));
        // }
        // else {
        //   //console.log("setting corporateTradeClientSelection to Null");
        //   this.client.set('corporateTradeClientSelection', null);
        //   //this.filterVisibleAccounts(null); //show all
        // }
      }
    } //If tradeClient is manually set then limit accounts list to accounts within that trade client


    updateOwner(newOwner) {
      if (newOwner) {
        let currentAccountId = this.get('client.defaultAccountSelection');
        let keepCurrent = false;

        if (currentAccountId) {
          let currentAccount = this.store.peekRecord('account', currentAccountId);

          if (currentAccount) {
            if (currentAccount.get('accountContactId')) {
              if (currentAccount.get('accountContactId') === newOwner.get('id')) {
                keepCurrent = true; //console.log("current account is valid = keep it");
              }
            }
          }
        }

        if (!keepCurrent) {
          //console.log("resettng defaultAccountSelection")
          this.client.set('defaultAccountSelection', '');
        }

        if (newOwner.get('customCaption1')) {
          this.client.set('customRegex6', newOwner.get('customRegex1'));
        }

        if (newOwner.get('customCaption2')) {
          this.client.set('customRegex7', newOwner.get('customRegex2'));
        }
      } else {
        this.set('client.corporateTradeClientSelection', 0);
      }

      this.set('currentOwner', newOwner);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "visibleAccounts", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "visibleAccounts"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "onlyOneAccount", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "onlyOneAccount"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "onlyOneTradeClient", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "onlyOneTradeClient"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "hasCustomFields", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "hasCustomFields"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "tradeClientCaption", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "tradeClientCaption"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "accountCaption", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "accountCaption"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "save", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "back", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "back"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "updateAccount", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "updateAccount"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "updateOwner", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "updateOwner"), _class2.prototype)), _class2)) || _class);
  _exports.default = FormProfileEditProfessional;
});