define("clientui/pods/components/widgets/address-line-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wpbjzExl",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row border-bottom border-top\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-4\"],[12],[2,\"\\n    \"],[10,\"small\"],[12],[2,\"\\n      \"],[10,\"strong\"],[12],[2,\"\\n        \"],[1,[35,0,[\"description\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-8\"],[12],[2,\"\\n    \"],[8,\"widgets/address-line-item-value\",[],[[\"@address\"],[[34,0]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"address\"]}",
    "moduleName": "clientui/pods/components/widgets/address-line-item/template.hbs"
  });

  _exports.default = _default;
});