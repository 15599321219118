define("clientui/models/contact", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model", "clientui/models/modelwithdependentrelationships"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model, _modelwithdependentrelationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;

  let Contact = (_dec = (0, _model.attr)('datetime'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('datetime'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('number'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('boolean'), _dec10 = (0, _model.attr)('string', {
    defaultValue: 'CT'
  }), _dec11 = (0, _model.attr)('datetime'), _dec12 = (0, _model.attr)('datetime'), _dec13 = (0, _model.attr)(), (_class = class Contact extends _modelwithdependentrelationships.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "createdOn", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "createdBy", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "updatedOn", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "updatedBy", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "version", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "mainPhone", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "mainEmail", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "mainMobile", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "closed", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "contactType", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "firstAccess", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "lastAccess", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "emberAction", _descriptor13, this);
    } //For sending action messages back to BOSS e.g. merge clients


  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "createdOn", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "createdBy", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updatedOn", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updatedBy", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "version", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "mainPhone", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "mainEmail", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "mainMobile", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "closed", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "contactType", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "firstAccess", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "lastAccess", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "emberAction", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Contact;
});