define("clientui/models/account", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model", "accounting/format-money", "accounting/to-fixed"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model, _formatMoney, _toFixed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  let Account = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('number'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('boolean'), _dec8 = (0, _model.attr)('number'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('number'), _dec11 = (0, _model.attr)('string'), (_class = class Account extends _model.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "name", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "accountCode", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "accountContactId", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "fop", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "fopLabel", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "nlSubCode", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "dualInvoicingRequired", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "creditLimit", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "currency", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "creditDays", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "directDebitConfigLabel", _descriptor11, this);
    }

    get accountName() {
      return this.name;
    }

    get selectDisplayText() {
      return `${this.accountCode} - ${this.name}`;
    }

    get creditLimitText() {
      if (this.creditLimit == -1) {
        return `Unlimited ${this.currency}`;
      } else {
        return (0, _formatMoney.default)((0, _toFixed.default)(this.creditLimit, 2), {
          symbol: this.currency,
          precision: 2,
          format: "%v %s"
        });
      }
    }

    get fopAndLimit() {
      if (this.fop === "CR") {
        return `${this.fopLabel} : ${this.creditDays} Days`;
      } else if (this.fop === "DD") {
        return `${this.directDebitConfigLabel} : ${this.creditLimitText}`;
      } else {
        return `${this.fopLabel}`;
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "accountCode", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "accountContactId", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fop", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fopLabel", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "nlSubCode", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dualInvoicingRequired", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "creditLimit", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "currency", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "creditDays", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "directDebitConfigLabel", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Account;
});