define("clientui/pods/components/inputs/validated-address/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor;

  let ValidatedAddress = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class ValidatedAddress extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _initializerDefineProperty2.default)(this, "errors", _descriptor, this);
      let address = this.args.address;

      if (address) {
        if (address.get('isNew')) {
          if (this.countries) {
            address.set('newCountry', this.countries[0]);
          }
        }

        address.set('addressErrors', []);
      }
    } // didReceiveAttrs() {
    //   let address = this.address;
    //   if (address) {
    //     if (address.get('isNew')) {
    //       if (this.countries) {
    //         address.set('newCountry', this.countries.get('firstObject'));
    //       }
    //     }
    //     address.set('addressErrors', []);
    //   }
    // }


    doValidate() {
      let newErrors = [];
      let item = this.args.address.newLine1;

      if (Ember.isBlank(item)) {
        newErrors.pushObject("Line 1 cannot be blank");
      }

      item = null;

      if (this.args.address.newCountry) {
        item = this.args.address.newCountry.get("id");
      }

      if (Ember.isBlank(item)) {
        newErrors.pushObject("Country cannot be blank");
      }

      this.errors = newErrors;
      this.args.address.addressErrors = newErrors;
      console.log("Address Error Count:", this.args.address.addressErrors.length);
      console.log("Address Errors:", this.args.address.addressErrors);
    }

    setCountry(country) {
      this.args.address.set('newCountry', country);
      this.doValidate();
    }

    setLine1(text) {
      console.log("Set Line1", text);
      this.args.address.set('newLine1', text);
      this.doValidate();
    }

    setLine2(text) {
      this.args.address.set('newLine2', text);
      this.doValidate();
    }

    setLine3(text) {
      this.args.address.set('newLine3', text);
      this.doValidate();
    }

    setCity(text) {
      this.args.address.set('newCity', text);
      this.doValidate();
    }

    setCounty(text) {
      this.args.address.set('newCounty', text);
      this.doValidate();
    }

    setPostCode(text) {
      this.args.address.set('newPostCode', text);
      this.doValidate();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "errors", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setCountry", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "setCountry"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setLine1", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setLine1"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setLine2", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "setLine2"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setLine3", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setLine3"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setCity", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setCity"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setCounty", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setCounty"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setPostCode", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "setPostCode"), _class.prototype)), _class));
  _exports.default = ValidatedAddress;
});