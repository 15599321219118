define("clientui/mixins/parent-managedclient-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    routePrefix: '',
    routePlural: 'managedprofiles',
    routeSingular: 'profile',
    parentModelName: 'client',
    routeSingularRequiresId: true
  });

  _exports.default = _default;
});