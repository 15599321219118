define("clientui/pods/downloadabledocuments/downloadabledocument/route", ["exports", "clientui/superclasses/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DownloadableDocumentRoute extends _authenticatedRoute.default {}

  _exports.default = DownloadableDocumentRoute;

  window.__CLASSIC_OWN_CLASSES__.set(DownloadableDocumentRoute, true);
});