define("clientui/pods/travelrequests/travelrequest/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uBLp1nBM",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[8,\"widgets/card-basic\",[],[[\"@header\",\"@inverse\"],[[34,0],true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"pages/page-manage-requests\",[],[[\"@client\",\"@clientportal\",\"@enquirycontainer\",\"@inverse\",\"@onSave\"],[[34,1],[34,2],[34,3],true,[30,[36,4],[\"save\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"title\",\"client\",\"clientportal\",\"enquirycontainer\",\"route-action\"]}",
    "moduleName": "clientui/pods/travelrequests/travelrequest/template.hbs"
  });

  _exports.default = _default;
});