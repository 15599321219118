define("clientui/serializers/travelrequest", ["exports", "clientui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      //enquiryContainer:                { serialize: 'id', deserialize: 'records' },
      requester: {
        embedded: 'always'
      },
      requestElementFlights: {
        embedded: 'always'
      } //requestedApprover:               { serialize: 'id', deserialize: 'records' },
      //changeRequests:                  { embedded: 'always' },

    }
  });

  _exports.default = _default;
});