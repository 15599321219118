define("clientui/models/receipt", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30;

  let Receipt = (_dec = (0, _model.attr)('number'), _dec2 = (0, _model.belongsTo)('account'), _dec3 = (0, _model.attr)('date'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.belongsTo)('currency'), _dec10 = (0, _model.attr)('number'), _dec11 = (0, _model.attr)('number'), _dec12 = (0, _model.attr)('date'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.belongsTo)('lodgement'), _dec16 = (0, _model.attr)('date'), _dec17 = (0, _model.attr)('date'), _dec18 = (0, _model.attr)('string'), _dec19 = (0, _model.attr)('string'), _dec20 = (0, _model.attr)('string'), _dec21 = (0, _model.hasMany)('allocationtransaction'), _dec22 = (0, _model.attr)('number'), _dec23 = (0, _model.attr)('number'), _dec24 = (0, _model.attr)('number'), _dec25 = (0, _model.attr)('number'), _dec26 = (0, _model.attr)('date'), _dec27 = (0, _model.attr)('string'), _dec28 = (0, _model.attr)('number'), _dec29 = (0, _model.attr)('number'), _dec30 = (0, _model.attr)('boolean'), (_class = class Receipt extends _model.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "version", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "account", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "date", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "status", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "statusLabel", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "type", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "typeLabel", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "clientRefNo", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "currency", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "fcAmount", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "amount", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "ccExpiry", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "ccAuthCode", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "bankReference", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "lodgement", _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, "createdOn", _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, "modifiedOn", _descriptor17, this);
      (0, _initializerDefineProperty2.default)(this, "createdBy", _descriptor18, this);
      (0, _initializerDefineProperty2.default)(this, "modifiedBy", _descriptor19, this);
      (0, _initializerDefineProperty2.default)(this, "notes", _descriptor20, this);
      (0, _initializerDefineProperty2.default)(this, "allocations", _descriptor21, this);
      (0, _initializerDefineProperty2.default)(this, "realexTransactionId", _descriptor22, this);
      (0, _initializerDefineProperty2.default)(this, "fcUnallocatedAmount", _descriptor23, this);
      (0, _initializerDefineProperty2.default)(this, "fcAllocatedAmount", _descriptor24, this);
      (0, _initializerDefineProperty2.default)(this, "fcUnallocatableAmount", _descriptor25, this);
      (0, _initializerDefineProperty2.default)(this, "cancelledOn", _descriptor26, this);
      (0, _initializerDefineProperty2.default)(this, "cancelledBy", _descriptor27, this);
      (0, _initializerDefineProperty2.default)(this, "preCnxFcAmount", _descriptor28, this);
      (0, _initializerDefineProperty2.default)(this, "preCnxAmount", _descriptor29, this);
      (0, _initializerDefineProperty2.default)(this, "usingClientInvoiceAllocations", _descriptor30, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "version", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "account", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "date", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "status", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "statusLabel", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "type", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "typeLabel", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "clientRefNo", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "currency", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fcAmount", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amount", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ccExpiry", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ccAuthCode", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "bankReference", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "lodgement", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "createdOn", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "modifiedOn", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "createdBy", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "modifiedBy", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "notes", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "allocations", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "realexTransactionId", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fcUnallocatedAmount", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fcAllocatedAmount", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fcUnallocatableAmount", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cancelledOn", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cancelledBy", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "preCnxFcAmount", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "preCnxAmount", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "usingClientInvoiceAllocations", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Receipt;
});