define("clientui/pods/components/widgets/collapsible-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z8UlmCUh",
    "block": "{\"symbols\":[\"@inverse\",\"@onEdit\",\"@label\",\"@helpkey\",\"@manualHelpText\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"card mb-3\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card-header d-flex justify-content-between align-items-center\"],[15,1,[32,0,[\"headingid\"]]],[12],[2,\"\\n    \"],[10,\"h5\"],[14,0,\"mb-0\"],[12],[2,\"\\n      \"],[10,\"button\"],[15,0,[30,[36,1],[\"btn btn-link \",[30,[36,3],[[32,1],\"text-info\"],null]],null]],[14,\"data-toggle\",\"collapse\"],[15,\"data-target\",[30,[36,1],[\"#\",[32,0,[\"panelid\"]]],null]],[14,\"aria-expanded\",\"false\"],[15,\"aria-controls\",[32,0,[\"panelid\"]]],[14,4,\"button\"],[12],[2,\"\\n        \"],[10,\"i\"],[15,0,[31,[\"fa fa-\",[32,0,[\"icon\"]]]]],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\" \"],[1,[32,0,[\"title\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,3],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"button\"],[16,0,[30,[36,1],[\"btn \",[30,[36,0],[[32,1],\"btn-primary\",\"btn-info\"],null]],null]],[24,4,\"button\"],[4,[38,2],[\"click\",[32,2]],null],[12],[2,\"\\n        Edit\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[8,\"widgets/help-icon\",[],[[\"@title\",\"@helpkey\",\"@manualHelpText\"],[[32,3],[32,4],[32,5]]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[11,\"div\"],[16,1,[32,0,[\"panelid\"]]],[24,0,\"collapse collapsible-section\"],[16,\"aria-labelledby\",[32,0,[\"headingid\"]]],[4,[38,4],[[32,0,[\"registerListener\"]]],null],[4,[38,5],[[32,0,[\"unregisterListener\"]]],null],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n      \"],[18,6,null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"unless\",\"concat\",\"on\",\"if\",\"did-insert\",\"will-destroy\"]}",
    "moduleName": "clientui/pods/components/widgets/collapsible-section/template.hbs"
  });

  _exports.default = _default;
});