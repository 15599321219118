define("clientui/pods/components/widgets/external-link/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component is a shortcut of _blank HTML link.
   *
   * @example
   * {{#external-link href="http://foo.bar"}}
   *     Wow!
   * {{/external-link}}
   *
   * @example
   * {{#external-link href="http://foo.bar"}}
   *     Wow!
   * {{/external-link}}
   *
   * @example
   * {{external-link href="http://foo.bar"}}
   *
   * @extends Ember.Component
   */
  class ExternalLink extends _component.default {
    /**
     * Link href attribute.
     *
     * @property href
     * @type String
     * @default ''
     */
    //href = '';
    get href() {
      return this.args.href;
    }
    /**
     * Link target attribute.
     *
     * @property target
     * @type String
     * @default '_blank'
     */


    get target() {
      var _this$args$target;

      return (_this$args$target = this.args.target) !== null && _this$args$target !== void 0 ? _this$args$target : this.defTarget;
    }

    get defTarget() {
      let result = null;

      if (this.href.startsWith("mailto:")) {
        result = null;
      } else {
        result = "_blank";
      }

      return result;
    }
    /**
     * Link rel attribute
     */


    get rel() {
      var _this$args$rel;

      return (_this$args$rel = this.args.rel) !== null && _this$args$rel !== void 0 ? _this$args$rel : 'noopener noreferrer';
    }

    get ariaLabel() {
      return this.args.ariaLabel ? this.args.ariaLabel + " " + this.defAriaLabel : this.defAriaLabel;
    }

    get defAriaLabel() {
      let result = null;

      if (this.href.startsWith("mailto:")) {
        result = "(sends mail from new window)";
      } else if (this.target == "_blank") {
        result = "(opens in new tab)";
      }

      return result;
    }

  }

  _exports.default = ExternalLink;

  window.__CLASSIC_OWN_CLASSES__.set(ExternalLink, true);
});