define("clientui/helpers/boss-money", ["exports", "accounting/format-money", "accounting/to-fixed"], function (_exports, _formatMoney, _toFixed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function bossMoney(_ref, _ref2) {
    let [amount] = _ref;
    let {
      currency,
      precision = 2
    } = _ref2;

    if (currency) {
      return (0, _formatMoney.default)((0, _toFixed.default)(amount, precision), {
        symbol: currency,
        precision: precision,
        format: "%v %s"
      });
    } else {
      return (0, _formatMoney.default)((0, _toFixed.default)(amount, precision), "", precision);
    }
  });

  _exports.default = _default;
});