define("clientui/mixins/child-clientfrequentflyers-mixin", ["exports", "clientui/validations/clientfrequentflyers"], function (_exports, _clientfrequentflyers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ClientFrequentFlyerValidations: _clientfrequentflyers.default,
    validationName: 'ClientFrequentFlyerValidations',
    paramName: '',
    modelType: 'client',

    newPreSaveHook() {//(changeset) {
    },

    editPreSaveHook() {//(changeset) {
    }

  });

  _exports.default = _default;
});