define("clientui/pods/components/wizards/wizard-start-profile/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let WizardStartProfile = (_dec = Ember.inject.service('system'), _dec2 = Ember._action, _dec3 = Ember._action, classic(_class = (_class2 = class WizardStartProfile extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
    }

    didReceiveAttrs() {
      this.system.set('saving', false);
    }

    save() {
      this.system.set('saving', true);
      let fields = this.fields;
      fields.validate().then(() => {
        if (fields.get("isValid")) {
          this.onSave(fields).then(() => {
            // Promise
            console.log('New Traveller Fields Promise Saved Successfully in Component');
            this.onClose(); //this.onRefreshRoute();

            this.system.set('saving', false);
          }).catch(errors => {
            console.log('New Traveller Fields Promise Failed to Save in Component');
            console.log(errors);
            this.set('servererrors', errors);
            this.system.set('saving', false);
          });
        } else {
          this.system.set('saving', false);
        }
      }, () => {
        this.system.set('saving', false);
      });
    }

    back() {
      this.onRollback();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "save", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "back", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "back"), _class2.prototype)), _class2)) || _class);
  _exports.default = WizardStartProfile;
});