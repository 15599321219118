define("clientui/pods/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K1afOMsE",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"wrapper\"],[14,0,\"container-fluid\"],[12],[2,\"\\n\"],[6,[37,4],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"wrapper0\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"wrapper1\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"wrapper2\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"wrapper3 text-blue\"],[12],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        Business Travel.\\n      \"],[13],[2,\"\\n      Simplified\\n    \"],[13],[2,\"\\n    \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n    \"],[8,\"widgets/cookie-consent\",[],[[\"@clientportal\"],[[34,2]]],null],[2,\"\\n\\n    \"],[8,\"dialogs/ie11-warning\",[],[[\"@close\"],[[30,[36,3],[\"explorerClick\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"clientportal\",\"route-action\",\"if\"]}",
    "moduleName": "clientui/pods/application/template.hbs"
  });

  _exports.default = _default;
});