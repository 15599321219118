define("clientui/pods/customeraccounts/statements/statement/downloadstatement/route", ["exports", "clientui/superclasses/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CustomeraccountsStatementsStatementDownloadstatementRoute extends _authenticatedRoute.default {
    model(params) {
      let promises = this.modelFor('customeraccounts.statements.statement');
      return Ember.RSVP.hash(promises);
    }

    setupController(controller, promises) {
      controller.set('accountId', promises['accountId']);
    }

  }

  _exports.default = CustomeraccountsStatementsStatementDownloadstatementRoute;

  window.__CLASSIC_OWN_CLASSES__.set(CustomeraccountsStatementsStatementDownloadstatementRoute, true);
});