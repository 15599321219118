define("clientui/helpers/text-to-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.textToHtml = textToHtml;

  function textToHtml(text
  /*, hash*/
  ) {
    if (Ember.isEmpty(text)) {
      return text;
    } //console.log('Helper -> TextToHtml ' + text);
    //text = text.replace(/</g, '(').replace(/>/g, ')');  //defang HTML in text by replacing angle brackets with round ones


    let div = document.createElement('div');
    div.innerText = text;
    text = div.innerHTML.replace(/\t/g, '    ').replace(/ /g, '&nbsp;');
    return Ember.String.htmlSafe(text.replace(/(?:\r\n|\r|\n)/g, '<br />'));
  }

  var _default = Ember.Helper.helper(textToHtml);

  _exports.default = _default;
});