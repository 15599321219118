define("clientui/pods/components/inputs/validated-input/component", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@glimmer/component", "validated-changeset"], function (_exports, _applyDecoratedDescriptor2, _component, _validatedChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  //import { tracked } from '@glimmer/tracking';
  let ValidatedInput = (_dec = Ember._action, _dec2 = Ember._action, (_class = class ValidatedInput extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      console.log('ValidatedInput: constructor ' + this.args.propertyName, this.args.changeset.get(this.args.propertyName));

      if (!(0, _validatedChangeset.isChangeset)(this.args.changeset)) {
        console.warn('Argument Passed to ValidatedInput is not a changeset for ' + this.args.propertyName);
      }
    }

    get currentValue() {
      return Ember.get(this.args.changeset, this.args.propertyName);
    }

    get isValidated() {
      let result = true;

      if ((0, _validatedChangeset.isChangeset)(this.args.changeset)) {
        for (var key in this.args.changeset.error) {
          if (key === this.args.propertyName) {
            console.log('The changeset.error has a error for ' + key, this.args.changeset.error); //['key']);

            result = false;
          }
        }
      }

      return result;
    }

    get inputId() {
      let idName = Ember.guidFor(this) + "-input";
      console.log("inputId", idName);
      return idName;
    }

    get calculatedPlaceHolder() {
      let result = this.args.placeholder;

      if (!result) {
        result = this.args.label;
      }

      return result;
    }

    setAction(item) {
      // console.log('ValidatedInput: Setting ' + this.args.propertyName + ' to ' + (item ? item : "null"));
      this.args.changeset.set(this.args.propertyName, item); //Change local variable as it is then tracked

      if (typeof this.args.onUpdate !== 'undefined') {
        // console.log('Calling onUpdate');
        this.args.onUpdate(item);
      }
    }

    validateProperty(changeset, property) {
      let result = true;

      if ((0, _validatedChangeset.isChangeset)(this.args.changeset)) {
        result = changeset.validate(property);
      }

      return result;
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "setAction", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "setAction"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "validateProperty", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "validateProperty"), _class.prototype)), _class));
  _exports.default = ValidatedInput;
});