define("clientui/services/emt-helper-base", ["exports", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let EmtHelperBaseService = classic(_class = class EmtHelperBaseService extends Ember.Service {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "actionFieldName", 'emt_action');
    }

    _buildColInternal(propertyName, title, filterPropertyName, className, componentName, defaultOptions, userOptions) {
      let result = {};

      if (defaultOptions) {
        Object.assign(result, defaultOptions);
      }

      if (userOptions) {
        Object.assign(result, userOptions);
      } //these will supersede any defaultOptions


      if (propertyName) {
        result['propertyName'] = propertyName;
      }

      if (title) {
        result['title'] = title;
      }

      if (filterPropertyName) {
        result['filteredBy'] = filterPropertyName;
      }

      if (className) {
        result['className'] = className;
      }

      if (componentName) {
        result['component'] = componentName;
      }

      return result;
    }

    buildDateCol(datePropertyName, title, filterPropertyName, className, componentName, userOptions) {
      let defaultOptions = {
        //Set defaults for date displays
        component: "ember-model-table/cell-date",
        className: "text-center"
      };
      return this._buildColInternal(datePropertyName, title, filterPropertyName, className, componentName, defaultOptions, userOptions);
    }

    buildBooleanCol(boolPropertyName, title, filterPropertyName, className, componentName, userOptions) {
      let defaultOptions = {
        //Set defaults for boolean displays
        component: "ember-model-table/cell-boolean",
        className: "text-center"
      };
      return this._buildColInternal(boolPropertyName, title, filterPropertyName, className, componentName, defaultOptions, userOptions);
    }

    buildStringCol(stringPropertyName, title, filterPropertyName, className, componentName, userOptions) {
      let defaultOptions = {
        component: "ember-model-table/cell-string",
        className: "text-left"
      };
      return this._buildColInternal(stringPropertyName, title, filterPropertyName, className, componentName, defaultOptions, userOptions);
    }

    buildMoneyCol(moneyPropertyName, title, filterPropertyName, className, componentName, userOptions) {
      let defaultOptions = {
        //Set defaults for money displays
        component: "ember-model-table/cell-money",
        className: "text-right"
      };
      return this._buildColInternal(moneyPropertyName, title, filterPropertyName, className, componentName, defaultOptions, userOptions);
    }

    buildMoneyIncZeroCol(moneyPropertyName, title, filterPropertyName, className, componentName, userOptions) {
      let defaultOptions = {
        //Set defaults for money displays
        component: "ember-model-table/cell-money-inc-zero",
        className: "text-right"
      };
      return this._buildColInternal(moneyPropertyName, title, filterPropertyName, className, componentName, defaultOptions, userOptions);
    }

    buildNumberCol(numberPropertyName, title, filterPropertyName, className, componentName, userOptions) {
      let defaultOptions = {
        //Set defaults for numeric displays
        className: "text-center"
      };
      return this._buildColInternal(numberPropertyName, title, filterPropertyName, className, componentName, defaultOptions, userOptions);
    }

    buildActionCol(title, actions, options) {
      let result = {
        //Set defaults for action cells
        component: "ember-model-table/cell-actions",
        disableSorting: true,
        mayBeHidden: false,
        disableFiltering: true,
        title: title,
        columnActions: actions,

        trigger() {
          this.columnActions.forEach(action => {
            Ember.set(action, 'conditionTrigger', 1 + Ember.get(action, 'conditionTrigger'));
          });
        }

      };

      if (options) {
        Object.assign(result, options);
      } //these will supersede any default settings


      return result;
    }

    buildAction(action, icon, tooltip, conditionField, conditionValue, options) {
      let result = {
        actionFieldName: this.actionFieldName,
        conditionTrigger: 0
      };

      if (options) {
        Object.assign(result, options);
      } //these will supersede any defaults


      if (action) {
        result['cellaction'] = action;
      }

      if (icon) {
        result['icon'] = icon;
      }

      if (tooltip) {
        result['tooltip'] = tooltip;
      }

      if (conditionField) {
        result['conditionField'] = conditionField;
      }

      if (typeof conditionValue != undefined) {
        result['conditionValue'] = conditionValue;
      }

      return result;
    }

    buildCheckBoxCol(title, options) {
      let result = {
        //Set defaults for action cells
        component: "ember-model-table/cell-checkbox",
        disableSorting: true,
        mayBeHidden: false,
        disableFiltering: true,
        title: title
      };

      if (options) {
        Object.assign(result, options);
      } //these will supersede any default settings


      return result;
    }

    buildExpandToggleCol(createExpandAllFilter, options) {
      let result = {
        //Set defaults for action cells
        component: "models-table/expand-toggle",
        //this is EMT default
        mayBeHidden: false,
        disableSorting: true,
        disableFiltering: true
      };

      if (createExpandAllFilter) {
        result['componentForFilterCell'] = "models-table/expand-all-toggle"; //EMT default
      }

      if (options) {
        Object.assign(result, options);
      } //these will supersede any default settings


      return result;
    }

    getActionItem(data) {
      let itemId = 0;
      let action = null;

      if (data.selectedItems) {
        if (data.selectedItems.length == 1) {
          let item = data.selectedItems[0];
          itemId = item.get('id');
          action = item.get(this.actionFieldName);
          item.set(this.actionFieldName, null); //clear action from record to prevent repetition
        }
      }

      return {
        itemId: itemId,
        action: action
      };
    }

  }) || _class;

  _exports.default = EmtHelperBaseService;
});