define("clientui/pods/components/widgets/search-form-pills/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "moment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  let WidgetsSearchFormPillsComponent = (_dec = Ember.inject.service('system'), _dec2 = Ember.inject.service('form-date-conversion'), (_class = class WidgetsSearchFormPillsComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "dateConversion", _descriptor2, this);
    }

    get displayParam() {
      let parameter = this.args.formParams.find(x => x.name === this.args.param);

      if (parameter) {
        if (parameter.value) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    get isDate() {
      // let paramName = this.args.param;
      // let parameter = this.args.formParams.filter(function(el) {
      //   return el.name === paramName;
      // });
      let parameter = this.args.formParams.find(x => x.name === this.args.param);

      if (parameter) {
        if (parameter.type == 'date') {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    get displayValue() {
      let parameter = this.args.formParams.find(x => x.name === this.args.param);

      if (parameter) {
        return parameter.value;
      } else {
        return null;
      }
    }

    get paramLabel() {
      let parameter = this.args.formParams.find(x => x.name === this.args.param);

      if (parameter) {
        return parameter.displayName;
      } else {
        return null;
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dateConversion", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = WidgetsSearchFormPillsComponent;
});