define("clientui/pods/components/widgets/card-basic/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9Ycv46JQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,4],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"h6\"],[14,0,\"card-header d-flex justify-content-between align-items-center mb-0 border\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"text-muted\"],[12],[2,\"\\n      \"],[1,[34,1]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"widgets/help-icon\",[],[[\"@title\",\"@helpkey\"],[[34,2],[34,3]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n\"],[6,[37,4],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h5\"],[14,0,\"card-title\"],[12],[2,\"\\n      \"],[1,[34,0]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"p\"],[14,0,\"card-text\"],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"title\",\"header\",\"label\",\"helpkey\",\"if\"]}",
    "moduleName": "clientui/pods/components/widgets/card-basic/template.hbs"
  });

  _exports.default = _default;
});