define("clientui/pods/page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZItN+Ybo",
    "block": "{\"symbols\":[],\"statements\":[[8,\"layout/page-header\",[],[[\"@clientportal\",\"@client\",\"@showBanner\"],[[32,0,[\"clientportal\"]],[32,0,[\"client\"]],true]],null],[2,\"\\n\"],[10,\"section\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[14,1,\"content\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"link\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"widgets/display-page\",[],[[\"@link\",\"@clientportal\",\"@client\"],[[32,0,[\"link\"]],[32,0,[\"clientportal\"]],[32,0,[\"client\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "clientui/pods/page/template.hbs"
  });

  _exports.default = _default;
});