define("clientui/pods/components/widgets/pax-type-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LJF5aEV0",
    "block": "{\"symbols\":[\"@description\"],\"statements\":[[10,\"span\"],[15,0,[31,[[34,0],\" pax-icon-adt\"]]],[12],[2,\"\\n  \"],[10,\"i\"],[15,0,[31,[\"fa fa-\",[34,1]]]],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"span\"],[15,0,[31,[[34,2],\" pax-icon-chd\"]]],[12],[2,\"\\n  \"],[10,\"i\"],[15,0,[31,[\"fa fa-\",[34,1]]]],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"span\"],[15,0,[31,[[34,3],\" pax-icon-inf\"]]],[12],[2,\"\\n  \"],[10,\"i\"],[15,0,[31,[\"fa fa-\",[34,1]]]],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[8,\"attach-tooltip\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"adtStyle\",\"icon\",\"chdStyle\",\"infStyle\"]}",
    "moduleName": "clientui/pods/components/widgets/pax-type-icon/template.hbs"
  });

  _exports.default = _default;
});