define("clientui/pods/downloadabledocuments/search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jAcfpha8",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"Search Documents\"],null]],[2,\"\\n\"],[8,\"pages/downloadabledocuments/page-downloadabledocuments-searchform\",[],[[\"@accounts\",\"@docTypes\",\"@formParams\",\"@onSubmit\",\"@onClearForm\"],[[32,0,[\"accounts\"]],[32,0,[\"docTypes\"]],[32,0,[\"formParams\"]],[30,[36,1],[\"formSubmit\"],null],[30,[36,2],[[32,0,[\"clearForm\"]]],null]]],null],[2,\"\\n\\n\"],[8,\"pages/downloadabledocuments/page-downloadabledocuments-searchlist\",[],[[\"@dataNavigationAction\",\"@downloadabledocuments\"],[[32,0,[\"dataNavigation\"]],[32,0,[\"downloadabledocuments\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"page-title\",\"route-action\",\"fn\"]}",
    "moduleName": "clientui/pods/downloadabledocuments/search/template.hbs"
  });

  _exports.default = _default;
});