define("clientui/pods/components/dialogs/form-profile-edit-personal/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let FormProfileEditPersonal = (_dec = Ember.inject.service('system'), _dec2 = Ember.inject.service, _dec3 = Ember.computed.sort('countries', 'countrySortDefinition'), _dec4 = Ember.computed('clientportal.homeCountryCode'), _dec5 = Ember.computed('client.requiresDateOfBirth'), _dec6 = Ember.computed('client.requiresNationality'), _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, classic(_class = (_class2 = class FormProfileEditPersonal extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "sortedCountries", _descriptor3, this);
    }

    init() {
      super.init(...arguments);
      console.log('Init Client Personal Form');
    }

    get hostName() {
      console.log(window.location.hostname);
      return window.location.hostname === 'demowtcportal.selective-travel.com';
    }

    didReceiveAttrs() {
      this.set('passengertypes', this.system.enum('passengertype'));
      this.set('genders', this.system.enum('gender'));
      this.set('genderidentities', this.system.enum('genderidentity'));
      this.set('titles', this.system.enum('title'));
      this.set('countries', this.system.queryAll('country'));

      if (Ember.isPresent(this.get('client.gender.id'))) {
        this.client.set('genderSelection', this.get('client.gender.id'));
      }

      this.set('genderManuallyUpdated', false);
      this.set('paxTypeManuallyUpdated', false);

      if (Ember.isPresent(this.get('client.clientProfile.nationality.id'))) {
        this.client.set('nationalitySelection', this.get('client.clientProfile.nationality.id'));
      } //Copy requiresDateOfBirth/requiresNationality into client changeset so it is accessible in the validator


      this.client.set('requiresDateOfBirth', this.get('clientportal.requiresDateOfBirth'));
      this.client.set('requiresNationality', this.get('clientportal.requiresNationality'));
      this.system.set('saving', false);
    }

    get countrySortDefinition() {
      console.log(this.get('clientportal.homeCountryCode'));
      return ['priority' + this.get('clientportal.homeCountryCode'), 'name'];
    }

    get dateOfBirthLabel() {
      let result = "Date of Birth";

      if (this.get('client.requiresDateOfBirth') === true) {
        result += " *";
      }

      return result;
    }

    get nationalityLabel() {
      let result = "Nationality";

      if (this.get('client.requiresNationality') === true) {
        result += " *";
      }

      return result;
    }

    save() {
      this.system.set('saving', true);
      let client = this.client;
      client.validate().then(() => {
        if (client.get("isValid")) {
          let fieldName = null;
          let selectionId = 0;
          let model = null;
          let promises = {};

          if (Ember.isPresent(this.get('client.genderSelection'))) {
            fieldName = 'gender';
            model = 'gender';
            selectionId = this.get('client.genderSelection');
            promises[fieldName] = this.store.peekRecord(model, selectionId);
          }

          if (Ember.isPresent(this.get('client.nationalitySelection'))) {
            fieldName = 'clientProfile.nationality';
            model = 'country';
            selectionId = this.get('client.nationalitySelection');
            promises[fieldName] = this.store.peekRecord(model, selectionId);
          }

          Ember.RSVP.hash(promises).then(item => {
            Object.keys(item).forEach(key => {
              client.set(key, item[key]);
            });
            this.onSave(client).then(() => {
              // Promise
              console.log('clientPersonal Promise Saved Successfully in Component');
              this.onClose(); //this.onRefreshRoute();

              this.system.set('saving', false);
            }, errors => {
              console.log('clientPersonal Promise Failed to Save in Component');
              console.log(errors);
              this.set('servererrors', errors);
              this.system.set('saving', false);
            });
          }, errors => {
            console.log('clientPersonal Promise Failed to Save in Component - unable to find one of gender/paxType');
            console.log(errors);
            this.set('servererrors', errors);
            this.system.set('saving', false);
          });
        } else {
          this.system.set('saving', false);
        }
      }, () => {
        this.system.set('saving', false);
      });
    }

    back() {
      //Rollback any changes
      this.client.rollback();

      this.client._content._internalModel._record.rollbackAttributes(); //Close dialog


      this.onClose();
      this.onRollback();
    } //If Title is set while gender or paxtype are null, or have not been manually set
    //then change gender and paxtype to match the title, where appropriate


    updateTitle(newTitle) {
      if (newTitle) {
        if (newTitle.get('defaultGenderCode')) {
          if (!this.client.get('genderSelection') || this.genderManuallyUpdated === false) {
            console.log("Setting genderSelection to " + newTitle.get('defaultGenderCode'));
            this.client.set('genderSelection', newTitle.get('defaultGenderCode'));
          }
        }

        if (newTitle.get('defaultPaxTypeCode')) {
          if (!this.client.get('paxType') || this.paxTypeManuallyUpdated === false) {
            console.log("Setting paxType to " + newTitle.get('defaultPaxTypeCode'));
            this.client.set('paxType', newTitle.get('defaultPaxTypeCode'));
          }
        }
      }
    }

    updateGender() {
      //console.log("genderManuallyUpdated");
      this.set('genderManuallyUpdated', true);
    }

    updatePaxType() {
      //console.log("paxTypeManuallyUpdated");
      this.set('paxTypeManuallyUpdated', true);
      this.client.validate('dateOfBirth');
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sortedCountries", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "countrySortDefinition", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "countrySortDefinition"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "dateOfBirthLabel", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "dateOfBirthLabel"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "nationalityLabel", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "nationalityLabel"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "save", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "back", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "back"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "updateTitle", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "updateTitle"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "updateGender", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "updateGender"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "updatePaxType", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "updatePaxType"), _class2.prototype)), _class2)) || _class);
  _exports.default = FormProfileEditPersonal;
});