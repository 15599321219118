define("clientui/pods/components/widgets/view-edit-delete-icons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/aDRda/E",
    "block": "{\"symbols\":[\"@hideTooltip\",\"@itemId2\",\"@itemId\",\"@onDelete\",\"@onEdit\",\"@onView\"],\"statements\":[[11,\"span\"],[16,0,[31,[\"shadowfade m-0 \",[32,0,[\"show\"]]]]],[4,[38,2],[\"mouseenter\",[32,0,[\"handleMouseEnter\"]]],null],[4,[38,2],[\"mouseleave\",[32,0,[\"handleMouseLeave\"]]],null],[12],[2,\"\\n\"],[6,[37,3],[[32,6]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"widgets/icon-for\",[],[[\"@target\",\"@tooltip\",\"@onClick\"],[[32,0,[\"viewIcon\"]],[30,[36,0],[[32,1],[32,0,[\"viewTooltip\"]]],null],[30,[36,1],[[32,6],[32,3],[32,2]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[[32,5]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"widgets/icon-for\",[],[[\"@target\",\"@tooltip\",\"@onClick\"],[[32,0,[\"editIcon\"]],[30,[36,0],[[32,1],[32,0,[\"editTooltip\"]]],null],[30,[36,1],[[32,5],[32,3],[32,2]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[[32,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"widgets/icon-for\",[],[[\"@target\",\"@tooltip\",\"@onClick\"],[[32,0,[\"deleteIcon\"]],[30,[36,0],[[32,1],[32,0,[\"deleteTooltip\"]]],null],[30,[36,1],[[32,4],[32,3],[32,2]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"unless\",\"fn\",\"on\",\"if\"]}",
    "moduleName": "clientui/pods/components/widgets/view-edit-delete-icons/template.hbs"
  });

  _exports.default = _default;
});