define("clientui/instance-initializers/emt-inject", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(appInstance) {
    appInstance.inject('component:models-table', 'themeInstance', `theme:emt-theme-wtc`);
    appInstance.inject('component:models-table-server-paginated', 'themeInstance', `theme:emt-theme-wtc`);
    appInstance.inject('component:ember-model-table/models-table-paginated', 'themeInstance', `theme:emt-theme-wtc`);
    appInstance.inject('component:ember-model-table/models-table-simple', 'themeInstance', `theme:emt-theme-wtc`);
    appInstance.inject('component:ember-model-table/models-table-searchresults', 'themeInstance', `theme:emt-theme-wtc`);
  }

  var _default = {
    name: 'emt-inject',
    initialize
  };
  _exports.default = _default;
});