define("clientui/pods/downloadabledocuments/route", ["exports", "clientui/superclasses/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DownloadableDocumentsRoute extends _authenticatedRoute.default {
    model() {
      let promises = {};
      promises['application'] = this.modelFor('application');
      return Ember.RSVP.hash(promises);
    }

    setupController(controller, promises) {
      controller.set('clientportal', promises['application']);
    }

  }

  _exports.default = DownloadableDocumentsRoute;

  window.__CLASSIC_OWN_CLASSES__.set(DownloadableDocumentsRoute, true);
});