define("clientui/pods/downloadabledocuments/downloadabledocument/delete/route", ["exports", "@babel/runtime/helpers/esm/defineProperty", "clientui/superclasses2/this-route-edit"], function (_exports, _defineProperty2, _thisRouteEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DeleteRoute extends _thisRouteEdit.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _defineProperty2.default)(this, "routeLeaf", 'edit');
      (0, _defineProperty2.default)(this, "enableDebugLogging", true);
      (0, _defineProperty2.default)(this, "paramName", 'downloadabledocument_id');
      (0, _defineProperty2.default)(this, "modelType", 'downloadabledocument');
      Object.assign(this, this.controllerFor('downloadabledocuments').downloadabledocumentSettings);
    }

  }

  _exports.default = DeleteRoute;

  window.__CLASSIC_HAS_CONSTRUCTOR__.set(DeleteRoute, true);

  window.__CLASSIC_OWN_CLASSES__.set(DeleteRoute, true);
});