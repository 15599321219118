define("clientui/models/country", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let Country = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = Ember.computed('id'), _dec4 = Ember.computed('id'), _dec5 = Ember.computed('name'), _dec6 = Ember.computed('dialCode', 'name'), classic(_class = (_class2 = class Country extends _model.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "name", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "dialCode", _descriptor2, this);
    }

    get priorityGB() {
      let result = 3;

      switch (this.id) {
        case 'GB':
          result = 1;
          break;

        case 'IE':
          result = 2;
          break;

        default:
          result = 3;
          break;
      }

      return result;
    }

    get priorityIE() {
      let result = 3;

      switch (this.id) {
        case 'IE':
          result = 1;
          break;

        case 'GB':
          result = 2;
          break;

        default:
          result = 3;
          break;
      }

      return result;
    }

    get selectDisplayText() {
      return `${this.name}`;
    }

    get dialCodeText() {
      return `+${this.dialCode} (${this.name})`;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "dialCode", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "priorityGB", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "priorityGB"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "priorityIE", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "priorityIE"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "selectDisplayText", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "selectDisplayText"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "dialCodeText", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "dialCodeText"), _class2.prototype)), _class2)) || _class);
  _exports.default = Country;
});