define("clientui/pods/components/widgets/dialog-box-route/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let DialogBoxRoute = (_dec = Ember.computed('inverse'), _dec2 = Ember._action, _dec3 = Ember._action, classic(_class = (_class2 = class DialogBoxRoute extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "isShowingDialog", true);
      (0, _defineProperty2.default)(this, "inverse", false);
    }

    get headerClass() {
      if (this.inverse === true) {
        return "bg-info";
      } else {
        return "bg-primary";
      }
    }

    showDialog() {
      console.log('Open Dialog');
      this.toggleProperty('isShowingDialog');
    }

    closeDialog() {
      console.log('Close Dialog');

      if (this.historyBack === true) {
        window.history.back();
      }

      this.toggleProperty('isShowingDialog');
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "headerClass", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "headerClass"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "showDialog", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "showDialog"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "closeDialog", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "closeDialog"), _class2.prototype)), _class2)) || _class);
  _exports.default = DialogBoxRoute;
});