define("clientui/pods/components/widgets/list-item-boolean/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6LPnq6X1",
    "block": "{\"symbols\":[\"@badge\",\"@item\",\"&default\",\"@labelbadge\",\"&attrs\",\"@label\",\"@helpkey\",\"@manualHelpText\"],\"statements\":[[11,\"div\"],[24,0,\"row\"],[17,5],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"list-item-label \",[32,0,[\"col1Class\"]]]]],[12],[2,\"\\n\"],[2,\"    \"],[1,[32,6]],[2,\"\\n\"],[6,[37,1],[[32,4]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"badge badge-secondary\"],[12],[2,\"\\n        \"],[1,[32,4]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[8,\"widgets/help-icon\",[],[[\"@title\",\"@helpkey\",\"@manualHelpText\"],[[32,6],[32,7],[32,8]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[32,0,[\"col2Class\"]]],[12],[2,\"\\n\"],[6,[37,1],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,3,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"custom-control custom-checkbox\"],[12],[2,\"\\n        \"],[8,\"input\",[[24,0,\"custom-control-input\"],[16,1,[32,0,[\"inputId\"]]]],[[\"@type\",\"@checked\",\"@indeterminate\",\"@disabled\"],[\"checkbox\",[32,2],[30,[36,0],[[32,2]],null],\"true\"]],null],[2,\"\\n        \"],[10,\"label\"],[14,0,\"custom-control-label\"],[15,\"for\",[32,0,[\"inputId\"]]],[12],[2,\"\\n           \\n\"],[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"span\"],[14,0,\"badge badge-secondary\"],[12],[2,\"\\n              \"],[1,[32,1]],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"is-empty\",\"if\"]}",
    "moduleName": "clientui/pods/components/widgets/list-item-boolean/template.hbs"
  });

  _exports.default = _default;
});