define("clientui/pods/components/pages/page-customeraccounts/page-customeraccounts-statement/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  let PageCustomaccountsStatement = (_dec = Ember.inject.service('emt-helper'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('system'), _dec4 = Ember._action, (_class = class PageCustomaccountsStatement extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _initializerDefineProperty2.default)(this, "emthelper", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor3, this);
    }

    get referenceColumns() {
      return [this.emthelper.buildStringCol("displayDescription", "Reference"), this.emthelper.buildStringCol("value", "Value")];
    }

    get itemColumns() {
      return [this.emthelper.buildStringCol("clientInvoiceIdentifier", "Client Invoice"), this.emthelper.buildDateCol("creationDate", "Activation Date"), this.emthelper.buildStringCol("leadPax", "Lead Name"), this.emthelper.buildDateCol("dateOfTravel", "Date of Travel"), this.emthelper.buildTotalledMoneyIncZeroCol("invoiceAmount", "Amount", null, null, null, {
        "currencyProperty": "currencyCode"
      }), this.emthelper.buildTotalledMoneyIncZeroCol("balance", "Balance"), this.emthelper.buildExpandToggleCol(true)];
    }

    goToDownload() {
      this.router.transitionTo('customeraccounts.statements.statement.downloadstatement', this.args.accountId);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "emthelper", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "goToDownload", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "goToDownload"), _class.prototype)), _class));
  _exports.default = PageCustomaccountsStatement;
});