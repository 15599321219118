define("clientui/pods/components/ember-model-table/cell-actions-item/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let CellActionsItem = (_dec = Ember.computed('cellaction.{conditionField,conditionValue,conditionTrigger}', 'record'), _dec2 = Ember._action, classic(_class = (_class2 = class CellActionsItem extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "tagName", '');
    }

    get visible() {
      let result = true;
      let actionName = this.cellaction; //if ((actionName.hasOwnProperty('conditionField')) && (actionName.hasOwnProperty('conditionValue'))) {

      if (Object.prototype.hasOwnProperty.call(actionName, 'conditionField') && Object.prototype.hasOwnProperty.call(actionName, 'conditionValue')) {
        //There is something to calculate
        result = this.record.get(actionName.conditionField) === actionName.conditionValue;
      }

      return result;
    }

    handleClick(actionFieldName, actionName) {
      Ember.set(this.record, actionFieldName, actionName); //store action within record so it will be seen in Route
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "visible", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "visible"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleClick", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "handleClick"), _class2.prototype)), _class2)) || _class);
  _exports.default = CellActionsItem;
});