define("clientui/pods/components/widgets/open-in-lightbox/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let OpenInLightbox = ( //import $ from 'jquery';
  _dec = Ember.inject.service('keycloak-session'), _dec2 = Ember.inject.service, classic(_class = (_class2 = class OpenInLightbox extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
      (0, _defineProperty2.default)(this, "url", null);
      (0, _defineProperty2.default)(this, "createdUrls", null);
      (0, _defineProperty2.default)(this, "lightboxUrl", null);
      (0, _defineProperty2.default)(this, "lightboxId", null);
      (0, _defineProperty2.default)(this, "lightboxTitle", 'Uploaded Image');
      (0, _defineProperty2.default)(this, "lightboxClass", 'lightbox-thumbnail');
      (0, _defineProperty2.default)(this, "galleryMode", false);
    }

    init() {
      super.init(...arguments);
      this.set('createdUrls', []);
    }

    didDestroyElement() {
      var windowUrl = window.URL || window.webkitURL;
      this.createdUrls.forEach(item => {
        console.log("Revoking URL " + item);
        windowUrl.revokeObjectURL(item);
      });
    }

    didReceiveAttrs() {
      this.set('lightboxId', this.elementId);

      if (this.gallerymode) {
        this.set('lightboxClass', 'lightbox-gallery');
      } else {
        this.set('lightboxClass', 'lightbox-thumbnail');
      }

      if (this.session.token) {
        this.set('headers', {
          'Authorization': `Bearer ${this.session.token}`,
          "X-WTC": this.system.getWtcRefHeaderValue(),
          "X-WTC2": this.system.getWtcRef2HeaderValue()
        });
      }

      if (this.url) {
        this.startDownloadImage();
      }
    }

    startDownloadImage() {
      let url = this.url;
      console.log("Starting download of attachment from " + url);

      if (this.get('session.token')) {
        this.session.updateToken().then(() => {
          this.downloadImage(url);
        });
      } else {
        this.downloadImage(url);
      }
    }

    downloadImage(url) {
      var oReq = new XMLHttpRequest();
      oReq.open("GET", url, true);
      oReq.responseType = "blob";

      if (this.get('session.token')) {
        oReq.setRequestHeader('Authorization', `Bearer ${this.get('session.token')}`);
      }

      var _this = this; //Set up Success callback


      oReq.onload = function () {
        let blob = oReq.response; //Convert blob to ObjectUrl

        let windowUrl = window.URL || window.webkitURL;
        let contentUrl = windowUrl.createObjectURL(blob);
        console.log(contentUrl);

        _this.get('createdUrls').pushObject(contentUrl);

        _this.set('lightboxUrl', contentUrl);
      }; //Trigger Ajax Call


      oReq.send();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = OpenInLightbox;
});