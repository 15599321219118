define("clientui/pods/components/sections/page-myprofilenew-profilenotes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CTslb10n",
    "block": "{\"symbols\":[\"note\"],\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"widgets/contact-card\",[],[[\"@header\"],[[32,1,[\"title\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"widgets/profile-note-item\",[],[[\"@note\",\"@dateTimeFormat\",\"@client\"],[[32,1],[34,0],[34,1]]],null],[2,\"\\n\"],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"  \"],[8,\"widgets/contact-card\",[],[[\"@header\"],[\"None\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"dateTimeFormat\",\"client\",\"sortedProfileNotes\",\"-track-array\",\"each\"]}",
    "moduleName": "clientui/pods/components/sections/page-myprofilenew-profilenotes/template.hbs"
  });

  _exports.default = _default;
});