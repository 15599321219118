define("clientui/pods/components/widgets/fade-alert/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J5SUlDm1",
    "block": "{\"symbols\":[\"&attrs\",\"@message\"],\"statements\":[[11,\"div\"],[24,0,\"col-12\"],[17,1],[4,[38,0],[[32,0,[\"setupFade\"]]],null],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"alert alert-primary\"],[12],[2,\"\\n    \"],[1,[32,2]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\"]}",
    "moduleName": "clientui/pods/components/widgets/fade-alert/template.hbs"
  });

  _exports.default = _default;
});