define("clientui/pods/components/sections/page-myprofilenew-documents/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let PageMyprofilenewDocuments = (_dec = (0, _component.classNames)('card-columns', 'document-panel'), _dec2 = Ember.computed.filter('client.clientProfile.documents', function (item) {
    return item.get('documentType') === 'PASSPORT';
  }), _dec3 = Ember.computed.sort('passports', 'sortDefinition'), _dec4 = Ember.computed.filter('client.clientProfile.documents', function (item) {
    return item.get('documentType') === 'DRIVLIC';
  }), _dec5 = Ember.computed.sort('drivingLicences', 'sortDefinition'), _dec6 = Ember.computed.filter('client.clientProfile.documents', function (item) {
    return item.get('documentType') === 'VISA';
  }), _dec7 = Ember.computed.sort('visas', 'sortDefinition'), _dec8 = Ember.computed.filter('client.clientProfile.documents', function (item) {
    return item.get('documentType') === 'CARREG';
  }), _dec9 = Ember.computed.sort('carRegs', 'sortDefinition'), _dec10 = Ember.computed.filter('client.clientProfile.documents', function (item) {
    return item.get('documentType') === 'ESTA';
  }), _dec11 = Ember.computed.sort('estas', 'sortDefinition'), _dec12 = Ember.computed('client.clientProfile.{tsaRedressNumber,countryOfBirth,tsaRedressIssueDate,tsaRedressCountry}'), _dec13 = Ember.computed('client.clientProfile.{tsaRedressNumber,countryOfBirth,tsaRedressIssueDate,tsaRedressCountry}'), _dec14 = Ember.computed('isTsaRedressComplete', 'hasTsaRedress'), _dec15 = Ember.computed('isTsaRedressComplete', 'hasTsaRedress'), classic(_class = _dec(_class = (_class2 = class PageMyprofilenewDocuments extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "passports", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "sortedPassports", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "drivingLicences", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "sortedDrivingLicences", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "visas", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "sortedVisas", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "carRegs", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "sortedCarRegs", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "estas", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "sortedEstas", _descriptor10, this);
      (0, _defineProperty2.default)(this, "sortDefinition", null);
    }

    get hasTsaRedress() {
      let result = false;

      if (this.get('client.clientProfile.tsaRedressNumber') || this.get('client.clientProfile.countryOfBirth.selectDisplayText') || this.get('client.clientProfile.tsaRedressIssueDate') || this.get('client.clientProfile.tsaRedressCountry.selectDisplayText')) {
        result = true;
      }

      return result;
    }

    get isTsaRedressComplete() {
      let result = false;

      if (this.get('client.clientProfile.tsaRedressNumber') && this.get('client.clientProfile.countryOfBirth.selectDisplayText') && this.get('client.clientProfile.tsaRedressIssueDate') && this.get('client.clientProfile.tsaRedressCountry.selectDisplayText')) {
        result = true;
      }

      return result;
    }

    get tsaBadgeStatus() {
      let result = '';

      if (this.hasTsaRedress) {
        if (this.isTsaRedressComplete === true) {
          result = 'OK';
        } else {
          result = 'Incomplete';
        }
      }

      return result;
    }

    get tsaBadgeClass() {
      let result = '';

      if (this.hasTsaRedress) {
        if (this.isTsaRedressComplete === true) {
          result = 'btn-success';
        } else {
          result = 'btn-warning';
        }
      }

      return result;
    }

    init() {
      super.init(...arguments);
      this.set('sortDefinition', ['displayDescription', 'referenceNumber']);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "passports", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sortedPassports", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "drivingLicences", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sortedDrivingLicences", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "visas", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sortedVisas", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "carRegs", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sortedCarRegs", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "estas", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sortedEstas", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "hasTsaRedress", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "hasTsaRedress"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isTsaRedressComplete", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "isTsaRedressComplete"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "tsaBadgeStatus", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "tsaBadgeStatus"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "tsaBadgeClass", [_dec15], Object.getOwnPropertyDescriptor(_class2.prototype, "tsaBadgeClass"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = PageMyprofilenewDocuments;
});