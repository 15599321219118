define("clientui/services/system", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-data", "clientui/config/environment", "moment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberData, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let SystemService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('keycloak-session'), _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, classic(_class = (_class2 = class SystemService extends Ember.Service {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "keycloakSession", _descriptor2, this);
      (0, _defineProperty2.default)(this, "DEBUG_DISABLE_KEYCLOAK", false);
      (0, _initializerDefineProperty2.default)(this, "isExplorer", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "explorerMessageShown", _descriptor4, this);
      (0, _defineProperty2.default)(this, "currentYear", 0);
      (0, _defineProperty2.default)(this, "currentDate", (0, _moment.default)(0, "HH").toDate());
      (0, _defineProperty2.default)(this, "clientportal", null);
      (0, _defineProperty2.default)(this, "dateFormat", null);
      (0, _defineProperty2.default)(this, "dateTimeFormat", null);
      (0, _defineProperty2.default)(this, "hideIdp", false);
      (0, _defineProperty2.default)(this, "lettersOnly", null);
      (0, _defineProperty2.default)(this, "numbersOnly", null);
      (0, _defineProperty2.default)(this, "lettersAndSpaceOnly", null);
      (0, _defineProperty2.default)(this, "surnameChars", null);
      (0, _defineProperty2.default)(this, "lettersAndNumbersOnly", null);
      (0, _defineProperty2.default)(this, "emailChars", null);
      (0, _defineProperty2.default)(this, "navigationKeys", null);
      (0, _defineProperty2.default)(this, "modelCache", null);
      (0, _defineProperty2.default)(this, "keycloakLandingSites", null);
      (0, _initializerDefineProperty2.default)(this, "saving", _descriptor5, this);
      (0, _defineProperty2.default)(this, "windowReference", null);
      (0, _defineProperty2.default)(this, "pathsWithParam", null);
      (0, _defineProperty2.default)(this, "pathsWithoutParams", null);
      (0, _defineProperty2.default)(this, "canSetPageViewId", undefined);
    }

    init() {
      super.init(...arguments);
      this.set('currentYear', new Date().getFullYear()); //It would be nice to generate these lists from Router but I can't see how

      this.set('pathsWithParam', ['/page/', '/blogs/blog/', '/managedprofiles/profile/', '/contact/', '/subscribe/', '/travelrequests/travelrequest']);
      this.set('pathsWithoutParams', ['/blogs', '/login', '/logout', '/managedprofiles', '/myprofile', '/newprofile', '/obt', '/obtmenu', '/railsso', '/technology', '/travelalerts', '/travelrequests', '/cookieconsent', '/trip', '/faqitems']);
      this.set('modelCache', {});
      this.set('lettersOnly', "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz");
      this.set('numbersOnly', '1234567890');
      this.set('lettersAndSpaceOnly', this.lettersOnly + " ");
      this.set('surnameChars', this.lettersAndSpaceOnly + "'");
      this.set('lettersAndNumbersOnly', this.lettersOnly + this.numbersOnly);
      this.set('emailChars', this.lettersAndNumbersOnly + "!#$%&'*+/=?^_`{|}~-@."); //These names are used by Firefox. Chrome and IE do not trigger keyPressed events for these keys

      this.set('navigationKeys', ['Backspace', 'Tab', 'PageUp', 'PageDown', 'End', 'Home', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'Insert', 'Delete']);
      this.set('keycloakLandingSites', ['portal.selective-travel.com', 'portal.worldtravel.ie']); //Establish if browser is Internet Explorer
      //http://stackoverflow.com/a/9851769/5187080

      this.set("isExplorer",
      /*@cc_on!@*/
      false || !!document.documentMode);

      if (this.isExplorer) {
        let cookie = this.getCookie("ie11notice");

        if (cookie) {
          this.set("explorerMessageShown", cookie == "shown");
        } else {
          this.set("explorerMessageShown", false);
        }
      }
    }
    /*
      modelCache is a simple Map where the key is the enum model name
      The map value is a PromiseArray (an object that implements both the Promise and Array interfaces)
      As long as the Promise is pending, the Array interface reports a length of 0
      Once the promise is resolved, all methods are proxied to the underlying array.
      This means that a computed property which is observing the length of the array (array.[])
      gets triggered once the promise resolves.
      This is exactly the same mechanism used in Ember Data Store.
    */


    enum(modelType) {
      let result = this.modelCache[modelType];

      if (!result) {
        this.modelCache[modelType] = _emberData.default.PromiseArray.create({
          promise: this.store.findAll(modelType).then(list => {
            return list;
          }, errors => {
            console.log("Error loading Enum " + modelType);
            console.log(errors);
            throw errors;
          })
        });
      }

      return this.modelCache[modelType];
    }

    queryAll(modelType) {
      let result = this.modelCache[modelType];

      if (!result) {
        this.modelCache[modelType] = _emberData.default.PromiseArray.create({
          promise: this.store.query(modelType, {
            'pno': -1
          }).then(list => {
            return list;
          }, errors => {
            console.log("Error loading Cached Model " + modelType);
            console.log(errors);
            throw errors;
          })
        });
      }

      return this.modelCache[modelType];
    }

    logout() {
      this.set('modelCache', {});
      this.store.unloadAll();
      let redirectUrl = window.location.protocol + "//" + window.location.hostname;

      if (window.location.port) {
        redirectUrl += ":" + window.location.port;
      }

      return this.keycloakSession.logout(redirectUrl);
    }

    isLinkPermitted(link, client) {
      let permitted = true;
      let role1 = link.get('role1');
      let role2 = link.get('role2');
      let clientRoles = [];

      if (client) {
        if (client.get('clientProfile.id')) {
          clientRoles = client.get('clientProfile.clientRoles');
        }
      }

      if (role1) {
        if (!clientRoles.includes(role1)) {
          permitted = false;
        }
      }

      if (permitted && role2) {
        if (!clientRoles.includes(role2)) {
          permitted = false;
        }
      }

      return permitted;
    }

    replacePlaceholderUrls(s) {
      if (this.clientportal) {
        if (s) {
          return s.replace("<OBTURL>", this.clientportal.obtUrl).replace("<TRACKINGURL>", this.clientportal.trackingUrl).replace("<MISURL>", this.clientportal.misUrl).replace("<VISAURL>", this.clientportal.visaUrl).replace("<CARHIREURL>", this.clientportal.carHireUrl).replace("<RAILURL>", this.clientportal.railUrl).replace("<FERRYURL>", this.clientportal.ferryUrl);
        }
      }

      return s;
    }

    mayEditApprovers
    /*inverse */
    () {
      let result = false;

      if (this.clientportal) {
        result = this.clientportal.usingApprovers === true && this.clientportal.enumApproversForAllTravellers == 'NO';
      }

      return result;
    }

    mayEditArrangers(inverse) {
      let result = false;

      if (this.clientportal) {
        result = this.clientportal.usingArrangers === true && this.clientportal.allArrangersForAllTravellers === false && (!(inverse === true) || this.clientportal.arrangerMayEditArrangers === true);
      }

      return result;
    }

    mayEditPermissions(inverse, isArranger) {
      let result = false;

      if (this.clientportal) {
        result = this.clientportal.obtUrl && (inverse === true || isArranger);
      }

      return result;
    }

    encodeBase64(data) {
      return window.btoa(data); //return Buffer.from(data).toString('base64');
    }

    decodeBase64(data) {
      return window.atob(data); //return Buffer.from(data, 'base64').toString('ascii');
    }

    getWtcRefHeaderValue() {
      /*
        Returns six fields delimited by '/':
          Matamo Site id
          Matamo Visitor Id (keycloak id once logged in, if we can use PageViewId. Otherwise the MatomoVisitorId)
          Matomo PageView Id (to link BOSS generated events to the pageview they occurred on)
          CustomDimension1 (WTC or TSM)
          CustomDimension2 (site name)
          CustomDimension3 (our YNumber)
        Resulting string is then encoded using Base64
      */
      let wtcRef = '?';

      if (window.Matomo) {
        var _window$Matomo$getAsy;

        let tracker = (_window$Matomo$getAsy = window.Matomo.getAsyncTracker()) !== null && _window$Matomo$getAsy !== void 0 ? _window$Matomo$getAsy : window.Matomo.getTracker();

        if (tracker) {
          var _this$matomoPageViewI, _ref, _tracker$getCustomDim, _ref2, _tracker$getCustomDim2;

          let siteId = tracker.getSiteId();
          let userId = null;

          if (typeof this.canSetPageViewId !== 'undefined') {
            //If using Matomo 4+ we share a common pageViewId with BOSS, so can use keycloakId as visitorId
            //Otherwise give the Matomo internal visitorId to BOSS
            if (this.canSetPageViewId) {
              userId = tracker.getUserId();

              if (!userId && this.keycloakSession) {
                userId = this.keycloakSession.get('subject');

                window._paq.push(['setUserId', userId]);
              }
            }
          }

          if (!userId) {
            userId = tracker.getVisitorId();
          }

          let pageviewId = (_this$matomoPageViewI = this.matomoPageViewId) !== null && _this$matomoPageViewI !== void 0 ? _this$matomoPageViewI : "";
          let customDimension1 = (_ref = (_tracker$getCustomDim = tracker.getCustomDimension(1)) !== null && _tracker$getCustomDim !== void 0 ? _tracker$getCustomDim : _environment.default.APP.CLIENTCODE) !== null && _ref !== void 0 ? _ref : "";
          let customDimension2 = (_ref2 = (_tracker$getCustomDim2 = tracker.getCustomDimension(2)) !== null && _tracker$getCustomDim2 !== void 0 ? _tracker$getCustomDim2 : window.location.host) !== null && _ref2 !== void 0 ? _ref2 : "";
          let customDimension3 = tracker.getCustomDimension(3);

          if (!customDimension3 && this.currentUser) {
            if (this.currentUser.tradeClient && this.currentUser.get('tradeClient.ynumber')) {
              customDimension3 = this.currentUser.get('tradeClient.ynumber');
            }
          }

          if (!customDimension3) {
            customDimension3 = "";
          }

          wtcRef = this.encodeBase64(`${siteId}/${userId}/${pageviewId}/${customDimension1}/${customDimension2}/${customDimension3}`);
        }
      } //console.log("ApplicationAdapter wtcRef", wtcRef);


      return wtcRef;
    }

    getWtcRef2HeaderValue() {
      //returns Base64 encoded URL of page being displayed
      return this.encodeBase64(window.location.href.split("?")[0].split("#")[0]); //remove hash and query from URL
    }

    generateUniqueId() {
      //copied from matomo.js
      var id = '';
      var chars = 'abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      var charLen = chars.length;
      var i;

      for (i = 0; i < 6; i++) {
        id += chars.charAt(Math.floor(Math.random() * charLen));
      }

      return id;
    }

    setMatomoPageViewId() {
      //We need to send the pageViewId to BOSS so that the events are linked to the correct pageView.
      //However the pageViewId is an internal variable in Matomo.js and cannot be retrieved
      //It can, however be set, so we set it to a known value on entering a page, remember it,
      //and then we can use it ourselves when building the X-WTC header
      if (typeof this.canSetPageViewId !== 'undefined') {
        if (this.canSetPageViewId) {
          //this.canSetPageViewId is true so we can set the pageViewId via _paq
          if (window._paq) {
            this.matomoPageViewId = this.generateUniqueId();

            window._paq.push(['setPageViewId', this.matomoPageViewId]);
          }
        }
      } else {
        //this.canSetPageViewId is undefined so we need to find out if we can set the matomoPageViewId
        //This capability was only introduced in Matomo 4+
        if (window.Matomo) {
          var _window$Matomo$getAsy2;

          let tracker = (_window$Matomo$getAsy2 = window.Matomo.getAsyncTracker()) !== null && _window$Matomo$getAsy2 !== void 0 ? _window$Matomo$getAsy2 : window.Matomo.getTracker();

          if (tracker) {
            if (tracker.setPageViewId) {
              this.canSetPageViewId = true; //this.canSetPageViewId is now true, and we have a handle on teh tracker object, so set pageViewId directly

              this.matomoPageViewId = this.generateUniqueId();
              tracker.setPageViewId(this.matomoPageViewId);
            } else {
              this.canSetPageViewId = false;
            }
          }
        }
      } //console.log("MatomoPageViewId", this.matomoPageViewId);

    }

    sendAnalytics(routeName, optionalPath) {
      if (window._paq) {
        let host = window.location.host;

        if (optionalPath) {
          window._paq.push(['setCustomUrl', optionalPath]);
        } else {
          let href = window.location.href.split("?")[0].split("#")[0]; //remove hash and query from URL

          window._paq.push(['setCustomUrl', href]);
        }

        let thisRouteName = routeName;

        if (thisRouteName.endsWith('.index')) {
          thisRouteName = thisRouteName.substring(0, thisRouteName.length - 6);
        }

        window._paq.push(['setDocumentTitle', thisRouteName]); //window._paq.push(['deleteCustomVariables', 'page']);


        window._paq.push(["setCustomDimension", 1, _environment.default.APP.CLIENTCODE]);

        window._paq.push(["setCustomDimension", 2, host]);

        let guid = this.keycloakSession.get('subject');

        if (guid) {
          window._paq.push(['setUserId', guid]); // if (this.currentUser) {
          //   if ((this.currentUser.tradeClient) && (this.currentUser.get('tradeClient.ynumber'))) {
          //     window._paq.push(['setCustomDimension', 3, this.currentUser.get('tradeClient.ynumber')]);
          //   }
          // }

        }

        window._paq.push(['trackPageView']);
      }
    } //////////////////////////////////////////////////
    // Cookie Management
    //////////////////////////////////////////////////


    setCookie(name, value, expirydays) {
      var exdate = new Date();
      expirydays = expirydays || 365;
      exdate.setDate(exdate.getDate() + expirydays);
      document.cookie = name + '=' + value + '; expires=' + exdate.toUTCString() + '; path=/;SameSite=Lax';
    }

    getCookie(cname) {
      var name = cname + "=";
      var ca = document.cookie.split(';');

      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];

        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }

        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }

      return "";
    } //////////////////////////////////////////////////
    // Cookie Consent Management
    //////////////////////////////////////////////////


    cookieConsentStatus() {
      //Default Value
      let status = {
        dialogDismissed: false,
        functionalCookiesAllowed: false,
        performanceCookiesAllowed: false,
        targetingCookiesAllowed: false
      };
      console.log("Default Cookie Consent Status", status); //Copy stored values from cookie-consent

      let cookie = this.getCookie("cookie-consent");

      if (cookie) {
        cookie.split('&').forEach(item => {
          console.log(item);
          let tuple = item.split("=");

          if (tuple.length > 1 && Object.prototype.hasOwnProperty.call(status, tuple[0])) {
            status[tuple[0]] = tuple[1] === 'true';
          }
        });
      } // if (window._paq) {
      //   if (status.performanceCookiesAllowed === true) {
      //     window._paq.push(['setCookieConsentGiven']);
      //   }
      //   else {
      //     window._paq.push(['forgetCookieConsentGiven']);
      //   }
      // }


      return status;
    }

    allowAllCookies() {
      let status = this.cookieConsentStatus();
      status['dialogDismissed'] = true;
      status['functionalCookiesAllowed'] = true;
      status['performanceCookiesAllowed'] = true;
      status['targetingCookiesAllowed'] = true;
      this.updateCookieConsentStatus(status);
      return status;
    }

    updateCookieConsentStatus(status) {
      console.log("updateCookieConsentStatus", status);
      let cookieString = "";
      let expiryDays = -1;

      if (status) {
        status['dialogDismissed'] = true;
        expiryDays = 365; //build cookie string

        for (const [key, value] of Object.entries(status)) {
          //console.log(key, value);
          if (value === true || value === false) {
            cookieString += `${key}=${value}&`;
          }
        }
      }

      console.log("Setting cookie-consent: ", cookieString);
      this.setCookie('cookie-consent', cookieString, expiryDays); // if (window._paq) {
      //   if (status.performanceCookiesAllowed === true) {
      //     window._paq.push(['setCookieConsentGiven']);
      //   }
      //   else {
      //     window._paq.push(['forgetCookieConsentGiven']);
      //   }
      // }

      return status;
    }

    removeAccents(s) {
      if (typeof s === 'string') {
        if (typeof s.normalize !== 'undefined') {
          //normalize function is not available in all browsers (e.g. IE 11)
          return s.normalize('NFD').replace(/[\u0300-\u036f]/g, ""); //Replace accented characters with 'bare' equivalents
        } else {
          //Version for IE11- handles most characters we may come across
          let accents = "ÀÁÂÃÄÅĄàáâãäåąßÒÓÔÕÕÖØŐòóôőõöøĎďDŽdžÈÉÊËĘèéêëęðÇçČčĆćÐÌÍÎÏìíîïÙÚÛÜŰùűúûüĽĹŁľĺłÑŇŃňñńŔŕŠŚšśŤťŸÝÿýŽŻŹžżź";
          let accentsOut = "AAAAAAAaaaaaaasOOOOOOOOoooooooDdDZdzEEEEEeeeeeeCcCcCcDIIIIiiiiUUUUUuuuuuLLLlllNNNnnnRrSSssTtYYyyZZZzzz";
          let str = s.split('');
          var strLen = str.length;
          var i, x;

          for (i = 0; i < strLen; i++) {
            if ((x = accents.indexOf(str[i])) != -1) {
              str[i] = accentsOut[x];
            }
          }

          return str.join('');
        }
      } else {
        //if s is not a string then just return it as is
        return s;
      }
    }

    setExplorerMessageShown() {
      this.set("explorerMessageShown", true);

      if (this.cookieConsentStatus().functionalCookiesAllowed) {
        this.setCookie("ie11notice", "shown", 1);
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "keycloakSession", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isExplorer", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "explorerMessageShown", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "saving", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class2)) || _class);
  _exports.default = SystemService;
});