define("clientui/validations/newtraveller", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //This is used for the initial form requesting firstname, surname and email address
  var _default = {
    firstname: (0, _validators.validatePresence)(true),
    surname: (0, _validators.validatePresence)(true),
    email: [(0, _validators.validateFormat)({
      allowBlank: false,
      type: 'email'
    }), (0, _validators.validateLength)({
      max: 50
    })]
  };
  _exports.default = _default;
});