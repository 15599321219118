define("clientui/serializers/shortclient", ["exports", "@babel/runtime/helpers/esm/defineProperty", "clientui/serializers/application"], function (_exports, _defineProperty2, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let Shortclient = classic(_class = class Shortclient extends _application.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "primaryKey", 'cuiId');
    }

  }) || _class;

  _exports.default = Shortclient;
});