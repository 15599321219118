define("clientui/pods/components/pages/page-subscribe-mailchimp/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@glimmer/component"], function (_exports, _defineProperty2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PageSubscribeMailchimp extends _component.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "tsmUniUrl", 'https://selective-travel.us2.list-manage.com/subscribe/post?u=5b3af18e9c0c5184a22f69e28&id=2af352f239');
      (0, _defineProperty2.default)(this, "tsmQubUrl", 'https://selective-travel.us2.list-manage.com/subscribe/post?u=5b3af18e9c0c5184a22f69e28&id=1d86b834a1');
      (0, _defineProperty2.default)(this, "tsmSmeUrl", 'https://selective-travel.us2.list-manage.com/subscribe/post?u=5b3af18e9c0c5184a22f69e28&id=e66abe4186');
      (0, _defineProperty2.default)(this, "tsmGovUrl", 'https://selective-travel.us2.list-manage.com/subscribe/post?u=5b3af18e9c0c5184a22f69e28&id=42e5bf06fc');
      (0, _defineProperty2.default)(this, "wtcSmeUrl", 'https://selective-travel.us2.list-manage.com/subscribe/post?u=5b3af18e9c0c5184a22f69e28&id=de34be4eba');
      (0, _defineProperty2.default)(this, "wtcGovUrl", 'https://selective-travel.us2.list-manage.com/subscribe/post?u=5b3af18e9c0c5184a22f69e28&id=fada883297');
      (0, _defineProperty2.default)(this, "wtcMisUrl", 'https://selective-travel.us2.list-manage.com/subscribe?u=5b3af18e9c0c5184a22f69e28&id=0b7af53f10');
      (0, _defineProperty2.default)(this, "wtcPubUrl", 'https://selective-travel.us2.list-manage.com/subscribe?u=5b3af18e9c0c5184a22f69e28&id=6515ac1688');
    }

    get listURL() {
      console.log("List is " + this.args.list);
      let result = this.tsmSmeUrl;

      switch (this.args.list) {
        case 'UNI':
          result = this.tsmUniUrl;
          break;

        case 'QUB':
          result = this.tsmQubUrl;
          break;

        case 'SME':
          result = this.tsmSmeUrl;
          break;

        case 'GOV':
          result = this.tsmGovUrl;
          break;

        case 'WTC':
          result = this.wtcSmeUrl;
          break;

        case 'IGV':
          result = this.wtcGovUrl;
          break;

        case 'MIS':
          result = this.wtcMisUrl;
          break;

        case 'PUB':
          //Public Contracts
          result = this.wtcPubUrl;
          break;
      }

      console.log("returning URL of " + result);
      return result;
    }

  }

  _exports.default = PageSubscribeMailchimp;

  window.__CLASSIC_OWN_CLASSES__.set(PageSubscribeMailchimp, true);
});