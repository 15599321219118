define("clientui/pods/components/inputs/text-input/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3;

  let TextInput = (_dec = Ember.inject.service('system'), _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class TextInput extends _component.default {
    //value is a string representing the unformatted content of the input box
    //The original Arg Value - this is not tracked as it is managed by argValueHasChanged below
    //focused - true if the input box has focus
    //Flag to prevent onEnter and onChange being sent (almost) simultaneously
    constructor(owner, args) {
      var _this$args$value;

      super(owner, args); // console.log('TextInput: constructor. Initial Value: ' + this.args.value);

      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "value", _descriptor2, this);
      (0, _defineProperty2.default)(this, "originalArgValue", undefined);
      (0, _initializerDefineProperty2.default)(this, "focused", _descriptor3, this);
      (0, _defineProperty2.default)(this, "onEnterSent", false);
      (0, _defineProperty2.default)(this, "lastValueSent", undefined);
      this.value = '' + ((_this$args$value = this.args.value) !== null && _this$args$value !== void 0 ? _this$args$value : '');

      if (this.forceUpperCase) {
        this.value = this.value.toUpperCase();
      }

      this.originalArgValue = this.args.value;
    }

    get baseId() {
      var _this$args$id;

      return (_this$args$id = this.args.id) !== null && _this$args$id !== void 0 ? _this$args$id : Ember.guidFor(this);
    }

    get inputId() {
      return this.baseId + "-input";
    }

    get type() {
      var _this$args$type;

      return (_this$args$type = this.args.type) !== null && _this$args$type !== void 0 ? _this$args$type : "text"; // default type to text box.
    }

    get isTextArea() {
      //Use TextArea if either cols or rows are specified
      return this.args.cols || this.args.rows;
    }

    get forceUpperCase() {
      var _this$args$forceUpper;

      return (_this$args$forceUpper = this.args.forceUpperCase) !== null && _this$args$forceUpper !== void 0 ? _this$args$forceUpper : false;
    }

    get claimFocus() {
      var _this$args$claimFocus;

      return (_this$args$claimFocus = this.args.claimFocus) !== null && _this$args$claimFocus !== void 0 ? _this$args$claimFocus : false;
    }

    get placeholder() {
      var _this$args$placeholde, _this$args$label;

      return (_this$args$placeholde = this.args.placeholder) !== null && _this$args$placeholde !== void 0 ? _this$args$placeholde : (_this$args$label = this.args.label) !== null && _this$args$label !== void 0 ? _this$args$label : null;
    }

    get argValueHasChanged() {
      //console.log("argValueHasChanged start");
      //if ((this.originalArgValue === undefined) || (this.originalArgValue !== this.args.value)) {
      if (this.originalArgValue !== this.args.value) {
        //I don't see any other way to recognise that the original args.value has changed, without introducing 'side effects'
        // eslint-disable-next-line ember/no-side-effects
        this.originalArgValue = this.args.value; // eslint-disable-next-line ember/no-side-effects

        this.value = this.args.value; // console.log("argValueHasChanged value DID change");

        return true;
      } else {
        // console.log("argValueHasChanged value did not change");
        return false;
      }
    }

    get validChars() {
      var _this$args$validChars;

      return (_this$args$validChars = this.args.validChars) !== null && _this$args$validChars !== void 0 ? _this$args$validChars : "";
    }

    get formattedValue() {
      //console.log("starting formattedValue getter");
      // eslint-disable-next-line no-unused-vars
      let ignoreMe = this.argValueHasChanged; //this is just here to create a dependency. the value is meaningless

      let formattedValue = null;
      formattedValue = this.value; // console.log("formattedValue getter", formattedValue);

      return formattedValue;
    } //This setter is called by the Input box component when the user types something in


    set formattedValue(newValue) {
      // console.log("set formattedValue", newValue);
      if (this.forceUpperCase) {
        this.value = newValue ? newValue.toUpperCase() : ""; //we set the underlying value, not the formatted value
      } else {
        this.value = newValue !== null && newValue !== void 0 ? newValue : ""; //we set the underlying value, not the formatted value
      } // console.log("this.value", this.value);

    }

    didInsert(element) {
      if (element.classList.contains('claimfocus')) {
        //console.log("claiming focus on ", element.id);
        element.focus();
      }
    }

    get clearButtonDisabled() {
      return Ember.isEmpty(this.formattedValue);
    }

    sendValue(isEnter) {
      let newValue = Ember.isEmpty(this.value) ? null : this.value;

      if (typeof this.lastValueSent === undefined || newValue !== this.lastValueSent) {
        if (isEnter && this.args.onEnter) {
          this.args.onEnter(newValue);
          this.onEnterSent = true;
        } else {
          if (this.args.onChange) {
            this.args.onChange(newValue);
          }
        }

        this.lastValueSent = newValue;
      }
    }

    onKeyDown(event) {
      //Prevent Enter key from 'submitting' entire page
      this.onEnterSent = false;

      if (!this.isTextArea && event.key == "Enter") {
        //this covers both the normal Enter and the one on the Numeric Keypad
        event.preventDefault();
        this.sendValue(true);
      }
    }

    onKeyPress(event) {
      this.onEnterSent = false;
      let proceed = true;

      if (this.validChars) {
        let key = event.key;

        if (event.char) {
          //IE11 stores character in char instead of key
          key = event.char;
        }

        key = this.system.removeAccents(key);

        if (!this.validChars.includes(key)) {
          if (!event.ctrlKey) {
            //Allow ctrl keys through (e.g. copy/paste)
            if (!this.system.navigationKeys.includes(key)) {
              //Allow Firefox navigation keys (Chrome and IE don't send keyPress events for these)
              // console.log("Dropping key " + key + " not in ", this.validChars);
              proceed = false;
            }
          }
        } // console.log("onKeyPress", proceed, key, this.validChars);

      } //Prevent invalid value from forming


      if (!proceed) {
        event.preventDefault();
      }
    }
    /*
     * When focus is received, the number format changes to 'unformatted'
     */


    onFocusIn(event) {
      this.onEnterSent = false;
      this.focused = true; //this.inFocusOut = false;
      //console.log('Entering Number-Input Value focusIn()');

      let target = event.target; //this.set('formattedValue', unformat(this.value));

      if (typeof target.setSelectionRange === "function") {
        Ember.run.next(this, function () {
          //After runloop completes, highlight the entire cell contents
          let fv = this.formattedValue;
          let rangeEnd = 0;

          if (fv) {
            rangeEnd = fv.length;
          }

          target.setSelectionRange(0, rangeEnd);
        });
      }
    }
    /*
     * When focus is lost, the number format changes to 'formatted'
     */


    onFocusOut() {
      //this.inFocusOut =true;
      this.lastValueSent = undefined;

      if (typeof this.args.onFocusOut === "function") {
        this.args.onFocusOut();
      }

      this.focused = false; //console.log('Leaving Number-Input Value focusOut() val:', val);
    }
    /**
     * This is a method to capture the customEvent 'paste' which is setup in app.js
     * It is required to capture the paste event for entire Ember app and deals with pasting into the phonenumber text box.
     * See app.js to see how the event is setup.
     * @param {This is a jQuery event for paste } event 
     */


    onPaste(event) {
      this.onEnterSent = false;
      let prefix = '';
      let suffix = '';
      let paste = '';
      let initialValue = this.formattedValue;
      let target = event.target;
      let selectionStart = target.selectionStart;
      let selectionEnd = target.selectionEnd;
      let clipboardData = event.clipboardData || window.clipboardData;
      let pastedData = clipboardData.getData('Text');

      if (pastedData) {
        if (selectionStart > 0) {
          prefix = initialValue.substring(0, selectionStart);
        }

        if (initialValue) {
          suffix = initialValue.substring(selectionEnd);
        }

        if (this.validChars) {
          for (var key of pastedData) {
            let bareKey = this.system.removeAccents(key);

            if (this.validChars.includes(bareKey)) {
              paste += key;
            } else {////console.log('rejecting ' + key);
            }
          }
        } else {
          paste = pastedData;
        }

        let maxLength = this.args.maxlength;

        if (!Ember.isNone(maxLength) && maxLength > 0) {
          let existingLength = prefix.length + suffix.length;
          let excessLength = existingLength + paste.length - maxLength;

          if (excessLength > 0) {
            paste = paste.substr(0, paste.length - excessLength);
          }
        }

        this.value = prefix + paste + suffix; //Store new value containing pasted data

        let pos = (prefix + paste).length;
        Ember.run.next(this, function () {
          //After runloop completes, set the cursor position back to the end of the pasted data
          target.setSelectionRange(pos, pos);
        });
      }

      this.sendValue(false);
      event.preventDefault();
    }

    clearInput() {
      this.value = "";
    }

    onChange() {
      // console.log("onChange event", event);
      if (!this.onEnterSent) {
        this.sendValue(false);
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "value", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "focused", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onKeyDown", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyDown"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onKeyPress", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyPress"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onFocusIn", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onFocusIn"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onFocusOut", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onFocusOut"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onPaste", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onPaste"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "clearInput", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "clearInput"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onChange", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype)), _class));
  _exports.default = TextInput;
});