define("clientui/pods/myprofile/editpermissions/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "clientui/superclasses/this-route-edit", "clientui/mixins/parent-client-mixin", "clientui/mixins/child-clientpermissions-mixin"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _thisRouteEdit, _parentClientMixin, _childClientpermissionsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let EditpermissionsRoute = (_dec = Ember.inject.service('system'), classic(_class = (_class2 = class EditpermissionsRoute extends _thisRouteEdit.default.extend(_parentClientMixin.default, _childClientpermissionsMixin.default) {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "routeLeaf", 'editname');
      (0, _defineProperty2.default)(this, "dialogTitle", 'Edit Permissions');
      (0, _defineProperty2.default)(this, "enableDebugLogging", true);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
    }

    setupController(controller) {
      let client = this.modelFor('myprofile');

      if (!client) {
        this.transitionTo('myprofile');
      }

      controller.set('clientportal', this.get('system.clientportal'));
      super.setupController(controller, client);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = EditpermissionsRoute;
});