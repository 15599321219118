define("clientui/pods/components/dialogs/manage-cookies/component", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember-decorators/component"], function (_exports, _applyDecoratedDescriptor2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ManageCookies = (_dec = (0, _component.tagName)(''), _dec2 = Ember._action, _dec3 = Ember._action, classic(_class = _dec(_class = (_class2 = class ManageCookies extends Ember.Component {
    allowAll() {
      this.allowall();
    }

    saveChanges() {
      // console.log("Saving Changes from manage-cookies dialog", this.consentStatus);
      this.savechanges(this.consentStatus);
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "allowAll", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "allowAll"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "saveChanges", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "saveChanges"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = ManageCookies;
});