define("clientui/pods/components/widgets/link-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K1b0hvBu",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"widgets/external-link\",[],[[\"@href\"],[[34,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"widgets/link-card\",[],[[\"@caption\",\"@img-url\"],[[34,1],[34,2]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"link-to\",[],[[\"@route\"],[[34,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"widgets/link-card\",[],[[\"@caption\",\"@img-url\"],[[34,1],[34,2]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"page\",[34,0]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"widgets/link-card\",[],[[\"@caption\",\"@img-url\"],[[34,1],[34,2]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"linkId\",\"caption\",\"img-url\",\"internalRoute\",\"if\",\"href\"]}",
    "moduleName": "clientui/pods/components/widgets/link-item/template.hbs"
  });

  _exports.default = _default;
});