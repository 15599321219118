define("clientui/models/quotetraveller", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model", "clientui/models/modelwithdependentrelationships"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model, _modelwithdependentrelationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let Quotetraveller = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('number', {
    default: 0
  }), _dec8 = (0, _model.attr)('dateonly'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('string'), _dec16 = (0, _model.attr)('string'), _dec17 = (0, _model.attr)('dateonly'), _dec18 = (0, _model.attr)('dateonly'), _dec19 = (0, _model.attr)('string'), _dec20 = (0, _model.attr)('string'), _dec21 = (0, _model.attr)('string'), _dec22 = (0, _model.attr)('string'), _dec23 = (0, _model.attr)('string'), _dec24 = (0, _model.attr)('string'), _dec25 = (0, _model.attr)('boolean'), _dec26 = (0, _model.attr)('string'), _dec27 = (0, _model.attr)('boolean'), _dec28 = (0, _model.attr)('string'), _dec29 = Ember.computed('firstName', 'surname'), classic(_class = (_class2 = class Quotetraveller extends _modelwithdependentrelationships.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "title", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "firstName", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "middleName", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "surname", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "gender", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "genderLabel", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "clientId", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "dateOfBirth", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "emailAddress", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "paxType", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "paxTypeLabel", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "mobilePhone", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "landlinePhone", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "passportFullName", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "passportNumber", _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, "passportCountryOfIssue", _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, "passportDateOfIssue", _descriptor17, this);
      (0, _initializerDefineProperty2.default)(this, "passportDateOfExpiry", _descriptor18, this);
      (0, _initializerDefineProperty2.default)(this, "flightSeatPreference", _descriptor19, this);
      (0, _initializerDefineProperty2.default)(this, "flightSeatPreferenceLabel", _descriptor20, this);
      (0, _initializerDefineProperty2.default)(this, "flightMealPreference", _descriptor21, this);
      (0, _initializerDefineProperty2.default)(this, "flightMealPreferenceLabel", _descriptor22, this);
      (0, _initializerDefineProperty2.default)(this, "countryOfOperation", _descriptor23, this);
      (0, _initializerDefineProperty2.default)(this, "airSpecialRequests", _descriptor24, this);
      (0, _initializerDefineProperty2.default)(this, "nameisAsPerPassport", _descriptor25, this);
      (0, _initializerDefineProperty2.default)(this, "travelType", _descriptor26, this);
      (0, _initializerDefineProperty2.default)(this, "guest", _descriptor27, this);
      (0, _initializerDefineProperty2.default)(this, "guestFrequentFlyer", _descriptor28, this);
    }

    get selectDisplayText() {
      return `${this.firstName} ${this.surname}`;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "title", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "firstName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "middleName", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "surname", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "gender", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "genderLabel", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "clientId", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "dateOfBirth", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "emailAddress", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "paxType", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "paxTypeLabel", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "mobilePhone", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "landlinePhone", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "passportFullName", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "passportNumber", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "passportCountryOfIssue", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "passportDateOfIssue", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "passportDateOfExpiry", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "flightSeatPreference", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "flightSeatPreferenceLabel", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "flightMealPreference", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "flightMealPreferenceLabel", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "countryOfOperation", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "airSpecialRequests", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "nameisAsPerPassport", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "travelType", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "guest", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "guestFrequentFlyer", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "selectDisplayText", [_dec29], Object.getOwnPropertyDescriptor(_class2.prototype, "selectDisplayText"), _class2.prototype)), _class2)) || _class);
  _exports.default = Quotetraveller;
});