define("clientui/pods/managedprofiles/profile/editprofilenotes/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/initializerWarningHelper", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "clientui/superclasses/this-route-edit", "clientui/mixins/parent-managedclient-mixin", "clientui/mixins/child-clientprofilenotes-mixin"], function (_exports, _initializerDefineProperty2, _defineProperty2, _initializerWarningHelper2, _applyDecoratedDescriptor2, _thisRouteEdit, _parentManagedclientMixin, _childClientprofilenotesMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let EditprofilenotesRoute = (_dec = Ember.computed('managedClient'), _dec2 = Ember.inject.service('system'), classic(_class = (_class2 = class EditprofilenotesRoute extends _thisRouteEdit.default.extend(_parentManagedclientMixin.default, _childClientprofilenotesMixin.default) {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "routeLeaf", 'editprofilenotes');
      (0, _defineProperty2.default)(this, "enableDebugLogging", true);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
    }

    get dialogTitle() {
      let result = 'Edit Business Information';

      if (this.get('managedClient.selectDisplayText')) {
        result += ' for ' + this.get('managedClient.selectDisplayText');
      }

      return result;
    }

    setupController(controller) {
      let promises = this.modelFor('managedprofiles.profile');
      let managedClient = promises['model'];

      if (!managedClient) {
        this.transitionTo('managedprofiles');
      }

      console.log(managedClient);
      this.set('managedClient', managedClient);
      let client = promises['client'];
      console.log(client);
      controller.set('loggedinclient', client);
      controller.set('clientportal', this.get('system.clientportal'));
      super.setupController(controller, managedClient);
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "dialogTitle", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "dialogTitle"), _class2.prototype), _descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = EditprofilenotesRoute;
});