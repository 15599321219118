define("clientui/validations/clientprofilenotes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import { validatePresence } from 'ember-changeset-validations/validators';
  var _default = {// value: validatePresence(true),
    // description: validatePresence(true),
  };
  _exports.default = _default;
});