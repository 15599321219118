define("clientui/pods/components/widgets/contact-card/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ContactCard = (_dec = (0, _component.classNames)('card'), classic(_class = _dec(_class = class ContactCard extends Ember.Component {}) || _class) || _class);
  _exports.default = ContactCard;
});