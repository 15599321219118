define("clientui/pods/impersonate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3iEdvpIS",
    "block": "{\"symbols\":[\"closeDialog\"],\"statements\":[[8,\"layout/page-header\",[],[[\"@clientportal\",\"@client\",\"@showBanner\",\"@pageTitle\"],[[34,0],[34,1],false,\"Contact Us\"]],null],[2,\"\\n\"],[10,\"section\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[14,1,\"content\"],[12],[2,\"\\n    \"],[8,\"widgets/dialog-box-route\",[],[[\"@title\",\"@historyBack\"],[\"Impersonate Another User\",false]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"dialogs/form-impersonate\",[],[[\"@clientportal\",\"@onSave\",\"@onRollback\",\"@onClose\"],[[34,0],[30,[36,2],[\"save\"],null],[30,[36,2],[\"back\"],null],[30,[36,3],[[32,0],[32,1]],null]]],null],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"clientportal\",\"client\",\"route-action\",\"action\"]}",
    "moduleName": "clientui/pods/impersonate/template.hbs"
  });

  _exports.default = _default;
});