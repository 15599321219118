define("clientui/pods/components/widgets/fade-alert/component", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@glimmer/component", "jquery"], function (_exports, _applyDecoratedDescriptor2, _component, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let WidgetsFadeAlertComponent = (_dec = Ember._action, (_class = class WidgetsFadeAlertComponent extends _component.default {
    setupFade(element) {
      // eslint-disable-next-line ember/no-jquery
      let jQueryElement = (0, _jquery.default)(element);
      jQueryElement.fadeIn('fast').delay(3000).fadeOut('slow');
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "setupFade", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "setupFade"), _class.prototype)), _class));
  _exports.default = WidgetsFadeAlertComponent;
});