define("clientui/mixins/child-clientcontacts-mixin", ["exports", "clientui/validations/clientcontacts"], function (_exports, _clientcontacts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ClientContactValidations: _clientcontacts.default,
    validationName: 'ClientContactValidations',
    paramName: '',
    modelType: 'client',

    newPreSaveHook() {//(changeset) {
    },

    editPreSaveHook() {//(changeset) {
    }

  });

  _exports.default = _default;
});