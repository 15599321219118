define("clientui/pods/travelrequests/requestnew/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "go29ZV3O",
    "block": "{\"symbols\":[],\"statements\":[[8,\"widgets/card-basic\",[],[[\"@header\",\"@inverse\",\"@title\"],[[34,0],true,\"Please complete the following steps\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"wizards/wizard-create-request\",[],[[\"@client\",\"@enquiryContainer\",\"@clientportal\",\"@managedclientlist\",\"@inverse\",\"@changeRequest\",\"@onSave\"],[[34,1],[34,2],[34,3],[34,4],true,false,[30,[36,5],[\"save\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"title\",\"client\",\"enquiryContainer\",\"clientportal\",\"managedclientlist\",\"route-action\"]}",
    "moduleName": "clientui/pods/travelrequests/requestnew/template.hbs"
  });

  _exports.default = _default;
});