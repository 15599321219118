define("clientui/models/booking", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38, _descriptor39;

  let Booking = (_dec = (0, _model.attr)('number'), _dec2 = (0, _model.belongsTo)('account'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('date'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('boolean'), _dec8 = (0, _model.belongsTo)('bookingcategory'), _dec9 = (0, _model.belongsTo)('shortclient'), _dec10 = (0, _model.belongsTo)('shortclient'), _dec11 = (0, _model.belongsTo)('shortclient'), _dec12 = (0, _model.attr)('date'), _dec13 = (0, _model.belongsTo)('currency'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('string'), _dec16 = (0, _model.attr)('string'), _dec17 = (0, _model.hasMany)('bookingpax'), _dec18 = (0, _model.hasMany)('transaction'), _dec19 = (0, _model.hasMany)('invoiceitem'), _dec20 = (0, _model.hasMany)('bookingreference'), _dec21 = (0, _model.hasMany)('ticket'), _dec22 = (0, _model.attr)('date'), _dec23 = (0, _model.attr)('date'), _dec24 = (0, _model.attr)('number'), _dec25 = (0, _model.attr)('number'), _dec26 = (0, _model.attr)('string'), _dec27 = (0, _model.attr)('number'), _dec28 = (0, _model.attr)('number'), _dec29 = (0, _model.belongsTo)('city'), _dec30 = (0, _model.belongsTo)('city'), _dec31 = (0, _model.belongsTo)('airline'), _dec32 = (0, _model.attr)('number'), _dec33 = (0, _model.attr)('date'), _dec34 = (0, _model.attr)('date'), _dec35 = (0, _model.attr)('string'), _dec36 = (0, _model.attr)('string'), _dec37 = (0, _model.attr)('boolean'), _dec38 = (0, _model.attr)('string'), _dec39 = (0, _model.belongsTo)('bookingaccessgroup'), (_class = class Booking extends _model.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "version", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "account", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "status", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "statusLabel", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "enquiryExpiryDate", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "nlSubCode", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "online", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "bookingCategory", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "leadPax", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "client", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "approver", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "dateOfTravel", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "currency", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "clientRef", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "billingStatus", _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, "billingStatusLabel", _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, "bookingPax", _descriptor17, this);
      (0, _initializerDefineProperty2.default)(this, "transactions", _descriptor18, this);
      (0, _initializerDefineProperty2.default)(this, "shortInvoiceItems", _descriptor19, this);
      (0, _initializerDefineProperty2.default)(this, "bookingReferences", _descriptor20, this);
      (0, _initializerDefineProperty2.default)(this, "tickets", _descriptor21, this);
      (0, _initializerDefineProperty2.default)(this, "activationDate", _descriptor22, this);
      (0, _initializerDefineProperty2.default)(this, "cancellationDate", _descriptor23, this);
      (0, _initializerDefineProperty2.default)(this, "outstandingBalance", _descriptor24, this);
      (0, _initializerDefineProperty2.default)(this, "fcOutstandingBalance", _descriptor25, this);
      (0, _initializerDefineProperty2.default)(this, "invoiceNotes", _descriptor26, this);
      (0, _initializerDefineProperty2.default)(this, "totalFcRevenue", _descriptor27, this);
      (0, _initializerDefineProperty2.default)(this, "totalFcNetInvoice", _descriptor28, this);
      (0, _initializerDefineProperty2.default)(this, "destination", _descriptor29, this);
      (0, _initializerDefineProperty2.default)(this, "depart", _descriptor30, this);
      (0, _initializerDefineProperty2.default)(this, "primaryAirline", _descriptor31, this);
      (0, _initializerDefineProperty2.default)(this, "paxCount", _descriptor32, this);
      (0, _initializerDefineProperty2.default)(this, "dateOfReturn", _descriptor33, this);
      (0, _initializerDefineProperty2.default)(this, "travelComplete", _descriptor34, this);
      (0, _initializerDefineProperty2.default)(this, "pricingLevel", _descriptor35, this);
      (0, _initializerDefineProperty2.default)(this, "pricingLevelLabel", _descriptor36, this);
      (0, _initializerDefineProperty2.default)(this, "usingclientInvoices", _descriptor37, this);
      (0, _initializerDefineProperty2.default)(this, "usingClientInvoiceAllocations", _descriptor38, this);
      (0, _initializerDefineProperty2.default)(this, "bookingAccessGroup", _descriptor39, this);
    }

    get statementDetails() {
      return `${this.leadPax}`;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "version", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "account", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "status", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "statusLabel", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "enquiryExpiryDate", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "nlSubCode", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "online", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "bookingCategory", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "leadPax", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "client", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "approver", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dateOfTravel", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "currency", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "clientRef", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "billingStatus", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "billingStatusLabel", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "bookingPax", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "transactions", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "shortInvoiceItems", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "bookingReferences", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tickets", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "activationDate", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cancellationDate", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "outstandingBalance", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fcOutstandingBalance", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "invoiceNotes", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "totalFcRevenue", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "totalFcNetInvoice", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "destination", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "depart", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "primaryAirline", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "paxCount", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dateOfReturn", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "travelComplete", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "pricingLevel", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "pricingLevelLabel", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "usingclientInvoices", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor38 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "usingClientInvoiceAllocations", [_dec38], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor39 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "bookingAccessGroup", [_dec39], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Booking;
});