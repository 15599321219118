define("clientui/models/specialservicerequest", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let Specialservicerequest = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.belongsTo)('airline'), _dec3 = (0, _model.attr)('string'), _dec4 = Ember.computed('ssrCode'), _dec5 = Ember.computed('airline', 'extraInfo'), classic(_class = (_class2 = class Specialservicerequest extends _model.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "ssrCode", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "airline", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "extraInfo", _descriptor3, this);
    }

    get selectDisplayText() {
      return `${this.ssrCode}`;
    }

    get displayText() {
      let s = null;

      if (this.airline.get('id')) {
        s = this.airline.get('id') + (this.extraInfo ? ': ' + this.extraInfo : '');
      } else {
        s = 'All airlines' + (this.extraInfo ? ': ' + this.extraInfo : '');
      }

      return s;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "ssrCode", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "airline", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "extraInfo", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "selectDisplayText", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "selectDisplayText"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "displayText", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "displayText"), _class2.prototype)), _class2)) || _class);
  _exports.default = Specialservicerequest;
});