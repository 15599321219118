define("clientui/pods/components/dialogs/form-profile-edit-profilenotes/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _class2, _descriptor, _descriptor2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let FormProfileEditProfilenotes = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('system'), _dec3 = Ember.computed('client.clientProfile.profileNoteItems.{[],@each.deleted}'), _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, classic(_class = (_class2 = class FormProfileEditProfilenotes extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
    }

    init() {
      super.init(...arguments); //console.log('Init Create Profile Wizard');
    }

    didReceiveAttrs() {}

    get sortedNotes() {
      return this.get('client.clientProfile.profileNoteItems').filter(item => !item.get('deleted')).sortBy('selectDisplayText', 'id');
    }

    onActivate(active) {
      //If true, page goes in to add/edit/delete mode
      if (active) {
        this.set('pageActive', true);
      } else {
        this.set('pageActive', false);
      }
    }

    doValidate() {
      let newErrors = [];
      let item = this.currentItem;

      if (item) {
        if (item.get('editing') === true) {
          if (Ember.isBlank(item.get('newTitle'))) {
            newErrors.pushObject('Note Title cannot be blank');
          }

          if (Ember.isBlank(item.get('newNote'))) {
            newErrors.pushObject('Note Content cannot be blank');
          }
        } //this.set('errors', newErrors);


        item.set('itemErrors', newErrors);
      }
    }

    copyField(obj, src, dst) {
      //if (is(obj.get(src))) {
      if (src in obj) {
        obj.set(dst, obj.get(src));
      }
    }

    edit() {
      if (!this.pageActive) {
        let item = this.selectedNote;

        if (item) {
          if (typeof item.get('editing') === 'undefined') {
            console.log("Storing Original Values");
            item.set('originalTitle', item.get('title'));
            item.set('originalNote', item.get('note'));
          }

          item.set('newTitle', item.get('title'));
          item.set('newNote', item.get('note'));
          this.onActivate(true);
          item.set('editing', true);
          item.set('deleting', false);
          this.set('hideAdd', true); //component-global flag

          this.set('currentItem', item);
          this.$('#view').collapse('hide');
          this.$('#addNew').collapse('show');
        }
      }
    }

    delete() {
      if (!this.pageActive) {
        let item = this.selectedNote;

        if (item) {
          if (typeof item.get('editing') === 'undefined') {
            console.log("Storing Original Values");
            item.set('originalTitle', item.get('title'));
            item.set('originalNote', item.get('note'));
          }

          item.set('newTitle', item.get('title'));
          item.set('newNote', item.get('note'));
          this.onActivate(true);
          item.set('editing', false);
          item.set('deleting', true);
          this.set('hideAdd', true); //component-global flag

          this.set('currentItem', item);
          this.$('#view').collapse('hide');
          this.$('#addNew').collapse('show');
        }
      }
    }

    addNew() {
      if (!this.pageActive) {
        this.set('adding', true);
        this.onActivate(true);
        let item = this.store.createRecord('profilenoteitem');
        item.set('editing', true);
        item.set('deleting', false);
        this.set('currentItem', item);
        this.$('#view').collapse('hide');
        this.$('#addNew').collapse('show');
      }
    }

    validate() {
      this.doValidate();
    }

    apply() {
      let item = this.currentItem;

      if (item) {
        let success = true;

        if (item.get('editing') === true) {
          this.doValidate();
          success = Ember.isEmpty(item.get('itemErrors'));
        } else if (item.get('deleting') === true) {
          item.set('deleted', true);
          this.set('selectedNote', null); //Clear Selection Box
        } else {
          console.log("nothing to do");
        }

        if (success === true) {
          if (item.get('editing') === true) {
            item.set('title', item.get('newTitle'));
            item.set('note', item.get('newNote'));

            if (Ember.isBlank(item.get("id"))) {
              this.get('client.clientProfile.profileNoteItems').pushObject(item);
            }
          }

          this.onActivate(false);
          item.set('editing', false);
          item.set('deleting', false);
          this.set('hideAdd', false); //component-global flag

          this.$('#addNew').collapse('hide');

          if (this.selectedNote) {
            this.$('#view').collapse('show');
          }
        }
      }
    }

    backAdd() {
      let item = this.currentItem;

      if (item) {
        this.onActivate(false);
        item.set('editing', false);
        item.set('deleting', false);
        this.set('hideAdd', false); //component-global flag

        this.$('#addNew').collapse('hide');

        if (this.selectedNote) {
          this.$('#view').collapse('show');
        }
      }
    } // backAdd() {
    //   this.set('adding', false);
    //   this.$('#addNew').collapse('hide');
    //   this.onActivate(false);
    // },


    back() {
      //Rollback any changes
      this.get('client.clientProfile.profileNoteItems').forEach(item => {
        this.copyField(item, 'originalTitle', 'title');
        this.copyField(item, 'originalNote', 'note');
        item.set('editing', undefined);
        item.set('deleting', undefined);
        item.set('deleted', undefined);
      });
      let newItems = this.get('client.clientProfile.profileNoteItems').filter(item => item.get('isNew'));
      newItems.forEach(item => {
        this.get('client.clientProfile.profileNoteItems').removeObject(item);
        this.store.unloadRecord(item);
      }); //Now at the client level

      this.client.rollback();

      this.client._content._internalModel._record.rollbackAttributes(); //Close dialog


      this.onClose();
      this.onRollback();
    }

    save() {
      this.system.set('saving', true);
      console.log("starting SAVE");
      let client = this.client;
      client.validate().then(() => {
        if (client.get("isValid")) {
          // let fieldName = null;
          // let selectionId = 0;
          // let model = null;
          let promises = {}; // if (isPresent(this.get('client.defaultDepartureAirport'))) {
          //   fieldName = 'clientProfile.airPrefDepartureAirport';
          //   model = 'airport';
          //   selectionId = this.get('client.defaultDepartureAirport');
          //   promises[fieldName] = this.get('store').peekRecord(model, selectionId);
          // }
          // if (isPresent(this.get('client.defaultDepartureStation'))) {
          //   fieldName = 'clientProfile.railPrefDepartureStation';
          //   model = 'trainstation';
          //   selectionId = this.get('client.defaultDepartureStation');
          //   promises[fieldName] = this.get('store').peekRecord(model, selectionId);
          // }

          let deletedItems = this.get('client.clientProfile.profileNoteItems').filter(item => item.get('deleted') === true);
          deletedItems.forEach(item => {
            this.get('client.clientProfile.profileNoteItems').removeObject(item);
          });
          this.get('client.clientProfile.profileNoteItems').forEach(item => {
            console.log("setting all editing flags to undefined");
            item.set('editing', undefined);
            item.set('deleting', undefined);
            item.set('deleted', undefined);
          }); //move all non-client fields into their appropriate places
          //this.fixupNewClient(client);

          Ember.RSVP.hash(promises).then(item => {
            Object.keys(item).forEach(key => {
              client.set(key, item[key]);
            });
            this.onSave(client).then(() => {
              // Promise
              console.log('ClientProfileNoteItems Promise Saved Successfully in Component');
              deletedItems.forEach(item => {
                this.store.unloadRecord(item);
              });
              this.onClose(); //this.onRefreshRoute();

              this.system.set('saving', false);
            }).catch(errors => {
              console.log('ClientProfileNoteItems Promise Failed to Save in Component');
              console.log(errors);
              this.set('servererrors', errors);
              this.system.set('saving', false);
            });
          }, errors => {
            console.log('ClientProfileNoteItems Promise Failed to Save in Component');
            console.log(errors);
            this.set('servererrors', errors);
            this.system.set('saving', false);
          });
        } else {
          this.system.set('saving', false);
        }
      }, () => {
        this.system.set('saving', false);
      });
    }

    setAction(item) {
      //console.log('Setting ' + this.get('propertyName') + ' to ' + item.id);
      this.set('selectedNote', item);

      if (item) {
        this.$('#view').collapse('show');
      }
    }

    handleFocus(select, e) {
      if (this.focusComesFromOutside(e)) {
        select.actions.open();
      }
    }

    focusComesFromOutside(e) {
      let blurredEl = e.relatedTarget;

      if (Ember.isBlank(blurredEl)) {
        return false;
      }

      return !blurredEl.classList.contains('ember-power-select-search-input');
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sortedNotes", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "sortedNotes"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "edit", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "edit"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "delete", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "delete"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "addNew", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "addNew"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "validate", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "validate"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apply", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "apply"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "backAdd", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "backAdd"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "back", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "back"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "save", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "setAction", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "setAction"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleFocus", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "handleFocus"), _class2.prototype)), _class2)) || _class);
  _exports.default = FormProfileEditProfilenotes;
});