define("clientui/serializers/clientinvoice", ["exports", "@babel/runtime/helpers/esm/defineProperty", "clientui/serializers/pageablelist"], function (_exports, _defineProperty2, _pageablelist) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class serializer extends _pageablelist.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "attrs", {
        account: {
          serialize: 'id',
          deserialize: 'records'
        },
        booking: {
          serialize: 'id',
          deserialize: 'records'
        },
        currency: {
          serialize: 'id',
          deserialize: 'records'
        },
        bookingReferences: {
          embedded: 'always'
        },
        clientInvoiceVatDetails: {
          embedded: 'always'
        },
        clientInvoiceLines: {
          embedded: 'always'
        },
        pax: {
          embedded: 'always'
        },
        transactions: {
          embedded: 'always'
        }
      });
    }

  }

  _exports.default = serializer;

  window.__CLASSIC_OWN_CLASSES__.set(serializer, true);
});