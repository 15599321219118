define("clientui/pods/components/widgets/profile-note-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ld/plgQB",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[14,0,\"card-text\"],[12],[2,\"\\n  \"],[1,[30,[36,3],[[35,1,[\"note\"]]],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,4],[[35,1,[\"createdOn\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"card-text mb-0\"],[12],[2,\"\\n    \"],[10,\"small\"],[12],[2,\"\\n      Created: \"],[1,[30,[36,2],[[35,1,[\"createdOn\"]],[35,0]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[35,1,[\"modifiedOn\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"card-text mb-0\"],[12],[2,\"\\n    \"],[10,\"small\"],[12],[2,\"\\n      Last Modified: \"],[1,[30,[36,2],[[35,1,[\"modifiedOn\"]],[35,0]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"dateTimeFormat\",\"note\",\"moment-format\",\"text-to-html\",\"if\"]}",
    "moduleName": "clientui/pods/components/widgets/profile-note-item/template.hbs"
  });

  _exports.default = _default;
});