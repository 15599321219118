define("clientui/pods/components/inputs/validated-document/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "clientui/config/environment", "jquery"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3;

  let ValidatedDocument = (_dec = Ember.inject.service('system'), _dec2 = Ember.inject.service('keycloak-session'), _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class ValidatedDocument extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "titles", _descriptor3, this);
      this.args.doc.set('documentErrors', []);
      this.system.enum('title').then(titles => {
        this.titles = titles;

        if (this.args.doc.newAltTitle) {
          this.args.doc.set('newAltTitleObject', titles.findBy('id', this.args.doc.newAltTitle));
        }
      });
    }

    doValidation() {
      let newErrors = [];
      let item = this.args.doc.newReferenceNumber;

      if (Ember.isBlank(item)) {
        newErrors.pushObject("Reference Number cannot be blank");
      }

      this.errors = newErrors;
      this.args.doc.set('documentErrors', newErrors);
    }

    get isPassport() {
      return this.args.category === 'PASSPORT';
    }

    get isCarReg() {
      return this.args.category === 'CARREG';
    }

    get uploadPath() {
      let result = null;
      let clientId = Ember.get(this.args.client, "id");
      let docId = Ember.get(this.args.doc, "id");

      if (clientId && docId) {
        let host = _environment.default.APP.JBOSS_URL;
        let path = "/v1/clients/" + clientId + "/documents/" + docId;
        result = host + path;
        console.log(result);
      }

      return result;
    }

    validate() {
      this.doValidation();
    }

    setCountryAction(country) {
      this.args.doc.set('newCountry', country);
    }

    setCitizenshipAction(country) {
      this.args.doc.set('newCitizenship', country);
    }

    setTitleAction(title) {
      this.args.doc.set('newAltTitleObject', title);
      this.args.doc.set('newAltTitle', title.id);
    }

    setText(fieldName, value) {
      //All the TextInput fields call this action
      this.args.doc.set(fieldName, value);
      this.doValidation();
    }

    uploadFile() {
      console.log("in uploadFile"); //Change file uploaded to false before resetting it to true so that a change is noticed and the image is reloaded

      if (this.args.doc.fileUploaded === true) {
        this.args.doc.set('fileUploaded', false);
      }

      Ember.run.next(this, function () {
        this.args.doc.set('fileUploaded', true);
      });
    }

    deleteUpload(url) {
      console.log(url);

      let _this = this;

      _jquery.default.ajax({
        url: url,
        type: 'DELETE',
        headers: {
          'Authorization': `Bearer ${this.session.token}`,
          "X-WTC": this.system.getWtcRefHeaderValue(),
          "X-WTC2": this.system.getWtcRef2HeaderValue()
        },
        success: function (result) {
          console.log(result);

          _this.args.doc.set('fileUploaded', false);
        },
        error: function (jqXHR, status) {
          console.log(status);
        }
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "titles", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "validate", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "validate"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setCountryAction", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setCountryAction"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setCitizenshipAction", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setCitizenshipAction"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setTitleAction", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setTitleAction"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setText", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "setText"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "uploadFile", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "uploadFile"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "deleteUpload", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "deleteUpload"), _class.prototype)), _class));
  _exports.default = ValidatedDocument;
});