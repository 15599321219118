define("clientui/pods/customeraccounts/statements/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CustomeraccountsStatementsRoute extends Ember.Route {}

  _exports.default = CustomeraccountsStatementsRoute;

  window.__CLASSIC_OWN_CLASSES__.set(CustomeraccountsStatementsRoute, true);
});