define("clientui/pods/components/dialogs/form-profile-edit-contact-address/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let FormProfileEditContactAddress = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('system'), _dec3 = Ember.computed('client.addresses.{[],@each.deleted}'), _dec4 = Ember.computed.sort('addresses', 'sortDefinition'), _dec5 = Ember.computed.sort('countries', 'countrySortDefinition'), _dec6 = Ember.computed('clientportal.homeCountryCode'), _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, classic(_class = (_class2 = class FormProfileEditContactAddress extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
      (0, _defineProperty2.default)(this, "sortDefinition", null);
      (0, _initializerDefineProperty2.default)(this, "sortedAddresses", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "sortedCountries", _descriptor4, this);
    }

    init() {
      super.init(...arguments); //console.log('Init Create Profile Wizard');

      this.set('sortDefinition', ['defaultAddress:desc', 'description', 'id']);
    }

    didReceiveAttrs() {
      // if (this.get('category') !== 'EMAIL') {
      //   this.set('validCountries', this.get('countries').filter((item) => (isPresent(item.get('dialCode')))));
      // }
      this.set('newAddress', this.store.createRecord('address'));
    }

    get addresses() {
      return this.get('client.addresses').filter(item => !item.get('deleted'));
    }

    get countrySortDefinition() {
      return ['priority' + this.get('clientportal.homeCountryCode'), 'name'];
    }

    doValidate(item) {
      let result = true;

      if (Ember.isBlank(item.get('newDescription'))) {
        item.set('newDescriptionError', 'Description cannot be blank');
        result = false;
      } else {
        item.set('newDescriptionError', null);
      }

      if (item.get('addressErrors').length > 0) {
        result = false;
      }

      return result;
    }

    doValidateNew() {
      let result = true;

      if (Ember.isBlank(this.get('newAddress.newDescription'))) {
        this.set('newItemDescriptionError', 'Description cannot be blank');
        result = false;
      } else {
        this.set('newItemDescriptionError', null);
      }

      if (this.get('newAddress.addressErrors').length > 0) {
        result = false;
      }

      return result;
    }

    commitChanges(address) {
      address.set('description', address.get('newDescription'));
      address.set('line1', address.get('newLine1'));
      address.set('line2', address.get('newLine2'));
      address.set('line3', address.get('newLine3'));
      address.set('city', address.get('newCity'));
      address.set('county', address.get('newCounty'));
      address.set('country', address.get('newCountry'));
      address.set('postCode', address.get('newPostCode'));
    }

    validateNew() {
      this.doValidateNew();
    }

    validate(item) {
      this.doValidate(item);
    }

    edit(item) {
      if (!this.pageActive) {
        if (typeof item.get('editing') === 'undefined') {
          console.log("Setting Original Values");
          item.set('originalDescription', item.get('description'));
          item.set('originalLine1', item.get('line1'));
          item.set('originalLine2', item.get('line2'));
          item.set('originalLine3', item.get('line3'));
          item.set('originalCity', item.get('city'));
          item.set('originalCounty', item.get('county'));
          item.set('originalCountry', item.get('country'));
          item.set('originalPostCode', item.get('postCode'));
        }

        item.set('newDescription', item.get('description'));
        item.set('newDescriptionEditable', !['BUSINESS', 'PERSONAL'].includes(item.get('description').toUpperCase()));
        item.set('newLine1', item.get('line1'));
        item.set('newLine2', item.get('line2'));
        item.set('newLine3', item.get('line3'));
        item.set('newCity', item.get('city'));
        item.set('newCounty', item.get('county'));
        item.set('newCountry', item.get('country'));
        item.set('newPostCode', item.get('postCode'));
        this.onActivate(true);
        item.set('editing', true);
        item.set('deleting', false);
        this.set('hideAdd', true); //component-global flag
      }
    }

    delete(item) {
      if (!this.pageActive) {
        this.onActivate(true);
        item.set('editing', false);
        item.set('deleting', true);
        this.set('hideAdd', true); //component-global flag
      }
    }

    addNew() {
      if (!this.pageActive) {
        this.set('adding', true);
        this.onActivate(true);
      }
    }

    back(item) {
      this.onActivate(false);
      item.set('editing', false);
      item.set('deleting', false);
      this.set('hideAdd', false); //component-global flag
    }

    backAdd() {
      this.set('adding', false);
      this.$('#addNew').collapse('hide');
      this.onActivate(false);
    }

    add() {
      if (this.doValidateNew()) {
        let newAddress = this.newAddress;
        this.commitChanges(newAddress);
        this.get('client.addresses').pushObject(newAddress);
        this.set('newAddress', this.store.createRecord('address'));
        this.$('#addNew').collapse('hide');
        this.set('adding', false);
        this.onActivate(false);
      }
    }

    apply(item) {
      let success = true;

      if (item.get('editing') === true) {
        if (this.doValidate(item)) {
          this.commitChanges(item);
        } else {
          success = false;
        }
      } else if (item.get('deleting') === true) {
        item.set('deleted', true);
      } else {
        console.log("nothing to do");
      }

      if (success) {
        this.onActivate(false);
        item.set('editing', false);
        item.set('deleting', false);
        this.set('hideAdd', false); //component-global flag
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "addresses", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "addresses"), _class2.prototype), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sortedAddresses", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sortedCountries", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "countrySortDefinition", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "countrySortDefinition"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "validateNew", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "validateNew"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "validate", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "validate"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "edit", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "edit"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "delete", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "delete"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "addNew", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "addNew"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "back", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "back"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "backAdd", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "backAdd"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "add", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "add"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apply", [_dec15], Object.getOwnPropertyDescriptor(_class2.prototype, "apply"), _class2.prototype)), _class2)) || _class);
  _exports.default = FormProfileEditContactAddress;
});