define("clientui/pods/components/widgets/pick-list/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let PickList = (_dec = Ember.computed('allOptions.[]', 'selectedOptions.[]'), _dec2 = Ember.computed('selectedOptions.[]', 'minSelectionCount'), _dec3 = Ember.computed('selectedOptions.[]', 'maxSelectionCount'), _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, classic(_class = (_class2 = class PickList extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "identityField", 'id');
    }

    init() {
      super.init(...arguments);
      console.log('Init Pick List');
    }

    get unselectedOptions() {
      let result = []; // console.log("----------Start---------")
      // console.log("AllOptions:")
      // console.log(this.get('allOptions'));
      // console.log("SelectedOptions:")
      // console.log(this.get('selectedOptions'));

      let selectedIds = [];

      if (Ember.isPresent(this.selectedOptions)) {
        this.selectedOptions.forEach(item => {
          selectedIds.push(item.get(this.identityField));
        });
      }

      if (Ember.isPresent(this.allOptions)) {
        this.allOptions.forEach(item => {
          //if (!this.get('selectedOptions').includes(item)) {
          if (!selectedIds.includes(item.get(this.identityField))) {
            result.pushObject(item);
          }
        });
      } // console.log("UnselectedOptions:")
      // console.log(result);


      return result;
    }

    get minSelectionValidation() {
      let result = null;
      let minSelectionCount = Number(this.minSelectionCount);

      if (minSelectionCount > 0) {
        if (this.selectedOptions.length < minSelectionCount) {
          result = "At least " + minSelectionCount + " item must be selected";
        }
      }

      return result;
    }

    get maxSelectionValidation() {
      let result = null;
      let maxSelectionCount = Number(this.maxSelectionCount);

      if (maxSelectionCount > 0) {
        if (this.selectedOptions.length > maxSelectionCount) {
          result = "At most " + maxSelectionCount + " items can be selected";
        }
      }

      return result;
    }

    dblClickLeft(option) {
      this.selectedOptions.pushObject(option);

      if (this.onUpdate) {
        this.onUpdate(this.selectedOptions);
      }
    }

    dblClickRight(option) {
      this.selectedOptions.removeObject(option);

      if (this.onUpdate) {
        this.onUpdate(this.selectedOptions);
      }
    }

    allLeft() {
      this.selectedOptions.clear();

      if (this.onUpdate) {
        this.onUpdate(this.selectedOptions);
      }
    }

    allRight() {
      this.selectedOptions.setObjects(this.allOptions);

      if (this.onUpdate) {
        this.onUpdate(this.selectedOptions);
      }
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "unselectedOptions", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "unselectedOptions"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "minSelectionValidation", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "minSelectionValidation"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "maxSelectionValidation", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "maxSelectionValidation"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "dblClickLeft", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "dblClickLeft"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "dblClickRight", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "dblClickRight"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "allLeft", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "allLeft"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "allRight", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "allRight"), _class2.prototype)), _class2)) || _class);
  _exports.default = PickList;
});