define("clientui/pods/components/sections/page-requests/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3Dedn+DU",
    "block": "{\"symbols\":[\"&attrs\",\"@enquirylist\",\"@onSelectAction\"],\"statements\":[[11,\"div\"],[17,1],[12],[2,\"\\n  \"],[8,\"ember-model-table/models-table-searchresults\",[],[[\"@data\",\"@columns\",\"@selectAction\"],[[32,2],[32,0,[\"columns\"]],[32,3]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "clientui/pods/components/sections/page-requests/template.hbs"
  });

  _exports.default = _default;
});