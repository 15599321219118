define("clientui/pods/components/widgets/card-line-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nHOXinn1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"li\"],[14,0,\"list-group-item p-2\"],[12],[2,\"\\n  \"],[10,\"small\"],[14,0,\"text-muted\"],[12],[2,\"\\n    \"],[1,[34,2]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"h6\"],[14,0,\"my-0\"],[12],[2,\"\\n\"],[6,[37,1],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,1],[[35,0],[35,0],\"-\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"value\",\"if\",\"caption\"]}",
    "moduleName": "clientui/pods/components/widgets/card-line-item/template.hbs"
  });

  _exports.default = _default;
});