define("clientui/pods/customeraccounts/statements/statement/downloadstatement/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IyTAqPZd",
    "block": "{\"symbols\":[\"closeDialog\"],\"statements\":[[8,\"widgets/dialog-box-route\",[],[[\"@title\",\"@historyBack\"],[\"Download Statement\",false]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[8,\"dialogs/form-download-statement\",[],[[\"@accountId\"],[[32,0,[\"accountId\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "clientui/pods/customeraccounts/statements/statement/downloadstatement/template.hbs"
  });

  _exports.default = _default;
});