define("clientui/pods/components/ember-model-table/cell-boolean/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HHe6V3cH",
    "block": "{\"symbols\":[],\"statements\":[[8,\"widgets/icon-for\",[],[[\"@target\",\"@tooltip\"],[[32,0,[\"value\"]],[32,0,[\"tooltip\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "clientui/pods/components/ember-model-table/cell-boolean/template.hbs"
  });

  _exports.default = _default;
});