define("clientui/serializers/bookingpax", ["exports", "@babel/runtime/helpers/esm/defineProperty", "clientui/serializers/pageablelist"], function (_exports, _defineProperty2, _pageablelist) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class serializer extends _pageablelist.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "attrs", {
        client: {
          serialize: 'id',
          deserialize: 'records'
        }
      });
    }

  }

  _exports.default = serializer;

  window.__CLASSIC_OWN_CLASSES__.set(serializer, true);
});