define("clientui/pods/components/inputs/text-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Yowp+iRp",
    "block": "{\"symbols\":[\"@disabled\",\"@maxlength\",\"&attrs\",\"@cols\",\"@rows\"],\"statements\":[[6,[37,0],[[32,0,[\"isTextArea\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"textarea\",[[16,0,[30,[36,1],[\"form-control \",[30,[36,0],[[32,0,[\"claimFocus\"]],\" claimfocus \",\"\"],null]],null]],[16,\"placeholder\",[32,0,[\"placeholder\"]]],[16,\"maxlength\",[32,2]],[17,3],[4,[38,2],[\"keydown\",[32,0,[\"onKeyDown\"]]],null],[4,[38,2],[\"keypress\",[32,0,[\"onKeyPress\"]]],null],[4,[38,2],[\"paste\",[32,0,[\"onPaste\"]]],null],[4,[38,2],[\"focus\",[32,0,[\"onFocusIn\"]]],null],[4,[38,2],[\"blur\",[32,0,[\"onFocusOut\"]]],null],[4,[38,2],[\"change\",[32,0,[\"onChange\"]]],null],[4,[38,3],[[32,0,[\"didInsert\"]]],null]],[[\"@id\",\"@value\",\"@readonly\",\"@cols\",\"@rows\"],[[32,0,[\"inputId\"]],[32,0,[\"formattedValue\"]],[32,1],[32,4],[32,5]]],null],[2,\"\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"input\",[[16,0,[30,[36,1],[\"form-control \",[30,[36,0],[[32,0,[\"claimFocus\"]],\" claimfocus \",\"\"],null]],null]],[16,\"disabled\",[32,1]],[16,\"maxlength\",[32,2]],[17,3],[4,[38,2],[\"keydown\",[32,0,[\"onKeyDown\"]]],null],[4,[38,2],[\"keypress\",[32,0,[\"onKeyPress\"]]],null],[4,[38,2],[\"paste\",[32,0,[\"onPaste\"]]],null],[4,[38,2],[\"focus\",[32,0,[\"onFocusIn\"]]],null],[4,[38,2],[\"blur\",[32,0,[\"onFocusOut\"]]],null],[4,[38,2],[\"change\",[32,0,[\"onChange\"]]],null],[4,[38,3],[[32,0,[\"didInsert\"]]],null]],[[\"@id\",\"@placeholder\",\"@type\",\"@value\"],[[32,0,[\"inputId\"]],[32,0,[\"placeholder\"]],[32,0,[\"type\"]],[32,0,[\"formattedValue\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\",\"on\",\"did-insert\"]}",
    "moduleName": "clientui/pods/components/inputs/text-input/template.hbs"
  });

  _exports.default = _default;
});