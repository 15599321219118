define("clientui/pods/components/sections/page-coordinator-searchlist/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  let PageCoordinatorSearchList = (_dec = Ember.inject.service('emtsp-helper'), _dec2 = Ember._action, (_class = class PageCoordinatorSearchList extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "emthelper", _descriptor, this);
      (0, _defineProperty2.default)(this, "actions", [this.emthelper.buildAction("view", "view", "View")]);
      (0, _defineProperty2.default)(this, "columns", [this.emthelper.buildStringCol("displayName", "Name"), this.emthelper.buildBooleanCol("obtPermitted", "Booking Tool"), this.emthelper.buildStringCol("obtRole", "Role"), this.emthelper.buildStringCol("scopeLabel", "Scope"), this.emthelper.buildStringCol("dependents", "Dependents", null, "text-center", null, {
        tooltipPropertyName: "dependentsTooltip"
      }), this.emthelper.buildActionCol("Actions", this.actions)]);
    }

    // groupedHeaders =
    //   [ //array of rows
    //     [ //array of titles
    //       {
    //         title: "User Role Search Results",
    //         colspan: this.columns.length
    //       }
    //     ]
    //   ];
    selectAction(itemId, actionName
    /* ,item */
    ) {
      if (itemId != null) {
        switch (actionName) {
          default:
            this.args.onEdit(itemId);
        }
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "emthelper", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectAction", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "selectAction"), _class.prototype)), _class));
  _exports.default = PageCoordinatorSearchList;
});