define("clientui/pods/coordinator/edit/route", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "clientui/superclasses/authenticated-route"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  let CoordinatorEditRoute = (_dec = Ember._action, _dec2 = Ember._action, (_class = class CoordinatorEditRoute extends _authenticatedRoute.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "Validations", []);
    }

    model(params) {
      let promises = {};
      let clientPortal = this.modelFor('application');
      promises['application'] = clientPortal;
      let session = this.session;

      if (session.get('authenticated') === true) {
        let guid = session.get('subject'); //Use query instead of find so that we can pass through the tcpid and so verify that the client is logging in to the correct site.
        //The server will return a redirect error if the two parameters do not match up.

        promises['client'] = this.store.query('client', {
          'guid': guid,
          'tcpid': clientPortal.get('tradeClientProfileId'),
          'pno': -1
        }).then(clients => {
          return clients.get('firstObject');
        }, errors => {
          console.log(errors);
          throw errors;
        });
      } //load lists for edit form


      promises['scopes'] = this.system.enum('clientprofilescope');
      promises['clientroletypes'] = this.system.enum('clientroletype');
      promises['clientrole'] = this.store.findRecord('clientrole', params['clientrole_id']);
      return Ember.RSVP.hash(promises);
    }

    setupController(controller, promises) {
      let client = promises['client'];

      if (client) {
        controller.set('client', client);
      }

      let clientportal = promises['application']; // console.log(clientportal);

      controller.set('clientportal', clientportal);
      controller.set('scopes', this.annoteScopes(promises['scopes'], clientportal, promises['clientrole']));
      controller.set('clientroletypes', promises['clientroletypes']);
      controller.set('clientrole', promises['clientrole']);
      controller.set('validations', this.Validations);
    }

    annoteScopes(scopes, clientportal, clientrole) {
      scopes.forEach(scope => {
        switch (scope.id) {
          case 'BAR':
            scope.description = 'BAR: All of "' + clientportal.portalDisplayName + '"';
            break;

          case 'TC':
            scope.description = 'TC: All of ' + clientportal.calcTradeClientCaption + ' "' + clientrole.ownerName + '"';
            break;

          case 'ACC':
            scope.description = 'ACC: All of ' + clientportal.calcAccountCaption + ' "' + clientrole.accountName + '"';
            break;

          default:
            scope.description = 'Unknown scope';
        }
      }); //console.log("Scopes", scopes);

      return scopes;
    }

    save(changeset) {
      return changeset.save().then(() => {
        this.replaceWith('coordinator');
      }, errors => {
        console.log('Edit clientroles.save returned FAILURE');
        console.log(errors);
        throw errors;
      });
    }

    back() {
      console.log('Rollback Clicked');
      this.replaceWith('coordinator');
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "save", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "back", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "back"), _class.prototype)), _class));
  _exports.default = CoordinatorEditRoute;
});