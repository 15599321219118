define("clientui/pods/components/pages/page-customeraccounts/page-customeraccounts-receipt/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let PageCustomaccountsReceipt = (_dec = Ember.inject.service('emt-helper'), (_class = class PageCustomaccountsReceipt extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _initializerDefineProperty2.default)(this, "emthelper", _descriptor, this);
    }

    get allocationColumns() {
      return [this.emthelper.buildStringCol("bookingId", "Booking", null, null, null, {
        "routeName": "customeraccounts.bookings.booking",
        'routeProperty': 'bookingId'
      }), this.emthelper.buildStringCol("clientInvoiceIdentifier", "Client Invoice", null, null, null, {
        "routeName": "customeraccounts.clientinvoices.clientinvoice",
        'routeProperty': 'clientInvoiceId'
      }), this.emthelper.buildDateCol("transDate", "Allocation Date"), this.emthelper.buildTotalledMoneyIncZeroCol("fcAmount", this.args.receipt.get('currency.id') + " Amount")];
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "emthelper", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PageCustomaccountsReceipt;
});