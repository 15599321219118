define("clientui/pods/customeraccounts/clientinvoices/clientinvoice/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WQqjIQxt",
    "block": "{\"symbols\":[],\"statements\":[[8,\"pages/page-customeraccounts/page-clientinvoice\",[],[[\"@clientInvoice\"],[[32,0,[\"clientInvoice\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "clientui/pods/customeraccounts/clientinvoices/clientinvoice/template.hbs"
  });

  _exports.default = _default;
});