define("clientui/serializers/enquirycontainer", ["exports", "clientui/serializers/pageablelist"], function (_exports, _pageablelist) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pageablelist.default.extend({
    attrs: {
      account: {
        serialize: 'id',
        deserialize: 'records'
      },
      //travelArranger:         { serialize: 'id', deserialize: 'records' },
      quoteClients: {
        embedded: 'always'
      },
      team: {
        serialize: 'id',
        deserialize: 'records'
      },
      //createdBy:              { serialize: 'id', deserialize: 'records' },
      //tradeClientProfileId:   { serialize: 'id', deserialize: 'records' },
      quoteVersions: {
        embedded: 'always'
      },
      travelRequests: {
        embedded: 'always'
      }
    }
  });

  _exports.default = _default;
});