define("clientui/models/ticket", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  let Ticket = (_dec = (0, _model.attr)('number'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('date'), _dec4 = (0, _model.attr)('date'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('number'), _dec7 = (0, _model.attr)('string'), (_class = class Ticket extends _model.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "tktno", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "paxName", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "dateOfIssue", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "dateofTravel", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "modifiedBy", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "ticketIssueDetailsId", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "pnrRef", _descriptor7, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tktno", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "paxName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dateOfIssue", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dateofTravel", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "modifiedBy", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ticketIssueDetailsId", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "pnrRef", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Ticket;
});