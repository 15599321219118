define("clientui/pods/components/dialogs/form-coordinator-edit-roles/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  let FormCoordinatorEditRoles = (_dec = Ember.inject.service('system'), _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class FormCoordinatorEditRoles extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "servererrors", _descriptor2, this);
    }

    //Only allow scopes to be changed if the logged in Travel Coordinator has a same or wider scope than the Client being edited
    //If the TraveCoordinator has the lowest scope (ACC - level 1) then there is nowhere else to go so editing is forbidden there too
    get mayEditScope() {
      let clientScopeLevel = this.args.client.get('clientProfile.scopeLevel');
      let thisScopeLevel = this.args.clientrole.get('scopeLevel');
      return +clientScopeLevel >= +thisScopeLevel && +clientScopeLevel > 1;
    }

    save() {
      this.system.set('saving', true);
      let clientrole = this.args.clientrole;
      clientrole.validate().then(() => {
        if (clientrole.get("isValid")) {
          // let fieldName = null;
          // let selectionId = 0;
          // let model = null;
          let promises = {};
          Ember.RSVP.hash(promises).then(item => {
            Object.keys(item).forEach(key => {
              clientrole.set(key, item[key]);
            });
            this.args.onSave(clientrole).then(() => {
              // Promise
              console.log('clientRole Promise Saved Successfully in Component');
              this.args.onClose(); //this.onRefreshRoute();

              this.system.set('saving', false);
            }, errors => {
              console.log('clientRole Promise Failed to Save in Component');
              console.log(errors);
              this.servererrors = errors;
              this.system.set('saving', false);
            });
          }, errors => {
            console.log('clientRole Promise Failed to Save in Component');
            console.log(errors);
            this.servererrors = errors;
            this.system.set('saving', false);
          });
        } else {
          this.system.set('saving', false);
        }
      }, () => {
        this.system.set('saving', false);
      });
    }

    back() {
      //Rollback any changes
      this.args.clientrole.rollback();

      this.args.clientrole._content._internalModel._record.rollbackAttributes(); //Close dialog


      this.args.onClose();
      this.args.onRollback();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "servererrors", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "save", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "back", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "back"), _class.prototype)), _class));
  _exports.default = FormCoordinatorEditRoles;
});