define("clientui/models/airport", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let Airport = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = Ember.computed('id', 'name'), _dec6 = Ember.computed('id', 'name', 'cityCode', 'cityName'), classic(_class = (_class2 = class Airport extends _model.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "name", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "cityCode", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "cityName", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "countryName", _descriptor4, this);
    }

    get selectDisplayText() {
      let result = `${this.id} - ${this.name}`;

      if (this.cityName !== this.name) {
        result += ', ' + this.cityName + ', ' + this.countryName;
      }

      return result;
    }

    get searchText() {
      return `${this.id}/${this.name}/${this.cityCode}/${this.cityName}`;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "cityCode", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "cityName", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "countryName", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "selectDisplayText", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "selectDisplayText"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "searchText", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "searchText"), _class2.prototype)), _class2)) || _class);
  _exports.default = Airport;
});