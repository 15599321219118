define("clientui/pods/components/layout/page-footer/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let PageFooter = (_dec = Ember.inject.service('system'), _dec2 = Ember.computed, classic(_class = (_class2 = class PageFooter extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
    }

    get isTopWindow() {
      return window.top === window.self;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isTopWindow", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "isTopWindow"), _class2.prototype)), _class2)) || _class);
  _exports.default = PageFooter;
});