define("clientui/pods/components/widgets/delete-form-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aXbb54YK",
    "block": "{\"symbols\":[\"@propertyName\",\"@label\",\"@helpkey\",\"@manualHelpText\",\"&attrs\",\"@value\"],\"statements\":[[11,\"div\"],[24,0,\"form-row has-error\"],[17,5],[12],[2,\"\\n\"],[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"col-4\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-form-label\"],[12],[2,\"\\n        \"],[10,\"label\"],[15,\"for\",[32,1]],[14,0,\"text-danger\"],[12],[2,\"\\n          \"],[1,[32,2]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[8,\"widgets/help-icon\",[],[[\"@title\",\"@helpkey\",\"@manualHelpText\"],[[32,2],[32,3],[32,4]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[15,0,[30,[36,0],[[32,2],\"col-8\",\"col-12\"],null]],[12],[2,\"\\n    \"],[8,\"input\",[[24,0,\"form-control is-invalid\"]],[[\"@id\",\"@disabled\",\"@type\",\"@value\"],[[32,1],true,\"text\",[32,6]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "clientui/pods/components/widgets/delete-form-input/template.hbs"
  });

  _exports.default = _default;
});