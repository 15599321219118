define("clientui/validations/travelrequest", ["exports", "ember-changeset-validations/validators", "clientui/validators/presence-if-required"], function (_exports, _validators, _presenceIfRequired) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    requestedApproverSelection: [(0, _presenceIfRequired.default)('validateApproverSelection')],
    visaRulesAccepted: [(0, _presenceIfRequired.default)('validateVisaRules')],
    enquiryType: [(0, _validators.validatePresence)(true)],
    concernFlightCodeSelection: [(0, _validators.validatePresence)(true)],
    concernCountryCodeSelection: [(0, _validators.validatePresence)(true)]
  };
  _exports.default = _default;
});