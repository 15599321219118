define("clientui/pods/travelalerts/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "clientui/superclasses/unauthenticated-route", "fetch"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _unauthenticatedRoute, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  //Note that this is NOT an authenticated route
  let TravelalertsRoute = (_dec = Ember.inject.service('system'), _dec2 = Ember.inject.service('keycloak-session'), (_class = class TravelalertsRoute extends _unauthenticatedRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor2, this);
      (0, _defineProperty2.default)(this, "atomURL", 'https://www.gov.uk/foreign-travel-advice.atom');
      (0, _defineProperty2.default)(this, "userURL", 'https://www.gov.uk/foreign-travel-advice');
    }

    model() {
      let promises = {};
      let clientPortal = this.modelFor('application');
      promises['application'] = clientPortal;
      let session = this.session;

      if (session.get('authenticated') === true) {
        let guid = session.get('subject'); //Use query instead of find so that we can pass through the tcpid and so verify that the client is logging in to the correct site.
        //The server will return a redirect error if the two parameters do not match up.

        promises['client'] = this.store.query('client', {
          'guid': guid,
          'tcpid': clientPortal.get('tradeClientProfileId'),
          'pno': -1
        }).then(clients => {
          return clients.get('firstObject');
        }, errors => {
          console.log(errors); //throw errors; do not throw an error as client does not have to be logged in to use this page
        });
      }

      promises['data'] = (0, _fetch.default)(this.atomURL).then(response => response.text()).then(body => {
        // console.log(body);
        let parser = new DOMParser();
        let obj = parser.parseFromString(body, "text/xml"); // console.log(obj);

        return obj;
      });
      return Ember.RSVP.hash(promises);
    }

    setupController(controller, promises) {
      let client = promises['client']; //Note client may be null

      let clientportal = promises['application'];
      let data = promises['data'];
      controller.set('clientportal', clientportal);
      controller.set('client', client);
      controller.set('data', data);
      controller.set('atomURL', this.atomURL);
      controller.set('userURL', this.userURL);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TravelalertsRoute;
});