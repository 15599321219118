define("clientui/pods/railsso/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WttUVqPF",
    "block": "{\"symbols\":[\"closeDialog\"],\"statements\":[[6,[37,1],[[32,0,[\"showDialog\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"widgets/dialog-box-route\",[],[[\"@title\",\"@historyBack\"],[\"Open Evolvi Rail Booking Engine\",false]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"dialogs/evolvi-sso\",[],[[\"@clientProfile\",\"@clientportal\",\"@ssoUrl\",\"@onClose\",\"@onFinish\"],[[32,0,[\"client\",\"clientProfile\"]],[32,0,[\"clientportal\"]],[32,0,[\"ssoUrl\"]],[32,1],[30,[36,0],[\"finish\"],null]]],null],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[1,[30,[36,3],[[30,[36,2],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"route-action\",\"if\",\"-outlet\",\"component\"]}",
    "moduleName": "clientui/pods/railsso/template.hbs"
  });

  _exports.default = _default;
});