define("clientui/pods/components/widgets/list-item-blankrow/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VXnBiwkv",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"row col-12\"],[17,1],[12],[2,\"\\n   \\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "clientui/pods/components/widgets/list-item-blankrow/template.hbs"
  });

  _exports.default = _default;
});