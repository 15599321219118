define("clientui/pods/newprofile/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "clientui/superclasses/this-route-new", "clientui/mixins/parent-client-mixin", "clientui/mixins/child-newclient-mixin"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _thisRouteNew, _parentClientMixin, _childNewclientMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let NewprofileRoute = (_dec = Ember.inject.service('keycloak-session'), _dec2 = Ember.inject.service('system'), _dec3 = Ember._action, classic(_class = (_class2 = class NewprofileRoute extends _thisRouteNew.default.extend(_parentClientMixin.default, _childNewclientMixin.default) {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
      (0, _defineProperty2.default)(this, "routeLeaf", 'new');
      (0, _defineProperty2.default)(this, "dialogTitle", 'New Traveller Profile');
      (0, _defineProperty2.default)(this, "enableDebugLogging", true);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
    }

    //TODO Replace this by extending AuthenticatedRoute
    beforeModel(transition) {
      super.beforeModel(...arguments);
      return this.session.checkTransition(transition);
    }

    model() {
      //Look for existing profile for this keycloak login
      this.set('guid', this.get('session.subject'));
      this.set('email', this.get('session.tokenParsed.email'));
      this.set('firstName', this.get('session.tokenParsed.given_name'));
      this.set('surname', this.get('session.tokenParsed.family_name'));
      this.set('clientportal', this.get('system.clientportal'));
      return this.store.query('client', {
        'tcpid': this.get('clientportal.tradeClientProfileId'),
        'guid': this.guid,
        'email': this.email,
        'kcfirstname': this.firstName,
        'kcsurname': this.surname,
        'testmultierror': 'false',
        'pno': -1
      }).then(clients => {
        return clients.get('firstObject');
      }, errors => {
        console.log(errors);
        throw errors;
      });
    }

    setupController(controller, serverclient) {
      if (serverclient.get('dbId') > 0) {
        //This is a previously persisted client profile so jump to myprofile page
        console.log("transitioning to myprofile from newprofile in setupController as dbId is " + serverclient.get('dbId'));
        this.transitionTo('myprofile');
      } else {
        let clientportal = this.system.clientportal; //capture fields which may have been populated via SSO

        let position = serverclient.position;
        let customValues = new Array(serverclient.clientProfile.get('customValue1'), serverclient.clientProfile.get('customValue2'), serverclient.clientProfile.get('customValue3'), serverclient.clientProfile.get('customValue4'), serverclient.clientProfile.get('customValue5'), serverclient.clientProfile.get('customValue6'), serverclient.clientProfile.get('customValue7'));
        let tradeClient = serverclient.owner; //Start fresh - remove serverclient from store

        if (serverclient.get('clientProfile')) {
          //Also remove serverclients clientProfile if it exists.
          //We can't remove it directly as it is a proxy, not a model, so we get the corresponding model as cp2 and unload that
          let clientProfile = serverclient.get('clientProfile');
          let cp2 = this.store.peekRecord('clientprofile', clientProfile.get('id'));
          cp2.unloadRecord();
          serverclient.set('clientProfile', undefined);
          clientProfile = undefined;
        }

        serverclient.unloadRecord();
        serverclient = undefined; //Call superclass

        super.setupController(...arguments); //Now the controller is populated with a new empty Client object by the superclass

        let client = controller.model;
        let clientProfile = this.store.createRecord('clientprofile');
        client.set('clientProfile', clientProfile);
        client.set('x-email1', this.email);
        client.set('firstName', this.firstName);
        client.set('surname', this.surname); //Apply SSO fields to new Profile

        client.position = position;
        client.owner = tradeClient;

        for (let i = 1; i <= 7; i++) {
          client.clientProfile.set('customValue' + i, customValues[i - 1]);
        }

        controller.set('clientportal', clientportal);
      }
    }

    donothing() {}

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "donothing", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "donothing"), _class2.prototype)), _class2)) || _class);
  _exports.default = NewprofileRoute;
});