define("clientui/superclasses/unauthenticated-route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  let UnauthenticatedRoute = (_dec = Ember.inject.service('keycloak-session'), _dec2 = Ember.inject.service('system'), (_class = class UnauthenticatedRoute extends Ember.Route {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
    }

    beforeModel() {
      super.beforeModel(...arguments);
      let session = this.session;

      if (session.ready === true && session.authenticated === true) {
        session.updateToken().then(null, //success - nothing to do
        function () {
          //failure - absorb error
          console.log("user is not authenticated");
        }); //ignore response
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = UnauthenticatedRoute;
});