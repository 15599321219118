define("clientui/pods/components/sections/page-myprofilenew-personal/component", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "moment"], function (_exports, _applyDecoratedDescriptor2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let PageMyprofilenewPersonal = (_dec = Ember.computed('client.dateOfBirth'), _dec2 = Ember.computed('client.dateOfBirth'), _dec3 = Ember.computed('client.clientProfile.nationality.name', 'clientportal.obtUrl'), classic(_class = (_class2 = class PageMyprofilenewPersonal extends Ember.Component {
    get hostName() {
      console.log(window.location.hostname);
      return window.location.hostname === 'demowtcportal.selective-travel.com';
    }

    get formattedDateOfBirth() {
      let result = '';

      if (this.get('client.dateOfBirth')) {
        if (_moment.default.isMoment(this.get('client.dateOfBirth'))) {
          result = this.get('client.dateOfBirth').format(this.dateFormat);
        } else {
          result = (0, _moment.default)(this.get('client.dateOfBirth')).format(this.dateFormat);
        }
      }

      return result;
    }

    get dateOfBirthWarning() {
      let result = "";

      if (!this.get('client.dateOfBirth')) {
        result = Ember.String.htmlSafe("MISSING<br/>Required For<br/>International Travel");
      }

      return result;
    }

    get nationalityWarning() {
      let result = "";

      if (this.get('clientportal.obtUrl')) {
        if (!this.get('client.clientProfile.nationality.name')) {
          result = Ember.String.htmlSafe("Required For<br/>Online Booking");
        }
      }

      return result;
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "formattedDateOfBirth", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "formattedDateOfBirth"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "dateOfBirthWarning", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "dateOfBirthWarning"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "nationalityWarning", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "nationalityWarning"), _class2.prototype)), _class2)) || _class);
  _exports.default = PageMyprofilenewPersonal;
});