define("clientui/mappings/icons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    default: {
      mode: '',
      icon: 'ban'
    },
    true: {
      mode: '',
      icon: 'check'
    },
    false: {
      mode: '',
      icon: 'times'
    },
    'open-menu': {
      mode: '',
      icon: 'angle-right'
    },
    'close-menu': {
      mode: '',
      icon: 'angle-left'
    },
    'close': {
      mode: '',
      icon: 'close'
    },
    'view': {
      mode: '',
      icon: 'eye'
    },
    'download': {
      mode: '',
      icon: 'save'
    },
    'delete': {
      mode: '',
      icon: 'trash'
    },
    'archive': {
      mode: '',
      icon: 'archive'
    },
    'unarchive': {
      mode: '',
      icon: 'minus-square'
    },
    expand: {
      mode: '',
      icon: 'chevron-down'
    },
    condense: {
      mode: '',
      icon: 'chevron-up'
    },
    help: {
      mode: '',
      icon: 'question-circle'
    } // 'true': {
    //   mode: '',
    //   icon: 'check'
    // },
    // 'false': {
    //   mode: '',
    //   icon: 'times'
    // }

  };
  _exports.default = _default;
});