define("clientui/pods/components/ember-model-table/cell-actions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P1mkpWgL",
    "block": "{\"symbols\":[\"cellaction\",\"@record\",\"@column\"],\"statements\":[[11,\"span\"],[16,0,[31,[\"shadowfade m-0 nowrap \",[32,0,[\"show\"]]]]],[4,[38,0],[\"mouseenter\",[32,0,[\"handleMouseEnter\"]]],null],[4,[38,0],[\"mouseleave\",[32,0,[\"handleMouseLeave\"]]],null],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,3,[\"columnActions\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"ember-model-table/cell-actions-item\",[],[[\"@record\",\"@cellaction\"],[[32,2],[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"on\",\"-track-array\",\"each\"]}",
    "moduleName": "clientui/pods/components/ember-model-table/cell-actions/template.hbs"
  });

  _exports.default = _default;
});