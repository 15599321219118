define("clientui/pods/components/widgets/pop-over/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "jquery"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  let PopoverComponent = (_dec = Ember.inject.service('system'), _dec2 = Ember._action, (_class = class PopoverComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
    }

    setupPopover(element) {
      // eslint-disable-next-line ember/no-jquery
      let jQueryElement = (0, _jquery.default)(element);

      if (jQueryElement) {
        let parent = jQueryElement.parent();

        if (parent) {
          parent.attr('title', this.args.title);
          let text = '';

          if (typeof this.args.html === 'function') {
            //functions always take clientportal as the param
            text = this.args.html(this.system.clientportal);
          } else {
            text = this.args.html;
          }

          parent.attr('data-content', text);

          if (parent.attr('data-toggle') === 'popover') {
            parent.popover('dispose');
          }

          parent.attr('data-toggle', 'popover');
          parent.popover({
            trigger: 'hover',
            html: true,
            animation: true
          });
        }
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setupPopover", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "setupPopover"), _class.prototype)), _class));
  _exports.default = PopoverComponent;
});