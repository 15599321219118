define("clientui/pods/customeraccounts/bookings/booking/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OqNF/Lui",
    "block": "{\"symbols\":[],\"statements\":[[8,\"pages/page-customeraccounts/page-customeraccounts-booking\",[],[[\"@booking\",\"@clientInvoices\",\"@downloadticket\"],[[32,0,[\"booking\"]],[32,0,[\"clientInvoices\"]],[30,[36,0],[\"downloadticket\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"route-action\"]}",
    "moduleName": "clientui/pods/customeraccounts/bookings/booking/template.hbs"
  });

  _exports.default = _default;
});