define("clientui/pods/application-error/route", ["exports", "clientui/superclasses/unauthenticated-route"], function (_exports, _unauthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //Note that this is NOT an authenticated route
  class ApplicationErrorRoute extends _unauthenticatedRoute.default {
    setupController(controller, error) {
      console.log(error.message);
      super.setupController(...arguments);
      controller.set('appErrors', error);
      controller.set('appErrString', JSON.stringify(error, undefined, 2));
      controller.set('clientportal', this.modelFor('application'));
    }

  }

  _exports.default = ApplicationErrorRoute;

  window.__CLASSIC_OWN_CLASSES__.set(ApplicationErrorRoute, true);
});