define("clientui/router", ["exports", "@babel/runtime/helpers/esm/defineProperty", "clientui/config/environment"], function (_exports, _defineProperty2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Router extends Ember.Router {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "location", _environment.default.locationType);
      (0, _defineProperty2.default)(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;

  window.__CLASSIC_OWN_CLASSES__.set(Router, true);

  Router.map(function () {
    this.route('myprofile', function () {
      this.route('editpersonal');
      this.route('editprofessional');
      this.route('editsubscriptions');
      this.route('editpreferences');
      this.route('editcontact');
      this.route('editdocuments');
      this.route('editpermissions');
      this.route('gdpr');
      this.route('editobtfields');
      this.route('editprofilenotes');
      this.route('editrequestfields');
      this.route('editevolvissofields');
      this.route('delete');
      this.route('undelete');
    });
    this.route('myprofile-admin');
    this.route('application-error');
    this.route('newprofile');
    this.route('managedprofiles', function () {
      this.route('profile', {
        path: 'profile/:guid'
      }, function () {
        this.route('editpersonal');
        this.route('editcontact');
        this.route('editprofessional');
        this.route('editdocuments');
        this.route('editsubscriptions');
        this.route('editpreferences');
        this.route('editpermissions');
        this.route('editobtfields');
        this.route('editprofilenotes');
        this.route('editevolvissofields');
        this.route('delete');
        this.route('undelete');
      });
      this.route('newprofile');
    });
    this.route('page', {
      path: 'page/:link_id'
    });
    this.route('logout');
    this.route('contact', {
      path: 'contact/:accmanid'
    });
    this.route('travelalerts');
    this.route('blogs', function () {
      this.route('blog', {
        path: 'blog/:blogid'
      });
    });
    this.route('subscribe', {
      path: 'subscribe/:listid'
    });
    this.route('technology', function () {
      this.route('demo-mis');
      this.route('demo-tracker');
    });
    this.route('login');
    this.route('railsso');
    this.route('obt');
    this.route('impersonate');
    this.route('feedback', function () {
      this.route('idea');
    });
    this.route('travelrequests', function () {
      this.route('travelrequest', {
        path: 'travelrequest/:travelrequest_id'
      });
      this.route('requestnew');
      this.route('requestchange');
      this.route('manage');
    });
    this.route('cookieconsent');
    this.route('trip', {
      path: 'trip/*key'
    });
    this.route('mis');
    this.route('coordinator', function () {
      this.route('edit', {
        path: 'edit/:clientrole_id'
      });
    });
    this.route('obtmenu');
    this.route('faqitems');
    this.route('customeraccounts', function () {
      this.route('receipts', function () {
        this.route('receipt', {
          path: 'receipt/:receipt_id'
        });
        this.route('search');
      });
      this.route('bookings', function () {
        this.route('booking', {
          path: 'booking/:booking_id'
        });
        this.route('search');
      });
      this.route('clientinvoices', function () {
        this.route('clientinvoice', {
          path: 'clientinvoice/:clientinvoice_id'
        });
        this.route('search');
      });
      this.route('statements', function () {
        this.route('statement', {
          path: 'statement/:account_id'
        }, function () {
          this.route('downloadstatement');
        });
        this.route('search');
      });
    });
    this.route('downloadabledocuments', function () {
      this.route('search');
      this.route('downloadabledocument', {
        path: '/downloadabledocument/:downloadabledocument_id'
      }, function () {
        this.route('archive');
        this.route('delete');
      });
    });
    this.route('login2');
    this.route('account', function () {
      this.route('login', {
        path: 'login/*key2'
      });
    });
  });
});