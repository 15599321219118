define("clientui/mappings/helptext", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
  Map Help keys to text (which may be HTML or a function)
  */
  var _default = {
    nameisasperpassport: "Tick here to confirm that your name above matches your (primary) passport",
    phone: "Enter phone number in three parts:<br/><ul><li>International Country Code</li><li>Area code or prefix (without the leading zero)</li><li>Local number</li></ul>",
    mobile: "Enter mobile phone number in two parts:<br/><ul><li>International Country Code</li><li>Remainder of number, without any leading zero</li></ul>",
    position: "Where your organisation has different travel policies for different staff grades, this information helps us get it right first time",
    corporateTradeClient: function (clientportal) {
      return `Select your ${clientportal.calcTradeClientCaption}.<br/>This and the ${clientportal.calcAccountCaption} below will tell us where to send invoices for your travel.`;
    },
    defaultaccount: function (clientportal) {
      return `Select your ${clientportal.calcAccountCaption}.<br/>This and the ${clientportal.calcTradeClientCaption} above will tell us where to send invoices for your travel.`;
    },
    primaryemail: "This is the email you used to register on this site and so is not changeable.",
    approv: "You are a designated Approver for travel within your organisation.<br/>By default, emails requesting approval will be delivered to your primairy email address.<br/>If you wish them to be delivered to an alternative address, or to multiple addresses, then add them here.<br/>The contents of this page will replace your primary email address for the purposes of Approval only.",
    emergencyContact: "Please identify the person you would like us to contact in the event of an emergency surrounding your travel plans, and we are unable to contact you directly.",
    passport: "Please enter your passport details.<br/>This is required for any international travel.<br/>Your name on this passport should match exactly your name on the first page of this form.<br/>Additional passports may be added later if required.",
    passport2: "Please enter your passport details.<br/>This is required for any international travel.<br/>Your name on each passport should match exactly your name in this profile.<br/>If it does not, for any reason, please complete the 'Alternate Name' section for that passport.",
    airMealPreference: "If you have any special dietary requirements, please select your preferred meal type here.<br/>Leave blank if you have no special requirements.<br/>Note that we are unable to guarantee the availability of a given meal type on any specific flight, but we will pass through your request to the airline.",
    airSeatPreference: "Select whether you prefer to sit by the window or the aisle. <br/>This is subject to availability on your chosen flight.",
    railSeatPreference: "Select your preferred seat style, which direction you wish to face, and any particular part of the train you prefer.<br/>Selections cannot be guaranteed, and are subject to availability.",
    drvlic: "Please enter your Driving Licence details, with exactly the same name as per your licence. This is required for any Car Hire that we may book on your behalf",
    visa: "Please enter any current visas that you hold",
    carregno: "Please enter your current car details in relation to booking car parking when required",
    esta: "You may add any electronic pre-authorisations here e.g. US ESTAs.<br/><br/>ESTA is an automated system that determines the eligibility of visitors to travel to the United States under the Visa Waiver Program (VWP). Authorization via ESTA does not determine whether a traveler is admissible to the United States. U.S. Customs and Border Protection officers determine admissibility upon travelers’ arrival. The ESTA application collects biographic information and answers to VWP eligibility questions. ESTA applications may be submitted at any time prior to travel, though it is recommended that travelers apply as soon as they begin preparing travel plans or prior to purchasing airline tickets.",
    tsaredress: "The Redress Control Number is the record identifier for people who apply for redress through the United States Department of Homeland Security Travel Redress Inquiry Program (DHS TRIP). DHS TRIP is for travelers who have been repeatedly identified for additional screening and who want to file an inquiry to have erroneous information corrected in DHS systems.",
    travelapprovers: "Your organisation has elected to employ an approval process for all travel costs. Please select at least one Approver from the lists below to whom your travel requests will be forwarded.<br/>By selecting an approver, you are consenting that your travel plans may be shared with this individual.",
    travelarrangers: "Your organisation has elected to have some or all travel booked via Travel Arrangers. Travel Arrangers have full access to your Travel Profile and may update it on your behalf. Please select at least one Arranger from the lists below who will be permitted to arrange your travel.<br/>By selecting an arranger, you are consenting that your Traveller Profile and travel plans may be shared with this individual.",
    //gdpr: "I confirm that the information given in this form is accurate.<br/>Click <a href='http://www.worldtravelcentregroup.com/wp-content/uploads/2018/02/WTCG-GDPR-Factsheet.pdf' target='_blank'> here </a> for our GDPR FAQs.<br/>Click <a href='https://www.selective-travel.co.uk/wp-content/uploads/2018/05/WTCG-Data-Protection-Policy.pdf' target='_blank'> here </a> for our GDPR Data Protection Policy.",
    gdpr: "I confirm that the information given in this form is accurate.<br/>If I have given information about health conditions or disabilities, I consent to its use by Selective Travel Management for the purposes of arranging my travel.<br/>I consent to the data supplied by me (or someone nominated by me) being used for the contracted purposes of arranging travel on my behalf.<br/>Click <a href='https://cdn.worldtravelcentregroup.com/docs/WTCG-GDPR-Factsheet.pdf' target='_blank'> here </a> for this site's GDPR FAQs.<br/>Click <a href='https://cdn.worldtravelcentregroup.com/docs/WTCG-Data-Protection-Policy.pdf' target='_blank'> here </a> for this site's GDPR Data Protection Policy.",
    tier: "Travel Policies are applied based on the Tier of the traveller. Select the Tier for this traveller from the list provided",
    companytravelpolicy: "Select Travel Policy to be applied to this traveller.",
    //To Add when true: This policy will be applied to any bookings made through the Booking Tool.
    obtpermitted: "Please tick if this traveller is allowed access to the Online Booking Tool.<br>Only Travel Arrangers are permitted to access or change this field.",
    specialRequests: "If you use the 'Special Requests' box to tell us about requirements due to a health condition or disability, we will process this information only as necessary to make travel arrangements which meet your needs.<br/>This may include sharing some information with third party travel and accommodation providers.<br/>By giving us this information here you consent to its use for this purpose.<br/>You can remove this information from your profile at any time.",
    approvedTravelRequests: "This is for travel requests that have been approved.",
    historicalTravel: "This is for past dated flights.",
    arrangedByArranger: "These are travel requests that were arranged by your Travel Arranger.",
    requiringYourApproval: "This is for travel requests that require your approval.",
    pendingApproval: "Flights that you have requested that have not yet been approved by your approver.",
    personalRequests: "Travel requests that have been made by you.",
    requestedApprover: "Travel requests that you have been selected to be the approver. You will need to either approve or decline these requests.",
    selectPassenger: "In the dropdown on the right hand side you can select passengers who intend to travel. Please select who is travelling from the dropdown list, you can see who is selected to travel below. <br/> If the passenger is a 'Guest Traveller', click that option on the dropdown list, fill in all their details. If you only have one Guest Traveller then click the next button at the bottom of the page. <br/> If you want to add another Guest Traveller, go to the top of the page select Guest Traveller from the dropdown and fill in their details. You will need to do this for every guest is travelling.",
    addPassenger: "Please select who is travelling from the dropdown list below, Once you select a traveller, you will see the passenger is added to travel. <br/> If the passenger is a 'Guest Traveller', click that option on the dropdown list, fill in all their details. If you only have one Guest Traveller then click the next button at the bottom of the page. <br/> If you want to add another Guest Traveller, go to the top of the page select Guest Traveller from the dropdown and fill in their details. You will need to do this for every guest is travelling.",
    selectAirport: "To select an airport, click on the dropdown and begin to type the airport you wish to select. For example, for Ndjamena Airport in Chad, type ndja and the airport will appear on the dropdown. Then click the airport you wish to select.",
    carbon_distance: 'Total distance travelled per pax (out and back if applicable)',
    carbon_traveltime: 'Total time spent travelling per pax (out and back if applicable)',
    clientrole_scope: function (clientportal) {
      return `This limits the visibility of other clients/approvers/arrangers etc. to all of ${clientportal.portalDisplayName}, or only those within the same ${clientportal.calcTradeClientCaption} / ${clientportal.calcAccountCaption} as themselves.`;
    } // specialRequestAir: this.specialRequests,
    // specialRequestRail: this.specialRequests,
    // specialRequestCarHire: this.specialRequests,
    // specialRequestAccommodation: this.specialRequests,

  };
  _exports.default = _default;
});