define("clientui/pods/components/ember-model-table/cell-money-inc-zero/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "diZTDX6G",
    "block": "{\"symbols\":[\"&attrs\",\"@column\",\"@record\"],\"statements\":[[11,\"div\"],[24,0,\"nowrap\"],[17,1],[12],[2,\"\\n  \"],[1,[30,[36,2],[[30,[36,0],[[32,3],[32,2,[\"propertyName\"]]],null]],[[\"currency\"],[[30,[36,1],[[32,2,[\"currency\"]],[32,2,[\"currency\"]],[30,[36,1],[[32,2,[\"currencyProperty\"]],[30,[36,0],[[32,3],[32,2,[\"currencyProperty\"]]],null]],null]],null]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"get\",\"if\",\"boss-money\"]}",
    "moduleName": "clientui/pods/components/ember-model-table/cell-money-inc-zero/template.hbs"
  });

  _exports.default = _default;
});