define("clientui/superclasses/authenticated-route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  let AuthenticatedRoute = (_dec = Ember.inject.service('keycloak-session'), _dec2 = Ember.inject.service('system'), _dec3 = Ember._action, (_class = class AuthenticatedRoute extends Ember.Route {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
    }

    /* This method doesn't work well because it kicks in AFTER the model hook and so the ApplicationAdapter Keycloak code
     * is accessed first.
     * The Adapter does not have access to the target toute (even from the router service) so asks keycloak to redirect the
     * user back to the base URL (home page)
     * Instead we have to check the keycloak status in the beforeModel hook, to ensure that the transition data is available 
     * in order to build the keycloak redirect URL
    */
    // buildRouteInfoMetadata() {
    //   return {
    //     updateKeycloakToken: this.system.DEBUG_DISABLE_KEYCLOAK ? false : true
    //   }
    // }
    beforeModel(transition) {
      super.beforeModel(...arguments);
      return this.session.checkTransition(transition);
    }

    error(errors
    /*, transition */
    ) {
      //console.log(errors);
      let error = null;

      if (errors.errors) {
        if (errors.errors[0]) {
          error = errors.errors[0];
        }
      } // console.log(error.status);
      // console.log(error.title);


      if (error) {
        if (error.status && error.status === '409') {
          if (error.title === 'MultipleMatchingClientProfiles') {
            console.log("transitioning to myprofile-admin");
            this.replaceWith('myprofile-admin');
          } else if (error.title === 'Redirect' && error.detail && !error.detail.includes('\n')) {
            console.log("transitioning to " + error.detail + window.location.pathname);
            window.location.replace('https://' + error.detail + window.location.pathname);
          } else {
            console.log(error); // Let the route above this handle the error.

            return true;
          }
        } else if (error.status && error.status === '401') {
          if (this.session.tokenParsed) {
            let portal = this.session.tokenParsed.portal;

            if (portal) {
              let suffix = window.location.pathname + window.location.hash;
              console.log("transitioning to " + portal + suffix);
              window.location.replace('https://' + portal + suffix);
            } else {
              console.log("session.tokenParsed.portal is not populated for this user.");
              console.log(error); // Let the route above this handle the error.

              return true;
            }
          } else {
            console.log("session.tokenParsed is not populated for this user.");
            console.log(error); // Let the route above this handle the error.

            return true;
          }
        } else {
          console.log(error); // Let the route above this handle the error.

          return true;
        }
      } else {
        console.log(errors); // Let the route above this handle the error.

        return true;
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "error", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "error"), _class.prototype)), _class));
  _exports.default = AuthenticatedRoute;
});