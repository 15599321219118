define("clientui/pods/components/sections/page-requests/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let PageRequests = (_dec = Ember.inject.service('emtsp-helper'), (_class = class PageRequests extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "emthelper", _descriptor, this);
      (0, _defineProperty2.default)(this, "columns", null);
      (0, _defineProperty2.default)(this, "groupedHeaders", null);
      (0, _defineProperty2.default)(this, "actions", [this.emthelper.buildAction("view", "view", "View") // Action, Icon, Tooltip
      ]);
      (0, _defineProperty2.default)(this, "columns", [this.emthelper.buildNumberCol("id", "ID"), this.emthelper.buildDateCol("createdOn", "Created", "createdOn"), this.emthelper.buildStringCol("quoteClients.firstObject.selectDisplayText", "Name"), this.emthelper.buildStringCol("quoteClients.length", "#Pax"), this.emthelper.buildStringCol("travelRequests.firstObject.requestElementFlights.firstObject.destination.name", "Destination"), this.emthelper.buildDateCol("travelRequests.firstObject.requestProcessedOn", "Processed", "processedon"), this.emthelper.buildStringCol("travelRequests.firstObject.approvedDisplayText", "Approved"), this.emthelper.buildActionCol("Actions", this.actions)]);
      (0, _defineProperty2.default)(this, "groupedHeaders", [//array of rows
      [//array of titles
      {
        title: "",
        colspan: this.columns.length
      }]]);
    } // @action
    // selectAction(itemId, action) {
    //   console.log("SelectAction being Clicked");
    //   //console.log("bankstatment-search-list selectAction", itemId, action);
    //   if (typeof this.onSelectAction !== 'undefined') {
    //     this.onSelectAction(itemId, action);
    //   }
    // }


  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "emthelper", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PageRequests;
});