define("clientui/pods/cookieconsent/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8kVZ0V17",
    "block": "{\"symbols\":[],\"statements\":[[8,\"dialogs/manage-cookies\",[],[[\"@isShowingDialog\",\"@consentStatus\",\"@allowall\",\"@savechanges\"],[[34,0],[34,1],[30,[36,2],[\"allowall\"],null],[30,[36,2],[\"savechanges\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"isShowingDialog\",\"consentStatus\",\"route-action\"]}",
    "moduleName": "clientui/pods/cookieconsent/template.hbs"
  });

  _exports.default = _default;
});