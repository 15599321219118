define("clientui/pods/myprofile/editprofessional/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bUoLDUli",
    "block": "{\"symbols\":[\"closeDialog\"],\"statements\":[[8,\"widgets/dialog-box-route\",[],[[\"@title\",\"@historyBack\"],[[34,0],false]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"dialogs/form-profile-edit-professional\",[],[[\"@client\",\"@clientportal\",\"@loggedinclient\",\"@onSave\",\"@onRollback\",\"@onClose\"],[[30,[36,3],[[35,2],[35,1]],null],[34,4],[34,2],[30,[36,5],[\"save\"],null],[30,[36,5],[\"back\"],null],[30,[36,6],[[32,0],[32,1]],null]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"title\",\"validation\",\"model\",\"changeset\",\"clientportal\",\"route-action\",\"action\"]}",
    "moduleName": "clientui/pods/myprofile/editprofessional/template.hbs"
  });

  _exports.default = _default;
});