define("clientui/pods/managedprofiles/index/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let IndexRoute = (_dec = Ember.inject.service('keycloak-session'), _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, classic(_class = (_class2 = class IndexRoute extends Ember.Route {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
    }

    model() {
      let promises = this.modelFor('managedprofiles'); //contains clientportal and client

      promises['managedclientlist'] = this.store.query("shortclient", {
        'pno': -1
      });
      return Ember.RSVP.hash(promises);
    }

    setupController(controller, promises) {
      let client = promises['client'];

      if (client) {
        controller.set('client', client);
      }

      let managedclientlist = promises['managedclientlist'];

      if (managedclientlist) {
        controller.set('managedclientlist', managedclientlist);
      }

      let clientportal = promises['application'];
      controller.set('clientportal', clientportal);
    }

    selectClient(item) {
      console.log("item selected");
      console.log(item);
      this.transitionTo('managedprofiles.profile', item.get('id'));
    }

    addClient() {
      console.log("New Client to be added");
      this.transitionTo('managedprofiles.newprofile');
    }

    myProfile() {
      this.transitionTo('myprofile');
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "selectClient", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "selectClient"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "addClient", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "addClient"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "myProfile", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "myProfile"), _class2.prototype)), _class2)) || _class);
  _exports.default = IndexRoute;
});