define("clientui/superclasses2/this-route-edit", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;

  let ThisRouteEditRoute = (_dec = Ember.inject.service('system'), _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class ThisRouteEditRoute extends Ember.Route {
    // routePlural = '';
    // routeSingular = '';
    // routeLeaf = '';
    // modelType = '';
    // validationName = '';
    // dialogTitle = '';
    // enableDebugLogging = true;
    editPreSaveHook() {}

    editPostSaveHook() {}

    constructor(owner, args) {
      super(owner, args);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
    }

    buildParentRoute(delimiter) {
      return (this.routePlural ? this.routePlural + delimiter : '') + this.routeSingular;
    }

    get parentRoute() {
      return this.buildParentRoute('.');
    }

    replaceRouteWithParent() {
      this.replaceWith(this.parentRoute, this.thisController.model.id);
    }

    model() {
      this.log('Route: ' + this.buildParentRoute('->') + '->' + this.routeLeaf + ' Getting model for ' + this.modelType);
      return this.modelFor(this.parentRoute);
    }

    setupController(controller, model) {
      this.log('Route: ' + this.buildParentRoute('->') + this.routeSingular + '->' + this.routeLeaf);
      this.log("this-route-edit model", model);

      if (model.model) {
        model = model.model; //extract model from hash
      }

      controller.set('model', model);

      if (this.validation) {
        controller.set('validation', this.validation);
      }

      controller.set('title', this.dialogTitle);
      this.system.set('saving', false);
      this.thisController = controller;
    }

    log() {
      if (this.enableDebugLogging) {
        console.log(...arguments);
      }
    }

    save(changeset) {
      this.system.set('saving', true);
      this.editPreSaveHook(changeset);
      return changeset.save().then(response => {
        this.log(this.parentRoute, this.thisController.model.id);
        this.editPostSaveHook(response);
        this.system.set('saving', false);
        this.replaceRouteWithParent();
      }, errors => {
        this.log('Edit ' + this.modelType + '.save returned FAILURE');
        this.log(errors);
        this.system.set('saving', false);
        throw errors;
      });
    }

    delete(changeset) {
      this.system.set('saving', true);
      this.editPreSaveHook(changeset);
      return changeset.save().then(response => {
        this.editPostSaveHook(response);
        this.system.set('saving', false);

        if (this.routePlural) {
          this.log("Replacing URL with " + this.routePlural + '.search');
          this.replaceWith(this.routePlural + '.search');
        } else {
          this.replaceWith('application');
        }
      }, errors => {
        this.log('Edit ' + this.modelType + '.save returned FAILURE');
        this.log(errors);
        this.system.set('saving', false);
        throw errors;
      });
    }

    back() {
      this.replaceRouteWithParent();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "save", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "delete", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "back", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "back"), _class.prototype)), _class));
  _exports.default = ThisRouteEditRoute;
});