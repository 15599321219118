define("clientui/pods/myprofile-admin/route", ["exports", "clientui/superclasses/unauthenticated-route"], function (_exports, _unauthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //Note that this is NOT an authenticated route
  class MyprofileAdminRoute extends _unauthenticatedRoute.default {}

  _exports.default = MyprofileAdminRoute;

  window.__CLASSIC_OWN_CLASSES__.set(MyprofileAdminRoute, true);
});