define("clientui/serializers/clientprofile", ["exports", "clientui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      frequentFlyerNumbers: {
        embedded: 'always'
      },
      specialServiceRequests: {
        embedded: 'always'
      },
      documents: {
        embedded: 'always'
      },
      profileNoteItems: {
        embedded: 'always'
      },
      createdOn: {
        serialize: false
      },
      modifiedOn: {
        serialize: false
      },
      defaultAccount: {
        serialize: 'id',
        deserialize: 'records'
      },
      accountsDefaultAccount: {
        serialize: 'id',
        deserialize: 'records'
      },
      tsaRedressCountry: {
        serialize: 'id',
        deserialize: 'records'
      },
      countryOfBirth: {
        serialize: 'id',
        deserialize: 'records'
      },
      nationality: {
        serialize: 'id',
        deserialize: 'records'
      },
      airPrefDepartureAirport: {
        serialize: 'id',
        deserialize: 'records'
      },
      railPrefDepartureStation: {
        serialize: 'id',
        deserialize: 'records'
      },
      approvers: {
        embedded: 'always'
      },
      arrangers: {
        embedded: 'always'
      },
      travelPolicy: {
        serialize: 'id',
        deserialize: 'records'
      }
    }
  });

  _exports.default = _default;
});