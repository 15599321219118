define("clientui/pods/components/ember-model-table/total-money-inc-zero/component", ["exports", "@ember-decorators/component", "ember-models-table/components/models-table/cell-column-summary"], function (_exports, _component, _cellColumnSummary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let TotalMoneyIncZero = (
  /*
    selectedItems=selectedItems
    expandedItems=expandedItems
    data=data
    mappedSelectedItems=(if column.propertyName (map-by column.propertyName selectedItems))
    mappedExpandedItems=(if column.propertyName (map-by column.propertyName expandedItems))
    mappedData=(if column.propertyName (map-by column.propertyName data))}}
   */
  _dec = (0, _component.classNames)('text-right', 'emt-total-cell'), classic(_class = _dec(_class = class TotalMoneyIncZero extends _cellColumnSummary.default {}) || _class) || _class);
  _exports.default = TotalMoneyIncZero;
});