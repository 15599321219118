define("clientui/pods/components/pages/downloadabledocuments/page-downloadabledocuments-searchlist/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let PageDownloadabledocumentsSearchlist = (_dec = Ember.inject.service('system'), _dec2 = Ember.inject.service('emtsp-helper'), _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, (_class = class PageDownloadabledocumentsSearchlist extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "emthelper", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "fetch", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor4, this);
      (0, _defineProperty2.default)(this, "actions", [this.emthelper.buildAction("download", "download", "Download"), this.emthelper.buildAction("archive", "archive", "Archive", "archived", false), this.emthelper.buildAction("unarchive", "unarchive", "Un-Archive", "archived", true), this.emthelper.buildAction("delete", "delete", "Delete")]);
    }

    get columns() {
      return [this.emthelper.buildStringCol("id", "ID"), this.emthelper.buildStringCol("documentDescription", "Description", null, null, null, {
        tooltipPropertyName: "fileName"
      }), this.emthelper.buildDateCol("createdOn", "Creation Date"), this.emthelper.buildNumberCol("recipients.length", "Recipients"), this.emthelper.buildActionCol("", this.actions)];
    }

    selectAction(itemId, actionName) {
      if (itemId > 0) {
        switch (actionName) {
          case 'download':
            this.fetch.fetchAndDownloadFile("downloadabledocuments/download/" + itemId);
            break;

          case 'archive':
            this.router.transitionTo('downloadabledocuments.downloadabledocument.archive', itemId);
            break;

          case 'unarchive':
            this.router.transitionTo('downloadabledocuments.downloadabledocument.archive', itemId);
            break;

          case 'delete':
            this.router.transitionTo('downloadabledocuments.downloadabledocument.delete', itemId);
            break;

          default:
            break;
        }
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "emthelper", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetch", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectAction", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "selectAction"), _class.prototype)), _class));
  _exports.default = PageDownloadabledocumentsSearchlist;
});