define("clientui/mixins/invalid-client-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
  This mixin is intended to handle any errors occurring on top level pages (myprofile, managedprofiles, page etc.)
  if the logged in client does not match the portal then they should be redirected to the correct portal
  */
  var _default = Ember.Mixin.create({
    actions: {
      error(errors
      /*, transition */
      ) {
        //console.log(errors);
        let error = null;

        if (errors.errors) {
          if (errors.errors[0]) {
            error = errors.errors[0];
          }
        } // console.log(error.status);
        // console.log(error.title);


        if (error) {
          if (error.status && error.status === '409') {
            if (error.title === 'MultipleMatchingClientProfiles') {
              console.log("transitioning to myprofile-admin");
              this.replaceWith('myprofile-admin');
            } else if (error.title === 'Redirect' && error.detail && !error.detail.includes('\n')) {
              console.log("transitioning to " + error.detail + window.location.pathname);
              window.location.replace('https://' + error.detail + window.location.pathname);
            } else {
              console.log(error); // Let the route above this handle the error.

              return true;
            }
          } else if (error.status && error.status === '401') {
            let portal = this.get('session.tokenParsed.portal');

            if (portal) {
              let suffix = window.location.pathname + window.location.hash;
              console.log("transitioning to " + portal + suffix);
              window.location.replace('https://' + portal + suffix);
            } else {
              console.log("session.tokenParsed.portal is not populated for this user.");
              console.log(error); // Let the route above this handle the error.

              return true;
            }
          } else {
            console.log(error); // Let the route above this handle the error.

            return true;
          }
        } else {
          console.log(errors); // Let the route above this handle the error.

          return true;
        }
      }

    }
  });

  _exports.default = _default;
});