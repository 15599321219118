define("clientui/pods/components/widgets/form-submit-button-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1KvTdh61",
    "block": "{\"symbols\":[\"@inverse\",\"&attrs\",\"@onBack\",\"@onSubmit\"],\"statements\":[[11,\"div\"],[24,0,\"\"],[17,2],[12],[2,\"\\n\"],[6,[37,0],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[24,0,\"btn btn-secondary\"],[16,\"disabled\",[32,0,[\"internalbackdisabled\"]]],[24,4,\"button\"],[4,[38,2],[\"click\",[32,0,[\"Back\"]]],null],[12],[2,\"\\n      \"],[1,[32,0,[\"backbuttonlabel\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,0],[[32,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[16,0,[31,[\"btn\\n        \",[30,[36,1],[[32,1],\"btn-primary\",\"btn-info\"],null],\"\\n        ld-ext-right\\n        \",[30,[36,0],[[32,0,[\"system\",\"saving\"]],\"running\"],null],\"\\n        \"]]],[16,\"disabled\",[32,0,[\"internalsavedisabled\"]]],[24,4,\"button\"],[4,[38,2],[\"click\",[32,0,[\"Submit\"]]],null],[12],[2,\"\\n      \"],[1,[32,0,[\"savebuttonlabel\"]]],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ld ld-ring ld-spin-fast\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"unless\",\"on\"]}",
    "moduleName": "clientui/pods/components/widgets/form-submit-button-edit/template.hbs"
  });

  _exports.default = _default;
});