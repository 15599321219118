define("clientui/pods/components/dialogs/form-profile-edit-documents-page/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "clientui/config/environment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _class2, _descriptor, _descriptor2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let FormProfileEditDocumentsPage = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('system'), _dec3 = Ember.computed('client.clientProfile.documents.{[],@each.deleted}'), _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, classic(_class = (_class2 = class FormProfileEditDocumentsPage extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
    }

    init() {
      super.init(...arguments); //console.log('Init Create Profile Wizard');
    }

    didReceiveAttrs() {
      // if (this.get('category') !== 'EMAIL') {
      //   this.set('validCountries', this.get('countries').filter((item) => (isPresent(item.get('dialCode')))));
      // }
      this.set('newDocument', this.store.createRecord('profiledocument'));
      this.newDocument.set('newDocumentType', this.category);
      console.log(this.newDocument);
    }

    uploadPath(doc) {
      let result = null;

      if (this.get('client.id') && doc.get('id')) {
        let host = _environment.default.APP.JBOSS_URL;
        let path = "/v1/clients/" + this.get('client.id') + "/documents/" + doc.get('id');
        result = host + path;
        console.log(result);
      }

      return result;
    }

    get sortedDocuments() {
      return this.get('client.clientProfile.documents').filter(item => item.get('documentType') === this.category && !item.get('deleted')).sortBy('displayDescription', 'country.name', 'expiryDate', 'id');
    }

    commitChanges(document) {
      //console.log(document);
      //console.log(JSON.stringify(document));
      document.set('documentType', document.get('newDocumentType'));
      document.set('description', document.get('newDescription'));
      document.set('referenceNumber', document.get('newReferenceNumber'));
      document.set('country', document.get('newCountry'));
      document.set('issueDate', document.get('newIssueDate'));
      document.set('expiryDate', document.get('newExpiryDate'));
      document.set('altTitle', document.get('newAltTitle'));
      document.set('altFirstName', document.get('newAltFirstName'));
      document.set('altMiddleName', document.get('newAltMiddleName'));
      document.set('altSurname', document.get('newAltSurname'));
      document.set('citizenship', document.get('newCitizenship'));
      document.set('carMake', document.get('newCarMake'));
      document.set('carModel', document.get('newCarModel'));
      document.set('carColour', document.get('newCarColour'));
    }

    validate(item) {
      if (item.get('documentErrors').length === 0) {
        if (Ember.isBlank(item.get('newReferenceNumber'))) {
          item.get('documentErrors').pushObject("Reference Number cannot be blank");
        }

        if (this.category == "PASSPORT" && Ember.isBlank(item.get('country'))) {
          item.get('documentErrors').pushObject("Country cannot be blank");
        }

        if (this.category == "PASSPORT" && Ember.isBlank(item.get('newIssueDate'))) {
          item.get('documentErrors').pushObject("Issue Date cannot be blank");
        }

        if (this.category == "PASSPORT" && Ember.isBlank(item.get('newExpiryDate'))) {
          item.get('documentErrors').pushObject("Expiry Date cannot be blank");
        }
      }

      return item.get('documentErrors').length === 0;
    }

    edit(item) {
      if (!this.pageActive) {
        if (typeof item.get('editing') === 'undefined') {
          console.log("Setting Original Values");
          item.set('originalDocumentType', item.get('documentType'));
          item.set('originalDescription', item.get('description'));
          item.set('originalReferenceNumber', item.get('referenceNumber'));
          item.set('originalCountry', item.get('country'));
          item.set('originalIssueDate', item.get('issueDate'));
          item.set('originalExpiryDate', item.get('expiryDate'));
          item.set('originalAltTitle', item.get('altTitle'));
          item.set('originalAltFirstName', item.get('altFirstName'));
          item.set('originalAltMiddleName', item.get('altMiddleName'));
          item.set('originalAltSurname', item.get('altSurname'));
          item.set('originalCitizenship', item.get('citizenship'));
          item.set('originalCarMake', item.get('carMake'));
          item.set('originalCarModel', item.get('carModel'));
          item.set('originalCarColour', item.get('carColour'));
        }

        item.set('newDocumentType', item.get('documentType'));
        item.set('newDescription', item.get('description'));
        item.set('newReferenceNumber', item.get('referenceNumber'));
        item.set('newCountry', item.get('country'));
        item.set('newIssueDate', item.get('issueDate'));
        item.set('newExpiryDate', item.get('expiryDate'));
        item.set('newAltTitle', item.get('altTitle'));
        item.set('newAltFirstName', item.get('altFirstName'));
        item.set('newAltMiddleName', item.get('altMiddleName'));
        item.set('newAltSurname', item.get('altSurname'));
        item.set('newCitizenship', item.get('citizenship'));
        item.set('newCarMake', item.get('carMake'));
        item.set('newCarModel', item.get('carModel'));
        item.set('newCarColour', item.get('carColour'));
        this.onActivate(true);
        item.set('editing', true);
        item.set('deleting', false);
        this.set('hideAdd', true); //component-global flag
      }
    }

    delete(item) {
      if (!this.pageActive) {
        this.onActivate(true);
        item.set('editing', false);
        item.set('deleting', true);
        this.set('hideAdd', true); //component-global flag
      }
    }

    addNew() {
      if (!this.pageActive) {
        this.set('adding', true);
        this.onActivate(true);
      }
    }

    back(item) {
      this.onActivate(false);
      item.set('editing', false);
      item.set('deleting', false);
      this.set('hideAdd', false); //component-global flag
    }

    backAdd() {
      this.set('adding', false);
      this.$('#addNew').collapse('hide');
      this.onActivate(false);
    }

    add() {
      let newDocument = this.newDocument;

      if (this.validate(newDocument)) {
        this.commitChanges(newDocument);
        this.get('client.clientProfile.documents').pushObject(newDocument);
        this.set('newDocument', this.store.createRecord('profiledocument'));
        this.newDocument.set('newDocumentType', this.category);
        this.set('adding', false);
        this.$('#addNew').collapse('hide');
        this.onActivate(false);
      }
    }

    apply(item) {
      let success = true;

      if (item.get('editing') === true) {
        if (this.validate(item)) {
          this.commitChanges(item);
        } else {
          //console.log("Document validation failed");
          success = false;
        }
      } else if (item.get('deleting') === true) {
        item.set('deleted', true);
      } else {
        console.log("nothing to do");
      }

      if (success) {
        this.onActivate(false);
        item.set('editing', false);
        item.set('deleting', false);
        this.set('hideAdd', false); //component-global flag
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sortedDocuments", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "sortedDocuments"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "edit", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "edit"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "delete", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "delete"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "addNew", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "addNew"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "back", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "back"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "backAdd", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "backAdd"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "add", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "add"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apply", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "apply"), _class2.prototype)), _class2)) || _class);
  _exports.default = FormProfileEditDocumentsPage;
});