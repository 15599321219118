define("clientui/pods/obt/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "clientui/superclasses/authenticated-route"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  let ObtRoute = (_dec = Ember.inject.service('keycloak-session'), _dec2 = Ember.inject.service('system'), (_class = class ObtRoute extends _authenticatedRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
    }

    //windowReference = null;
    model() {
      //Initiate opening of window while on main thread responding to user's click
      //If this is not the case, then Safari will not open the window
      //See https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
      if (this.system.windowReference == null) {
        console.log("System.windowReference is not set - setting it now in /opt model hook - this may not work for Safari");
        this.system.windowReference = window.open("about:blank", "_blank");
      }

      let guid = this.session.subject;
      let email = null;
      let firstName = null;
      let surname = null;

      if (this.session.authenticated) {
        email = this.session.tokenParsed.email;
        firstName = this.session.tokenParsed.given_name;
        surname = this.session.tokenParsed.family_name;
      }

      let clientportal = this.system.clientportal;
      let clientportalId = null;

      if (clientportal) {
        clientportalId = clientportal.get('tradeClientProfileId');
      }

      return this.store.query('client', {
        'tcpid': clientportalId,
        'guid': guid,
        'email': email,
        'kcfirstname': firstName,
        'kcsurname': surname,
        'testmultierror': 'false',
        'pno': -1
      }).then(clients => {
        return clients.get('firstObject');
      }, errors => {
        console.log(errors);
        throw errors;
      });
    }

    afterModel(client
    /* , transition */
    ) {
      this.system.sendAnalytics('obt', 'obt');
      let clientportal = this.system.get('clientportal');
      console.log('dbId: ' + client.get('dbId'));
      console.log('obtPermitted: ' + client.get('clientProfile.obtPermitted'));
      console.log('hasMissingObtFields: ' + client.get('hasMissingObtFields'));

      if (client.get('dbId') === 0) {
        this.system.windowReference.close();
        console.log("transitioning to newprofile from login in afterModel as dbId is " + client.get('dbId'));
        this.transitionTo('newprofile');
      } else if (client.get('clientProfile.obtPermitted') && client.get('hasMissingObtFields')) {
        this.system.windowReference.close();
        console.log("transitioning to myprofile from login in afterModel as hasMissingObtFields is true");
        this.transitionTo('myprofile'); //Change to 'required fields' editor when written
      } else if (clientportal && clientportal.hasObt && client.get('clientProfile.obtPermitted')) {
        this.system.windowReference.location = clientportal.obtUrl; //window.open(clientportal.obtUrl, '_blank');

        this.transitionTo('application');
      } else {
        //console.log("transitioning to application from login in afterModel as dbId is " + client.get('dbId'));
        this.system.windowReference.close();
        this.transitionTo('application');
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ObtRoute;
});