define("clientui/validations/clientdocuments", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    tsaRedressNumber: [(0, _validators.validateFormat)({
      allowBlank: true,
      regex: /\d{7}/
    }), (0, _validators.validateLength)({
      max: 7
    })]
  };
  _exports.default = _default;
});