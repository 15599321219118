define("clientui/models/tradeclient", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let Tradeclient = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('boolean'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('boolean'), _dec11 = (0, _model.attr)('boolean'), _dec12 = Ember.computed('name'), _dec13 = Ember.computed('name', 'ynumber'), classic(_class = (_class2 = class Tradeclient extends _model.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "name", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "ynumber", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "internalTradeClient", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "customCaption1", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "customCaption2", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "customRegex1", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "customRegex2", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "customHelp1", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "customHelp2", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "customSelection1", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "customSelection2", _descriptor11, this);
    }

    get orgName() {
      return this.name;
    }

    get selectDisplayText() {
      return `${this.name} (${this.ynumber})`;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "ynumber", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "internalTradeClient", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "customCaption1", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "customCaption2", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "customRegex1", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "customRegex2", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "customHelp1", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "customHelp2", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "customSelection1", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "customSelection2", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "orgName", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "orgName"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "selectDisplayText", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "selectDisplayText"), _class2.prototype)), _class2)) || _class);
  _exports.default = Tradeclient;
});