define("clientui/pods/components/widgets/stepwizard-step/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mLUIEbrS",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"stepwizard-step col\"],[12],[2,\"\\n  \"],[11,\"button\"],[16,0,[30,[36,6],[\"btn btn-raised \",[30,[36,5],[[30,[36,4],[[35,3],[35,2]],null],[30,[36,1],[[35,0],\"btn-primary\",\"btn-info\"],null],\"btn-secondary\"],null],\" btn-fab btn-fab-mini\"],null]],[4,[38,7],[[32,0],\"gotoPage\",[35,2]],null],[12],[2,\"\\n    \"],[1,[34,2]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[15,0,[30,[36,1],[[35,0],\"primary\",\"inverse\"],null]],[12],[2,\"\\n    \"],[10,\"small\"],[12],[2,\"\\n      \"],[1,[34,8]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"inverse\",\"unless\",\"thisPage\",\"currentPage\",\"eq\",\"if\",\"concat\",\"action\",\"caption\"]}",
    "moduleName": "clientui/pods/components/widgets/stepwizard-step/template.hbs"
  });

  _exports.default = _default;
});