define("clientui/pods/components/dialogs/pre-cbt-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZK7hcpE9",
    "block": "{\"symbols\":[\"@clientportal\",\"@close\",\"@showPreCbtMessage\"],\"statements\":[[6,[37,1],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"modal-dialog\",[],[[\"@translucentOverlay\",\"@targetAttachment\",\"@containerClass\",\"@overlayClass\",\"@wrapperClass\"],[true,\"none\",\"centered-scrolling-container forcewide\",\"centered-scrolling-overlay\",\"centered-scrolling-wrapper\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"card-header text-light bg-primary\"],[12],[2,\"\\n        \"],[10,\"h4\"],[14,0,\"modal-title\"],[12],[2,\"\\n          Pre-Booking Message\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n      \"],[10,\"p\"],[14,0,\"card-text\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[[32,1,[\"preCbtMessage\"]]],null]],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"row forcewide mt-3\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n          \"],[8,\"widgets/form-submit-button-edit\",[],[[\"@onSubmit\",\"@inverse\",\"@savebuttonlabel\"],[[32,2],false,\"OK\"]],null],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"text-to-html\",\"if\"]}",
    "moduleName": "clientui/pods/components/dialogs/pre-cbt-message/template.hbs"
  });

  _exports.default = _default;
});