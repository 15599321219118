define("clientui/pods/components/inputs/validated-datetime-picker/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "validated-changeset", "moment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _validatedChangeset, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;

  let ValidatedDatetimePicker = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, (_class = class ValidatedDatetimePicker extends _component.default {
    //Lists for dropdowns (days is a property as it changes depending on the month/year)
    //selected values from dropdowns - using strings as they are padded with zeroes
    //member of monthList
    //Numeric representations of dropdown values for use in calculations
    constructor(owner, args) {
      super(owner, args);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
      (0, _defineProperty2.default)(this, "years", null);
      (0, _initializerDefineProperty2.default)(this, "months", _descriptor3, this);
      (0, _defineProperty2.default)(this, "hours", null);
      (0, _defineProperty2.default)(this, "minutes", null);
      (0, _initializerDefineProperty2.default)(this, "selectedDay", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "selectedMonth", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "selectedYear", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "selectedHour", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "selectedMinute", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "selectedDayNo", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "selectedMonthNo", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "selectedYearNo", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "selectedHourNo", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "selectedMinuteNo", _descriptor13, this);
      console.log('ValidatedDatetimePicker: constructor ' + this.args.propertyName, this.args.changeset.get(this.args.propertyName), "showTime", this.args.showTime);

      if (!(0, _validatedChangeset.isChangeset)(this.args.changeset)) {
        console.log('Argument Passed to ValidatedDatetimePicker is not a changeset for ' + this.args.propertyName);
      }

      this.buildMonthList();
      this.updateDate(null, [this.args.changeset.get(this.args.propertyName), true]);
      this.buildYearList();
      this.hours = Array(...Array(24)).map((_, i) => `${i.toString().padStart(2, '0')}`);
      this.minutes = Array(...Array(60)).map((_, i) => `${i.toString().padStart(2, '0')}`);
    }

    get currentValue() {
      return Ember.get(this.args.changeset, this.args.propertyName);
    }

    updateDate(element, _ref) {
      let [initialDate, fromConstructor] = _ref;

      // console.log(this.args.propertyName + " updateDate", initialDate, fromConstructor);
      if (initialDate) {
        let momentDate = new _moment.default(initialDate);
        this.selectedDayNo = momentDate.date();
        this.selectedMonthNo = momentDate.month() + 1;
        this.selectedYearNo = momentDate.year();
        this.selectedHourNo = momentDate.hour();
        this.selectedMinuteNo = momentDate.minute();
        this.selectedMonth = this.months[momentDate.month()];
        this.selectedDay = this.selectedDayNo.toString().padStart(2, '0');
        this.selectedYear = this.selectedYearNo.toString().padStart(4, '0');
        this.selectedHour = this.selectedHourNo.toString().padStart(2, '0');
        this.selectedMinute = this.selectedMinuteNo.toString().padStart(2, '0');
      } else if (initialDate === undefined) {
        //initialDate is null after passing through buildDate
        this.selectedDay = "";
        this.selectedMonth = null; //member of monthList

        this.selectedYear = "";
        this.selectedHour = "";
        this.selectedMinute = "";
        this.selectedDayNo = 0;
        this.selectedMonthNo = 0;
        this.selectedYearNo = 0;
        this.selectedHourNo = 0;
        this.selectedMinuteNo = 0;
      }
    }

    get isValidated() {
      let result = true;

      for (var key in this.args.changeset.error) {
        if (key === this.args.propertyName) {
          console.log('The changeset.error has a error for ' + key, this.args.changeset.error); //['key']);

          result = false;
        }
      }

      return result;
    }

    get inputId() {
      let idName = Ember.guidFor(this) + "-input"; //console.log("inputId", idName);

      return idName;
    }

    get showTime() {
      var _this$args$showTime;

      return (_this$args$showTime = this.args.showTime) !== null && _this$args$showTime !== void 0 ? _this$args$showTime : false;
    }

    get disabled() {
      var _this$args$disabled;

      return (_this$args$disabled = this.args.disabled) !== null && _this$args$disabled !== void 0 ? _this$args$disabled : false;
    }

    get allowClear() {
      var _this$args$allowClear;

      return (_this$args$allowClear = this.args.allowClear) !== null && _this$args$allowClear !== void 0 ? _this$args$allowClear : false;
    } //forceDay Can be 'first' or 'last'. Forces day value to first or last day of month
    //If not null, then day selectionbox is hidden


    get forceDay() {
      let result = null;

      if (this.args.forceDay) {
        if (['first', 'last'].includes(this.args.forceDay.toLowerCase())) {
          result = this.args.forceDay.toLowerCase();
        }
      }

      return result;
    }

    get startYear() {
      var _this$args$startYear;

      return (_this$args$startYear = this.args.startYear) !== null && _this$args$startYear !== void 0 ? _this$args$startYear : this.system.currentYear - 10;
    }

    get endYear() {
      var _this$args$endYear;

      return (_this$args$endYear = this.args.endYear) !== null && _this$args$endYear !== void 0 ? _this$args$endYear : this.system.currentYear + 10;
    } //This is a property as the number of days varies by month


    get days() {
      let maxDay = 31;

      if (this.selectedMonthNo > 0 && this.selectedMonthMax > 0) {
        maxDay = this.selectedMonthMax;
      }

      if (maxDay < this.selectedDayNo) {
        maxDay = this.selectedDayNo;
      } //console.log("Building daylist for maxDay", maxDay);


      return Array(...Array(maxDay)).map((_, i) => `${(i + 1).toString().padStart(2, '0')}`);
    }

    get isLeapYear() {
      let year = this.selectedYearNo;
      return year % 4 === 0 && year % 100 !== 0 || year % 400 === 0;
    }

    get selectedMonthMax() {
      let result = Number(this.selectedMonth.max);

      if (this.selectedMonthNo === 2) {
        //February
        if (this.isLeapYear) {
          result = 29;
        }
      }

      return result;
    }

    setDay(day) {
      if (day) {
        this.selectedDay = day;
        this.selectedDayNo = Number(day);
      } else {
        this.clearAllSelections();
      }

      this.buildDate();
    }

    setMonth(month) {
      //console.log("starting setMonth", month);
      if (month) {
        this.selectedMonthNo = Number(month.no);
        this.selectedMonth = month; //console.log("selectedMonthNo, selectedMonth", this.selectedMonthNo, this.selectedMonth);
      } else if (this.allowClear) {
        this.clearAllSelections();
      } //console.log(month.name);


      this.buildDate();
    }

    setYear(year) {
      if (year) {
        this.selectedYear = year;
        this.selectedYearNo = Number(year);
      } else if (this.allowClear) {
        this.clearAllSelections();
      } //console.log(year);


      this.buildDate();
    }

    setHour(hour) {
      if (hour) {
        this.selectedHour = hour;
        this.selectedHourNo = Number(hour);
      } else if (this.allowClear) {
        this.clearAllSelections();
      } //console.log("hour: " + hour);


      this.buildDate();
    }

    setMinute(minute) {
      if (minute) {
        this.selectedMinute = minute;
        this.selectedMinuteNo = Number(minute);
      } else if (this.allowClear) {
        this.clearAllSelections();
      } //console.log("minute: " + minute);


      this.buildDate();
    }

    validateProperty(changeset, property) {
      return changeset.validate(property);
    }

    handleFocus() {//not necessary here with multiple Power selects
    }

    clearAllSelections() {
      this.selectedDay = null;
      this.selectedDayNo = 0;
      this.selectedMonthNo = 0;
      this.selectedMonth = null;
      this.selectedYearNo = 0;
      this.selectedYear = null;
      this.selectedHour = null;
      this.selectedMinute = null;
      this.selectedHourNo = 0;
      this.selectedMinuteNo = 0;
      this.buildDate();
    }

    buildDate() {
      let dateValue = null;

      if (this.selectedYearNo > 0) {
        if (this.selectedMonthNo > 0) {
          //Apply forceDay logic
          if (this.forceDay) {
            if (this.forceDay.toLowerCase() === 'last') {
              this.selectedDayNo = this.selectedMonthMax;
            } else {
              this.selectedDayNo = 1;
            }
          }

          if (this.selectedDayNo > 0) {
            let dateArray;

            if (this.showTime && this.selectedHour && this.selectedMinute) {
              dateArray = [this.selectedYearNo, this.selectedMonthNo - 1, this.selectedDayNo, this.selectedHourNo, this.selectedMinuteNo];
            } else {
              dateArray = [this.selectedYearNo, this.selectedMonthNo - 1, this.selectedDayNo];
            } //console.log("building date from " + dateArray);


            if (new _moment.default(dateArray).isValid()) {
              dateValue = new _moment.default(dateArray);
              console.log('DateValue', dateValue.format());
            } else {
              //this.get('changeset').get('errors').get(this.get('propertyName')).push("Invalid Date");
              console.log("Invalid date: " + dateArray);
            }
          }
        }
      } //Update changeset with either null or a valid date (with optional time)


      if (this.args.changeset) {
        if (this.args.propertyName) {
          this.args.changeset.set(this.args.propertyName, dateValue);

          if (typeof this.args.onUpdate !== 'undefined') {
            //console.log('Calling onUpdate');
            this.args.onUpdate(dateValue);
          }
        }
      }
    } /////////////////////////////////////////////////////////
    // Build all lists
    /////////////////////////////////////////////////////////


    buildYearList() {
      let startYear = Number(this.startYear);
      let endYear = Number(this.endYear);
      let selectedYear = this.selectedYearNo;
      let years = [];

      if (startYear > endYear) {
        //list in descending order
        if (selectedYear > startYear) {
          startYear = selectedYear;
        }

        if (selectedYear !== 0 && selectedYear < endYear) {
          endYear = selectedYear;
        }

        for (let year = startYear; year >= endYear; year--) {
          years.push(year.toString());
        }
      } else {
        //list in ascending order
        if (selectedYear !== 0 && selectedYear < startYear) {
          startYear = selectedYear;
        }

        if (selectedYear > endYear) {
          endYear = selectedYear;
        }

        for (let year = startYear; year <= endYear; year++) {
          years.push(year.toString());
        }
      }

      this.years = years;
    }

    buildMonthList() {
      let months = [];
      months.push({
        'no': 1,
        'name': 'January',
        'max': 31
      });
      months.push({
        'no': 2,
        'name': 'February',
        'max': 28
      });
      months.push({
        'no': 3,
        'name': 'March',
        'max': 31
      });
      months.push({
        'no': 4,
        'name': 'April',
        'max': 30
      });
      months.push({
        'no': 5,
        'name': 'May',
        'max': 31
      });
      months.push({
        'no': 6,
        'name': 'June',
        'max': 30
      });
      months.push({
        'no': 7,
        'name': 'July',
        'max': 31
      });
      months.push({
        'no': 8,
        'name': 'August',
        'max': 31
      });
      months.push({
        'no': 9,
        'name': 'September',
        'max': 30
      });
      months.push({
        'no': 10,
        'name': 'October',
        'max': 31
      });
      months.push({
        'no': 11,
        'name': 'November',
        'max': 30
      });
      months.push({
        'no': 12,
        'name': 'December',
        'max': 31
      });
      this.months = months;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "months", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedDay", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedMonth", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedYear", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedHour", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedMinute", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedDayNo", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedMonthNo", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedYearNo", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedHourNo", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedMinuteNo", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateDate", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "updateDate"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setDay", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "setDay"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setMonth", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "setMonth"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setYear", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "setYear"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setHour", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "setHour"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setMinute", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "setMinute"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "validateProperty", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "validateProperty"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleFocus", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "handleFocus"), _class.prototype)), _class));
  _exports.default = ValidatedDatetimePicker;
});