define("clientui/themes/emt-theme-wtc", ["exports", "@babel/runtime/helpers/esm/defineProperty", "ember-models-table/themes/bootstrap4"], function (_exports, _defineProperty2, _bootstrap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let EmberModelTableBootstrap4MaterialTheme = classic(_class = class EmberModelTableBootstrap4MaterialTheme extends _bootstrap.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "paginationSimpleComponent", 'ember-model-table/pagination-simple');
      (0, _defineProperty2.default)(this, "pageSelectComponent", 'ember-model-table/page-select');
    } // buttonLink = 'btn btn-small';
    // expandRow = 'p-0 m-0';
    // collapseRow = 'p-0 m-0';


  }) || _class;

  _exports.default = EmberModelTableBootstrap4MaterialTheme;
});