define("clientui/pods/components/sections/page-coordinator-searchform/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3;

  let SectionsPageCoordinatorSearchformComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class SectionsPageCoordinatorSearchformComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "tradeclient", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "account", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "clientroletype", _descriptor3, this);
    }

    updateLocalVars(formParams) {
      this.setTradeClient(this.args.tradeclients.find(item => item.id == formParams.tradeclientid));
      this.setAccount(this.args.accounts.find(item => item.id == formParams.accountid));
      this.setClientRoleType(this.args.clientroletypes.find(item => item.id == formParams.clientroletype));
    }

    didInsertComponent(element, _ref) {
      let [formParams] = _ref;
      this.updateLocalVars(formParams);
    }

    didUpdateComponent(element, _ref2) {
      let [formParams] = _ref2;
      this.updateLocalVars(formParams);
    }

    get filteredAccounts() {
      if (this.tradeclient) {
        return this.args.accounts.filter(item => item.accountContactId == this.tradeclient.id);
      } else {
        return this.args.accounts;
      }
    }

    setTradeClient(item) {
      this.tradeclient = item;

      if (this.account && this.tradeclient && this.account.accountContactId != this.tradeclient.id) {
        this.account = null;
      }
    }

    setAccount(item) {
      this.account = item;

      if (this.account) {
        this.tradeclient = this.args.tradeclients.find(item => item.id == this.account.accountContactId);
      }
    }

    setClientRoleType(item) {
      this.clientroletype = item;
    }

    setSearchField(enter, value) {
      Ember.set(this.args.formParams, 'searchfield', value);

      if (enter) {
        this.search();
      }
    }

    onSubmit(evt) {
      evt.preventDefault();
      this.search();
    }

    search() {
      Ember.set(this.args.formParams, 'tradeclientid', this.tradeclient ? this.tradeclient.id : null);
      Ember.set(this.args.formParams, 'accountid', this.account ? this.account.id : null);
      Ember.set(this.args.formParams, 'clientroletype', this.clientroletype ? this.clientroletype.id : null);
      this.args.onSubmit(this.args.formParams);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tradeclient", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "account", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "clientroletype", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "didInsertComponent", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "didInsertComponent"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "didUpdateComponent", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "didUpdateComponent"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setTradeClient", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setTradeClient"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setAccount", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setAccount"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setClientRoleType", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "setClientRoleType"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setSearchField", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "setSearchField"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onSubmit", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmit"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "search"), _class.prototype)), _class));
  _exports.default = SectionsPageCoordinatorSearchformComponent;
});