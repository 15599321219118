define("clientui/pods/components/inputs/validated-phonenumber/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "validated-changeset"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _validatedChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  let ValidatedPhonenumber = (_dec = Ember.inject.service('system'), _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class ValidatedPhonenumber extends _component.default {
    get noMargin() {
      var _this$args$noMargin;

      return (_this$args$noMargin = this.args.noMargin) !== null && _this$args$noMargin !== void 0 ? _this$args$noMargin : false;
    }

    get currentValue() {
      return Ember.get(this.args.changeset, this.args.propertyName);
    }

    updateValue(element, _ref) {
      let [value] = _ref;

      // console.log(this.args.propertyName + " updateValue", value);
      if (value) {
        this.parseInitialValue(value);
      } else if (value === undefined) {
        let validCountries = this.validCountries;

        if (validCountries && validCountries.length > 0) {
          this.country = validCountries[0];
        } else {
          this.country = null;
        }

        this.prefix = null;
        this.localNo = null;
      }
    }

    get isValidated() {
      // console.log('changeset.error has changed');
      // console.log(this.get('changeset.error'));
      // console.log(this.get('changeset.errors'));
      let result = true;

      for (let key in this.args.changeset.error) {
        if (key === this.args.propertyName) {
          console.log('The changeset.error has a error for ' + key);
          result = false;
        }
      }

      return result;
    }

    get validCountries() {
      return this.args.countries.filter(item => Ember.isPresent(item.get('dialCode')));
    }

    get helpkey() {
      var _this$args$helpkey;

      return (_this$args$helpkey = this.args.helpkey) !== null && _this$args$helpkey !== void 0 ? _this$args$helpkey : 'phone';
    }

    get hidePrefix() {
      var _this$args$hidePrefix;

      return (_this$args$hidePrefix = this.args.hidePrefix) !== null && _this$args$hidePrefix !== void 0 ? _this$args$hidePrefix : false;
    }

    get showPrefix() {
      return this.hasPrefix === true || this.hidePrefix !== true;
    }

    constructor(owner, args) {
      super(owner, args);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "country", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "prefix", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "localNo", _descriptor4, this);
      (0, _defineProperty2.default)(this, "validChars", '1234567890');
      (0, _initializerDefineProperty2.default)(this, "hasPrefix", _descriptor5, this);
      this.updateValue(null, [this.args.changeset.get(this.args.propertyName)]); // let initialValue = this.args.changeset.get(this.args.propertyName);
      // if (initialValue) {
      //   this.parseInitialValue(initialValue);
      // }
      // else {
      //   let validCountries = this.validCountries;
      //   if ((validCountries) && (validCountries.length > 0)) {
      //     this.country = validCountries[0];
      //   }
      //   else {
      //     this.country = null;
      //   }
      //   this.prefix = null;
      //   this.localNo = null;
      // }
    }

    parseInitialValue(initialValue) {
      //Clean out non-numerics (keep digits, + and - only)
      let r1 = /[^0-9+-]/g;
      initialValue = initialValue.replace(r1, ''); //Parse number assuming the format of +xxx-xxx-xxxxxxxx or xxxx-xxxxxxxx

      let r2 = /^(\+(\d+)-)?((\d+)-)?(\d+)$/;
      let sections = r2.exec(initialValue);

      if (sections != null) {
        console.log(sections);
        let countryCode = sections[2];
        this.prefix = sections[4];
        this.localNo = sections[5];

        if (countryCode) {
          this.country = this.args.countries.findBy('dialCode', countryCode);
        }

        this.hasPrefix = Ember.isPresent(this.prefix);
      }
    }

    keyUp() {
      this.rebuildPhoneNumber();
    }

    rebuildPhoneNumber() {
      let result = '';

      if (this.localNo) {
        if (this.country) {
          result += '+' + this.country.dialCode;
        }

        if (this.prefix) {
          if (result.length > 0 && this.prefix.startsWith('0')) {
            this.prefix = this.prefix.slice(1);
          }

          if (this.prefix) {
            if (result.length > 0) {
              result += '-';
            }

            result += this.prefix;
          }
        }

        if (this.localNo) {
          if (this.showPrefix === true) {
            result += '-' + this.localNo;
          } else {
            //treat start of local number as the prefix
            if (result.length > 0 && this.localNo.startsWith('0')) {
              this.localNo = this.localNo.slice(1);
            }

            if (result.length > 0) {
              result += '-';
            }

            result += this.localNo;
          }
        }
      }

      console.log("full number: " + result);
      this.fullNumber = result; //temporary

      this.args.changeset.set(this.args.propertyName, result);
    }

    setCountry(item) {
      //item is a country
      console.log("setCountry", item);
      this.country = item;
      this.rebuildPhoneNumber();
    }

    setPrefix(item) {
      //item is a numeric String
      console.log("setPrefix", item);
      this.prefix = item;
      this.rebuildPhoneNumber();
    }

    setLocal(item) {
      //item is a numeric String
      console.log("setLocal", item);
      this.localNo = item;
      this.rebuildPhoneNumber();
    }

    validateProperty(changeset, property) {
      if ((0, _validatedChangeset.isChangeset)(changeset)) {
        console.log('focusOut Event Fires validateProperty' + property); // changeset.validate(property).then((result)=> {
        //   console.log('Validation Successful');
        //   console.log(result);
        // }).catch((errors)=> {
        //   console.log(errors);
        // });

        return changeset.validate(property);
      } else {
        return true;
      }
    }

    handleFocus(select, e) {
      if (this.focusComesFromOutside(e)) {
        select.actions.open();
      }
    }

    focusComesFromOutside(e) {
      let blurredEl = e.relatedTarget;

      if (Ember.isBlank(blurredEl)) {
        return false;
      }

      return !blurredEl.classList.contains('ember-power-select-search-input');
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "country", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "prefix", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "localNo", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateValue", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "updateValue"), _class.prototype), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "hasPrefix", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setCountry", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setCountry"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setPrefix", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "setPrefix"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setLocal", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "setLocal"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "validateProperty", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "validateProperty"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleFocus", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "handleFocus"), _class.prototype)), _class));
  _exports.default = ValidatedPhonenumber;
});