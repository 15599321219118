define("clientui/services/store", ["exports", "@ember-data/store", "clientui/mappings/adapters", "clientui/mappings/serializers"], function (_exports, _store, _adapters, _serializers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let StoreService = classic(_class = class StoreService extends _store.default {
    adapterFor(modelName) {
      if (_adapters.default[modelName]) {
        return super.adapterFor(_adapters.default[modelName]);
      }

      return super.adapterFor(modelName);
    }

    serializerFor(modelName) {
      if (_serializers.default[modelName]) {
        return super.serializerFor(_serializers.default[modelName]);
      }

      return super.serializerFor(modelName);
    }

  }) || _class;

  _exports.default = StoreService;
});