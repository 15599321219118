define("clientui/adapters/unauthenticated", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/adapter/rest", "clientui/config/environment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _rest, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let Unauthenticated = (_dec = Ember.inject.service, (_class = class Unauthenticated extends _rest.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "host", _environment.default.APP.JBOSS_WEBURL);
      (0, _defineProperty2.default)(this, "namespace", 'v1');
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
    }

    get headers() {
      let wtcRef = this.system.getWtcRefHeaderValue();
      let wtcRef2 = this.system.getWtcRef2HeaderValue();
      return {
        "X-WTC": wtcRef,
        "X-WTC2": wtcRef2
      };
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Unauthenticated;
});