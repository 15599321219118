define("clientui/pods/components/sections/page-myprofilenew-profilenotes/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let PageMyprofilenewProfilenotes = (_dec = (0, _component.classNames)('card-columns', 'contact-panel'), _dec2 = Ember.computed.sort('client.clientProfile.profileNoteItems', 'sortDefinition'), classic(_class = _dec(_class = (_class2 = class PageMyprofilenewProfilenotes extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "sortedProfileNotes", _descriptor, this);
    }

    init() {
      super.init(...arguments);
      this.set('sortDefinition', ['selectDisplayText']);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sortedProfileNotes", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class);
  _exports.default = PageMyprofilenewProfilenotes;
});