define("clientui/pods/components/pages/page-customeraccounts/page-customeraccounts-booking/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2;

  let PageCustomeraccountsBooking = (_dec = Ember.inject.service('emt-helper'), _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class PageCustomeraccountsBooking extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _initializerDefineProperty2.default)(this, "emthelper", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor2, this);
      (0, _defineProperty2.default)(this, "paymentActions", [this.emthelper.buildAction("view", "view", "View")]);
      (0, _defineProperty2.default)(this, "ticketActions", [this.emthelper.buildAction("download", "download", "Download")]);
    }

    get invoiceItemColumns() {
      return [this.emthelper.buildExpandToggleCol(true), this.emthelper.buildStringCol("description", "Description"), this.emthelper.buildStringCol("startCity.id", "From", null, "text-center", null, {
        tooltipPropertyName: "startCity.name"
      }), this.emthelper.buildStringCol("endCity.id", "To", null, "text-center", null, {
        tooltipPropertyName: "endCity.name"
      }), this.emthelper.buildDateCol("startDate", "Date Of Travel"), this.emthelper.buildNumberCol("paxCount", "Passengers")];
    }

    get paymentColumns() {
      return [this.emthelper.buildDateCol("transDate", "Date"), this.emthelper.buildStringCol("transType.id", "Type", null, "text-center", null, {
        tooltipPropertyName: "transType.description"
      }), this.emthelper.buildStringCol("details", "Details"), this.emthelper.buildTotalledMoneyIncZeroCol("fcAmount", this.args.booking.get('currency.id') + " Amount"), this.emthelper.buildActionCol("Actions", this.paymentActions)];
    }

    get passengersColumns() {
      return [this.emthelper.buildStringCol("client.firstName", "First Name"), this.emthelper.buildStringCol("client.surname", "Surname")];
    }

    get referenceColumns() {
      return [this.emthelper.buildStringCol("displayDescription", "Reference", null, null, null, {
        "sortDirection": "asc",
        "sortPrecedence": 1
      }), this.emthelper.buildStringCol("value", "Value")];
    }

    get clientInvoiceColumns() {
      return [this.emthelper.buildNumberCol("clientInvoiceIdentifier", "Client Invoice", null, null, null, {
        "routeName": "customeraccounts.clientinvoices.clientinvoice",
        'routeProperty': 'id'
      }), this.emthelper.buildStringCol("account.name", "Account", null, "text-center", null, {
        tooltipPropertyName: "account.accountCode"
      }), this.emthelper.buildDateCol("issueDate", "Issue Date"), this.emthelper.buildMoneyIncZeroCol("totalGross", "Total Amount"), this.emthelper.buildStringCol("reversedReversing", "Reversed/Reversing")];
    }

    get arrangerApprover() {
      let result = true;

      if (this.args.booking.client == null && this.args.booking.approver == null) {
        result = false;
      }

      return result;
    }

    get ticketColumns() {
      return [this.emthelper.buildNumberCol("tktno", "Ticket #"), this.emthelper.buildStringCol("paxName", "Name"), this.emthelper.buildDateCol("dateOfIssue", "Issue Date"), this.emthelper.buildDateCol("dateofTravel", "Date of Travel"), this.emthelper.buildStringCol("pnrRef", "PNR Reference"), this.emthelper.buildActionCol("Actions", this.ticketActions)];
    }

    selectClientInvoiceAction(itemId) {
      if (itemId > 0) {
        this.router.transitionTo('customeraccounts.clientinvoices.clientinvoice', itemId);
      }
    }

    selectTicketAction(itemId, actionName, item) {
      if (itemId > 0) {
        switch (actionName) {
          case 'download':
            this.args.downloadticket(item.pnrRef, item.tktno);
            break;

          default:
            break;
        }
      }
    }

    selectPaymentAction(itemId, actionName, item) {
      if (item) {
        console.warn(item);

        if (item.receiptId) {
          switch (actionName) {
            case 'view':
              this.router.transitionTo('customeraccounts.receipts.receipt', item.receiptId);
              break;

            default:
              break;
          }
        }
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "emthelper", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectClientInvoiceAction", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "selectClientInvoiceAction"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectTicketAction", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "selectTicketAction"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectPaymentAction", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "selectPaymentAction"), _class.prototype)), _class));
  _exports.default = PageCustomeraccountsBooking;
});