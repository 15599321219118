define("clientui/services/evolvissofix", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let EvolvissofixService = classic(_class = class EvolvissofixService extends Ember.Service {
    async fixupRedirectUrl(url) {
      //open the redirect URL three times to make sure that the cookies are reset
      if (window.fetch) {
        let counter = 1;
        console.log("Starting fixupRedirectUrl (Native)");
        let status = await this.fetchOnce(url);
        console.log("Attempt #" + counter + " returned " + status);

        while (counter < 3) {
          counter++;
          status = await this.fetchOnce(url); //should get 302 now

          console.log("Attempt #" + counter + " returned " + status);
        }

        return true;
      } else {
        console.log("Unable to fixupRedirectUrl for " + url + " as this browser does not support native fetch");
        return false;
      }
    }

    async fetchOnce(url) {
      //returns promise which resolves to a status number
      //but the number is meaningless as it is hidded (opaque) due to the no-cors mode
      //Using no-cors mode we are unable to see the headers or content of the response, but the cookies are correctly processed in the background
      //this requires that we use native fetch as the polyfill does not support this mode
      return await fetch(url, {
        method: 'GET',
        credentials: 'include',
        redirect: 'follow',
        mode: 'no-cors',
        headers: {//'Content-Type': 'application/json;charset=UTF-8',
          //'Authorization': 'Bearer ' + this.session.token,
        }
      }).then(response => {
        console.log(response);
        return response.status;
      }).catch(error => {
        console.log(error);
        return 999;
      });
    }

  }) || _class;

  _exports.default = EvolvissofixService;
});