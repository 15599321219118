define("clientui/pods/components/pages/faq-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K7RhFB3N",
    "block": "{\"symbols\":[\"category\",\"@dashdata\"],\"statements\":[[10,\"div\"],[14,0,\"row justify-content-center\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-6 text-md-left text-center\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"categories\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"pages/faq-category-accordion\",[],[[\"@dashdata\",\"@category\"],[[32,2],[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "moduleName": "clientui/pods/components/pages/faq-display/template.hbs"
  });

  _exports.default = _default;
});