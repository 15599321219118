define("clientui/pods/managedprofiles/profile/delete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SxFuVVkE",
    "block": "{\"symbols\":[\"closeDialog\"],\"statements\":[[1,[30,[36,0],[\"Delete\"],null]],[2,\"\\n\"],[8,\"widgets/dialog-box-route\",[],[[\"@title\",\"@historyBack\"],[[32,0,[\"title\"]],false]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"dialogs/form-profile-delete\",[],[[\"@client\",\"@clientportal\",\"@btncaption\",\"@onDelete\",\"@onRollback\",\"@onRefreshRoute\",\"@onClose\"],[[30,[36,1],[[32,0,[\"model\"]]],null],[32,0,[\"clientportal\"]],\"MARK FOR PERMANENT DELETION IN SEVEN DAYS\",[30,[36,2],[\"deleteAction\"],null],[30,[36,2],[\"back\"],null],[30,[36,2],[\"refreshRoute\"],null],[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"page-title\",\"changeset\",\"route-action\"]}",
    "moduleName": "clientui/pods/managedprofiles/profile/delete/template.hbs"
  });

  _exports.default = _default;
});