define("clientui/models/clientinvoice", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28;

  let Clientinvoice = (_dec = (0, _model.attr)('number'), _dec2 = (0, _model.belongsTo)('account'), _dec3 = (0, _model.belongsTo)('shortbooking'), _dec4 = (0, _model.attr)('dateonly'), _dec5 = (0, _model.attr)('number'), _dec6 = (0, _model.hasMany)('clientinvoiceline'), _dec7 = (0, _model.hasMany)('clientinvoicevatdetail'), _dec8 = (0, _model.attr)('number'), _dec9 = (0, _model.attr)('number'), _dec10 = (0, _model.hasMany)('bookingreference'), _dec11 = (0, _model.attr)('datetime'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('number'), _dec14 = (0, _model.attr)('number'), _dec15 = (0, _model.attr)('number'), _dec16 = (0, _model.attr)('string'), _dec17 = (0, _model.attr)('number'), _dec18 = (0, _model.attr)('boolean'), _dec19 = (0, _model.attr)('boolean'), _dec20 = (0, _model.attr)('boolean'), _dec21 = (0, _model.attr)('datetime'), _dec22 = (0, _model.attr)('string'), _dec23 = (0, _model.belongsTo)('currency'), _dec24 = (0, _model.attr)('string'), _dec25 = (0, _model.attr)('string'), _dec26 = (0, _model.attr)('boolean'), _dec27 = (0, _model.hasMany)('shortclient'), _dec28 = (0, _model.hasMany)('transaction'), (_class = class Clientinvoice extends _model.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "version", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "account", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "booking", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "issueDate", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "sequence", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "clientInvoiceLines", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "clientInvoiceVatDetails", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "totalNet", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "totalGross", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "bookingReferences", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "sentToClientOn", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "sentToClientBy", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "totalNetBeforeVAT", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "totalVAT", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "clientInvoiceSummaryId", _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, "note", _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, "allocatedAmount", _descriptor17, this);
      (0, _initializerDefineProperty2.default)(this, "reversed", _descriptor18, this);
      (0, _initializerDefineProperty2.default)(this, "reversing", _descriptor19, this);
      (0, _initializerDefineProperty2.default)(this, "dummyClientInvoice", _descriptor20, this);
      (0, _initializerDefineProperty2.default)(this, "createdOn", _descriptor21, this);
      (0, _initializerDefineProperty2.default)(this, "createdBy", _descriptor22, this);
      (0, _initializerDefineProperty2.default)(this, "currency", _descriptor23, this);
      (0, _initializerDefineProperty2.default)(this, "clientInvoiceIdentifier", _descriptor24, this);
      (0, _initializerDefineProperty2.default)(this, "clientInvoiceDisplay", _descriptor25, this);
      (0, _initializerDefineProperty2.default)(this, "paidInFull", _descriptor26, this);
      (0, _initializerDefineProperty2.default)(this, "pax", _descriptor27, this);
      (0, _initializerDefineProperty2.default)(this, "transactions", _descriptor28, this);
    }

    get reversedReversing() {
      let result = "no";

      if (this.reversed) {
        result = "reversed";
      } else if (this.reversing) {
        result = "reversing";
      }

      return result;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "version", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "account", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "booking", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "issueDate", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "sequence", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "clientInvoiceLines", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "clientInvoiceVatDetails", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "totalNet", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "totalGross", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "bookingReferences", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "sentToClientOn", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "sentToClientBy", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "totalNetBeforeVAT", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "totalVAT", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "clientInvoiceSummaryId", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "note", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "allocatedAmount", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "reversed", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "reversing", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dummyClientInvoice", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "createdOn", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "createdBy", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "currency", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "clientInvoiceIdentifier", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "clientInvoiceDisplay", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "paidInFull", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "pax", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "transactions", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Clientinvoice;
});