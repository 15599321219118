define("clientui/pods/components/widgets/login-logout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l4MwO7Fe",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\\n\"],[6,[37,3],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"link-to\",[[24,0,\"navbar-brand text-white pt-1 pb-1 mr-0\"]],[[\"@route\"],[\"logout\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-user circle-icon bg-light text-dark\"],[15,\"title\",[34,1]],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"navbar-brand text-white pt-1 pb-1 mr-0\"]],[[\"@route\"],[\"impersonate\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-exchange circle-icon bg-light text-dark\"],[14,\"title\",\"Impersonate\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"link-to\",[[24,0,\"navbar-brand text-white pt-1 pb-1 mr-0\"]],[[\"@route\"],[\"logout\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      LOG OUT \"],[10,\"i\"],[14,0,\"fa fa-user circle-icon bg-light text-dark\"],[15,\"title\",[34,1]],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \\n  \"],[8,\"link-to\",[[24,0,\"navbar-brand text-white pt-1 pb-1 mr-0\"],[4,[38,0],[\"click\",[32,0,[\"login\"]]],null]],[[\"@route\"],[\"login\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    LOG IN \"],[10,\"i\"],[14,0,\"fa fa-user circle-icon bg-light text-dark\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"on\",\"usertitle\",\"mayImpersonate\",\"if\",\"authenticated\"]}",
    "moduleName": "clientui/pods/components/widgets/login-logout/template.hbs"
  });

  _exports.default = _default;
});