define("clientui/pods/components/widgets/form-wizard-buttons/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _class2, _descriptor, _descriptor2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let FormWizardButtons = (_dec = (0, _component.classNameBindings)('row'), _dec2 = Ember.inject.service('system'), _dec3 = Ember.inject.service('keycloak-session'), _dec4 = Ember.computed('session'), _dec5 = Ember.computed('session'), _dec6 = Ember.computed('pageActive', 'forcedisabled', 'system.saving'), _dec7 = Ember.computed('disabled', 'page', 'system.saving'), _dec8 = Ember.computed('disabled', 'page', 'lastPage', 'system.saving'), _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, classic(_class = _dec(_class = (_class2 = class FormWizardButtons extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
      (0, _defineProperty2.default)(this, "backbuttonlabel", 'BACK');
      (0, _defineProperty2.default)(this, "savebuttonlabel", 'Finish');
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor2, this);
      (0, _defineProperty2.default)(this, "forcedisabled", false);
    }

    get hasBetaTesterPermission() {
      return this.session.hasResourceRole('BetaTester');
    }

    get hasReadOnlyPermission() {
      return this.session.hasResourceRole('ReadOnly');
    }

    get disabled() {
      return this.forcedisabled || this.pageActive || this.get('system.saving');
    }

    get prevdisabled() {
      return this.disabled || this.page === 1 || this.get('system.saving');
    }

    get nextdisabled() {
      return this.disabled || this.page === this.lastPage || this.get('system.saving');
    }

    willDestroyElement() {
      Ember.run.cancel(this._debounced);
      super.willDestroyElement(...arguments);
    }

    Back() {
      this.set('forcedisabled', true);
      this.onBack();
      this._debounced = Ember.run.later(this, () => {
        this.set('forcedisabled', false);
      }, 500);
    }

    Submit() {
      this.set('forcedisabled', true);
      this.onSubmit();
      this._debounced = Ember.run.later(this, () => {
        this.set('forcedisabled', false);
      }, 500);
    }

    Prev() {
      this.onPrev();
    }

    Next() {
      this.onNext();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "hasBetaTesterPermission", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "hasBetaTesterPermission"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "hasReadOnlyPermission", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "hasReadOnlyPermission"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "disabled", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "disabled"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "prevdisabled", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "prevdisabled"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "nextdisabled", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "nextdisabled"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "Back", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "Back"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "Submit", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "Submit"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "Prev", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "Prev"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "Next", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "Next"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = FormWizardButtons;
});