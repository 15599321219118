define("clientui/pods/components/pages/page-list-of-travel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xx3Nuy3w",
    "block": "{\"symbols\":[\"@onSelectAction\",\"@travelrequests\",\"&default\"],\"statements\":[[6,[37,6],[[30,[36,5],[[35,4,[\"clientProfile\",\"travelApprover\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"widgets/collapsible-section\",[],[[\"@title\",\"@open\",\"@helpkey\",\"@inverse\"],[\"Travel Requests\",true,\"personalRequests\",[34,0]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"sections/page-requests\",[],[[\"@enquirylist\",\"@dateFormat\",\"@inverse\",\"@onSelectAction\"],[[32,2],[34,2],[34,0],[32,1]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,6],[[35,4,[\"clientProfile\",\"travelApprover\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"widgets/collapsible-section\",[],[[\"@title\",\"@helpkey\",\"@open\",\"@inverse\"],[\"Approval Requests\",\"requestedApprover\",true,[34,0]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"sections/page-requests\",[],[[\"@enquirylist\",\"@dateFormat\",\"@inverse\",\"@onSelectAction\"],[[34,1],[34,2],[34,0],[32,1]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"widgets/collapsible-section\",[],[[\"@title\",\"@open\",\"@helpkey\",\"@inverse\"],[\"Travel Requests\",true,\"personalRequests\",[34,0]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"sections/page-requests\",[],[[\"@enquirylist\",\"@dateFormat\",\"@inverse\",\"@onSelectAction\"],[[34,3],[34,2],[34,0],[32,1]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[18,3,null]],\"hasEval\":false,\"upvars\":[\"inverse\",\"approvalrequests\",\"dateFormat\",\"travelrequests\",\"client\",\"not\",\"if\"]}",
    "moduleName": "clientui/pods/components/pages/page-list-of-travel/template.hbs"
  });

  _exports.default = _default;
});