define("clientui/validations/clientpersonal", ["exports", "ember-changeset-validations/validators", "clientui/validators/dateofbirth-v-paxtype", "clientui/validators/presence-if-required"], function (_exports, _validators, _dateofbirthVPaxtype, _presenceIfRequired) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    title: (0, _validators.validatePresence)(true),
    firstName: (0, _validators.validatePresence)(true),
    surname: (0, _validators.validatePresence)(true),
    dateOfBirth: [(0, _dateofbirthVPaxtype.default)('paxType'), (0, _presenceIfRequired.default)('requiresDateOfBirth')],
    nationalitySelection: (0, _presenceIfRequired.default)('requiresNationality')
  };
  _exports.default = _default;
});