define("clientui/helpers/prefix-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function prefixUrl(_ref) {
    let [url, prefix] = _ref;
    let result = url;

    if (url) {
      if (prefix) {
        if (!url.startsWith(prefix)) {
          result = prefix + url;
        }
      }
    }

    return result;
  });

  _exports.default = _default;
});