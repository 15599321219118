define("clientui/pods/components/pages/page-customeraccounts/page-clientinvoice/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  let PagesPageCustomeraccountsPageClientinvoiceComponent = (_dec = Ember.inject.service('emt-helper'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class PagesPageCustomeraccountsPageClientinvoiceComponent extends _component.default {
    constructor(owner, args) {
      super(owner, args); //the following code is used to display invoice lines with a seperation between
      //invoice items

      (0, _initializerDefineProperty2.default)(this, "emthelper", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "fetch", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "items", _descriptor5, this);
      (0, _defineProperty2.default)(this, "clientInvoiceLinesColumns", [this.emthelper.buildStringCol("invoiceItem.description", "Invoice Item"), this.emthelper.buildNumberCol("quantity", "Quantity"), this.emthelper.buildStringCol("description", "Description"), this.emthelper.buildTotalledMoneyIncZeroCol("fcPrice", this.args.clientInvoice.get('currency.id') + " Amount")]);
      (0, _defineProperty2.default)(this, "clientInvoiceVATDetailsColumns", [this.emthelper.buildNumberCol("vatRatePercent", this.args.clientInvoice.get('currency.id') + " VAT Rate"), this.emthelper.buildMoneyIncZeroCol("totalBeforeVat", this.args.clientInvoice.get('currency.id') + " Total Before VAT"), this.emthelper.buildMoneyIncZeroCol("totalVAT", this.args.clientInvoice.get('currency.id') + " Total VAT"), this.emthelper.buildMoneyIncZeroCol("totalAfterVat", this.args.clientInvoice.get('currency.id') + " Total After VAT")]);
      (0, _defineProperty2.default)(this, "bookingReferenceColumns", [this.emthelper.buildStringCol("displayDescription", "Reference", null, null, null, {
        "sortDirection": "asc",
        "sortPrecedence": 1
      }), this.emthelper.buildStringCol("value", "Value")]);
      (0, _defineProperty2.default)(this, "paymentActions", [this.emthelper.buildAction("view", "view", "View")]);
      this.items = [];
      this.args.clientInvoice.clientInvoiceLines.forEach(line => {
        if (line !== null) {
          if (line.invoiceItem != null) {
            if (!this.items.includes(line.invoiceItem.get('id'))) {
              this.items.push(line.invoiceItem.get('id'));
            }
          }
        }
      });
      this.groupedLinesArray = [];

      for (let i = 0; i < this.items.length; i++) {
        this.groupedLinesArray[i] = Ember.A([]);
        let item = this.items[i];
        this.args.clientInvoice.clientInvoiceLines.forEach(line => {
          if (line.invoiceItem.get('id') === item) {
            this.groupedLinesArray[i].pushObject(line);
          }
        });
      }
    }

    get passengersColumns() {
      return [this.emthelper.buildStringCol("firstName", "First Name"), this.emthelper.buildStringCol("surname", "Surname")];
    }

    get balance() {
      return this.args.clientInvoice.totalGross - this.args.clientInvoice.allocatedAmount;
    }

    get reversedOrReversing() {
      let result = false;

      if (this.args.clientInvoice.reversed || this.args.clientInvoice.reversing) {
        result = true;
      }

      return result;
    }

    viewBooking(bookingId) {
      console.log("View Booking");
      this.router.transitionTo('customeraccounts.bookings.booking', bookingId);
    } // @action
    // downloadInvoices() {
    //   return this.fetch.fetchAndDownloadFile("clientinvoices/" + this.args.clientInvoice.account.get('id') +"/download", null, null).then(() => {
    //   }, (errors) => {
    //     console.log("errors in promise", errors);
    //     this.toast.error(errors);
    //   });
    // }


    downloadInvoice() {
      return this.fetch.fetchAndDownloadFile("clientinvoices/" + this.args.clientInvoice.id + "/doc", null, null).then(() => {}, errors => {
        console.log("errors in promise", errors);
        this.toast.error(errors);
      });
    }

    get paymentColumns() {
      return [this.emthelper.buildDateCol("transDate", "Date"), this.emthelper.buildStringCol("transType.id", "Type", null, "text-center", null, {
        tooltipPropertyName: "transType.description"
      }), this.emthelper.buildStringCol("details", "Details"), this.emthelper.buildTotalledMoneyIncZeroCol("fcAmount", "Amount", null, null, null, {
        currencyProperty: "currency.id"
      }), this.emthelper.buildActionCol("Actions", this.paymentActions)];
    }

    selectPaymentAction(itemId, actionName, item) {
      if (item) {
        console.warn(item);

        if (item.receiptId) {
          switch (actionName) {
            case 'view':
              this.router.transitionTo('customeraccounts.receipts.receipt', item.receiptId);
              break;

            default:
              break;
          }
        }
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "emthelper", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetch", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "items", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "viewBooking", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "viewBooking"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "downloadInvoice", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "downloadInvoice"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectPaymentAction", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "selectPaymentAction"), _class.prototype)), _class));
  _exports.default = PagesPageCustomeraccountsPageClientinvoiceComponent;
});