define("clientui/pods/components/ember-model-table/cell-actions-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YdeC87XK",
    "block": "{\"symbols\":[\"@cellaction\"],\"statements\":[[6,[37,1],[[32,0,[\"visible\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"widgets/icon-for\",[],[[\"@target\",\"@tooltip\",\"@onClick\"],[[32,1,[\"icon\"]],[32,1,[\"tooltip\"]],[30,[36,0],[[32,0,[\"handleClick\"]],[32,1,[\"actionFieldName\"]],[32,1,[\"cellaction\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"if\"]}",
    "moduleName": "clientui/pods/components/ember-model-table/cell-actions-item/template.hbs"
  });

  _exports.default = _default;
});