define("clientui/pods/coordinator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0/qQoyhI",
    "block": "{\"symbols\":[],\"statements\":[[8,\"layout/page-header\",[],[[\"@clientportal\",\"@client\",\"@showBanner\",\"@pageTitle\"],[[32,0,[\"clientportal\"]],[32,0,[\"client\"]],false,\"Search Roles\"]],null],[2,\"\\n\"],[10,\"section\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[14,1,\"content\"],[12],[2,\"\\n    \"],[8,\"layout/content-heading\",[],[[\"@portalDisplayName\",\"@firstName\",\"@surname\",\"@email\",\"@position\",\"@tradeclient\"],[[32,0,[\"clientportal\",\"portalDisplayName\"]],[32,0,[\"client\",\"firstName\"]],[32,0,[\"client\",\"surname\"]],[32,0,[\"client\",\"mainEmail\"]],[32,0,[\"client\",\"position\"]],[32,0,[\"client\",\"owner\",\"selectDisplayText\"]]]],null],[2,\"\\n\\n    \"],[8,\"sections/page-coordinator-searchform\",[],[[\"@formParams\",\"@clientportal\",\"@tradeclients\",\"@accounts\",\"@clientroletypes\",\"@onSubmit\",\"@onClearForm\"],[[32,0,[\"formParams\"]],[32,0,[\"clientportal\"]],[32,0,[\"tradeclients\"]],[32,0,[\"accounts\"]],[32,0,[\"clientroletypes\"]],[30,[36,0],[\"formSubmit\"],null],[30,[36,1],[[32,0,[\"clearForm\"]]],null]]],null],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"clientroles\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"sections/page-coordinator-searchlist\",[],[[\"@clientRoles\",\"@onDataNavigation\",\"@onEdit\"],[[32,0,[\"clientroles\"]],[32,0,[\"dataNavigation\"]],[30,[36,0],[\"editClientRole\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[32,0,[\"noneFound\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"widgets/fade-alert\",[[24,0,\"mt-3\"]],[[\"@message\"],[\"No Matching Client Roles Found\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,4],[[30,[36,3],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"route-action\",\"fn\",\"if\",\"-outlet\",\"component\"]}",
    "moduleName": "clientui/pods/coordinator/template.hbs"
  });

  _exports.default = _default;
});