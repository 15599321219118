define("clientui/pods/components/pages/page-list-of-travel/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "clientui/mappings/helptext"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _helptext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let PageListOfTravel = (_dec = Ember.computed('clientportal.{usingApprovers,usingArrangers,obtUrl}'), _dec2 = Ember.computed('client', 'requests'), _dec3 = Ember.computed('client', 'requests'), _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, classic(_class = (_class2 = class PageListOfTravel extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "helpTextMap", _helptext.default);
    }

    get hasPermissions() {
      return this.get('clientportal.usingApprovers') === true || this.get('clientportal.usingArrangers') === true || this.get('clientportal.obtUrl');
    }

    get reqAwaitingApprovalByApprover() {
      return this.requests.filter(item => {
        return item.get('travelRequests.firstObject.requestedApproverId') === this.client.get('dbId');
      });
    }

    get travelRequests() {
      return this.requests.filter(item => {
        return item.get('travelRequests.firstObject.requester.dbId') === this.client.get('dbId');
      });
    } // approvedBookings: computed('enquirylist.[]', function () {
    //     return this.get('enquirylist').filter((item) => {
    //       //return item.get('travelRequests.firstObject.approved') === true;
    //       //console.log(typeof(item.get('travelRequests.firstObject.approved')));
    //       return (item.get('travelRequests.firstObject.approved') === true && item.get('travelRequests.firstObject.requestProcessedOn') !== null);
    //     });
    // }),
    // notApprovedBookings: computed('enquirylist.[]', function () {
    //   return this.get('enquirylist').filter((item) => {
    //     //return item.get('travelRequests.firstObject.approved') === false;
    //     //console.log(typeof(item.get('travelRequests.firstObject.approved')));
    //     return (item.get('travelRequests.firstObject.approved') === false && item.get('travelRequests.firstObject.requestProcessedOn') == null);
    //   });
    // }),
    // pastDatedBookings: computed('enquirylist.[]', function () {
    //   var today = new Date();
    //   return this.get('enquirylist').filter((item) => {
    //     return (item.get('travelRequests.firstObject.approved') === false && item.get('travelRequests.firstObject.requestElementFlights.firstObject.departDate') < today);
    //   });
    // }),
    // upComingBookings: computed('enquirylist.[]', function () {
    //   var today = new Date();
    //   return this.get('enquirylist').filter((item) => {
    //     return (item.get('travelRequests.firstObject.approved') === false && item.get('travelRequests.firstObject.requestElementFlights.firstObject.departDate') > today);
    //   });
    // }),


    expandAll() {
      this.$(".collapsible-section.collapse").collapse('show');
    }

    closeAll() {
      this.$(".collapsible-section.collapse").collapse('hide');
    }

    changeRequest() {
      this.onChangeRequest();
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "hasPermissions", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "hasPermissions"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "reqAwaitingApprovalByApprover", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "reqAwaitingApprovalByApprover"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "travelRequests", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "travelRequests"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "expandAll", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "expandAll"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "closeAll", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "closeAll"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "changeRequest", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "changeRequest"), _class2.prototype)), _class2)) || _class);
  _exports.default = PageListOfTravel;
});