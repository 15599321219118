define("clientui/validations/clientpermissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import { validatePresence } from 'ember-changeset-validations/validators';
  var _default = {};
  _exports.default = _default;
});