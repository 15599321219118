define("clientui/pods/railsso/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "clientui/superclasses/authenticated-route"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  let RailssoRoute = (_dec = Ember.inject.service('system'), _dec2 = Ember.inject.service('keycloak-session'), _dec3 = Ember.inject.service('evolvisso'), _dec4 = Ember._action, (_class = class RailssoRoute extends _authenticatedRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "evolvisso", _descriptor3, this);
    }

    model()
    /* params */
    {
      let promises = {};
      let clientPortal = this.modelFor('application');
      promises['application'] = clientPortal;
      let session = this.session;

      if (session.get('authenticated') === true) {
        let guid = session.get('subject'); //Use query instead of find so that we can pass through the tcpid and so verify that the client is logging in to the correct site.
        //The server will return a redirect error if the two parameters do not match up.

        promises['client'] = this.store.query('client', {
          'guid': guid,
          'tcpid': clientPortal.get('tradeClientProfileId'),
          'pno': -1
        }).then(clients => {
          return clients.get('firstObject');
        }, errors => {
          console.log(errors); //throw errors; do not throw an error as client does not have to be logged in to use this page
        });
      }

      return Ember.RSVP.hash(promises);
    }

    afterModel(promises
    /* , transition */
    ) {
      let client = promises['client'];
      let clientportal = promises['application'];

      if (clientportal.hasEvolvi && clientportal.usingEvolviSso) {
        if (client && client.get('hasMissingEvolviSsoFields')) {
          console.log("transitioning to myprofile from login in afterModel as hasMissingEvolviSsoFields is true");
          this.transitionTo('myprofile'); //Change to 'required fields' editor when written
        }
      }
    }

    setupController(controller, promises) {
      this.system.sendAnalytics('railsso', 'railsso');
      controller.set('showDialog', false);
      let client = promises['client'];

      if (client) {
        console.log("Got Client", client);
        controller.set('client', client);
      }

      let clientportal = promises['application'];
      console.log(clientportal);
      controller.set('clientportal', clientportal);

      if (clientportal.hasEvolvi && clientportal.usingEvolviSso) {
        if (client) {
          controller.set('showDialog', true);
          this.evolvisso.getEvolviSSO().then(ssoUrl => {
            console.log("Route got SSO Url " + ssoUrl);

            if (ssoUrl) {
              controller.set('ssoUrl', ssoUrl);
              controller.set('showDialog', true);
            }
          });
        } //else just allow authentication to kick in

      } else {
        if (clientportal.hasRail) {
          controller.set('showDialog', true);
          window.open(clientportal.railUrl);
        }
      }

      if (!controller.showDialog) {
        this.transitionTo('application');
      }
    }

    finish() {
      this.controller.set('showDialog', false);
      this.transitionTo('application');
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "evolvisso", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "finish", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "finish"), _class.prototype)), _class));
  _exports.default = RailssoRoute;
});