define("clientui/models/adminvoiceitem", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _dec41, _dec42, _dec43, _dec44, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38, _descriptor39, _descriptor40, _descriptor41, _descriptor42, _descriptor43, _descriptor44;

  let adminvoiceitem = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('date'), _dec7 = (0, _model.attr)('date'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('number'), _dec11 = (0, _model.attr)('number'), _dec12 = (0, _model.attr)('number'), _dec13 = (0, _model.attr)('number'), _dec14 = (0, _model.attr)('number'), _dec15 = (0, _model.attr)('number'), _dec16 = (0, _model.attr)('number'), _dec17 = (0, _model.attr)('number'), _dec18 = (0, _model.attr)('number'), _dec19 = (0, _model.attr)('number'), _dec20 = (0, _model.attr)('number'), _dec21 = (0, _model.attr)('number'), _dec22 = (0, _model.attr)('number'), _dec23 = (0, _model.attr)('number'), _dec24 = (0, _model.attr)('boolean'), _dec25 = (0, _model.belongsTo)('city'), _dec26 = (0, _model.belongsTo)('city'), _dec27 = (0, _model.attr)('date'), _dec28 = (0, _model.attr)('date'), _dec29 = (0, _model.belongsTo)('supplier'), _dec30 = (0, _model.attr)('number'), _dec31 = (0, _model.attr)('number'), _dec32 = (0, _model.belongsTo)('currency'), _dec33 = (0, _model.attr)('boolean'), _dec34 = (0, _model.attr)('string'), _dec35 = (0, _model.attr)('string'), _dec36 = (0, _model.attr)('string'), _dec37 = (0, _model.attr)('string'), _dec38 = (0, _model.attr)('string'), _dec39 = (0, _model.attr)('number'), _dec40 = (0, _model.attr)('number'), _dec41 = (0, _model.attr)('string'), _dec42 = (0, _model.attr)('string'), _dec43 = (0, _model.attr)('string'), _dec44 = (0, _model.attr)('string'), (_class = class adminvoiceitem extends _model.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "description", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "invoiceItemType", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "invoiceItemTypeLabel", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "invoiceItemBaseType", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "invoiceItemBaseTypeLabel", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "createdOn", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "modifiedOn", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "createdBy", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "modifiedBy", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "totalGrossSellingPrice", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "totalNetSellingPrice", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "totalNetServiceFees", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "totalGrossHomeCurrSellingPrice", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "totalNetHomeCurrSellingPrice", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "totalNetHomeCurrServiceFees", _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, "totalNetVat", _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, "totalNetHomeCurrVat", _descriptor17, this);
      (0, _initializerDefineProperty2.default)(this, "totalNetService", _descriptor18, this);
      (0, _initializerDefineProperty2.default)(this, "totalNetHomeCurrService", _descriptor19, this);
      (0, _initializerDefineProperty2.default)(this, "totalNetServiceFeeVat", _descriptor20, this);
      (0, _initializerDefineProperty2.default)(this, "totalNetHomeCurrServiceFeeVat", _descriptor21, this);
      (0, _initializerDefineProperty2.default)(this, "totalNetServiceIncVat", _descriptor22, this);
      (0, _initializerDefineProperty2.default)(this, "totalNetHomeCurrServiceIncVat", _descriptor23, this);
      (0, _initializerDefineProperty2.default)(this, "cancelled", _descriptor24, this);
      (0, _initializerDefineProperty2.default)(this, "startCity", _descriptor25, this);
      (0, _initializerDefineProperty2.default)(this, "endCity", _descriptor26, this);
      (0, _initializerDefineProperty2.default)(this, "startDate", _descriptor27, this);
      (0, _initializerDefineProperty2.default)(this, "endDate", _descriptor28, this);
      (0, _initializerDefineProperty2.default)(this, "supplier", _descriptor29, this);
      (0, _initializerDefineProperty2.default)(this, "paxCount", _descriptor30, this);
      (0, _initializerDefineProperty2.default)(this, "roomCount", _descriptor31, this);
      (0, _initializerDefineProperty2.default)(this, "supplierCurrency", _descriptor32, this);
      (0, _initializerDefineProperty2.default)(this, "paidByClientDirect", _descriptor33, this);
      (0, _initializerDefineProperty2.default)(this, "invoiceItemSourceType", _descriptor34, this);
      (0, _initializerDefineProperty2.default)(this, "invoiceItemSourceTypeLabel", _descriptor35, this);
      (0, _initializerDefineProperty2.default)(this, "invoiceItemSourceRef1", _descriptor36, this);
      (0, _initializerDefineProperty2.default)(this, "invoiceItemSourceRef2", _descriptor37, this);
      (0, _initializerDefineProperty2.default)(this, "supplierReference", _descriptor38, this);
      (0, _initializerDefineProperty2.default)(this, "distanceInKMPerPax", _descriptor39, this);
      (0, _initializerDefineProperty2.default)(this, "travelTimeInMinsPerPax", _descriptor40, this);
      (0, _initializerDefineProperty2.default)(this, "routeType", _descriptor41, this);
      (0, _initializerDefineProperty2.default)(this, "routeTypeLabel", _descriptor42, this);
      (0, _initializerDefineProperty2.default)(this, "reviewStatus", _descriptor43, this);
      (0, _initializerDefineProperty2.default)(this, "reviewStatusLabel", _descriptor44, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "description", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "invoiceItemType", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "invoiceItemTypeLabel", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "invoiceItemBaseType", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "invoiceItemBaseTypeLabel", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "createdOn", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "modifiedOn", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "createdBy", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "modifiedBy", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "totalGrossSellingPrice", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "totalNetSellingPrice", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "totalNetServiceFees", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "totalGrossHomeCurrSellingPrice", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "totalNetHomeCurrSellingPrice", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "totalNetHomeCurrServiceFees", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "totalNetVat", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "totalNetHomeCurrVat", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "totalNetService", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "totalNetHomeCurrService", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "totalNetServiceFeeVat", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "totalNetHomeCurrServiceFeeVat", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "totalNetServiceIncVat", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "totalNetHomeCurrServiceIncVat", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cancelled", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "startCity", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "endCity", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "startDate", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "endDate", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "supplier", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "paxCount", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "roomCount", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "supplierCurrency", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "paidByClientDirect", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "invoiceItemSourceType", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "invoiceItemSourceTypeLabel", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "invoiceItemSourceRef1", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "invoiceItemSourceRef2", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor38 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "supplierReference", [_dec38], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor39 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "distanceInKMPerPax", [_dec39], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor40 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "travelTimeInMinsPerPax", [_dec40], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor41 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "routeType", [_dec41], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor42 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "routeTypeLabel", [_dec42], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor43 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "reviewStatus", [_dec43], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor44 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "reviewStatusLabel", [_dec44], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = adminvoiceitem;
});