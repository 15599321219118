define("clientui/pods/components/sections/page-myprofilenew-permissions/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let PageMyprofilenewPermissions = (_dec = (0, _component.classNames)('card-columns', 'contact-panel'), _dec2 = Ember.computed.sort('client.clientProfile.approvers', 'sortDefinition'), _dec3 = Ember.computed.sort('client.clientProfile.arrangers', 'sortDefinition'), classic(_class = _dec(_class = (_class2 = class PageMyprofilenewPermissions extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "sortedApprovers", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "sortedArrangers", _descriptor2, this);
      (0, _defineProperty2.default)(this, "sortDefinition", null);
    }

    init() {
      super.init(...arguments);
      this.set('sortDefinition', ['clientDisplayName', 'clientId']);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sortedApprovers", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sortedArrangers", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class);
  _exports.default = PageMyprofilenewPermissions;
});