define("clientui/models/clientprofile", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _dec41, _dec42, _dec43, _dec44, _dec45, _dec46, _dec47, _dec48, _dec49, _dec50, _dec51, _dec52, _dec53, _dec54, _dec55, _dec56, _dec57, _dec58, _dec59, _dec60, _dec61, _dec62, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38, _descriptor39, _descriptor40, _descriptor41, _descriptor42, _descriptor43, _descriptor44, _descriptor45, _descriptor46, _descriptor47, _descriptor48, _descriptor49, _descriptor50, _descriptor51, _descriptor52, _descriptor53, _descriptor54, _descriptor55, _descriptor56, _descriptor57, _descriptor58, _descriptor59, _descriptor60, _descriptor61, _descriptor62;

  let Clientprofile = (_dec = (0, _model.attr)('number'), _dec2 = (0, _model.hasMany)('frequentflyernumber', {
    inverse: null
  }), _dec3 = (0, _model.hasMany)('specialservicerequest', {
    inverse: null
  }), _dec4 = (0, _model.hasMany)('profiledocument', {
    inverse: null
  }), _dec5 = (0, _model.hasMany)('profilenoteitem', {
    inverse: null
  }), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('datetime'), _dec10 = (0, _model.attr)('datetime'), _dec11 = (0, _model.belongsTo)('account', {
    inverse: null
  }), _dec12 = (0, _model.belongsTo)('account', {
    inverse: null
  }), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('boolean'), _dec16 = (0, _model.attr)('boolean'), _dec17 = (0, _model.attr)('boolean'), _dec18 = (0, _model.attr)('boolean'), _dec19 = (0, _model.attr)('boolean'), _dec20 = (0, _model.attr)('boolean'), _dec21 = (0, _model.attr)('boolean'), _dec22 = (0, _model.attr)('string'), _dec23 = (0, _model.attr)('string'), _dec24 = (0, _model.attr)('string'), _dec25 = (0, _model.attr)('dateonly'), _dec26 = (0, _model.belongsTo)('country', {
    inverse: null
  }), _dec27 = (0, _model.belongsTo)('country', {
    inverse: null
  }), _dec28 = (0, _model.belongsTo)('country', {
    inverse: null
  }), _dec29 = (0, _model.attr)('string'), _dec30 = (0, _model.belongsTo)('airport', {
    inverse: null
  }), _dec31 = (0, _model.attr)('string'), _dec32 = (0, _model.attr)('string'), _dec33 = (0, _model.attr)('string'), _dec34 = (0, _model.attr)('string'), _dec35 = (0, _model.attr)('string'), _dec36 = (0, _model.belongsTo)('trainstation', {
    inverse: null
  }), _dec37 = (0, _model.attr)('string'), _dec38 = (0, _model.attr)('string'), _dec39 = (0, _model.attr)('string'), _dec40 = (0, _model.attr)('string'), _dec41 = (0, _model.attr)('string'), _dec42 = (0, _model.attr)('string'), _dec43 = (0, _model.attr)('string'), _dec44 = (0, _model.attr)('string'), _dec45 = (0, _model.attr)('string'), _dec46 = (0, _model.attr)('string'), _dec47 = (0, _model.attr)('string'), _dec48 = (0, _model.hasMany)('clientprofilepermission', {
    inverse: null
  }), _dec49 = (0, _model.hasMany)('clientprofilepermission', {
    inverse: null
  }), _dec50 = (0, _model.attr)('string'), _dec51 = (0, _model.attr)('string'), _dec52 = (0, _model.attr)('string'), _dec53 = (0, _model.attr)('string'), _dec54 = (0, _model.attr)('string'), _dec55 = (0, _model.attr)('string'), _dec56 = (0, _model.attr)('string'), _dec57 = (0, _model.attr)('boolean'), _dec58 = (0, _model.attr)('boolean'), _dec59 = (0, _model.belongsTo)('companytravelpolicy', {
    inverse: null
  }), _dec60 = (0, _model.attr)('string'), _dec61 = (0, _model.attr)('number'), _dec62 = (0, _model.attr)('string'), (_class = class Clientprofile extends _model.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "version", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "frequentFlyerNumbers", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "specialServiceRequests", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "documents", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "profileNoteItems", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "emergencyContactName", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "emergencyContactPhone", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "emergencyContactEmail", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "createdOn", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "modifiedOn", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "defaultAccount", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "accountsDefaultAccount", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "emergencyContactRelationship", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "emergencyContactRelationshipLabel", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "traveller", _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, "travelApprover", _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, "travelArranger", _descriptor17, this);
      (0, _initializerDefineProperty2.default)(this, "travelCoordinator", _descriptor18, this);
      (0, _initializerDefineProperty2.default)(this, "accountsRole", _descriptor19, this);
      (0, _initializerDefineProperty2.default)(this, "misRole", _descriptor20, this);
      (0, _initializerDefineProperty2.default)(this, "obtPermitted", _descriptor21, this);
      (0, _initializerDefineProperty2.default)(this, "tier", _descriptor22, this);
      (0, _initializerDefineProperty2.default)(this, "tierLabel", _descriptor23, this);
      (0, _initializerDefineProperty2.default)(this, "tsaRedressNumber", _descriptor24, this);
      (0, _initializerDefineProperty2.default)(this, "tsaRedressIssueDate", _descriptor25, this);
      (0, _initializerDefineProperty2.default)(this, "tsaRedressCountry", _descriptor26, this);
      (0, _initializerDefineProperty2.default)(this, "countryOfBirth", _descriptor27, this);
      (0, _initializerDefineProperty2.default)(this, "nationality", _descriptor28, this);
      (0, _initializerDefineProperty2.default)(this, "keycloakUserId", _descriptor29, this);
      (0, _initializerDefineProperty2.default)(this, "airPrefDepartureAirport", _descriptor30, this);
      (0, _initializerDefineProperty2.default)(this, "seatPreference", _descriptor31, this);
      (0, _initializerDefineProperty2.default)(this, "seatPreferenceLabel", _descriptor32, this);
      (0, _initializerDefineProperty2.default)(this, "mealPreference", _descriptor33, this);
      (0, _initializerDefineProperty2.default)(this, "mealPreferenceLabel", _descriptor34, this);
      (0, _initializerDefineProperty2.default)(this, "airSpecialRequests", _descriptor35, this);
      (0, _initializerDefineProperty2.default)(this, "railPrefDepartureStation", _descriptor36, this);
      (0, _initializerDefineProperty2.default)(this, "railSeatPreference", _descriptor37, this);
      (0, _initializerDefineProperty2.default)(this, "railSeatPreferenceLabel", _descriptor38, this);
      (0, _initializerDefineProperty2.default)(this, "railSeatDirection", _descriptor39, this);
      (0, _initializerDefineProperty2.default)(this, "railSeatDirectionLabel", _descriptor40, this);
      (0, _initializerDefineProperty2.default)(this, "railSeatLocation", _descriptor41, this);
      (0, _initializerDefineProperty2.default)(this, "railSeatLocationLabel", _descriptor42, this);
      (0, _initializerDefineProperty2.default)(this, "railSpecialRequests", _descriptor43, this);
      (0, _initializerDefineProperty2.default)(this, "carHirePrefTransmission", _descriptor44, this);
      (0, _initializerDefineProperty2.default)(this, "carHirePrefTransmissionLabel", _descriptor45, this);
      (0, _initializerDefineProperty2.default)(this, "carHireSpecialRequests", _descriptor46, this);
      (0, _initializerDefineProperty2.default)(this, "accommodationSpecialRequests", _descriptor47, this);
      (0, _initializerDefineProperty2.default)(this, "approvers", _descriptor48, this);
      (0, _initializerDefineProperty2.default)(this, "arrangers", _descriptor49, this);
      (0, _initializerDefineProperty2.default)(this, "customValue1", _descriptor50, this);
      (0, _initializerDefineProperty2.default)(this, "customValue2", _descriptor51, this);
      (0, _initializerDefineProperty2.default)(this, "customValue3", _descriptor52, this);
      (0, _initializerDefineProperty2.default)(this, "customValue4", _descriptor53, this);
      (0, _initializerDefineProperty2.default)(this, "customValue5", _descriptor54, this);
      (0, _initializerDefineProperty2.default)(this, "customValue6", _descriptor55, this);
      (0, _initializerDefineProperty2.default)(this, "customValue7", _descriptor56, this);
      (0, _initializerDefineProperty2.default)(this, "gdprAccepted", _descriptor57, this);
      (0, _initializerDefineProperty2.default)(this, "additionalConditionsAccepted", _descriptor58, this);
      (0, _initializerDefineProperty2.default)(this, "travelPolicy", _descriptor59, this);
      (0, _initializerDefineProperty2.default)(this, "scope", _descriptor60, this);
      (0, _initializerDefineProperty2.default)(this, "scopeLevel", _descriptor61, this);
      (0, _initializerDefineProperty2.default)(this, "accountsScope", _descriptor62, this);
    }

    // createdBy: DS.attr(),
    // modifiedBy: DS.attr(),
    // profileExports: DS.attr(),
    //@computed('traveller', 'travelApprover', 'travelArranger', 'accountsRole')
    get clientRoles() {
      let roles = [];

      if (this.traveller === true) {
        roles.pushObject('TRV');
      }

      if (this.travelApprover === true) {
        roles.pushObject('APP');
      }

      if (this.travelArranger === true) {
        roles.pushObject('ARR');
      }

      if (this.accountsRole === true) {
        roles.pushObject('ACC');
      }

      return roles;
    } //Extract list of passports from all documents for use in New Enquiry Wizard
    //@computed('documents')


    get passports() {
      return this.documents.filter(item => item.get('documentType') === 'PASSPORT');
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "version", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "frequentFlyerNumbers", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "specialServiceRequests", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "documents", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "profileNoteItems", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "emergencyContactName", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "emergencyContactPhone", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "emergencyContactEmail", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "createdOn", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "modifiedOn", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "defaultAccount", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "accountsDefaultAccount", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "emergencyContactRelationship", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "emergencyContactRelationshipLabel", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "traveller", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "travelApprover", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "travelArranger", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "travelCoordinator", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "accountsRole", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "misRole", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "obtPermitted", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tier", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tierLabel", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tsaRedressNumber", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tsaRedressIssueDate", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tsaRedressCountry", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "countryOfBirth", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "nationality", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "keycloakUserId", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "airPrefDepartureAirport", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "seatPreference", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "seatPreferenceLabel", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "mealPreference", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "mealPreferenceLabel", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "airSpecialRequests", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "railPrefDepartureStation", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "railSeatPreference", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor38 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "railSeatPreferenceLabel", [_dec38], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor39 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "railSeatDirection", [_dec39], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor40 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "railSeatDirectionLabel", [_dec40], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor41 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "railSeatLocation", [_dec41], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor42 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "railSeatLocationLabel", [_dec42], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor43 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "railSpecialRequests", [_dec43], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor44 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "carHirePrefTransmission", [_dec44], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor45 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "carHirePrefTransmissionLabel", [_dec45], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor46 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "carHireSpecialRequests", [_dec46], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor47 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "accommodationSpecialRequests", [_dec47], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor48 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "approvers", [_dec48], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor49 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "arrangers", [_dec49], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor50 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customValue1", [_dec50], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor51 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customValue2", [_dec51], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor52 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customValue3", [_dec52], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor53 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customValue4", [_dec53], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor54 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customValue5", [_dec54], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor55 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customValue6", [_dec55], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor56 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customValue7", [_dec56], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor57 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "gdprAccepted", [_dec57], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor58 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "additionalConditionsAccepted", [_dec58], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor59 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "travelPolicy", [_dec59], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor60 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "scope", [_dec60], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor61 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "scopeLevel", [_dec61], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor62 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "accountsScope", [_dec62], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Clientprofile;
});