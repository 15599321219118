define("clientui/pods/components/pages/page-customeraccounts/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  let PagesPageCustomeraccounts = (_dec = Ember.inject.service('emt-helper'), _dec2 = Ember.inject.service('system'), _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, (_class = class PagesPageCustomeraccounts extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _initializerDefineProperty2.default)(this, "emthelper", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "creditLimit", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "selectedAccount", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "onlyOneAccount", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "fops", _descriptor6, this);
      (0, _defineProperty2.default)(this, "creditLimitColumns", [this.emthelper.buildStringCol("name", "Account Name"), this.emthelper.buildStringCol("fopAndLimit", "Credit Limit/Days")]);
      this.args.accounts.forEach(account => {
        switch (account.fop) {
          case "CASH":
            this.fops.cash = true;
            break;

          case "DD":
            this.fops.dd = true;
            break;

          case "CR":
            this.fops.cr = true;
            break;

          case "LC":
            this.fops.lc = true;
            break;

          default:
            //do nothing
            break;
        }
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "emthelper", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "creditLimit", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedAccount", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onlyOneAccount", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fops", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        cash: false,
        dd: false,
        cr: false,
        lc: false
      };
    }
  })), _class));
  _exports.default = PagesPageCustomeraccounts;
});