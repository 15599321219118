define("clientui/pods/components/widgets/form-save-buttons/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let FormSubmitButtonEdit = (_dec = Ember.inject.service('keycloak-session'), _dec2 = Ember.inject.service('system'), _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class FormSubmitButtonEdit extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "savedisabled", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "backdisabled", _descriptor4, this);
    }

    get backbuttonlabel() {
      return this.args.backbuttonlabel || 'BACK';
    }

    get savebuttonlabel() {
      return this.args.savebuttonlabel || 'Save';
    }

    get internalsavedisabled() {
      return this.savedisabled || this.system.saving;
    }

    get internalbackdisabled() {
      return this.backdisabled || this.system.saving;
    }

    willDestroy() {
      if (this._debounced) {
        Ember.run.cancel(this._debounced);
      }
    }

    clickBack() {
      this.savedisabled = true;
      this.backdisabled = true;
      this.args.onBack();
      this._debounced = Ember.run.later(this, () => {
        this.savedisabled = false;
        this.backdisabled = false;
      }, 500);
    }

    clickSubmit() {
      this.savedisabled = true;
      this.backdisabled = true;
      this.args.onSubmit();
      this._debounced = Ember.run.later(this, () => {
        this.savedisabled = false;
        this.backdisabled = false;
      }, 500);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "savedisabled", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "backdisabled", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "clickBack", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "clickBack"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "clickSubmit", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "clickSubmit"), _class.prototype)), _class));
  _exports.default = FormSubmitButtonEdit;
});