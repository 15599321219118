define("clientui/pods/components/ember-model-table/total-money-inc-zero/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QqXQmTtm",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[[35,0]],[[\"precision\"],[2]]]]],\"hasEval\":false,\"upvars\":[\"sumData\",\"format-number\"]}",
    "moduleName": "clientui/pods/components/ember-model-table/total-money-inc-zero/template.hbs"
  });

  _exports.default = _default;
});