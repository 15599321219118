define("clientui/mappings/serializers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   *This is a simple map which identifies which serializer should be used for each model.
   *If a model is not mentioned here then a serializer with the same name as the model is
   *tried (in /serializers), finally application.serializer is used.
   */
  var _default = {
    //model_name : serializer_name
    //'clientportallink': 'pageablelist',
    'account': 'pageablelist',
    'bookingcategory': 'pageablelist',
    'bookingreference': 'pageablelist',
    'clientinvoicevatdetail': 'pageablelist',
    'contact': 'pageablelist',
    'currency': 'pageablelist',
    'shortaccount': 'pageablelist',
    'shortbooking': 'pageablelist',
    'faqcategory': 'pageablelist',
    'invoicelinecategory': 'pageablelist',
    'lodgement': 'pageablelist',
    'person': 'pageablelist',
    'statementobject': 'pageablelist',
    'ticket': 'pageablelist',
    'transtype': 'pageablelist'
  };
  _exports.default = _default;
});