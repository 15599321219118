define("clientui/pods/components/widgets/login-logout/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let LoginLogout = (_dec = Ember.inject.service('keycloak-session'), _dec2 = Ember.inject.service, _dec3 = Ember.computed('wasAuthenticated', 'session.authenticated'), _dec4 = Ember.computed('authenticated'), _dec5 = Ember._action, classic(_class = (_class2 = class LoginLogout extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor2, this);
      (0, _defineProperty2.default)(this, "wasAuthenticated", false);
    }

    didReceiveAttrs() {
      //console.log("Setting wasAuthenticated to " + this.session.get('authenticated'));
      this.set('wasAuthenticated', this.session.get('authenticated'));
    }

    get authenticated() {
      //console.log("Computed: WasAuthenticated: " + this.wasAuthenticated + "  session.authenticated is " + this.session.get('authenticated'));
      return this.get('session.authenticated') || this.wasAuthenticated;
    }

    get mayImpersonate() {
      return this.session.hasResourceRole('impersonation', 'realm-management');
    }

    login(e) {
      e.preventDefault();
      console.log(e);

      if (e.shiftKey) {
        this.router.transitionTo("login2");
      } else {
        this.router.transitionTo("login");
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "authenticated", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "authenticated"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "mayImpersonate", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "mayImpersonate"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "login", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "login"), _class2.prototype)), _class2)) || _class);
  _exports.default = LoginLogout;
});