define("clientui/pods/components/dialogs/form-profile-edit-preferences/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-concurrency"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let FormProfileEditPreferences = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('system'), _dec3 = Ember.computed('page', 'lastPage'), _dec4 = (0, _emberConcurrency.task)(function* (term) {
    if (term.length < 3) {
      return;
    }

    yield (0, _emberConcurrency.timeout)(250);
    return this.store.query('airport', {
      'pno': -1,
      'search': term
    });
  }).restartable(), _dec5 = (0, _emberConcurrency.task)(function* (term) {
    if (term.length < 3) {
      return;
    }

    yield (0, _emberConcurrency.timeout)(250);
    return this.store.query('trainstation', {
      'pno': -1,
      'search': term
    });
  }).restartable(), _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, classic(_class = (_class2 = class FormProfileEditPreferences extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
      (0, _defineProperty2.default)(this, "page", 1);
      (0, _defineProperty2.default)(this, "lastPage", 1);
      (0, _defineProperty2.default)(this, "hasAir", false);
      (0, _defineProperty2.default)(this, "hasRail", false);
      (0, _defineProperty2.default)(this, "hasCarHire", false);
      (0, _defineProperty2.default)(this, "hasAccommodation", false);
      (0, _defineProperty2.default)(this, "airPage", 0);
      (0, _defineProperty2.default)(this, "railPage", 0);
      (0, _defineProperty2.default)(this, "carHirePage", 0);
      (0, _defineProperty2.default)(this, "accommodationPage", 0);
      (0, _initializerDefineProperty2.default)(this, "airportSearch", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "trainStationSearch", _descriptor4, this);
    }

    init() {
      super.init(...arguments); //console.log('Init Create Profile Wizard');
    }

    didReceiveAttrs() {
      this.set('seatpreferences', this.system.enum('seatpreference'));
      this.set('mealpreferences', this.system.enum('mealpreference'));
      this.set('carhiretransmissions', this.system.enum('carhiretransmission'));
      this.set('departureairports', this.store.query('airport', {
        'pno': -1,
        'initialdeparture': 'true',
        'existingid': this.get('client.clientProfile.airPrefDepartureAirport.id')
      }));
      this.set('departurestations', this.store.query('trainstation', {
        'pno': -1,
        'initialdeparture': 'true',
        'existingid': this.get('client.clientProfile.railPrefDepartureStation.id')
      }));
      this.set('railseatpreferences', this.system.enum('railseatpreference'));
      this.set('railseatdirections', this.system.enum('railseatdirection'));
      this.set('railseatlocations', this.system.enum('railseatlocation'));

      if (Ember.isPresent(this.get('client.clientProfile.airPrefDepartureAirport.id'))) {
        this.client.set('defaultDepartureAirport', this.get('client.clientProfile.airPrefDepartureAirport.id'));
      }

      if (Ember.isPresent(this.get('client.clientProfile.railPrefDepartureStation.id'))) {
        this.client.set('defaultDepartureStation', this.get('client.clientProfile.railPrefDepartureStation.id'));
      }

      this.initialiseFormFields(this.client);
      this.buildPages(); //Reset to page 1

      this.set('page', 1);
      this.set('pageActive', false);
      this.system.set('saving', false);
    }

    buildPages() {
      let portal = this.clientportal;
      let nextPage = 1;

      if (portal.get('usingAirPreferences') === true) {
        this.set('airPage', nextPage);
        this.set('hasAir', true);
        nextPage++;
      }

      if (portal.get('usingRailPreferences') === true) {
        this.set('railPage', nextPage);
        this.set('hasRail', true);
        nextPage++;
      }

      if (portal.get('usingCarHirePreferences') === true) {
        this.set('carHirePage', nextPage);
        this.set('hasCarHire', true);
        nextPage++;
      }

      if (portal.get('usingAccommodationPreferences') === true) {
        this.set('accommodationPage', nextPage);
        this.set('hasAccommodation', true);
        nextPage++;
      }

      this.set('lastPage', nextPage - 1);
    }

    initialiseFormFields(client) {
      let profile = client.get('clientProfile');
      client.set('airSeatPreference', profile.get('seatPreference'));
      client.set('airMealPreference', profile.get('mealPreference'));
      client.set('airSpecialRequests', profile.get('airSpecialRequests'));
      client.set('railSeatPreference', profile.get('railSeatPreference'));
      client.set('railSeatDirection', profile.get('railSeatDirection'));
      client.set('railSeatLocation', profile.get('railSeatLocation'));
      client.set('railSpecialRequests', profile.get('railSpecialRequests'));
      client.set('carHirePrefTransmission', profile.get('carHirePrefTransmission'));
      client.set('carHireSpecialRequests', profile.get('carHireSpecialRequests'));
      client.set('accommodationSpecialRequests', profile.get('accommodationSpecialRequests'));
    }

    fixupNewClient(client) {
      let profile = client.get('clientProfile');
      profile.set('seatPreference', client.get('airSeatPreference'));
      profile.set('mealPreference', client.get('airMealPreference'));
      profile.set('airSpecialRequests', client.get('airSpecialRequests'));
      profile.set('railSeatPreference', client.get('railSeatPreference'));
      profile.set('railSeatDirection', client.get('railSeatDirection'));
      profile.set('railSeatLocation', client.get('railSeatLocation'));
      profile.set('railSpecialRequests', client.get('railSpecialRequests'));
      profile.set('carHirePrefTransmission', client.get('carHirePrefTransmission'));
      profile.set('carHireSpecialRequests', client.get('carHireSpecialRequests'));
      profile.set('accommodationSpecialRequests', client.get('accommodationSpecialRequests'));
    }

    get isLastPage() {
      return this.page === this.lastPage;
    }

    activate(active) {
      if (active) {
        this.set('pageActive', true);
      } else {
        this.set('pageActive', false);
      }
    }

    next() {
      let page = this.page + 1;

      if (page > this.lastPage) {
        page = this.lastPage;
      }

      this.set('page', page);
    }

    prev() {
      let page = this.page - 1;

      if (page < 1) {
        page = 1;
      }

      this.set('page', page);
    }

    gotoPage(pageNo) {
      if (!this.pageActive) {
        this.set('page', pageNo);
      }
    }

    save() {
      this.system.set('saving', true);
      console.log("starting SAVE");
      let client = this.client;
      client.validate().then(() => {
        if (client.get("isValid")) {
          let fieldName = null;
          let selectionId = 0;
          let model = null;
          let promises = {};

          if (Ember.isPresent(this.get('client.defaultDepartureAirport'))) {
            fieldName = 'clientProfile.airPrefDepartureAirport';
            model = 'airport';
            selectionId = this.get('client.defaultDepartureAirport');
            promises[fieldName] = this.store.peekRecord(model, selectionId);
          }

          if (Ember.isPresent(this.get('client.defaultDepartureStation'))) {
            fieldName = 'clientProfile.railPrefDepartureStation';
            model = 'trainstation';
            selectionId = this.get('client.defaultDepartureStation');
            promises[fieldName] = this.store.peekRecord(model, selectionId);
          }

          let deletedFF = this.get('client.clientProfile.frequentFlyerNumbers').filter(item => item.get('deleted') === true);
          deletedFF.forEach(item => {
            this.get('client.clientProfile.frequentFlyerNumbers').removeObject(item);
          }); //move all non-client fields into their appropriate places

          this.fixupNewClient(client);
          Ember.RSVP.hash(promises).then(item => {
            Object.keys(item).forEach(key => {
              client.set(key, item[key]);
            });
            this.onSave(client).then(() => {
              // Promise
              console.log('ClientName Promise Saved Successfully in Component');
              deletedFF.forEach(item => {
                this.store.unloadRecord(item);
              });
              this.onClose(); //this.onRefreshRoute();

              this.system.set('saving', false);
            }).catch(errors => {
              console.log('ClientName Promise Failed to Save in Component');
              console.log(errors);
              this.set('servererrors', errors);
              this.system.set('saving', false);
            });
          }, errors => {
            console.log('ClientName Promise Failed to Save in Component - unable to find ' + model + ' with id ' + selectionId);
            console.log(errors);
            this.set('servererrors', errors);
            this.system.set('saving', false);
          });
        } else {
          this.system.set('saving', false);
        }
      }, () => {
        this.system.set('saving', false);
      });
    }

    back() {
      // if (this.get('page') === 1) {
      //Rollback any changes
      this.get('client.clientProfile.frequentFlyerNumbers').forEach(item => {
        if (Ember.isPresent(item.get('originalNumber'))) {
          item.set('membershipNumber', item.get('originalNumber'));
        }

        item.set('editing', undefined);
        item.set('deleting', undefined);
        item.set('deleted', undefined);
      });
      let newItems = this.get('client.clientProfile.frequentFlyerNumbers').filter(item => item.get('isNew'));
      newItems.forEach(item => {
        this.get('client.clientProfile.frequentFlyerNumbers').removeObject(item);
        this.store.unloadRecord(item);
      });
      this.client.rollback();

      this.client._content._internalModel._record.rollbackAttributes(); //Close dialog


      this.onClose();
      this.onRollback(); // }
      // else {
      //   this.set('page', this.get('page') - 1);
      //   // this.initBootstrapMaterial();
      // }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isLastPage", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "isLastPage"), _class2.prototype), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "airportSearch", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "trainStationSearch", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "activate", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "activate"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "next", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "next"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "prev", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "prev"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "gotoPage", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "gotoPage"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "save", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "back", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "back"), _class2.prototype)), _class2)) || _class);
  _exports.default = FormProfileEditPreferences;
});