define("clientui/services/emt-component-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class EmtComponentHelperService extends Ember.Service {
    sendSingleSelectAction(thisInstance) {
      const actionFieldName = 'emt_action'; //this must match the same constant in emthelper-base
      //Send a selectAction if the user selects a row if multipleSelect is false

      let selectAction = thisInstance.selectAction;
      let selectActionIsFunction = typeof selectAction === 'function';

      if (selectActionIsFunction) {
        let selectedItems = thisInstance.selectedItems;

        if (selectedItems && thisInstance.selectRowOnClick && !thisInstance.multipleSelect) {
          if (selectedItems.length == 1) {
            let item = selectedItems[0];
            console.log("SelectedItem", item);
            selectedItems.length = 0; //clear selectedItems immediately to be ready for next click

            let itemId = Ember.get(item, 'id');
            let actionName = Ember.get(item, actionFieldName);

            if (actionName) {
              console.log("actionName", actionName);
              Ember.set(item, actionFieldName, null); //clear action from record to prevent repetition
            }

            if (itemId) {
              console.log("itemId", itemId);
              console.log("Calling selectAction", itemId, actionName, item);
              selectAction(itemId, actionName, item);
            }
          }
        }
      }
    }

    _navHasChanged(newNav, prevNav) {
      let result = false;

      if (newNav) {
        if (!prevNav) {
          result = true;
        } else {
          result = prevNav.currentPageNumber != newNav.currentPageNumber;
          result = result ? result : prevNav.pageSize != newNav.pageSize;
          result = result ? result : prevNav.sortItem != newNav.sortItem;
          result = result ? result : prevNav.sortField != newNav.sortField;
          result = result ? result : prevNav.sortDir != newNav.sortDir;
        }
      }

      return result;
    } //This action should only be called in the context of a server-paginated table. It has no meaning in a simple/client-paginated table


    sendNavigationAction(thisInstance) {
      //Send a navigationAction if the currentpageNumber, pageSize or sortProperties have changed
      let navigationAction = thisInstance.dataNavigationAction;
      let navigationActionIsFunction = typeof navigationAction === 'function';

      if (navigationActionIsFunction) {
        let sortItem, sortField, sortDir;

        if (!Ember.isEmpty(thisInstance.sortProperties)) {
          sortItem = thisInstance.sortProperties[0]; //console.log("sortItem", sortItem);

          let s = sortItem.split(":");

          if (s.length == 2) {
            sortField = s[0];
            sortDir = s[1];
          }
        }

        let navResult = {
          //build result object
          currentPageNumber: thisInstance.currentPageNumber,
          pageSize: thisInstance.pageSize,
          //sort: thisInstance.get('sortProperties'), //full array of sort properties (strings in the form field:direction)
          sortItem: sortItem,
          //First item of the sortProperties array
          sortField: sortField,
          //FieldName of first item of the sortProperties array
          sortDir: sortDir //SortDirection of first item of the sortProperties array (asc or desc)

        };

        if (this._navHasChanged(navResult, thisInstance._prevNav)) {
          navigationAction(navResult);
          Ember.set(thisInstance, '_prevNav', navResult);
        }
      }
    }

    _unNull(o) {
      return o ? o : '';
    }

    _buildColKey(cols) {
      let result = '';
      cols.forEach(col => {
        result += '|' + this._unNull(col.propertyName) + '.' + this._unNull(col.title) + '.' + this._unNull(col.filteredBy) + '.' + this._unNull(col.className) + '.' + this._unNull(col.component);
      });
      return result;
    }

    _columnsHaveChanged(thisInstance) {
      let oldCols = thisInstance._prevCols;
      let newCols = thisInstance.columns;
      let result = false; //defaults to no change

      if (!oldCols) {
        result = true;
      } else if (oldCols.length != newCols.length) {
        result = true;
      } else {
        let oldKey = this._buildColKey(oldCols);

        let newKey = this._buildColKey(newCols);

        result = oldKey != newKey;
      }

      if (result) {
        Ember.set(thisInstance, '_prevCols', newCols);
      }

      return result;
    }

    _setupColumns(thisInstance) {
      if (thisInstance.columnsAreUpdateable && this._columnsHaveChanged(thisInstance)) {
        thisInstance._setupColumns(); //Call original _setupColumns from EMT

      }
    }
    /**
     * Component init
     *
     * Set visibility and filtering attributes for each column
     *
     * This is called from within super.init(...arguments) called from the componenet init()
     * This is a direct copy from ember-models-table.setup() except that there is a check for null/undefined on recordsCount
     * This throws an error on initialisation using the original code
     *
     * @method setup
     * @protected
     */


    _setup(thisInstance) {
      thisInstance._setupSelectedRows();

      thisInstance._setupColumns();

      thisInstance._setupPageSizeOptions();
      /* eslint-disable ember/no-observers */


      if (thisInstance.columnsAreUpdateable) {
        (true && !('array' === Ember.typeOf(thisInstance.columnFieldsToCheckUpdate)) && Ember.assert('`columnFieldsToCheckUpdate` should be an array of strings', 'array' === Ember.typeOf(thisInstance.columnFieldsToCheckUpdate)));
        thisInstance.columnFieldsToCheckUpdate.forEach(propertyName => thisInstance.addObserver(`columns.@each.${propertyName}`, thisInstance, thisInstance._setupColumnsOnce));
      }

      thisInstance.addObserver('visibleContent.length', thisInstance, thisInstance.visibleContentObserver);
      thisInstance.addObserver('filteredContent.length', thisInstance, thisInstance.filteredContentObserver);
      /* eslint-enable ember/no-observers */

      Ember.set(thisInstance, 'publicAPI', {});
      thisInstance.updateState({
        recordsCount: thisInstance.filteredContent ? thisInstance.filteredContent.length || 0 : 0,
        refilter: thisInstance.refilter.bind(thisInstance)
      });
    }

  }

  _exports.default = EmtComponentHelperService;

  window.__CLASSIC_OWN_CLASSES__.set(EmtComponentHelperService, true);
});