define("clientui/pods/components/widgets/single-item-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mmzWQZDq",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"card-body p-2\"],[12],[2,\"\\n  \"],[10,\"p\"],[14,0,\"card-text mb-1\"],[12],[2,\"\\n    \"],[10,\"small\"],[14,0,\"text-muted\"],[12],[2,\"\\n      \"],[1,[34,1]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"widgets/help-icon\",[],[[\"@title\",\"@helpkey\",\"@manualHelpText\"],[[34,1],[34,2],[34,3]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[14,0,\"card-text\"],[12],[2,\"\\n    \"],[1,[30,[36,5],[[35,4],[35,4],\"-\"],null]],[2,\"\\n\"],[6,[37,5],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"badge btn-warning badge-pill\"],[12],[2,\"\\n        \"],[1,[34,0]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"warning\",\"caption\",\"helpkey\",\"manualHelpText\",\"value\",\"if\"]}",
    "moduleName": "clientui/pods/components/widgets/single-item-card/template.hbs"
  });

  _exports.default = _default;
});