define("clientui/pods/login/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "clientui/superclasses/authenticated-route"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  let LoginRoute = (_dec = Ember.inject.service('keycloak-session'), _dec2 = Ember.inject.service('system'), (_class = class LoginRoute extends _authenticatedRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
    }

    model() {
      let guid = this.session.subject;
      let email = null;
      let firstName = null;
      let surname = null;

      if (this.session.authenticated) {
        email = this.session.tokenParsed.email;
        firstName = this.session.tokenParsed.given_name;
        surname = this.session.tokenParsed.family_name;
      }

      let clientportal = this.system.clientportal;
      let clientportalId = null;

      if (clientportal) {
        clientportalId = clientportal.get('tradeClientProfileId');
      }

      return this.store.query('client', {
        'tcpid': clientportalId,
        'guid': guid,
        'email': email,
        'kcfirstname': firstName,
        'kcsurname': surname,
        'testmultierror': 'false',
        'pno': -1
      }).then(clients => {
        return clients.get('firstObject');
      }, errors => {
        console.log(errors);
        throw errors;
      });
    }

    afterModel(client
    /* , transition */
    ) {
      console.log("Logged In Client", client);

      if (client.get('dbId') === 0) {
        console.log("transitioning to newprofile from login in afterModel as dbId is " + client.get('dbId'));
        this.transitionTo('newprofile');
      } else if (client.get('clientProfile.obtPermitted') && client.get('hasMissingObtFields')) {
        console.log("transitioning to myprofile from login in afterModel as hasMissingObtFields is true");
        this.transitionTo('myprofile'); //Change to 'required fields' editor when written
      } else {
        //console.log("transitioning to application from login in afterModel as dbId is " + client.get('dbId'));
        this.transitionTo('application');
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = LoginRoute;
});