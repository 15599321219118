define("clientui/pods/downloadabledocuments/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DownloadabledocumentsController extends Ember.Controller {
    get downloadabledocumentSettings() {
      return {
        routePlural: 'downloadabledocuments',
        routeSingular: 'downloadabledocument',
        parentModelName: 'downloadabledocument'
      };
    }

  }

  _exports.default = DownloadabledocumentsController;

  window.__CLASSIC_OWN_CLASSES__.set(DownloadabledocumentsController, true);
});