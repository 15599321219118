define("clientui/pods/customeraccounts/bookings/booking/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "clientui/superclasses/authenticated-route", "file-saver"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _authenticatedRoute, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  let BookingRoute = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = class BookingRoute extends _authenticatedRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "fetch", _descriptor, this);
    }

    fetchBooking(bookingId) {
      return this.fetch.fetch('get', 'cors', 'bookings/' + bookingId).then(response => {
        if (response.ok) {
          return response.json().then(booking => {
            return booking;
          });
        } else {
          return null;
        }
      }, errors => {
        throw errors;
      });
    }

    fetchClientInvoices(bookingId) {
      return this.fetch.fetch('get', 'cors', 'clientinvoices/?bookingid=' + bookingId).then(response => {
        if (response.ok) {
          return response.json().then(clientInvoices => {
            return clientInvoices;
          });
        } else {
          return null;
        }
      }, errors => {
        throw errors;
      });
    }

    model(params) {
      let promises = {};
      promises['booking'] = this.store.findRecord('booking', params.booking_id); // promises['clientInvoices'] = this.fetchClientInvoices(params.booking_id);

      promises['clientInvoices'] = this.store.query("clientinvoice", {
        bookingid: params.booking_id,
        sort: "clientInvoiceIdentifier",
        sortdir: "asc"
      });
      return Ember.RSVP.hash(promises);
    }

    setupController(controller, promises) {
      controller.set('booking', promises['booking']);
      controller.set('clientInvoices', promises['clientInvoices']);
    }

    downloadticket(pnrRef, tktno) {
      var postData = {
        ticketnumbers: String(tktno),
        includeheader: true,
        includebookingref: false,
        onedocumentperticker: true
      }; // let fetchUrl = 'etickets/' + pnrRef + '/docs';
      // return this.fetch.fetchAndDownloadFile(fetchUrl, null, null, 'post', postData, null).then(() => {
      // }, (errors) => {
      //   console.log("errors in promise", errors);
      //   this.toast.error('Unable To Download Ticket');
      // });

      return this.fetch.fetch('post', 'cors', 'etickets/' + pnrRef + '/docs', postData).then(response => {
        if (response.ok) {
          return response.blob().then(blob => {
            let fileName;
            let mimeType; //Override fileName using data from response headers if available

            let cd = response.headers.get('content-disposition'); //Content-Disposition: attachment; filename="example.pdf"

            if (cd) {
              let regex = /filename\s*=\s*"(?<filename>[^"]+)"/;
              let match = regex.exec(cd);
              let fn = match.groups.filename;

              if (fn) {
                fileName = fn;
              }
            } //Override mimeType using data from response headers if available


            let ct = response.headers.get('content-type'); //Content-Type: application/pdf

            if (ct) {
              mimeType = ct;
            }

            _fileSaver.default.saveAs(new Blob([blob], {
              type: mimeType
            }), fileName);
          });
        } else {
          return response.text().then(message => {
            this.generalErrorMessage = message;
            console.error(message);
            return response.ok;
          });
        }
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "downloadticket", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "downloadticket"), _class.prototype)), _class));
  _exports.default = BookingRoute;
});