define("clientui/pods/components/layout/page-footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QrqVfMxD",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,1,\"footer\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row justify-content-between m-0 p-0\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col\"],[12],[2,\"\\n        Copyright © \"],[1,[32,0,[\"system\",\"currentYear\"]]],[2,\" \"],[1,[35,0,[\"ourCompanyName\"]]],[2,\"\\n        \"],[10,\"br\"],[12],[13],[2,\"\\n        All Rights Reserved.\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col text-right m-0 p-0\"],[12],[2,\"\\n\"],[6,[37,1],[[35,0,[\"ourCroNumber\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          Comp Reg No \"],[1,[35,0,[\"ourCroNumber\"]]],[10,\"br\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[35,0,[\"ourCompanyName\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[1,[35,0,[\"ourCompanyName\"]]],[10,\"br\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[35,0,[\"ourTradingName\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          Trading as \"],[1,[35,0,[\"ourTradingName\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"clientportal\",\"if\",\"isTopWindow\"]}",
    "moduleName": "clientui/pods/components/layout/page-footer/template.hbs"
  });

  _exports.default = _default;
});