define("clientui/services/fetch", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "fetch", "clientui/config/environment", "file-saver"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _fetch, _environment, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  /**
   * Using ember-fetch allows us to have a promise-aware asynchroneous call to the server 
   * whereby headers are refreshed before attempting to the send the request to the server.
   * 
   * The main issue with the Ajax service was that the headers were not promise-aware and 
   * so the request was being sent before the headers were refreshed.
   * 
   * NB: This service should replace all calls to the ajax service.
   */
  let FetchService = (_dec = Ember.inject.service('keycloak-session'), _dec2 = Ember.inject.service, (_class = class FetchService extends Ember.Service {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
      (0, _defineProperty2.default)(this, "host", _environment.default.APP.JBOSS_URL + '/v1/');
    }

    /**
     * A promise that returns refreshed headers before sending the request to the server.
     */
    getHeaders() {
      return this.session.updateToken().then(() => {
        let keycloak = this.session.keycloak;
        var myHeaders = new _fetch.Headers();
        myHeaders.append("Authorization", `Bearer ${keycloak['token']}`);
        myHeaders.append("Content-Type", 'application/json; charset=utf-8');
        myHeaders.append('X-WTC', this.system.getWtcRefHeaderValue());
        myHeaders.append('X-WTC2', this.system.getWtcRef2HeaderValue());
        return myHeaders;
      });
    }
    /**
     * See ember-fetch for more details.
     * @param {*} method 'get', 'post', 'put'
     * @param {*} mode 'cors' 
     * @param {*} url url to execute
     * @param {*} data data to be send to the server. This is JSON.stringify(ed) before being sent to the server.
     */


    fetch(method, mode, url, data) {
      return this.getHeaders().then(headers => {
        //console.log(headers);
        return (0, _fetch.default)(this.host + url, {
          mode: mode,
          //credentials: 'include',
          method: method,
          headers: headers,
          body: JSON.stringify(data)
        });
      });
    }

    fetchAndDownloadFile(url, fileName, mimeType) {
      let host = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      let method = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 'get';
      let data = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : undefined;
      let signal = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : undefined;
      //fileName and mimeType MAY be null. Either way, if values exist in the download headers, then they are used in preference
      return this.fetch(method, 'cors', url, data, host, signal).then(response => {
        if (response.ok) {
          return response.blob().then(blob => {
            //Override fileName using data from response headers if available
            let cd = response.headers.get('content-disposition'); //Content-Disposition: attachment; filename="example.pdf"

            if (cd) {
              let regex = /filename\s*=\s*"(?<filename>[^"]+)"/;
              let match = regex.exec(cd);
              let fn = match.groups.filename;

              if (fn) {
                fileName = fn;
              }
            } //Override mimeType using data from response headers if available


            let ct = response.headers.get('content-type'); //Content-Type: application/pdf

            if (ct) {
              mimeType = ct;
            }

            _fileSaver.default.saveAs(new Blob([blob], {
              type: mimeType
            }), fileName);
          });
        } else {
          //response NOT ok
          return response.json().then(errors => {
            console.log("FileDownloadError", errors);
            throw errors;
          });
        }
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = FetchService;
});