define("clientui/pods/components/pages/page-customeraccounts/expanded-statement-component/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;

  let PagesPageCustomeraccountsExpandedStatementComponentComponent = (_dec = Ember.inject.service('emtsp-helper'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('system'), _dec4 = Ember._action, _dec5 = Ember._action, (_class = class PagesPageCustomeraccountsExpandedStatementComponentComponent extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _initializerDefineProperty2.default)(this, "emthelper", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor3, this);
    }

    get referenceColumns() {
      return [this.emthelper.buildStringCol("displayDescription", "Reference", null, null, null, {
        "sortDirection": "asc",
        "sortPrecedence": 1
      }), this.emthelper.buildStringCol("value", "Value")];
    }

    viewBooking(bookingId) {
      this.router.transitionTo('customeraccounts.bookings.booking', bookingId);
    }

    viewInvoice(invoiceId) {
      this.router.transitionTo('customeraccounts.clientinvoices.clientinvoice', invoiceId);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "emthelper", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "viewBooking", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "viewBooking"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "viewInvoice", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "viewInvoice"), _class.prototype)), _class));
  _exports.default = PagesPageCustomeraccountsExpandedStatementComponentComponent;
});