define("clientui/pods/coordinator/controller", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  let CoordinatorController = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._action, _dec13 = Ember._action, (_class = class CoordinatorController extends Ember.Controller {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "queryParams", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "accountid", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "tradeclientid", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "clientroletype", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "searchfield", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "pageno", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "psz", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "sort", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "sortdir", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "noneFound", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "clientroles", _descriptor11, this);
    }

    get formParams() {
      console.log("Getting formParams");
      let f = {}; //EmberObject.extend().create();
      // ['accountid',
      // 'tradeclientid',
      // 'clientroletype',
      // 'searchfield']

      this.queryParams.forEach(s => {
        let v = Ember.get(this, s);
        console.log("Setting formParams", s, v);
        Ember.set(f, s, v);
      });
      console.log("formParams", f);
      return f;
    }

    clearForm() {
      this.accountid = null;
      this.tradeclientid = null;
      this.clientroletype = null;
      this.searchfield = null;
    }

    dataNavigation(navData) {
      //Update page level variables to match whatever has happened in the table.
      console.log("Search Controller dataNavigation navData", navData);
      this.pageno = navData.currentPageNumber;
      this.psz = navData.pageSize;
      this.sort = navData.sortField;
      this.sortdir = navData.sortDir;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "queryParams", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return ['accountid', 'tradeclientid', 'clientroletype', 'searchfield', 'pageno', 'psz', 'sort', 'sortdir'];
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "accountid", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tradeclientid", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "clientroletype", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "searchfield", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "pageno", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "psz", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 10;
    }
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "sort", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "sortdir", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "noneFound", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "clientroles", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "clearForm", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "clearForm"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dataNavigation", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "dataNavigation"), _class.prototype)), _class));
  _exports.default = CoordinatorController;
});