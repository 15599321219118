define("clientui/pods/components/ember-model-table/cell-reconciled-status/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let CellReconciledStatus = classic(_class = class CellReconciledStatus extends Ember.Component {
    init() {
      if (this.themeInstance) {
        Ember.set(this, 'tagName', this.themeInstance.cellContentTagName);
      }

      super.init(...arguments);
    }

  }) || _class;

  _exports.default = CellReconciledStatus;
});