define("clientui/pods/components/pages/page-customeraccounts/page-customeraccounts-clientinvoices-search-list/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  let PagesPageCustomeraccountsPageCustomeraccountsClientinvoicesSearchListComponent = (_dec = Ember.inject.service('emtsp-helper'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, (_class = class PagesPageCustomeraccountsPageCustomeraccountsClientinvoicesSearchListComponent extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _initializerDefineProperty2.default)(this, "emthelper", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "fetch", _descriptor3, this);
      (0, _defineProperty2.default)(this, "actions", [this.emthelper.buildAction("view", "view", "View"), this.emthelper.buildAction("download", "download", "Download")]);
    }

    get columns() {
      return [this.emthelper.buildStringCol("clientInvoiceIdentifier", "Client Invoice"), this.emthelper.buildStringCol("account.name", "Account", null, null, null, {
        sortedBy: "account"
      }), this.emthelper.buildDateCol("issueDate", "Issue Date"), this.emthelper.buildMoneyCol("totalGross", "Total Amount", null, null, null, {
        currencyProperty: "currency.id"
      }), this.emthelper.buildBooleanCol("paidInFull", "Paid In Full", null, null, null, {
        disableSorting: true
      }), this.emthelper.buildActionCol("", this.actions)];
    }

    selectAction(itemId, actionName) {
      if (itemId > 0) {
        switch (actionName) {
          case 'view':
            this.router.transitionTo('customeraccounts.clientinvoices.clientinvoice', itemId);
            break;

          case 'download':
            this.fetch.fetchAndDownloadFile("clientinvoices/" + itemId + "/doc", null, null);
            break;

          default:
            this.router.transitionTo('customeraccounts.clientinvoices.clientinvoice', itemId);
        }
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "emthelper", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetch", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectAction", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "selectAction"), _class.prototype)), _class));
  _exports.default = PagesPageCustomeraccountsPageCustomeraccountsClientinvoicesSearchListComponent;
});