define("clientui/superclasses/sublist-component-delete", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let SublistComponentDelete = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, classic(_class = (_class2 = class SublistComponentDelete extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor, this);
    }

    // modelName = '';
    // modelDescription = '';
    // enableDebugLogging = true;
    init() {
      super.init(...arguments);
      this.log('Init ' + this.modelDescription + ' Delete Form');
    }

    log(s) {
      if (this.enableDebugLogging) {
        console.log(s);
      }
    }

    delete() {
      this.onDelete(this.get(this.modelName)).then(() => {
        // Promise
        this.log(this.modelDescription + ' Deletion Saved Successfully in Component');
        this.onClose(); //this.onRefreshRoute();
      }, errors => {
        this.log(this.modelDescription + ' Deletion FAILED to Save in Component');
        this.log(errors);
        this.set('servererrors', errors);
      });
    }

    back() {
      //Close dialog
      this.onClose();
      this.onRollback(); //this.onRefreshRoute();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "delete", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "delete"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "back", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "back"), _class2.prototype)), _class2)) || _class);
  _exports.default = SublistComponentDelete;
});