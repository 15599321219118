define("clientui/models/requestelement", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember-data/model"], function (_exports, _applyDecoratedDescriptor2, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let Requestelement = (_dec = Ember.computed('id'), classic(_class = (_class2 = class Requestelement extends _model.default {
    get selectDisplayText() {
      return `${this.id}`;
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "selectDisplayText", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "selectDisplayText"), _class2.prototype)), _class2)) || _class);
  _exports.default = Requestelement;
});