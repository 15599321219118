define("clientui/pods/customeraccounts/receipts/route", ["exports", "clientui/superclasses/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ReceiptsRoute extends _authenticatedRoute.default {}

  _exports.default = ReceiptsRoute;

  window.__CLASSIC_OWN_CLASSES__.set(ReceiptsRoute, true);
});