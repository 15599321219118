define("clientui/pods/feedback/idea/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "clientui/superclasses/unauthenticated-route", "@ember-data/store/-private", "fetch", "clientui/config/environment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _unauthenticatedRoute, _private, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  //Note that this is NOT an authenticated route
  let IdeaRoute = (_dec = Ember.inject.service('system'), _dec2 = Ember.inject.service('keycloak-session'), _dec3 = Ember.inject.service('toast'), _dec4 = Ember._action, (_class = class IdeaRoute extends _unauthenticatedRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "notifications", _descriptor3, this);
    }

    model() {
      let promises = {};
      let clientPortal = this.modelFor('application');
      promises['application'] = clientPortal;
      let session = this.session;

      if (session.get('authenticated') === true) {
        let guid = session.get('subject'); //Use query instead of find so that we can pass through the tcpid and so verify that the client is logging in to the correct site.
        //The server will return a redirect error if the two parameters do not match up.

        promises['client'] = this.store.query('client', {
          'guid': guid,
          'tcpid': clientPortal.get('tradeClientProfileId'),
          'pno': -1
        }).then(clients => {
          return clients.get('firstObject');
        }, errors => {
          console.log(errors); //throw errors; do not throw an error as client does not have to be logged in to use this page
        });
      }

      return Ember.RSVP.hash(promises);
    }

    setupController(controller, promises) {
      let client = promises['client'];

      if (client) {
        controller.set('client', client);
      }

      let clientportal = promises['application'];
      this.clientportal = clientportal;
      console.log(clientportal);
      controller.set('clientportal', clientportal);
    }

    save(idea, email, company) {
      let bearerToken = this.session.get('token');
      let postData = "portal=" + encodeURIComponent(this.clientportal.tradeClientProfileId);

      if (email) {
        postData += "&email=" + encodeURIComponent(email);
      }

      if (company) {
        postData += "&company=" + encodeURIComponent(company);
      }

      if (idea) {
        postData += "&idea=" + encodeURIComponent(idea);
      }

      let urlSuffix = '/v1/actionidea';
      let url = _environment.default.APP.JBOSS_WEBURL + urlSuffix;
      let headers = new _fetch.Headers();
      headers.set('Content-Type', 'application/x-www-form-urlencoded');
      headers.set('Accept', 'text/plain');
      headers.set("X-WTC", this.system.getWtcRefHeaderValue());
      headers.set("X-WTC2", this.system.getWtcRef2HeaderValue());

      if (bearerToken) {
        headers.set('Authorization', 'Bearer ' + bearerToken);
      }

      url = _environment.default.APP.JBOSS_URL + urlSuffix;
      let options = {
        method: 'POST',
        headers: headers,
        cache: "no-cache",
        body: postData
      };
      return _private.PromiseObject.create({
        promise: (0, _fetch.default)(url, options).then(response => {
          if (response.status == 200) {
            response.text().then(msg => {
              console.log(msg);
              this.notifications.success(msg, "Success");
              this.transitionTo('application');
            });
          } else {
            response.text().then(msg => {
              this.notifications.warning("There was a problem submitting your feedback (Status " + response.status + ")" + msg, "Error"); //this.transitionTo('application');
            });
          }
        }, errors => {
          this.notifications.error("Error Sending feedback to BOSS");
          console.log(errors);
          throw errors;
        })
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "notifications", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "save", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  _exports.default = IdeaRoute;
});