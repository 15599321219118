define("clientui/serializers/booking", ["exports", "@babel/runtime/helpers/esm/defineProperty", "clientui/serializers/pageablelist"], function (_exports, _defineProperty2, _pageablelist) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class serializer extends _pageablelist.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "attrs", {
        account: {
          serialize: 'id',
          deserialize: 'records'
        },
        approver: {
          serialize: 'id',
          deserialize: 'records'
        },
        bookingAccessGroup: {
          serialize: 'id',
          deserialize: 'records'
        },
        bookingCategory: {
          serialize: 'id',
          deserialize: 'records'
        },
        bookingPax: {
          embedded: 'always'
        },
        bookingReferences: {
          embedded: 'always'
        },
        currency: {
          serialize: 'id',
          deserialize: 'records'
        },
        client: {
          serialize: 'id',
          deserialize: 'records'
        },
        depart: {
          serialize: 'id',
          deserialize: 'records'
        },
        destination: {
          serialize: 'id',
          deserialize: 'records'
        },
        primaryAirline: {
          serialize: 'id',
          deserialize: 'records'
        },
        shortInvoiceItems: {
          embedded: 'always'
        },
        leadPax: {
          serialize: 'id',
          deserialize: 'records'
        },
        tickets: {
          embedded: 'always'
        },
        transactions: {
          embedded: 'always'
        }
      });
    }

  }

  _exports.default = serializer;

  window.__CLASSIC_OWN_CLASSES__.set(serializer, true);
});