define("clientui/pods/components/layout/page-header/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@glimmer/component"], function (_exports, _defineProperty2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PageHeader extends _component.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "showBanner", true);
    }

    get isTopWindow() {
      return window.top === window.self;
    }

    get displayTitle() {
      return null; // if (this.pageTitle) {
      //   if (this.pageTitle === '-') {
      //     //magic value that means None
      //     return null;
      //   }
      //   else return this.pageTitle;
      // }
      // else {
      //   return 'Selective Travel Management';
      // }
    }

  }

  _exports.default = PageHeader;

  window.__CLASSIC_OWN_CLASSES__.set(PageHeader, true);
});