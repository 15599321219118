define("clientui/pods/components/widgets/list-item-boolean/component", ["exports", "clientui/pods/components/widgets/list-item/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AdminListItemBoolean extends _component.default {}

  _exports.default = AdminListItemBoolean;

  window.__CLASSIC_OWN_CLASSES__.set(AdminListItemBoolean, true);
});