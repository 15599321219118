define("clientui/pods/components/dialogs/evolvi-sso/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let FormCoordinatorEditRoles = (_dec = Ember.inject.service('evolvisso'), _dec2 = Ember.inject.service('evolvissofix'), _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class FormCoordinatorEditRoles extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "evolvisso", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "evolvissofix", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "ssoUrl", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "errorMessage", _descriptor4, this);
    }

    openSsoUrl(ssoUrl) {
      if (!Ember.get(this.args.clientProfile, 'obtPermitted')) {
        this.errorMessage = 'We have been unable to log you in to Evolvi. You do not have the required permissions.';
      } else if (ssoUrl && ssoUrl.includes('Token')) {
        (async () => {
          let result = await this.evolvissofix.fixupRedirectUrl(ssoUrl);
          console.log(result);

          if (!result) {
            console.log("Trying polyfill"); //If native version fails then try polyfill

            await this.evolvisso.fixupRedirectUrl(ssoUrl);
          }

          if (ssoUrl.includes('Token')) {
            this.ssoUrl = ssoUrl;
          } else {
            this.errorMessage = 'We have been unable to log you in to Evolvi. Please retry or contact the Online Helpdesk for assistance.';
          }
        })();
      } else {
        this.errorMessage = 'We have been unable to log you in to Evolvi as their SSO endpoint has returned an error. Please retry or contact the Online Helpdesk for assistance.';
      }
    }

    get buttonText() {
      let result = null;

      if (this.errorMessage) {
        result = "OK";
      } else if (this.ssoUrl) {
        result = 'Open Evolvi in New Tab';
      }

      return result;
    }

    get spinClass() {
      let result = 'running';

      if (this.errorMessage || this.ssoUrl) {
        result = '';
      }

      return result;
    }

    get message() {
      let result = 'Please wait while we prepare your Evolvi login...';

      if (this.ssoUrl) {
        result += 'DONE!';
      }

      return result;
    }

    didInsertComponent(element, _ref) {
      let [ssoUrl] = _ref;
      console.log("didInsertComponent", ssoUrl);

      if (ssoUrl) {
        this.openSsoUrl(ssoUrl);
      }
    }

    didUpdateComponent(element, _ref2) {
      let [ssoUrl] = _ref2;
      console.log("didUpdateComponent", ssoUrl);
      this.openSsoUrl(ssoUrl);
    }

    close() {
      if (this.ssoUrl) {
        console.log("Opening " + this.ssoUrl + " in new tab");
        window.open(this.ssoUrl);
      }

      this.args.onClose();
      this.args.onFinish();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "evolvisso", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "evolvissofix", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ssoUrl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "errorMessage", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "didInsertComponent", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "didInsertComponent"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "didUpdateComponent", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "didUpdateComponent"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "close", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype)), _class));
  _exports.default = FormCoordinatorEditRoles;
});