define("clientui/mixins/keycloak-unauthenticated-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Ember Mixin that can be combined with an ember route. It is intended to check that the keycloak session is fresh
   * before a route transition. If the keycloak session is not valid then nothing is done as this is an unauthenticated route.
   * The purpose is to maintain the session as the user navigates pages not requiring authentication
   */
  var _default = Ember.Mixin.create({
    session: Ember.inject.service('keycloak-session'),

    beforeModel() {
      this._super(...arguments);

      let session = this.session;

      if (session.ready === true && session.authenticated === true) {
        session.updateToken().then(null, //success - nothing to do
        function () {
          //failure - absorb error
          console.log("user is not authenticated");
        }); //ignore response
      }
    }

  });

  _exports.default = _default;
});