define("clientui/pods/components/dialogs/form-profile-edit-contact/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let FormProfileEditContact = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('system'), _dec3 = Ember.computed('page', 'lastPage'), _dec4 = Ember.computed.sort('countries', 'dialcodeSortDefinition'), _dec5 = Ember.computed('clientportal.homeCountryCode'), _dec6 = Ember.computed.sort('countries', 'countrySortDefinition'), _dec7 = Ember.computed('clientportal.homeCountryCode'), _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, classic(_class = (_class2 = class FormProfileEditContact extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
      (0, _defineProperty2.default)(this, "page", 1);
      (0, _defineProperty2.default)(this, "lastPage", 1);
      (0, _initializerDefineProperty2.default)(this, "sortedDialCodes", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "sortedCountries", _descriptor4, this);
    }

    init() {
      super.init(...arguments); //console.log('Init Create Profile Wizard');
    }

    didReceiveAttrs() {
      this.set('countries', this.system.queryAll('country'));
      this.set('relationships', this.system.enum('relationship')); // if (isPresent(this.get('client.clientProfile.airPrefDepartureAirport.id'))) {
      //   this.get('client').set('defaultDepartureAirport', this.get('client.clientProfile.airPrefDepartureAirport.id'));
      // }
      // if (isPresent(this.get('client.clientProfile.railPrefDepartureStation.id'))) {
      //   this.get('client').set('defaultDepartureStation', this.get('client.clientProfile.railPrefDepartureStation.id'));
      // }

      this.initialiseFormFields(this.client); //Reset to page 1

      this.set('page', 1);
      let lastPage = 5;

      if (this.client.get('clientProfile.travelApprover')) {
        lastPage = 6;
      }

      this.set('lastPage', lastPage);
      this.set('pageActive', false);
      this.system.set('saving', false);
    }

    initialiseFormFields(client) {
      let profile = client.get('clientProfile');
      client.set('emergencyContactName', profile.get('emergencyContactName'));
      client.set('emergencyContactEmail', profile.get('emergencyContactEmail'));
      client.set('emergencyContactPhone', profile.get('emergencyContactPhone'));
      client.set('emergencyContactRelationship', profile.get('emergencyContactRelationship'));
    }

    fixupNewClient(client) {
      let profile = client.get('clientProfile');
      profile.set('emergencyContactName', client.get('emergencyContactName'));
      profile.set('emergencyContactEmail', client.get('emergencyContactEmail'));
      profile.set('emergencyContactPhone', client.get('emergencyContactPhone'));
      profile.set('emergencyContactRelationship', client.get('emergencyContactRelationship'));
    }

    get isLastPage() {
      return this.page === this.lastPage;
    }

    get dialcodeSortDefinition() {
      return ['priority' + this.get('clientportal.homeCountryCode'), 'dialCode'];
    }

    get countrySortDefinition() {
      return ['priority' + this.get('clientportal.homeCountryCode'), 'name'];
    } // hasAir: false,
    // hasRail: false,
    // hasCarHire: false,
    // hasAccommodation: false,
    // airPage: 0,
    // railPage: 0,
    // carHirePage: 0,
    // accommodationPage: 0,


    copyField(obj, src, dst) {
      //if (is(obj.get(src))) {
      if (src in obj) {
        obj.set(dst, obj.get(src));
      }
    }

    activate(active) {
      if (active) {
        this.set('pageActive', true);
      } else {
        this.set('pageActive', false);
      }
    }

    next() {
      let page = this.page + 1;

      if (page > this.lastPage) {
        page = this.lastPage;
      }

      this.set('page', page);
    }

    prev() {
      let page = this.page - 1;

      if (page < 1) {
        page = 1;
      }

      this.set('page', page);
    }

    gotoPage(pageNo) {
      if (!this.pageActive) {
        this.set('page', pageNo);
      }
    }

    save() {
      this.system.set('saving', true);
      console.log("starting SAVE");
      let client = this.client;
      client.validate().then(() => {
        if (client.get("isValid")) {
          let fieldName = null;
          let selectionId = 0;
          let model = null;
          let promises = {};

          if (Ember.isPresent(this.get('client.defaultDepartureAirport'))) {
            fieldName = 'clientProfile.airPrefDepartureAirport';
            model = 'airport';
            selectionId = this.get('client.defaultDepartureAirport');
            promises[fieldName] = this.store.peekRecord(model, selectionId);
          }

          if (Ember.isPresent(this.get('client.defaultDepartureStation'))) {
            fieldName = 'clientProfile.railPrefDepartureStation';
            model = 'trainstation';
            selectionId = this.get('client.defaultDepartureStation');
            promises[fieldName] = this.store.peekRecord(model, selectionId);
          }

          let deletedCD = this.get('client.contactDetails').filter(item => item.get('deleted') === true);
          deletedCD.forEach(item => {
            this.get('client.contactDetails').removeObject(item);
          });
          let deletedAddresses = this.get('client.addresses').filter(item => item.get('deleted') === true);
          deletedAddresses.forEach(item => {
            this.get('client.addresses').removeObject(item);
          }); //move all non-client fields into their appropriate places

          this.fixupNewClient(client);
          Ember.RSVP.hash(promises).then(item => {
            Object.keys(item).forEach(key => {
              client.set(key, item[key]);
            });
            this.onSave(client).then(() => {
              // Promise
              console.log('ClientName Promise Saved Successfully in Component');
              deletedCD.forEach(item => {
                this.store.unloadRecord(item);
              });
              deletedAddresses.forEach(item => {
                this.store.unloadRecord(item);
              });
              this.onClose(); //this.onRefreshRoute();

              this.system.set('saving', false);
            }).catch(errors => {
              console.log('ClientName Promise Failed to Save in Component');
              console.log(errors);
              this.set('servererrors', errors);
              this.system.set('saving', false);
            });
          }, errors => {
            console.log('ClientName Promise Failed to Save in Component - unable to find ' + model + ' with id ' + selectionId);
            console.log(errors);
            this.set('servererrors', errors);
            this.system.set('saving', false);
          });
        } else {
          this.system.set('saving', false);
        }
      }, () => {
        this.system.set('saving', false);
      });
    }

    back() {
      // if (this.get('page') === 1) {
      //Rollback any changes
      this.get('client.contactDetails').forEach(item => {
        this.copyField(item, 'originalDescription', 'description');
        this.copyField(item, 'originalValue', 'value');
        item.set('editing', undefined);
        item.set('deleting', undefined);
        item.set('deleted', undefined);
      });
      let newItems = this.get('client.contactDetails').filter(item => item.get('isNew'));
      newItems.forEach(item => {
        this.get('client.contactDetails').removeObject(item);
        this.store.unloadRecord(item);
      });
      this.get('client.addresses').forEach(item => {
        console.log(item);
        this.copyField(item, 'originalDescription', 'description');
        this.copyField(item, 'originalLine1', 'line1');
        this.copyField(item, 'originalLine2', 'line2');
        this.copyField(item, 'originalLine3', 'line3');
        this.copyField(item, 'originalCity', 'city');
        this.copyField(item, 'originalCounty', 'county');
        this.copyField(item, 'originalCountry', 'country');
        this.copyField(item, 'originalPostCode', 'postCode');
        item.set('editing', undefined);
        item.set('deleting', undefined);
        item.set('deleted', undefined);
      });
      let newAddresses = this.get('client.addresses').filter(item => item.get('isNew'));
      newAddresses.forEach(item => {
        this.get('client.addresses').removeObject(item);
        this.store.unloadRecord(item);
      });
      this.client.rollback();

      this.client._content._internalModel._record.rollbackAttributes(); //Close dialog


      this.onClose();
      this.onRollback(); // }
      // else {
      //   this.set('page', this.get('page') - 1);
      //   // this.initBootstrapMaterial();
      // }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isLastPage", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "isLastPage"), _class2.prototype), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sortedDialCodes", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "dialcodeSortDefinition", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "dialcodeSortDefinition"), _class2.prototype), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sortedCountries", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "countrySortDefinition", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "countrySortDefinition"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "activate", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "activate"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "next", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "next"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "prev", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "prev"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "gotoPage", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "gotoPage"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "save", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "back", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "back"), _class2.prototype)), _class2)) || _class);
  _exports.default = FormProfileEditContact;
});