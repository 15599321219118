define("clientui/pods/components/widgets/help-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3gnL7pKl",
    "block": "{\"symbols\":[\"&attrs\",\"@title\"],\"statements\":[[6,[37,0],[[32,0,[\"helpText\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"span\"],[24,0,\"popover-container float-right\"],[17,1],[12],[2,\"\\n     \\n    \"],[8,\"widgets/icon-for\",[],[[\"@target\"],[\"help\"]],null],[2,\"\\n    \"],[8,\"widgets/pop-over\",[],[[\"@title\",\"@html\"],[[32,2],[32,0,[\"helpText\"]]]],null],[2,\" \\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "clientui/pods/components/widgets/help-icon/template.hbs"
  });

  _exports.default = _default;
});