define("clientui/pods/components/sections/page-myprofilenew-preferences-acc/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1zI8u7G2",
    "block": "{\"symbols\":[\"ff\",\"@client\",\"@clientportal\"],\"statements\":[[10,\"div\"],[14,0,\"card-columns air-preferences-panel\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"card-deck\"],[12],[2,\"\\n      \"],[8,\"widgets/single-item-card\",[],[[\"@caption\",\"@value\",\"@helpkey\"],[\"Special Requests\",[32,2,[\"clientProfile\",\"accommodationSpecialRequests\"]],\"specialRequests\"]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,5],[[32,3,[\"blockPersonalFFNumbers\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"widgets/contact-card\",[],[[\"@header\"],[\"Loyalty Schemes\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"sortedLoyaltySchemes\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"widgets/contactdetail-line-item\",[],[[\"@description\",\"@value\"],[[32,1,[\"frequentFlyerProgramme\",\"name\"]],[30,[36,1],[[32,1,[\"membershipNumber\"]],[30,[36,2],[[32,1,[\"expiryDate\"]],[30,[36,1],[\" (Expires \",[30,[36,0],[[32,1,[\"expiryDate\"]],[32,0,[\"system\",\"dateFormat\"]]],null],\")\"],null]],null]],null]]],null],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"        \"],[8,\"widgets/contactdetail-line-item\",[],[[\"@description\",\"@value\",\"@force\"],[\"None\",\"\",true]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"moment-format\",\"concat\",\"if\",\"-track-array\",\"each\",\"unless\"]}",
    "moduleName": "clientui/pods/components/sections/page-myprofilenew-preferences-acc/template.hbs"
  });

  _exports.default = _default;
});