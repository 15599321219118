define("clientui/models/modelwithdependentrelationships", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ModelWithDependentRelationships extends _model.default {
    /*
      This superclass solves the problem described here:
       This issue occurs when 
      (1) an existing record without an id exist in the store, 
      (2) this record is saved via a parent-child relationship [embedded] and 
      (3) when returned from the server, with an id, 
      result: this child document will get duplicated.
       Solution is to remove the isNew record (without an ID) after the save result is returned from the server
      See https://github.com/emberjs/data/issues/1829#issuecomment-134270337
       Sample usage:
        import ModelWithDependentRelationships from './modelwithdependentrelationships';
       
      export default class MyModel extends ModelWithDependentRelationships {
        @hasMany('tag')
        tags;
      }
     * Updated version which handles the same issue in nested models
    * Original mixin taken from https://github.com/emberjs/data/issues/1829#issuecomment-144029847
    * & https://gist.github.com/amk221/dd0eb6d9b72d0e249f5ae359b87b8b58
    * 
    * Extend this in models to make sure duplicate models are not created when
    * relationship data is received from the server.
    * Issue: https://github.com/emberjs/data/issues/1829
    *
     */
    save() {
      const hasMany = [];
      const belongsTo = [];
      const seenRecords = [];

      function implicitFix(record) {
        const rels = record._internalModel._recordData._implicitRelationships;
        Object.keys(rels).forEach(name => delete rels[name]);
      }

      function recurse(record) {
        if (seenRecords.includes(record)) {
          return;
        } else {
          seenRecords.push(record);
        }

        record.eachRelationship((name, descriptor) => {
          if (descriptor.kind === 'hasMany') {
            const rel = record.hasMany(name).value();

            if (rel) {
              rel.forEach(_record => addHasMany(_record));
            }
          } else if (descriptor.kind === 'belongsTo') {
            const _record = record.belongsTo(name).value();

            if (_record) {
              addBelongsTo(_record);
            }
          }
        });
      }

      function addHasMany(record) {
        hasMany.push(record);
        recurse(record);
      }

      function addBelongsTo(record) {
        belongsTo.push(record);
        recurse(record);
      }

      function deDupe() {
        hasMany.forEach(record => {
          if (record.get('isNew')) {
            record.deleteRecord();
          }
        });
        belongsTo.forEach(record => {
          if (record.get('isNew')) {
            implicitFix(record);
            record.deleteRecord();
          }
        });
      }

      recurse(this);
      return super.save(...arguments).then(deDupe).then(() => this);
    }

  }

  _exports.default = ModelWithDependentRelationships;

  window.__CLASSIC_OWN_CLASSES__.set(ModelWithDependentRelationships, true);
});