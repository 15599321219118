define("clientui/pods/components/ember-model-table/cell-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SeDQXrIj",
    "block": "{\"symbols\":[],\"statements\":[[10,\"i\"],[14,0,\"material-icons md-18\"],[12],[2,\"\\n  \"],[1,[30,[36,1],[[35,0,[\"checked\"]],\"check_box\",\"check_box_outline_blank\"],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"record\",\"if\"]}",
    "moduleName": "clientui/pods/components/ember-model-table/cell-checkbox/template.hbs"
  });

  _exports.default = _default;
});