define("clientui/pods/components/widgets/contact-detail-list/component", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor"], function (_exports, _applyDecoratedDescriptor2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ContactDetailList = (_dec = Ember._action, _dec2 = Ember._action, classic(_class = (_class2 = class ContactDetailList extends Ember.Component {
    addNew() {
      this.onAddNew(this.type);
    }

    edit(item) {
      this.onEdit(item);
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "addNew", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "addNew"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "edit", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "edit"), _class2.prototype)), _class2)) || _class);
  _exports.default = ContactDetailList;
});