define("clientui/pods/travelrequests/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "clientui/superclasses/authenticated-route"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  let TravelrequestsRoute = (_dec = Ember.inject.service('keycloak-session'), _dec2 = Ember.inject.service('system'), (_class = class TravelrequestsRoute extends _authenticatedRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
    }

    model() {
      let guid = this.session.subject;
      let email = null;
      let firstName = null;
      let surname = null;

      if (this.session.authenticated) {
        email = this.session.tokenParsed.email;
        firstName = this.session.tokenParsed.given_name;
        surname = this.session.tokenParsed.family_name;
      }

      let clientportal = this.system.clientportal;
      let clientportalId = null;

      if (clientportal) {
        clientportalId = clientportal.get('tradeClientProfileId');
      }

      return this.store.query('client', {
        'tcpid': clientportalId,
        'guid': guid,
        'email': email,
        'kcfirstname': firstName,
        'kcsurname': surname,
        'testmultierror': 'false',
        'pno': -1
      }).then(clients => {
        return clients.get('firstObject');
      }, errors => {
        console.log(errors);
        throw errors;
      });
    }

    afterModel(client
    /* , transition */
    ) {
      this.system.sendAnalytics('travelrequests', 'travelrequests'); //capture analytics in case transition doesn't complete
      // let clientportal = this.system.get('clientportal');

      console.log('dbId: ' + client.get('dbId'));
      console.log('hasMissingObtFields: ' + client.get('hasMissingObtFields'));

      if (client.get('dbId') === 0) {
        console.log("transitioning to newprofile from travelrequests in afterModel as dbId is " + client.get('dbId'));
        this.transitionTo('newprofile');
      } //TODO validate necessary fields here
      // else if ((client.get('clientProfile.obtPermitted')) && (client.get('hasMissingObtFields'))) {
      //   console.log("transitioning to myprofile from travelrequests in afterModel as hasMissingObtFields is true");
      //   this.transitionTo('myprofile');
      // }


      if (client.get('hasMissingTravelRequestFields')) {
        // console.log('=============================' + client.get('hasMissingTravelRequestFields'));
        // console.log("transitioning to myprofile from travelrequests in afterModel as hasMissingTravelRequestFields =========");
        this.transitionTo('myprofile.editrequestfields');
      } // else if((client.get('hasMissingTravelRequestFields') || (client.get('clientProfile.passports').length < 1))){
      //   if((client.get('clientProfile.passports').length < 1)){
      //     this.transitionTo('myprofile.editdocuments');
      //   } else if(client.get('hasMissingTravelRequestFields')){
      //     console.log('Missing something')
      //     //this.transitionTo('managedprofiles.profile.editobtfields');
      //     this.transitionTo('myprofile');
      //   }
      //   console.log("transitioning to myprofile from travelrequests in afterModel as hasMissingTravelRequestFields is true or has no passport");
      //   // this.transitionTo('managedprofiles.profile.editobtfields');
      //   //this.transitionTo('myprofile');
      // }
      //else allow to pass through

    }

    setupController(controller, promises) {
      let client = promises['client'];

      if (client) {
        controller.set('client', client);
      }

      controller.set('clientportal', this.system.clientportal);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TravelrequestsRoute;
});