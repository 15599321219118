define("clientui/pods/impersonate/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "clientui/superclasses/authenticated-route", "clientui/config/environment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _authenticatedRoute, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  let ImpersonateRoute = (_dec = Ember.inject.service('system'), _dec2 = Ember.inject.service('keycloak-session'), _dec3 = Ember._action, _dec4 = Ember._action, (_class = class ImpersonateRoute extends _authenticatedRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor2, this);
    }

    //impersonateUrl: "https://auth.worldtravelcentregroup.com/auth/realms/SelectiveClients/protocol/openid-connect/token",
    didReceiveAttrs() {//this.system.impersonate("ahall@bournemouth.ac.uk");
    }

    model() {
      let promises = {};
      let clientPortal = this.modelFor('application');
      promises['application'] = clientPortal;
      let session = this.session;

      if (session.get('authenticated') === true) {
        let guid = session.get('subject'); //Use query instead of find so that we can pass through the tcpid and so verify that the client is logging in to the correct site.
        //The server will return a redirect error if the two parameters do not match up.

        promises['client'] = this.store.query('client', {
          'guid': guid,
          'tcpid': clientPortal.get('tradeClientProfileId'),
          'pno': -1
        }).then(clients => {
          return clients.get('firstObject');
        }, errors => {
          console.log(errors); //throw errors; do not throw an error as client does not have to be logged in to use this page
        });
      }

      return Ember.RSVP.hash(promises); //let userId = 'ahall@bournemouth.ac.uk';

      /*
      let form = new FormData();
      form.set('client_id', 'Boss-Clients-TSM');
      form.set('requested_subject', userId);
      form.set('subject_token', this.session.token)
      form.set('grant_type', 'urn:ietf:params:oauth:grant-type:token-exchange');
      */

      /*
          let form='client_id=Boss-Clients-TSM' +
                  '&requested_subject=' + encodeURIComponent(userId) +
                  '&grant_type=' + encodeURIComponent('urn:ietf:params:oauth:grant-type:token-exchange') +
                  '&subject_token=' + encodeURIComponent(this.session.token);
          promises['data'] = fetch(this.impersonateUrl, {
            method: 'POST',
            body: form,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              //'Authorization': 'Bearer ' + this.session.token,
            }
          })
          .then(response => {
            console.log(response)
          });
      */

      /*
      */

      /*
      .then(response => response.text())
      .then(body => {
        // console.log(body);
        let parser = new DOMParser();
        let obj = parser.parseFromString(body, "text/xml");
        // console.log(obj);
        return obj;
      });
      */
    }

    afterModel
    /* client, transition */
    () {
      if (!this.session.hasResourceRole('impersonation', 'realm-management')) {
        this.replaceWith("application");
      }
    }

    setupController(controller, promises) {
      let client = promises['client'];

      if (client) {
        controller.set('client', client);
      }

      let clientportal = promises['application'];
      console.log(clientportal);
      controller.set('clientportal', clientportal);
    }

    tryImpersonation(kcuserid) {
      if (!Ember.isBlank(kcuserid)) {
        kcuserid = kcuserid.trim();
        let impersonateUrl = _environment.default.APP.KEYCLOAK_URL + "/admin/realms/" + _environment.default.APP.KEYCLOAK_REALM + "/users/" + kcuserid + "/impersonation";
        let form = '{"realm":"' + _environment.default.APP.KEYCLOAK_REALM + '","user":"' + kcuserid + '"}';
        return fetch(impersonateUrl, {
          method: 'POST',
          body: form,
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + this.session.token
          }
        }).then(response => {
          if (response.status == '200') {
            console.log("Impersonation Successful");
            response.json().then(r => {
              console.log(r);
            });
            let redirectUrl = null;

            switch (_environment.default.APP.CLIENTCODE) {
              case 'WTC':
                redirectUrl = "https://portal.worldtravel.ie";
                break;

              case 'TSM':
                redirectUrl = "https://portal.selective-travel.com";
                break;
            } // let redirectUrl = window.location.protocol + "//" + window.location.hostname
            // if (window.location.port) {
            //   redirectUrl += ":" + window.location.port;
            // }


            window.location.href = redirectUrl;
          } else {
            console.log(response.status + ": " + response.statusText);
            throw {
              errors: [{
                title: response.status + ": " + response.statusText
              }]
            }; //servererror format
          }
        });
      } else {
        throw {
          errors: [{
            title: "Missing or Empty Keycloak User Id"
          }]
        }; //servererror format
      }
    }

    save(kcuserid) {
      return this.tryImpersonation(kcuserid);
    }

    back() {
      this.replaceWith("application");
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "save", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "back", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "back"), _class.prototype)), _class));
  _exports.default = ImpersonateRoute;
});