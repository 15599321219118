define("clientui/pods/components/dialogs/form-profile-edit-gdpr/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wLdFFH+1",
    "block": "{\"symbols\":[\"@client\",\"@clientportal\",\"@inverse\"],\"statements\":[[10,\"form\"],[14,0,\"form\"],[12],[2,\"\\n  \"],[10,\"fieldset\"],[12],[2,\"\\n    \"],[8,\"inputs/validated-checkbox\",[],[[\"@label\",\"@propertyName\",\"@changeset\",\"@label2\"],[\"Traveller Profile Information\",\"clientProfile.gdprAccepted\",[32,1],[32,0,[\"gdprText\"]]]],null],[2,\"\\n\\n\"],[6,[37,0],[[32,2,[\"additionalConditions\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"inputs/validated-checkbox\",[],[[\"@label\",\"@propertyName\",\"@changeset\",\"@label2\"],[\"Terms & Conditions\",\"clientProfile.additionalConditionsAccepted\",[32,1],[32,0,[\"additionalConditionsText\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row forcewide\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n        \"],[8,\"widgets/form-submit-button-edit\",[],[[\"@onSubmit\",\"@onBack\",\"@inverse\"],[[32,0,[\"save\"]],[32,0,[\"back\"]],[32,3]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1,[\"errors\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"validation\"],[12],[2,\"\\n              There is an error on the form. Please fix this error before continuing. The error will be marked in red.\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"          \"],[8,\"widgets/server-errors\",[],[[\"@servererrors\"],[[32,0,[\"servererrors\"]]]],null],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "clientui/pods/components/dialogs/form-profile-edit-gdpr/template.hbs"
  });

  _exports.default = _default;
});