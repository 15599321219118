define("clientui/pods/components/ember-model-table/models-table-searchresults/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-models-table/components/models-table-server-paginated"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _modelsTableServerPaginated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ModelsTableSearchResults = (_dec = Ember.inject.service('emt-component-helper'), _dec2 = Ember.computed('meta', 'filteredContent.meta', 'metaItemsCountProperty'), _dec3 = Ember.computed('meta', 'filteredContent.meta', 'metaPagesCountProperty'), classic(_class = (_class2 = class ModelsTableSearchResults extends _modelsTableServerPaginated.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "emtcomponenthelper", _descriptor, this);
      (0, _defineProperty2.default)(this, "pageSize", 10);
      (0, _defineProperty2.default)(this, "currentPageNumber", 1);
      (0, _defineProperty2.default)(this, "pageSizeValues", null);
      (0, _defineProperty2.default)(this, "filterQueryParameters", null);
      (0, _defineProperty2.default)(this, "multipleColumnsSorting", false);
      (0, _defineProperty2.default)(this, "showComponentFooter", true);
      (0, _defineProperty2.default)(this, "showCurrentPageNumberSelect", true);
      (0, _defineProperty2.default)(this, "useNumericPagination", false);
      (0, _defineProperty2.default)(this, "showPageSize", true);
      (0, _defineProperty2.default)(this, "showColumnsDropdown", false);
      (0, _defineProperty2.default)(this, "useFilteringByColumns", false);
      (0, _defineProperty2.default)(this, "showGlobalFilter", false);
      (0, _defineProperty2.default)(this, "filterString", '');
      (0, _defineProperty2.default)(this, "focusGlobalFilter", false);
      (0, _defineProperty2.default)(this, "selectRowOnClick", true);
      (0, _defineProperty2.default)(this, "multipleSelect", false);
      (0, _defineProperty2.default)(this, "data", null);
      (0, _defineProperty2.default)(this, "columns", null);
      (0, _defineProperty2.default)(this, "groupedHeaders", null);
      (0, _defineProperty2.default)(this, "displayDataChangedAction", null);
      (0, _defineProperty2.default)(this, "dataNavigationAction", null);
      (0, _defineProperty2.default)(this, "selectAction", null);
      (0, _defineProperty2.default)(this, "rowDoubleClickAction", null);
      (0, _defineProperty2.default)(this, "rowHoverAction", null);
      (0, _defineProperty2.default)(this, "rowOutAction", null);
      (0, _defineProperty2.default)(this, "metaPagesCountProperty", 'totalPages');
      (0, _defineProperty2.default)(this, "metaItemsCountProperty", 'totalRecords');
      (0, _defineProperty2.default)(this, "_prevNav", null);
      (0, _defineProperty2.default)(this, "_prevCols", null);
    }

    //stores previous column settings so that the event only fires if there is a change
    init() {
      this.set('filterQueryParameters', {
        //these are the field names that BOSS expects in the query
        //globalFilter: 'notused',
        sort: 'sort',
        sortDirection: 'sortdir',
        page: 'pageno',
        pageSize: 'psz'
      }); //Set default values

      this.set('pageSizeValues', [10, 25, 50, 100]);
      super.init(...arguments);
    }
    /**
     * Component init
     *
     * Set visibility and filtering attributes for each column
     * This is called from within super.init(...arguments) called above
     * This is a direct copy from ember-models-table except that there is a check for null/undefined on recordsCount
     * This throws an error on initialisation using the original code
     *
     * @method setup
     * @protected
     */


    setup() {
      this.emtcomponenthelper._setup(this);
    }

    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      console.log("ModelsTableSearchresults. Data: ", this.data);

      if (this.data) {
        var _this$data$meta, _this$meta;

        let meta = (_this$data$meta = this.data.meta) !== null && _this$data$meta !== void 0 ? _this$data$meta : (_this$meta = this.meta) !== null && _this$meta !== void 0 ? _this$meta : null;

        if (meta) {
          //console.log("meta: ", this.data.meta);

          /* The BOSS meta data contains the current pagesize, pagenumber etc. in the form
                  "meta": {
                    "estimatedTotalRecords": false, 
                    "pageNumber": 13, 
                    "pageSize": 10, 
                    "sortProperties": [
                      "reference:ASC"
                    ], 
                    "totalPages": 15, 
                    "totalRecords": 144
                  }
              Here, we update the display table so that the correct values are shown in the footer
          */
          if (Ember.isPresent(meta.pageSize)) {
            this.set('pageSize', meta.pageSize); //console.log("pageSize", this.pageSize);
          }

          if (Ember.isPresent(meta.pageNumber)) {
            let pageNumber = meta.pageNumber + 1; //Switch from Zero-based to 1-based counting

            this.set('currentPageNumber', pageNumber); //console.log("EMT pageNumber", this.currentPageNumber);
          } //while we can extract sortProperties here, there is no way to pass them to ember-models-table
          //in such a way that the sort 'arrows' appear at the top of the sort column.
          // if (this.data.meta.sortProperties) {
          //   this.set('sortProperties', this.data.meta.sortProperties);
          //   console.log("sortProperties", this.sortProperties);
          // }

        }
      }
    }

    userInteractionObserverOnce() {
      super.userInteractionObserverOnce(...arguments); //do regular stuff first

      this.emtcomponenthelper.sendNavigationAction(this); //send navigationAction if one defined (from mixin)

      this.emtcomponenthelper.sendSingleSelectAction(this); //send selectAction if one defined (from mixin)
    } //override EMT updateColumns so that _setupColumns is only called if the columns have changed
    //otherwise, filters/sorting etc are lost at inappropriate times


    updateColumns() {
      this.emtcomponenthelper._setupColumns(this);
    }
    /**
     * The total content length is get from the meta information.
     * Set metaItemsCountProperty to change from which meta property this is loaded.
     *
     * @property arrangedContentLength
     * @type number
     * @protected
     */


    get arrangedContentLength() {
      var _this$meta2;

      const meta = this.filteredContent && this.filteredContent.meta ? this.filteredContent.meta : (_this$meta2 = this.meta) !== null && _this$meta2 !== void 0 ? _this$meta2 : {};
      return Ember.get(meta, this.metaItemsCountProperty) || 0;
    }
    /**
     * The pages count is get from the meta information.
     * Set metaPagesCountProperty to change from which meta property this is loaded.
     * 
     * @property pagesCount
     * @type number
     * @protected
     */


    get pagesCount() {
      var _this$meta3;

      const meta = this.filteredContent && this.filteredContent.meta ? this.filteredContent.meta : (_this$meta3 = this.meta) !== null && _this$meta3 !== void 0 ? _this$meta3 : {};
      return Ember.get(meta, this.metaPagesCountProperty) || 1;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "emtcomponenthelper", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "arrangedContentLength", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "arrangedContentLength"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "pagesCount", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "pagesCount"), _class2.prototype)), _class2)) || _class);
  _exports.default = ModelsTableSearchResults;
});