define("clientui/superclasses/sublist-route-edit", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor"], function (_exports, _applyDecoratedDescriptor2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let SublistRouteEditRoute = (_dec = Ember._action, _dec2 = Ember._action, classic(_class = (_class2 = class SublistRouteEditRoute extends Ember.Route {
    // routePrefix = '';
    // routePlural = '';
    // routeSingular = '';
    // routeLeaf = '';
    // paramName = '';
    // parentModelName = '';
    // modelType = '';
    // parentModelFieldName = '';
    // validationName = '';
    // dialogTitle = '';
    // enableDebugLogging = true;
    editPreSaveHook() {//(thisModel, parentModel) {
    }

    init() {
      super.init(...arguments);
      this.log('Route: ' + this.buildRoute() + '->' + this.routeLeaf);
    }

    model(params) {
      this.log('Route: ' + this.buildRoute() + '->' + this.routeLeaf + ' Getting model for ' + this.modelType + ' id ' + params[this.paramName]);
      this.log(params);
      return this.store.peekRecord(this.modelType, params[this.paramName]);
    }

    setupController(controller, model) {
      this.log('Route: ' + this.buildRoute() + '->' + this.routeLeaf + ' setupcontroller');
      let parentmodel = this.modelFor(this.routePrefix + '.' + this.routePlural + '.' + this.routeSingular);
      controller.set('parentModel', parentmodel);
      controller.set('model', model);
      controller.set('validation', this.get(this.validationName));
      controller.set('title', this.dialogTitle);
    }

    log(s) {
      if (this.enableDebugLogging) {
        console.log(s);
      }
    }

    buildRoute() {
      let newRoute = '';

      if (this.routePrefix) {
        newRoute += this.routePrefix + '.';
      }

      if (this.routePlural) {
        newRoute += this.routePlural + '.';
      }

      if (this.routeSingular) {
        newRoute += this.routeSingular;
      }

      return newRoute;
    }

    save(changeset, deletedObjects) {
      console.log("Superclass before execute");
      changeset = changeset.execute(); //apply changes

      console.log("Superclass after execute");
      let parentModel = this.get('controller.parentModel');
      let thisModel = changeset._content; // Ember.assert("Parent Model must exist", Ember.isPresent(parentModel));
      // Ember.assert("Child Model must exist", Ember.isPresent(thisModel));

      this.editPreSaveHook(thisModel, parentModel);
      console.log("Superclass save execute");
      return parentModel.save().then(() => {
        console.log("Superclass after save");

        if (deletedObjects) {
          if (Ember.isArray(deletedObjects)) {
            deletedObjects.forEach(element => {
              element.unloadRecord();
            });
          }
        }

        this.replaceWith(this.buildRoute(), this.get('controller.parentModel.id'));
      }, errors => {
        this.log('Edit' + this.routeLeaf + ' ' + this.parentModelName + '.save returned FAILURE');
        this.log(errors);
        throw errors;
      });
    }

    back() {
      this.log('Rollback Clicked');
      this.replaceWith(this.buildRoute(), this.get('controller.parentModel.id'));
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "save", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "back", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "back"), _class2.prototype)), _class2)) || _class);
  _exports.default = SublistRouteEditRoute;
});