define("clientui/pods/components/widgets/file-uploader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3OKZ/Ltm",
    "block": "{\"symbols\":[\"dropzone\",\"queue\"],\"statements\":[[8,\"file-dropzone\",[],[[\"@class\",\"@name\"],[\"dropzone-upload-area p-3\",[34,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[32,1,[\"active\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],[[32,1,[\"valid\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      Drop to upload\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      Invalid\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[32,2,[\"files\",\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    Uploading \"],[1,[32,2,[\"files\",\"length\"]]],[2,\" file. (\"],[1,[32,2,[\"progress\"]]],[2,\"%)\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"h6\"],[12],[2,\"\\n      \"],[1,[34,2]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"mb-0\"],[12],[2,\"\\n      \"],[10,\"small\"],[12],[2,\"\\n\"],[6,[37,3],[[32,1,[\"supported\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          Drag and drop \"],[1,[30,[36,1],[[35,0],\"replacement \"],null]],[2,\"image onto this area for upload or\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[8,\"file-upload\",[],[[\"@name\",\"@accept\",\"@multiple\",\"@onfileadd\"],[[34,4],[34,5],false,[30,[36,6],[[32,0],\"uploadFile\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[10,\"a\"],[14,0,\"btn btn-info btn-sm text-light\"],[14,\"tabindex\",\"0\"],[14,4,\"button\"],[12],[2,\"\\n            Click Here\\n          \"],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"new\",\"unless\",\"heading\",\"if\",\"name\",\"mimetypes\",\"action\"]}",
    "moduleName": "clientui/pods/components/widgets/file-uploader/template.hbs"
  });

  _exports.default = _default;
});