define("clientui/pods/downloadabledocuments/search/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "clientui/superclasses/search-route"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _searchRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  let _SearchRoute = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class _SearchRoute extends _searchRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "fetch", _descriptor, this);
      (0, _defineProperty2.default)(this, "queryParams", {
        id: {
          refreshModel: true
        },
        accountid: {
          refreshModel: true
        },
        documenttype: {
          refreshModel: true
        },
        description: {
          refreshModel: true
        },
        datefrom: {
          refreshModel: true
        },
        dateto: {
          refreshModel: true
        },
        read: {
          refreshModel: true
        },
        archived: {
          refreshModel: true
        },
        pageno: {
          refreshModel: false
        },
        psz: {
          refreshModel: false
        },
        sort: {
          refreshModel: false
        },
        sortdir: {
          refreshModel: false
        }
      });
    }

    ignorableParams() {
      return super.ignorableParams(...arguments).concat(['read']);
    }

    model(params
    /*, transition*/
    ) {
      if (this.controller) {
        this.controller.set('noneFound', false);
      }

      let promises = {};
      promises['downloadabledocuments'] = this.search('downloadabledocument', params);
      promises['accounts'] = this.store.query('account', {
        accountsscope: true,
        pno: -1
      });
      return Ember.RSVP.hash(promises);
    }

    setupController(controller, promises) {
      let model = promises['downloadabledocuments'];

      if (model.get('length') === 0 && this.filterValid === true) {
        controller.set('noneFound', true);
      }

      controller.set('downloadabledocuments', model);
      controller.set('accounts', promises['accounts']);
      controller.set('docTypes', this.system.enum('documenttype'));
    }

    formSubmit(formParams) {
      this.tidyFormParams(formParams);
      this.transitionTo('downloadabledocuments.search', {
        queryParams: formParams
      });
    }

    clearSelectedItems() {
      this.controller.set('selectedItems', Ember.A([]));
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "formSubmit", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "formSubmit"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "clearSelectedItems", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "clearSelectedItems"), _class.prototype)), _class));

  _exports.default = _SearchRoute;
});