define("clientui/pods/components/pages/page-application-index/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let PageApplicationIndex = (_dec = (0, _component.classNames)('row', 'justify-content-center', 'd-flex', 'mb-4'), _dec2 = Ember.inject.service('system'), _dec3 = Ember.computed.filter('clientportal.links', function (item) {
    return this.system.isLinkPermitted(item, this.client); // let valid = true;
    // let role1 = item.get('role1');
    // let role2 = item.get('role2');
    // let clientRoles = [];
    // if (this.get('client.clientProfile.id')) {
    //   clientRoles = this.get('client.clientProfile.clientRoles')
    // }
    // if (role1) {
    //   if (!clientRoles.includes(role1)) {
    //     valid = false;
    //   }
    // }
    // if (valid && role2) {
    //   if (!clientRoles.includes(role2)) {
    //     valid = false;
    //   }
    // }
    // return valid;
  }), classic(_class = _dec(_class = (_class2 = class PageApplicationIndex extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "filteredLinks", _descriptor2, this);
    }

    didReceiveAttrs() {// console.log("Component page-application-index");
      // console.log(this.get('client'));
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "filteredLinks", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class);
  _exports.default = PageApplicationIndex;
});