define("clientui/pods/components/widgets/pax-type-icon/component", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor"], function (_exports, _applyDecoratedDescriptor2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let PaxTypeIcon = (_dec = Ember.computed('gender'), _dec2 = Ember.computed('paxtype'), _dec3 = Ember.computed('paxtype'), _dec4 = Ember.computed('paxtype'), classic(_class = (_class2 = class PaxTypeIcon extends Ember.Component {
    get icon() {
      if (this.gender === 'M') {
        return 'male';
      } else if (this.gender === 'F') {
        return 'female';
      } else {
        return 'question';
      }
    }

    get adtStyle() {
      let result = '';

      if (this.paxtype === 'ADT') {
        if (this.inverse === true) {
          result = 'text-info';
        } else {
          result = 'text-primary';
        }
      }

      return result;
    }

    get chdStyle() {
      let result = '';

      if (this.paxtype === 'CHD') {
        if (this.inverse === true) {
          result = 'text-info';
        } else {
          result = 'text-primary';
        }
      }

      return result;
    }

    get infStyle() {
      let result = '';

      if (this.paxtype === 'INF') {
        if (this.inverse === true) {
          result = 'text-info';
        } else {
          result = 'text-primary';
        }
      }

      return result;
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "icon", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "icon"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "adtStyle", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "adtStyle"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "chdStyle", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "chdStyle"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "infStyle", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "infStyle"), _class2.prototype)), _class2)) || _class);
  _exports.default = PaxTypeIcon;
});