define("clientui/pods/index/controller", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let IndexController = (_dec = Ember.inject.service('system'), _dec2 = Ember.computed.filter('clientportal.links', function (item) {
    console.log(item.caption + ": " + item.linkType + ": TileSize " + item.tileSize + ": " + this.system.isLinkPermitted(item, this.client));
    return item.tileSize > 0 && this.system.isLinkPermitted(item, this.client);
  }), _dec3 = Ember.computed("filteredLinks.{[],@each.caption}"), classic(_class = (_class2 = class IndexController extends Ember.Controller {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "filteredLinks", _descriptor2, this);
    }

    get rows() {
      let rows = Ember.A([]);
      let currentRow = Ember.A([]);
      let currentWidth = 0;
      let rowNumber = 1;
      this.filteredLinks.forEach(link => {
        //console.log(link);
        link.displaySize = link.tileSize;
        link.lastItem = false;
        let linkWidth = link.tileSize + 1;

        if (currentWidth + linkWidth > 12) {
          //console.log("End of Row " + rowNumber + " CurrentWidth = " + currentWidth + " and there are " + currentRow.length + " tiles");
          this.spreadOutRow(currentRow, currentWidth);
          currentRow.lastObject.lastItem = true;
          rows.pushObject(currentRow);
          currentRow = Ember.A([]);
          currentWidth = 0;
          rowNumber++;
        }

        link.rowNumber = rowNumber;
        link.firstItem = currentRow.length == 0;
        currentRow.pushObject(link);
        currentWidth += linkWidth;
      });

      if (currentRow.length > 0) {
        this.spreadOutRow(currentRow, currentWidth);
        currentRow.lastObject.lastItem = true;
        rows.pushObject(currentRow);
      } //console.log(rows);


      return rows;
    }

    spreadOutRow(currentRow, currentWidth) {
      if (currentRow.length >= 3) {
        //Max tile width is 4 columns so if less than three items then we would never exit the while loop
        let index = currentRow.length - 1;
        let x = 0;

        while (currentWidth < 12 && x < 12) {
          x++;
          let thisLink = currentRow.objectAt(index); //console.log("Got tile " + thisLink.caption);

          if (thisLink.displaySize < 3) {
            //console.log("Widening "+ thisLink.caption + " from " + thisLink.displaySize + " to " + (thisLink.displaySize + 1));
            thisLink.displaySize++;
            currentWidth++;
          }

          index--;

          if (index < 0) {
            index = currentRow.length - 1;
          }
        }
      } else {
        //Widen all tiles to LARGE
        currentRow.forEach(link => {
          link.displaySize = 3;
        }); //Add dummy tiles containing OurLogo to fill out row

        while (currentRow.length < 3) {
          let newLink = this.store.createRecord('clientportallink');
          newLink.displaySize = 3;
          newLink.firstItem = false;
          newLink.rowNumber = currentRow.firstObject.rowNumber;
          newLink.set('imageUrl', this.clientportal.ourLogoUrl);
          currentRow.pushObject(newLink);
        }

        currentRow.lastObject.lastItem = true;
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "filteredLinks", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "rows", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "rows"), _class2.prototype)), _class2)) || _class);
  _exports.default = IndexController;
});