define("clientui/pods/components/sections/page-myprofilenew-preferences-acc/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let PageMyprofilenewPreferencesAcc = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.computed.filter('client.clientProfile.frequentFlyerNumbers', function (item) {
    return item.get('frequentFlyerProgramme.category') === 'ACC';
  }), _dec4 = Ember.computed.sort('loyaltySchemes', 'sortDefinition'), classic(_class = _dec(_class = (_class2 = class PageMyprofilenewPreferencesAcc extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "loyaltySchemes", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "sortedLoyaltySchemes", _descriptor3, this);
      (0, _defineProperty2.default)(this, "sortDefinition", null);
    }

    init() {
      super.init(...arguments);
      this.set('sortDefinition', ['frequentFlyerProgramme.name']);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "loyaltySchemes", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sortedLoyaltySchemes", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class);
  _exports.default = PageMyprofilenewPreferencesAcc;
});