define("clientui/pods/customeraccounts/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CCLUIM3b",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[12],[2,\"\\n\"],[8,\"pages/page-customeraccounts\",[],[[\"@clientPortal\",\"@client\",\"@accounts\"],[[32,0,[\"clientPortal\"]],[32,0,[\"client\"]],[32,0,[\"accounts\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "clientui/pods/customeraccounts/index/template.hbs"
  });

  _exports.default = _default;
});