define("clientui/pods/components/pages/page-contact/component", ["exports", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let PageContact = classic(_class = class PageContact extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "hideHeadOfContract", false);
      (0, _defineProperty2.default)(this, "hideOnlineHelpdesk", false);
      (0, _defineProperty2.default)(this, "colClass", 'col-4');
      (0, _defineProperty2.default)(this, "hideOptionalCols", false);
    }

    didReceiveAttrs() {
      if (this.hideHeadOfContract && this.hideOnlineHelpdesk) {
        this.colClass = 'col-6';
        this.hideOptionalCols = true;
      } else {
        this.colClass = 'col-4';
        this.hideOptionalCols = false;
      }
    }

  }) || _class;

  _exports.default = PageContact;
});