define("clientui/pods/obtmenu/controller", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  let IndexController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, (_class = class IndexController extends Ember.Controller {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "showPreCbtMessage", _descriptor3, this);
    }

    openObt(step) {
      if (this.clientportal.preCbtMessage && step == 1) {
        this.showPreCbtMessage = true;
      } else {
        //Initiate opening of window while on main thread responding to user's click
        //If this is not the case, then Safari will not open the window
        //See https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
        this.system.windowReference = window.open("about:blank", "_blank");
        this.router.transitionTo('obt');
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showPreCbtMessage", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "openObt", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "openObt"), _class.prototype)), _class));
  _exports.default = IndexController;
});