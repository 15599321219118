define("clientui/pods/index/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "clientui/superclasses/unauthenticated-route"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _unauthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  //Note that this is NOT an authenticated route
  let IndexRoute = (_dec = Ember.inject.service('keycloak-session'), (_class = class IndexRoute extends _unauthenticatedRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
    }

    model() {
      let promises = {};
      let clientPortal = this.modelFor('application');
      promises['application'] = clientPortal;
      let session = this.session;

      if (session.get('authenticated') === true) {
        let guid = session.get('subject'); //Use query instead of find so that we can pass through the tcpid and so verify that the client is logging in to the correct site.
        //The server will return a redirect error if the two parameters do not match up.

        promises['client'] = this.store.query('client', {
          'guid': guid,
          'tcpid': clientPortal.get('tradeClientProfileId'),
          'pno': -1
        }).then(clients => {
          return clients.get('firstObject');
        }, errors => {
          console.log(errors); //throw errors; - do not throw an error as this page is permitted even if the user has no profile.
        });
      }

      return Ember.RSVP.hash(promises);
    }

    setupController(controller, promises) {
      let client = promises['client'];

      if (client) {
        controller.set('client', client);
      }

      let clientportal = promises['application'];
      console.log(clientportal);
      controller.set('clientportal', clientportal);
    } // actions: {
    //   error(errors /*, transition */) {
    //     let error = null;
    //     if (errors.errors) {
    //       if (errors.errors[0]) {
    //         error = errors.errors[0];
    //       }
    //     }
    //     if (error) {
    //       if (error.status === '401') {
    //         this.transitionTo('myprofile');
    //         return false;
    //       }
    //       else if (error.status === '409') {
    //         if ((error.title === 'Redirect') && (error.detail) && (!error.detail.includes('\n'))) {
    //           console.log("transitioning to " + error.detail);
    //           window.location.replace('http://' + error.detail)
    //         }
    //         else {
    //           console.log(error);
    //           // Let the route above this handle the error.
    //           return true;
    //         }
    //       }
    //       else {
    //         console.log(error);
    //         // Let the route above this handle the error.
    //         return true;
    //       }
    //     }
    //     return true;  //Allow error to bubble up
    //   }
    // }


  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = IndexRoute;
});