define("clientui/pods/technology/demo-mis/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nsgYDDlS",
    "block": "{\"symbols\":[],\"statements\":[[8,\"layout/page-header\",[],[[\"@clientportal\",\"@client\",\"@showBanner\"],[[34,0],[34,1],true]],null],[2,\"\\n\"],[10,\"section\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[14,1,\"content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row mt-2\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col col-4 text-center home-box bg-purple1 last-item br\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"hovereffect row1 d-table\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"box-content d-table-cell align-middle text-white\"],[12],[2,\"\\n            \"],[8,\"widgets/open-in-lightbox\",[],[[\"@gallerymode\",\"@url\",\"@lightboxTitle\"],[true,\"/demo/mis2.png\",\"View Dashboards containing up-to-date data\"]],null],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col col-8 bg-purple-base last-item p-4 text-dark text-left\"],[12],[2,\"\\n        View Dashboards containing up-to-date data\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"row mt-2\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col col-4 text-center home-box bg-purple1 last-item br\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"hovereffect row1 d-table\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"box-content d-table-cell align-middle text-white\"],[12],[2,\"\\n            \"],[8,\"widgets/open-in-lightbox\",[],[[\"@gallerymode\",\"@url\",\"@lightboxTitle\"],[true,\"/demo/mis3.png\",\"Drill down to see more detailed analysis\"]],null],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col col-8 bg-purple-base last-item p-4 text-dark text-left\"],[12],[2,\"\\n        Drill down to see more detailed analysis\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"clientportal\",\"client\"]}",
    "moduleName": "clientui/pods/technology/demo-mis/template.hbs"
  });

  _exports.default = _default;
});