define("clientui/pods/customeraccounts/clientinvoices/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CustomeraccountsClientinvoicesRoute extends Ember.Route {}

  _exports.default = CustomeraccountsClientinvoicesRoute;

  window.__CLASSIC_OWN_CLASSES__.set(CustomeraccountsClientinvoicesRoute, true);
});