define("clientui/pods/components/ember-model-table/cell-boolean/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let CellBoolean = classic(_class = class CellBoolean extends Ember.Component {
    init() {
      if (this.themeInstance) {
        Ember.set(this, 'tagName', this.themeInstance.cellContentTagName);
      }

      super.init(...arguments);
    } //@computed


    get rawValue() {
      return Ember.get(this.record, this.column.propertyName);
    }

    get value() {
      let rawValue = this.rawValue; // console.log("cell-boolean rawvalue", rawValue, typeof rawValue);

      let bValue = undefined;
      let value = undefined;

      switch (typeof rawValue) {
        case "undefined":
          bValue = false;
          break;

        case "object":
        case "symbol":
        case "function":
          bValue = rawValue !== null;
          break;

        case "boolean":
          bValue = rawValue;
          break;

        case "number":
        case "bigint":
          bValue = rawValue !== 0;
          break;

        case "string":
          value = rawValue;
          break;
      }

      if (typeof value === "undefined" && typeof bValue !== "undefined") {
        value = bValue ? "true" : "false";
      } // console.log("cell-boolean value", value, typeof value);


      return value;
    }

    get rawTooltip() {
      if (this.column.tooltipPropertyName) {
        return Ember.get(this.record, this.column.tooltipPropertyName);
      } else {
        return null;
      }
    }

    get tooltip() {
      var _this$rawTooltip;

      let tooltip = (_this$rawTooltip = this.rawTooltip) !== null && _this$rawTooltip !== void 0 ? _this$rawTooltip : this.value + " "; // console.log("cell-boolean tooltip", tooltip, typeof tooltip);

      return tooltip;
    }

  }) || _class;

  _exports.default = CellBoolean;
});