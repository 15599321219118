define("clientui/models/downloadabledocument", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;

  let Downloadabledocument = (_dec = (0, _model.attr)('number'), _dec2 = (0, _model.attr)('number'), _dec3 = (0, _model.hasMany)('person'), _dec4 = (0, _model.belongsTo)('bookingaccessgroup'), _dec5 = (0, _model.attr)('number'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('boolean'), _dec10 = (0, _model.attr)('boolean'), _dec11 = (0, _model.attr)('datetime'), _dec12 = (0, _model.attr)(), (_class = class Downloadabledocument extends _model.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "version", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "accountId", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "recipients", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "bookingAccessGroup", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "documentId", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "documentDescription", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "documentType", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "fileName", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "read", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "archived", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "createdOn", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "emberAction", _descriptor12, this);
    }

    get statementDetails() {
      return `${this.description}`;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "version", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "accountId", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "recipients", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "bookingAccessGroup", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "documentId", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "documentDescription", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "documentType", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fileName", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "read", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "archived", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "createdOn", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "emberAction", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Downloadabledocument;
});