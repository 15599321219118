define("clientui/pods/feedback/route", ["exports", "clientui/superclasses/unauthenticated-route"], function (_exports, _unauthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //Note that this is NOT an authenticated route
  class FeedbackRoute extends _unauthenticatedRoute.default {
    model() {
      console.log("Starting feedback");
    }

  }

  _exports.default = FeedbackRoute;

  window.__CLASSIC_OWN_CLASSES__.set(FeedbackRoute, true);
});