define("clientui/validations/clientprofessional", ["exports", "ember-changeset-validations/validators", "clientui/validators/regex-if-present"], function (_exports, _validators, _regexIfPresent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    corporateTradeClientSelection: (0, _validators.validatePresence)(true),
    defaultAccountSelection: (0, _validators.validatePresence)(true),
    customValue1: (0, _regexIfPresent.default)('customRegex1', null, true),
    customValue2: (0, _regexIfPresent.default)('customRegex2', null, true),
    customValue3: (0, _regexIfPresent.default)('customRegex3', null, true),
    customValue4: (0, _regexIfPresent.default)('customRegex4', null, true),
    customValue5: (0, _regexIfPresent.default)('customRegex5', null, true),
    customValue6: (0, _regexIfPresent.default)('customRegex6', null, true),
    customValue7: (0, _regexIfPresent.default)('customRegex7', null, true)
  };
  _exports.default = _default;
});