define("clientui/pods/components/dialogs/form-impersonate/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let FormImpersonate = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('system'), _dec3 = Ember._action, _dec4 = Ember._action, classic(_class = (_class2 = class FormImpersonate extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
      (0, _defineProperty2.default)(this, "kcuserid", null);
    }

    save() {
      this.onSave(this.kcuserid).then(() => {
        // Promise
        console.log('ClientName Promise Saved Successfully in Component');
        this.onClose(); //this.onRefreshRoute();

        this.system.set('saving', false);
      }).catch(errors => {
        console.log('ClientName Promise Failed to Save in Component');
        console.log(errors);
        this.set('servererrors', errors);
        this.system.set('saving', false);
      });
    }

    back() {
      //Close dialog
      this.onClose();
      this.onRollback();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "save", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "back", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "back"), _class2.prototype)), _class2)) || _class);
  _exports.default = FormImpersonate;
});