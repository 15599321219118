define("clientui/superclasses/this-route-new", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor"], function (_exports, _applyDecoratedDescriptor2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ThisRouteNewRoute = (_dec = Ember._action, _dec2 = Ember._action, classic(_class = (_class2 = class ThisRouteNewRoute extends Ember.Route {
    // routePrefix = '';
    // routePlural = '';
    // routeSingular = '';
    // routeSingularRequiresId = true;
    // routeLeaf = '';
    // modelType = '';
    // validationName = '';
    // dialogTitle = '';
    // enableDebugLogging = true;
    newPreSaveHook() {//(changeset) {
    }

    init() {
      super.init(...arguments);
      this.log('Route: ' + this.buildRoute() + '->' + this.routeLeaf);
    }

    setupController(controller) {
      let model = this.store.createRecord(this.modelType); //this.log(model);

      controller.set('model', model);
      controller.set('validation', this.get(this.validationName));
      controller.set('title', this.dialogTitle);
    }

    log(s) {
      if (this.enableDebugLogging) {
        console.log(s);
      }
    }

    buildParentRoute() {
      let newRoute = '';

      if (this.routePrefix) {
        newRoute += this.routePrefix + '.';
      }

      if (this.routePlural) {
        newRoute += this.routePlural;
      }

      if (newRoute === '') {
        newRoute = 'application';
      }

      return newRoute;
    }

    buildRoute() {
      let newRoute = '';

      if (this.routePrefix) {
        newRoute += this.routePrefix + '.';
      }

      if (this.routePlural) {
        newRoute += this.routePlural + '.';
      }

      if (this.routeSingular) {
        newRoute += this.routeSingular;
      }

      return newRoute;
    }

    save(changeset) {
      this.newPreSaveHook(changeset);
      return changeset.save().then(() => {
        if (this.routeSingularRequiresId) {
          this.replaceWith(this.buildRoute(), this.get('controller.model.id'));
        } else {
          this.replaceWith(this.buildRoute());
        }
      }, errors => {
        this.log('New ' + this.modelType + '.save returned FAILURE');
        this.log(errors);
        throw errors;
      });
    }

    back() {
      this.log('Rollback Clicked');
      this.replaceWith(this.buildParentRoute());
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "save", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "back", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "back"), _class2.prototype)), _class2)) || _class);
  _exports.default = ThisRouteNewRoute;
});