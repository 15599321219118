define("clientui/models/clientportal", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _dec41, _dec42, _dec43, _dec44, _dec45, _dec46, _dec47, _dec48, _dec49, _dec50, _dec51, _dec52, _dec53, _dec54, _dec55, _dec56, _dec57, _dec58, _dec59, _dec60, _dec61, _dec62, _dec63, _dec64, _dec65, _dec66, _dec67, _dec68, _dec69, _dec70, _dec71, _dec72, _dec73, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38, _descriptor39, _descriptor40, _descriptor41, _descriptor42, _descriptor43, _descriptor44, _descriptor45, _descriptor46, _descriptor47, _descriptor48, _descriptor49, _descriptor50, _descriptor51, _descriptor52, _descriptor53, _descriptor54, _descriptor55, _descriptor56, _descriptor57, _descriptor58, _descriptor59, _descriptor60, _descriptor61, _descriptor62, _descriptor63, _descriptor64, _descriptor65, _descriptor66, _descriptor67, _descriptor68, _descriptor69, _descriptor70, _descriptor71, _descriptor72, _descriptor73;

  let Clientportal = (_dec = (0, _model.attr)('number'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('string'), _dec16 = (0, _model.attr)('string'), _dec17 = (0, _model.attr)('string'), _dec18 = (0, _model.attr)('string'), _dec19 = (0, _model.attr)('string'), _dec20 = (0, _model.attr)('string'), _dec21 = (0, _model.attr)('string'), _dec22 = (0, _model.attr)('string'), _dec23 = (0, _model.hasMany)('clientportallink'), _dec24 = (0, _model.attr)('string', {
    defaultValue: 'Corporate Trade Client'
  }), _dec25 = (0, _model.attr)('string', {
    defaultValue: 'Account'
  }), _dec26 = (0, _model.hasMany)('marketinggroup'), _dec27 = (0, _model.attr)('boolean', {
    defaultValue: true
  }), _dec28 = (0, _model.attr)('boolean', {
    defaultValue: true
  }), _dec29 = (0, _model.attr)('boolean', {
    defaultValue: true
  }), _dec30 = (0, _model.attr)('boolean', {
    defaultValue: true
  }), _dec31 = (0, _model.attr)('boolean', {
    defaultValue: true
  }), _dec32 = (0, _model.attr)('boolean', {
    defaultValue: true
  }), _dec33 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec34 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec35 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec36 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec37 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec38 = (0, _model.attr)('string'), _dec39 = (0, _model.attr)('string'), _dec40 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec41 = (0, _model.attr)('string'), _dec42 = (0, _model.attr)('string'), _dec43 = (0, _model.attr)('string'), _dec44 = (0, _model.attr)('string'), _dec45 = (0, _model.attr)('string'), _dec46 = (0, _model.attr)('string'), _dec47 = (0, _model.attr)('string'), _dec48 = (0, _model.attr)('string'), _dec49 = (0, _model.attr)('string'), _dec50 = (0, _model.attr)('string'), _dec51 = (0, _model.attr)('string'), _dec52 = (0, _model.attr)('string'), _dec53 = (0, _model.attr)('string'), _dec54 = (0, _model.attr)('string'), _dec55 = (0, _model.attr)('string'), _dec56 = (0, _model.attr)('boolean'), _dec57 = (0, _model.attr)('boolean'), _dec58 = (0, _model.attr)('boolean'), _dec59 = (0, _model.attr)('boolean'), _dec60 = (0, _model.attr)('boolean'), _dec61 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec62 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec63 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec64 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec65 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec66 = (0, _model.attr)('string'), _dec67 = (0, _model.attr)('string'), _dec68 = (0, _model.hasMany)('companytravelpolicy'), _dec69 = (0, _model.belongsTo)('companytravelpolicy', {
    inverse: null
  }), _dec70 = (0, _model.attr)('string'), _dec71 = (0, _model.attr)('string'), _dec72 = (0, _model.attr)('string'), _dec73 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), (_class = class Clientportal extends _model.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "tradeClientProfileId", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "ourLogoUrl", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "clientLogoUrl", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "topBannerImageUrl", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "bottomBannerImageUrl", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "portalDisplayName", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "ourDaytimePhone", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "ourOpeningHours", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "ourEmergencyPhone", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "ourEmailAddress", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "ourCroNumber", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "ourCompanyName", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "ourTradingName", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "obtUrl", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "trackingUrl", _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, "misUrl", _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, "visaUrl", _descriptor17, this);
      (0, _initializerDefineProperty2.default)(this, "carHireUrl", _descriptor18, this);
      (0, _initializerDefineProperty2.default)(this, "railUrl", _descriptor19, this);
      (0, _initializerDefineProperty2.default)(this, "ferryUrl", _descriptor20, this);
      (0, _initializerDefineProperty2.default)(this, "systemStatusUrl", _descriptor21, this);
      (0, _initializerDefineProperty2.default)(this, "homeCountryCode", _descriptor22, this);
      (0, _initializerDefineProperty2.default)(this, "links", _descriptor23, this);
      (0, _initializerDefineProperty2.default)(this, "tradeClientCaption", _descriptor24, this);
      (0, _initializerDefineProperty2.default)(this, "accountCaption", _descriptor25, this);
      (0, _initializerDefineProperty2.default)(this, "offeredMarketingGroups", _descriptor26, this);
      (0, _initializerDefineProperty2.default)(this, "usingAirPreferences", _descriptor27, this);
      (0, _initializerDefineProperty2.default)(this, "usingRailPreferences", _descriptor28, this);
      (0, _initializerDefineProperty2.default)(this, "usingCarHirePreferences", _descriptor29, this);
      (0, _initializerDefineProperty2.default)(this, "usingAccommodationPreferences", _descriptor30, this);
      (0, _initializerDefineProperty2.default)(this, "usingApprovers", _descriptor31, this);
      (0, _initializerDefineProperty2.default)(this, "usingArrangers", _descriptor32, this);
      (0, _initializerDefineProperty2.default)(this, "requiresApprover", _descriptor33, this);
      (0, _initializerDefineProperty2.default)(this, "requiresArranger", _descriptor34, this);
      (0, _initializerDefineProperty2.default)(this, "arrangerMayEditArrangers", _descriptor35, this);
      (0, _initializerDefineProperty2.default)(this, "allArrangersForAllTravellers", _descriptor36, this);
      (0, _initializerDefineProperty2.default)(this, "showGenderIdentity", _descriptor37, this);
      (0, _initializerDefineProperty2.default)(this, "enumApproversForAllTravellers", _descriptor38, this);
      (0, _initializerDefineProperty2.default)(this, "enumApproversForAllTravellersLabel", _descriptor39, this);
      (0, _initializerDefineProperty2.default)(this, "blockPersonalFFNumbers", _descriptor40, this);
      (0, _initializerDefineProperty2.default)(this, "customCaption1", _descriptor41, this);
      (0, _initializerDefineProperty2.default)(this, "customCaption2", _descriptor42, this);
      (0, _initializerDefineProperty2.default)(this, "customCaption3", _descriptor43, this);
      (0, _initializerDefineProperty2.default)(this, "customCaption4", _descriptor44, this);
      (0, _initializerDefineProperty2.default)(this, "customCaption5", _descriptor45, this);
      (0, _initializerDefineProperty2.default)(this, "customRegex1", _descriptor46, this);
      (0, _initializerDefineProperty2.default)(this, "customRegex2", _descriptor47, this);
      (0, _initializerDefineProperty2.default)(this, "customRegex3", _descriptor48, this);
      (0, _initializerDefineProperty2.default)(this, "customRegex4", _descriptor49, this);
      (0, _initializerDefineProperty2.default)(this, "customRegex5", _descriptor50, this);
      (0, _initializerDefineProperty2.default)(this, "customHelp1", _descriptor51, this);
      (0, _initializerDefineProperty2.default)(this, "customHelp2", _descriptor52, this);
      (0, _initializerDefineProperty2.default)(this, "customHelp3", _descriptor53, this);
      (0, _initializerDefineProperty2.default)(this, "customHelp4", _descriptor54, this);
      (0, _initializerDefineProperty2.default)(this, "customHelp5", _descriptor55, this);
      (0, _initializerDefineProperty2.default)(this, "customSelection1", _descriptor56, this);
      (0, _initializerDefineProperty2.default)(this, "customSelection2", _descriptor57, this);
      (0, _initializerDefineProperty2.default)(this, "customSelection3", _descriptor58, this);
      (0, _initializerDefineProperty2.default)(this, "customSelection4", _descriptor59, this);
      (0, _initializerDefineProperty2.default)(this, "customSelection5", _descriptor60, this);
      (0, _initializerDefineProperty2.default)(this, "requiresDateOfBirth", _descriptor61, this);
      (0, _initializerDefineProperty2.default)(this, "requiresNationality", _descriptor62, this);
      (0, _initializerDefineProperty2.default)(this, "requiresCustomFields", _descriptor63, this);
      (0, _initializerDefineProperty2.default)(this, "usingClientTiers", _descriptor64, this);
      (0, _initializerDefineProperty2.default)(this, "usingClientRoleTypes", _descriptor65, this);
      (0, _initializerDefineProperty2.default)(this, "identifier1", _descriptor66, this);
      (0, _initializerDefineProperty2.default)(this, "idpHint", _descriptor67, this);
      (0, _initializerDefineProperty2.default)(this, "travelPolicies", _descriptor68, this);
      (0, _initializerDefineProperty2.default)(this, "defaultTravelPolicy", _descriptor69, this);
      (0, _initializerDefineProperty2.default)(this, "additionalConditions", _descriptor70, this);
      (0, _initializerDefineProperty2.default)(this, "preCbtMessage", _descriptor71, this);
      (0, _initializerDefineProperty2.default)(this, "defaultScope", _descriptor72, this);
      (0, _initializerDefineProperty2.default)(this, "usingEvolviSso", _descriptor73, this);
    }

    get calcTradeClientCaption() {
      let result = this.tradeClientCaption;

      if (Ember.isEmpty(result)) {
        result = "Trade Client";
      }

      return result;
    }

    get calcAccountCaption() {
      let result = this.accountCaption;

      if (Ember.isEmpty(result)) {
        result = "Account";
      }

      return result;
    }

    get hasObt() {
      return !Ember.isBlank(this.obtUrl);
    }

    get hasAetm() {
      return !Ember.isBlank(this.obtUrl) && this.obtUrl.toLowerCase().includes("amadeus");
    }

    get hasEtravel() {
      return !Ember.isBlank(this.obtUrl) && this.obtUrl.toLowerCase().includes("selective-travel");
    }

    get hasTracking() {
      return !Ember.isBlank(this.trackingUrl);
    }

    get hasMis() {
      return !Ember.isBlank(this.misUrl);
    }

    get hasVisa() {
      return !Ember.isBlank(this.visaUrl);
    }

    get hasCarHire() {
      return !Ember.isBlank(this.carHireUrl);
    }

    get hasRail() {
      return !Ember.isBlank(this.railUrl);
    }

    get hasEvolvi() {
      return !Ember.isBlank(this.railUrl) && (this.railUrl.toLowerCase().includes("evolvi.co.uk") || this.railUrl.toLowerCase().includes("evolviuat.co.uk"));
    }

    get hasTrainLine() {
      return !Ember.isBlank(this.railUrl) && this.railUrl.toLowerCase().includes("thetrainline.com");
    }

    get hasFerry() {
      return !Ember.isBlank(this.ferryUrl);
    }

    get carParkingLink() {
      return this.links.findBy('linkName', 'carparking');
    }

    get hasTechnologyLink() {
      return this.hasTracking || this.hasMis || this.hasVisa || this.hasCarHire || this.hasFerry || this.carParkingLink;
    }

    get hasTravelPolicies() {
      return this.travelPolicies.length > 0;
    }

    get hasMultipleTravelPolicies() {
      return this.travelPolicies.length > 1;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tradeClientProfileId", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ourLogoUrl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "clientLogoUrl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "topBannerImageUrl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "bottomBannerImageUrl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "portalDisplayName", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ourDaytimePhone", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ourOpeningHours", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ourEmergencyPhone", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ourEmailAddress", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ourCroNumber", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ourCompanyName", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ourTradingName", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "obtUrl", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "trackingUrl", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "misUrl", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "visaUrl", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "carHireUrl", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "railUrl", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ferryUrl", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "systemStatusUrl", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "homeCountryCode", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "links", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tradeClientCaption", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "accountCaption", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "offeredMarketingGroups", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "usingAirPreferences", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "usingRailPreferences", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "usingCarHirePreferences", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "usingAccommodationPreferences", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "usingApprovers", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "usingArrangers", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "requiresApprover", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "requiresArranger", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "arrangerMayEditArrangers", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "allArrangersForAllTravellers", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showGenderIdentity", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor38 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "enumApproversForAllTravellers", [_dec38], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor39 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "enumApproversForAllTravellersLabel", [_dec39], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor40 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "blockPersonalFFNumbers", [_dec40], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor41 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customCaption1", [_dec41], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor42 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customCaption2", [_dec42], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor43 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customCaption3", [_dec43], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor44 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customCaption4", [_dec44], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor45 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customCaption5", [_dec45], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor46 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customRegex1", [_dec46], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor47 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customRegex2", [_dec47], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor48 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customRegex3", [_dec48], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor49 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customRegex4", [_dec49], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor50 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customRegex5", [_dec50], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor51 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customHelp1", [_dec51], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor52 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customHelp2", [_dec52], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor53 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customHelp3", [_dec53], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor54 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customHelp4", [_dec54], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor55 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customHelp5", [_dec55], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor56 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customSelection1", [_dec56], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor57 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customSelection2", [_dec57], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor58 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customSelection3", [_dec58], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor59 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customSelection4", [_dec59], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor60 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customSelection5", [_dec60], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor61 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "requiresDateOfBirth", [_dec61], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor62 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "requiresNationality", [_dec62], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor63 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "requiresCustomFields", [_dec63], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor64 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "usingClientTiers", [_dec64], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor65 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "usingClientRoleTypes", [_dec65], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor66 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "identifier1", [_dec66], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor67 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "idpHint", [_dec67], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor68 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "travelPolicies", [_dec68], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor69 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "defaultTravelPolicy", [_dec69], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor70 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "additionalConditions", [_dec70], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor71 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "preCbtMessage", [_dec71], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor72 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "defaultScope", [_dec72], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor73 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "usingEvolviSso", [_dec73], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Clientportal;
});