define("clientui/pods/customeraccounts/clientinvoices/clientinvoice/route", ["exports", "clientui/superclasses/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CustomeraccountsClientinvoicesClientinvoiceRoute extends _authenticatedRoute.default {
    model(params) {
      let promises = {};
      let clientPortal = this.modelFor('application');
      promises['application'] = clientPortal;
      promises['clientInvoiceId'] = params['clientinvoice_id'];
      promises['clientInvoice'] = this.store.findRecord('clientinvoice', params['clientinvoice_id']);
      return Ember.RSVP.hash(promises);
    }

    setupController(controller, promises) {
      let clientportal = promises['application'];
      controller.set('clientportal', clientportal);
      controller.set('clientInvoiceId', promises['clientInvoiceId']);
      controller.set('clientInvoice', promises['clientInvoice']);
      controller.set('passengers', promises['passengers']);
    }

  }

  _exports.default = CustomeraccountsClientinvoicesClientinvoiceRoute;

  window.__CLASSIC_OWN_CLASSES__.set(CustomeraccountsClientinvoicesClientinvoiceRoute, true);
});