define("clientui/serializers/application", ["exports", "@ember-data/serializer/rest", "@ember-data/serializer/json"], function (_exports, _rest, _json) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let Application = classic(_class = class Application extends _json.default.extend(_rest.EmbeddedRecordsMixin) {
    // If enabled, this code will restrict serialisation to new/changed fields, and the version field
    serializeAttribute(snapshot, json, key, attributes) {
      if (snapshot.record.get('isNew') || snapshot.changedAttributes()[key] || key === 'version' || key === 'dbId') {
        super.serializeAttribute(snapshot, json, key, attributes);
      }
    } // normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    //   console.log("normalizeResponse");
    //   console.log(primaryModelClass);
    //   console.log(payload);
    //   console.log(id);
    //   console.log(requestType);
    //   let response = this._super.apply(this, arguments);
    //   console.log(response);
    //   return response;
    // },
    // normalize(typeClass, hash) {
    //   console.log("normalize");
    //   console.log(typeClass);
    //   console.log(hash);
    //   let response = this._super.apply(this, arguments);
    //   return response;
    // }


  }) || _class;

  _exports.default = Application;
});