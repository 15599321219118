define("clientui/pods/application/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "clientui/superclasses/unauthenticated-route", "clientui/config/environment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _unauthenticatedRoute, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  //Note that this is NOT an authenticated route
  let ApplicationRoute = (_dec = Ember.inject.service('keycloak-session'), _dec2 = Ember.inject.service('system'), _dec3 = Ember.inject.service, _dec4 = Ember._action, (_class = class ApplicationRoute extends _unauthenticatedRoute.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor3, this);
      let hideIdp = false;

      if (window.location.hash.includes('#wtc')) {
        hideIdp = true;
      } else if (window.location.search.includes('redirect_fragment=wtc')) {
        //Keycloak rewrites hash as a query param on redirects
        hideIdp = true;
      }

      this.system.set('hideIdp', hideIdp);
      let router = this.router;
      router.on('routeWillChange', () => {
        this.system.setMatomoPageViewId();
      });
      router.on('routeDidChange', () => {
        //const page = router.currentURL;
        const routeName = router.currentRouteName || 'unknown';

        if (routeName !== 'page') {
          //The 'page' route handles its own tracking
          this.system.sendAnalytics(routeName);
        }
      });
    }

    beforeModel() {
      if (!this.system.DEBUG_DISABLE_KEYCLOAK) {
        // console.log('Attempting to login');
        // console.log('ENV.APP.KEYCLOAK_HOST= ' + ENV.APP.KEYCLOAK_HOST);
        // console.log('urlPrefix= ' + this.urlPrefix);
        // console.log('urlPrefix= ' + this.get('urlPrefix'));
        // console.log('realm= ' + ENV.APP.KEYCLOAK_REALM);
        // console.log('url= ' + ENV.APP.KEYCLOAK_URL);
        // console.log('clientId= ' + ENV.APP.KEYCLOAK_CLIENTID);
        var session = this.session;
        var options = {
          'realm': _environment.default.APP.KEYCLOAK_REALM,
          'url': _environment.default.APP.KEYCLOAK_URL,
          'clientId': _environment.default.APP.KEYCLOAK_CLIENTID
        }; // this will result in a newly constructed keycloak object

        session.installKeycloak(options); // set any keycloak init parameters where defaults need to be overidden

        session.set('responseMode', 'fragment');
        session.set('defaultLoginRoute', 'application');
        session.set('defaultLogoutRoute', 'application');
        session.set('onLoad', 'check-sso');
        session.set('checkLoginIframe', false);
        /*
        checkLoginIframe
        ----------------
        With checkLoginIframe as false, the onLoad->check-sso setting will cause a redirect to the auth server, followed by a redirect back to this site.
        Within that redirect process, Localstorage is used by keycloak.js to store state.
        For some browsers, (esp. FF in Private Browsing mode) this Localstorage is cleared once there is no tab showing that site.
        If the internet connection is slow, then the cleardown happens before we are redirected back to this site and so the state is lost, resulting in redirect loops.
        The simplest mitigation is to handle the auth processes in an iframe as we are doing here, so avoiding any redirect. However that may cause future difficulties with SameSite cookies etc. so we will have to keep an eye on that.
        Other possible mitigations include:
         - use of the silentCheckSsoRedirectUri keycloak setting - this requires updating ember-keycloak-auth to pass this parameter through to keycloak.js
         - force keycloak.js to use CookieStorage instead of LocalStorage. This requires inventing a new parameter, updating ember-keycloak-auth to pass through this parameter, and changing the logic in keycloak.js to honour it.
        */
        // console.log("Authenticated: " + session.get('authenticated'));
        // console.log("Token: " + session.get('token'));
        // console.log("RefreshToken: " + session.get('refreshToken'));
        // finally init the service and return promise to pause router.
        // finally init the service and return promise to pause router.

        return session.initKeycloak();
      }
    }

    model() {
      if (this.system.hostname) {
        if (this.system.hostname === window.location.hostname) {
          if (this.system.clientportal) {
            return this.system.clientportal;
          }
        }
      }

      this.system.set('hostname', window.location.hostname);
      console.log(this.system.hostname);

      if (this.system.keycloakLandingSites.includes(this.system.hostname)) {
        console.log("This is a keycloak landing site");
        return null;
      } else {
        console.log("Calling findrecord for clientportal");
        return this.store.findRecord('clientportal', this.system.hostname);
      }
    }

    afterModel(clientportal) {
      this.system.set('clientportal', clientportal);
      this.system.set('dateFormat', 'DD-MMM-YYYY');
      this.system.set('dateTimeFormat', 'DD-MMM-YYYY HH:mm:ss');

      if (clientportal) {
        if (!this.system.hideIdp) {
          if (!Ember.isBlank(clientportal.get('idpHint'))) {
            console.log("idpHint: " + clientportal.get('idpHint'));
            this.session.set('idpHint', clientportal.get('idpHint'));
          }
        }
      }
    }

    redirect() {
      //When transition is complete, if we have no clientportal (due to this being a keycloak landing site)
      //we transition to the myprofile page where the error handling logic will redirect the user to their correct site
      if (this.system.clientportal === null) {
        console.log('Redirecting to /myprofile as clientportal is null');
        this.replaceWith('myprofile');
      }
    }

    setupController(controller, clientportal) {
      controller.set('clientportal', clientportal);
    }

    explorerClick() {
      this.system.setExplorerMessageShown();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "explorerClick", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "explorerClick"), _class.prototype)), _class));
  _exports.default = ApplicationRoute;
});