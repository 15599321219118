define("clientui/pods/components/pages/page-feedback-idea/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@glimmer/component"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  let PageFeedbackIdea = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class PageFeedbackIdea extends _component.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "idea", null);
      (0, _defineProperty2.default)(this, "email", null);
      (0, _defineProperty2.default)(this, "company", null);
      (0, _defineProperty2.default)(this, "emailValidator", /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i);
      (0, _defineProperty2.default)(this, "emailValid", true);
      (0, _defineProperty2.default)(this, "ideaValid", true);
    }

    submitAction(e) {
      e.preventDefault();

      if (!Ember.isBlank(this.idea)) {
        this.ideaValid = true;
        console.log(this.idea);
      } else {
        this.ideaValid = false; //blank field - return error
      }

      if (this.emailValid) {
        console.log(this.email);
      } else {
        console.log("Invalid Email: " + this.email);
      }

      console.log(this.company);

      if ((this.args.client || this.emailValid) && this.ideaValid) {
        this.args.onSubmit(this.idea, this.email, this.company);
      }
    }

    validateEmail(e) {
      e.preventDefault();
      let email = e.target.value;

      if (!Ember.isBlank(email)) {
        if (Ember.isEmpty(email.match(this.emailValidator))) {
          this.emailValid = false; //blank field - return error
        } else {
          this.emailValid = true;
        }
      } else {
        this.emailValid = false; //blank field - return error
      }
    }

    validateIdea(e) {
      e.preventDefault();
      let idea = e.target.value;

      if (!Ember.isBlank(idea)) {
        this.ideaValid = true;
      } else {
        this.ideaValid = false; //blank field - return error
      }
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "submitAction", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "submitAction"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "validateEmail", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "validateEmail"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "validateIdea", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "validateIdea"), _class.prototype)), _class));
  _exports.default = PageFeedbackIdea;
});