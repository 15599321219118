define("clientui/models/enquirycontainer", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model", "clientui/models/modelwithdependentrelationships"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model, _modelwithdependentrelationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let Enquirycontainer = (_dec = (0, _model.belongsTo)('account'), _dec2 = (0, _model.attr)('number'), _dec3 = (0, _model.hasMany)('quotetraveller'), _dec4 = (0, _model.belongsTo)('team'), _dec5 = (0, _model.attr)('number'), _dec6 = (0, _model.attr)('datetime'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('number'), _dec9 = (0, _model.hasMany)('quotecontainer'), _dec10 = (0, _model.hasMany)('travelrequest'), _dec11 = Ember.computed('account'), classic(_class = (_class2 = class Enquirycontainer extends _modelwithdependentrelationships.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "account", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "travelArrangerId", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "quoteClients", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "team", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "userId", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "createdOn", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "description", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "tradeClientProfileId", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "quoteVersions", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "travelRequests", _descriptor10, this);
    }

    get selectDisplayText() {
      return `${this.account}`;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "account", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "travelArrangerId", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "quoteClients", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "team", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "userId", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "createdOn", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "description", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "tradeClientProfileId", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "quoteVersions", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "travelRequests", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "selectDisplayText", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "selectDisplayText"), _class2.prototype)), _class2)) || _class);
  _exports.default = Enquirycontainer;
});