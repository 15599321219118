define("clientui/pods/components/pages/page-feedback/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let PageFeedback = classic(_class = class PageFeedback extends Ember.Component {}) || _class;

  _exports.default = PageFeedback;
});