define("clientui/pods/newprofile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WQT0TIgB",
    "block": "{\"symbols\":[],\"statements\":[[8,\"layout/page-header\",[],[[\"@clientportal\",\"@client\",\"@showBanner\",\"@pageTitle\"],[[34,0],[34,1],false,\"Selective Profile Suite\"]],null],[2,\"\\n\"],[10,\"section\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[14,1,\"content\"],[12],[2,\"\\n    \"],[8,\"layout/content-heading\",[],[[\"@portalDisplayName\",\"@firstName\",\"@surname\",\"@email\",\"@position\",\"@tradeclient\"],[[34,0,[\"portalDisplayName\"]],[34,1,[\"firstName\"]],[34,1,[\"surname\"]],[34,1,[\"mainEmail\"]],[34,1,[\"position\"]],[34,1,[\"owner\",\"selectDisplayText\"]]]],null],[2,\"\\n\\n    \"],[8,\"widgets/card-basic\",[],[[\"@header\",\"@title\"],[[34,2],\"Please complete the following steps\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"wizards/wizard-create-profile\",[],[[\"@client\",\"@clientportal\",\"@onSave\",\"@onRollback\",\"@onClose\"],[[30,[36,5],[[35,4],[35,3]],null],[34,0],[30,[36,6],[\"save\"],null],[30,[36,6],[\"back\"],null],[30,[36,6],[\"donothing\"],null]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"clientportal\",\"client\",\"title\",\"validation\",\"model\",\"changeset\",\"route-action\"]}",
    "moduleName": "clientui/pods/newprofile/template.hbs"
  });

  _exports.default = _default;
});