define("clientui/pods/components/sections/page-myprofilenew-professional/component", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember-decorators/component"], function (_exports, _applyDecoratedDescriptor2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let PageMyprofilenewProfessional = (_dec = (0, _component.classNames)('card-columns', 'contact-panel'), _dec2 = Ember.computed('clientportal.tradeClientCaption'), _dec3 = Ember.computed('clientportal.accountCaption'), _dec4 = Ember.computed('clientportal.{customCaption1,customCaption2,customCaption3,customCaption4,customCaption5}', 'client.owner.{customCaption1,customCaption2}'), classic(_class = _dec(_class = (_class2 = class PageMyprofilenewProfessional extends Ember.Component {
    get tradeClientCaption() {
      let result = this.get('clientportal.tradeClientCaption');

      if (Ember.isBlank(result)) {
        result = "Trade Client";
      }

      return result;
    }

    get accountCaption() {
      let result = this.get('clientportal.accountCaption');

      if (Ember.isBlank(result)) {
        result = "Account";
      }

      return result;
    }

    get hasCustomFields() {
      let result = false;

      if (!Ember.isBlank(this.get('clientportal.customCaption1')) || !Ember.isBlank(this.get('clientportal.customCaption2')) || !Ember.isBlank(this.get('clientportal.customCaption3')) || !Ember.isBlank(this.get('clientportal.customCaption4')) || !Ember.isBlank(this.get('clientportal.customCaption5')) || !Ember.isBlank(this.get('client.owner.customCaption1')) || !Ember.isBlank(this.get('client.owner.customCaption2'))) {
        result = true;
      }

      return result;
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "tradeClientCaption", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "tradeClientCaption"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "accountCaption", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "accountCaption"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "hasCustomFields", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "hasCustomFields"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = PageMyprofilenewProfessional;
});