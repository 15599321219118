define("clientui/pods/managedprofiles/profile/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _class2, _descriptor, _descriptor2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ProfileRoute = (_dec = Ember.inject.service('keycloak-session'), _dec2 = Ember.inject.service('system'), _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, classic(_class = (_class2 = class ProfileRoute extends Ember.Route {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
    }

    model(params) {
      let promises = this.modelFor('managedprofiles');
      promises['model'] = this.store.find('client', params.guid);
      return Ember.RSVP.hash(promises);
    }

    afterModel(promises
    /* transition */
    ) {
      let clientportal = this.system.clientportal;
      let managedClient = promises['model'];

      if (managedClient) {
        if (clientportal.get('obtUrl') && managedClient.get('clientProfile.obtPermitted') && managedClient.get('hasMissingObtFields')) {
          this.transitionTo('managedprofiles.profile.editobtfields');
        } else if (clientportal.get('hasEvolvi') && managedClient.get('usingEvolviSso') && managedClient.get('hasMissingEvolviSsoFields')) {
          this.transitionTo('managedprofiles.profile.editevolvissofields');
        }
      }
    }

    setupController(controller, promises) {
      let client = promises['client'];

      if (client) {
        controller.set('client', client);
      }

      let managedClient = promises['model'];

      if (managedClient) {
        controller.set('managedClient', managedClient);
        this.set("managedClientId", managedClient.get('id'));
      }

      let clientportal = promises['application'];
      controller.set('clientportal', clientportal);
      controller.set('system', this.system);
    }

    editPersonal() {
      this.transitionTo('managedprofiles.profile.editpersonal', this.managedClientId);
    }

    editContact() {
      this.transitionTo('managedprofiles.profile.editcontact', this.managedClientId);
    }

    editProfessional() {
      this.transitionTo('managedprofiles.profile.editprofessional', this.managedClientId);
    }

    editDocuments() {
      this.transitionTo('managedprofiles.profile.editdocuments', this.managedClientId);
    }

    editProfileNotes() {
      this.transitionTo('managedprofiles.profile.editprofilenotes', this.managedClientId);
    }

    editSubscriptions() {
      this.transitionTo('managedprofiles.profile.editsubscriptions', this.managedClientId);
    }

    editPreferences() {
      this.transitionTo('managedprofiles.profile.editpreferences', this.managedClientId);
    }

    editPermissions() {
      this.transitionTo('managedprofiles.profile.editpermissions', this.managedClientId);
    }

    delete() {
      this.transitionTo('managedprofiles.profile.delete', this.managedClientId);
    }

    undelete() {
      this.transitionTo('managedprofiles.profile.undelete', this.managedClientId);
    }

    back() {
      this.transitionTo('managedprofiles');
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "editPersonal", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "editPersonal"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "editContact", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "editContact"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "editProfessional", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "editProfessional"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "editDocuments", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "editDocuments"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "editProfileNotes", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "editProfileNotes"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "editSubscriptions", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "editSubscriptions"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "editPreferences", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "editPreferences"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "editPermissions", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "editPermissions"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "delete", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "delete"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "undelete", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "undelete"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "back", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "back"), _class2.prototype)), _class2)) || _class);
  _exports.default = ProfileRoute;
});