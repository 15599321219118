define("clientui/models/bookingpax", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let BookingPax = (_dec = (0, _model.belongsTo)('client'), _dec2 = (0, _model.attr)('number'), _dec3 = (0, _model.attr)('boolean'), (_class = class BookingPax extends _model.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "client", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "seq", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "foundUsingProfile", _descriptor3, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "client", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "seq", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "foundUsingProfile", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BookingPax;
});