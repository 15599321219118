define("clientui/pods/myprofile/editobtfields/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ClrYz2+3",
    "block": "{\"symbols\":[\"closeDialog\",\"@model\"],\"statements\":[[8,\"widgets/dialog-box-route\",[],[[\"@title\",\"@historyBack\"],[[32,0,[\"title\"]],false]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"dialogs/form-profile-edit-obt\",[],[[\"@client\",\"@clientportal\",\"@missingFields\",\"@onSave\",\"@onRollback\",\"@onClose\"],[[30,[36,0],[[32,2],[32,0,[\"validation\"]]],null],[32,0,[\"clientportal\"]],[32,2,[\"missingObtFieldsForm\"]],[30,[36,1],[\"save\"],null],[30,[36,1],[\"back\"],null],[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"changeset\",\"route-action\"]}",
    "moduleName": "clientui/pods/myprofile/editobtfields/template.hbs"
  });

  _exports.default = _default;
});