define("clientui/pods/travelrequests/manage/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NTILEgH1",
    "block": "{\"symbols\":[],\"statements\":[[8,\"widgets/card-basic\",[],[[\"@header\",\"@inverse\"],[[34,0],true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"pages/page-list-of-travel\",[],[[\"@client\",\"@travelrequests\",\"@approvalrequests\",\"@clientportal\",\"@inverse\",\"@onSelectAction\"],[[34,1],[34,2],[34,3],[34,4],true,[30,[36,5],[\"selectAction\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"title\",\"client\",\"travelrequests\",\"approvalrequests\",\"clientportal\",\"route-action\"]}",
    "moduleName": "clientui/pods/travelrequests/manage/template.hbs"
  });

  _exports.default = _default;
});