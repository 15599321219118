define("clientui/pods/components/sections/page-myprofilenew-preferences/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let PageMyprofilenewPreferences = classic(_class = class PageMyprofilenewPreferences extends Ember.Component {}) || _class;

  _exports.default = PageMyprofilenewPreferences;
});