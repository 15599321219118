define("clientui/pods/components/widgets/help-icon/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@glimmer/component", "clientui/mappings/helptext"], function (_exports, _defineProperty2, _component, _helptext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class HelpIcon extends _component.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "helpTextMap", _helptext.default);
    }

    //import mapping
    get helpkeyinternal() {
      let result = this.args.helpkey;

      if (!result) {
        result = this.args.propertyName;
      }

      return result;
    }

    get helpText() {
      let result = this.args.manualHelpText;

      if (Ember.isNone(result)) {
        result = this.helpTextMap[this.helpkeyinternal];
      }

      return result;
    }

  }

  _exports.default = HelpIcon;

  window.__CLASSIC_OWN_CLASSES__.set(HelpIcon, true);
});