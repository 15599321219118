define("clientui/pods/components/widgets/stepwizard-step/component", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor"], function (_exports, _applyDecoratedDescriptor2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let StepwizardStep = (_dec = Ember._action, classic(_class = (_class2 = class StepwizardStep extends Ember.Component {
    gotoPage(pageNo) {
      if (typeof this.onGotoPage !== 'undefined') {
        this.onGotoPage(pageNo);
      }
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "gotoPage", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "gotoPage"), _class2.prototype)), _class2)) || _class);
  _exports.default = StepwizardStep;
});