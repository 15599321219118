define("clientui/pods/components/wizards/wizard-start-profile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g6AkTww0",
    "block": "{\"symbols\":[],\"statements\":[[11,\"form\"],[24,0,\"form\"],[4,[38,0],[[32,0],\"submit\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n  \"],[10,\"fieldset\"],[12],[2,\"\\n    \"],[8,\"inputs/validated-input\",[],[[\"@label\",\"@propertyName\",\"@maxlength\",\"@changeset\",\"@validChars\"],[\"First Name *\",\"firstname\",\"50\",[34,1],[34,2,[\"lettersAndSpaceOnly\"]]]],null],[2,\"\\n    \"],[8,\"inputs/validated-input\",[],[[\"@label\",\"@propertyName\",\"@maxlength\",\"@changeset\",\"@validChars\"],[\"Surname *\",\"surname\",\"50\",[34,1],[34,2,[\"surnameChars\"]]]],null],[2,\"\\n    \"],[8,\"inputs/validated-input\",[],[[\"@label\",\"@propertyName\",\"@maxlength\",\"@helpkey\",\"@changeset\",\"@validChars\"],[\"Email Address *\",\"email\",\"50\",\"primaryemail\",[34,1],[34,2,[\"emailChars\"]]]],null],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"row forcewide\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n        \"],[8,\"widgets/form-submit-button-edit\",[],[[\"@onSubmit\",\"@onBack\",\"@savebuttonlabel\",\"@inverse\"],[[30,[36,0],[[32,0],\"save\"],null],[30,[36,0],[[32,0],\"back\"],null],\"Start Profile\",[34,3]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\"],[6,[37,4],[[35,1,[\"errors\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"validation\"],[12],[2,\"\\n              There is an error on the form. Please fix this error before continuing. The error will be marked in red.\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"          \"],[8,\"widgets/server-errors\",[],[[\"@servererrors\"],[[34,5]]],null],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"fields\",\"system\",\"inverse\",\"if\",\"servererrors\"]}",
    "moduleName": "clientui/pods/components/wizards/wizard-start-profile/template.hbs"
  });

  _exports.default = _default;
});