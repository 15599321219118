define("clientui/models/travelrequest", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let Travelrequest = (_dec = (0, _model.belongsTo)('client'), _dec2 = (0, _model.hasMany)('requestelementflight'), _dec3 = (0, _model.attr)('boolean'), _dec4 = (0, _model.attr)('datetime'), _dec5 = (0, _model.attr)('number'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('boolean'), _dec9 = (0, _model.attr)('boolean'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('boolean'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('string'), _dec16 = Ember.computed('enquiryContainer'), _dec17 = Ember.computed('approved', 'requestProcessedOn'), classic(_class = (_class2 = class Travelrequest extends _model.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "requester", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "requestElementFlights", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "approved", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "requestProcessedOn", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "requestedApproverId", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "approverComment", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "requesterComment", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "active", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "visaRulesAccepted", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "enquiryType", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "enquiryTypeLabel", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "changeRequest", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "existingReference", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "concernFlightCode", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "concernCountryCode", _descriptor15, this);
    }

    get selectDisplayText() {
      return `${this.enquiryContainer}`;
    }

    get approvedDisplayText() {
      return this.approved ? "Yes" : this.requestProcessedOn ? "No" : "";
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "requester", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "requestElementFlights", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "approved", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "requestProcessedOn", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "requestedApproverId", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "approverComment", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "requesterComment", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "active", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "visaRulesAccepted", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "enquiryType", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "enquiryTypeLabel", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "changeRequest", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "existingReference", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "concernFlightCode", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "concernCountryCode", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "selectDisplayText", [_dec16], Object.getOwnPropertyDescriptor(_class2.prototype, "selectDisplayText"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "approvedDisplayText", [_dec17], Object.getOwnPropertyDescriptor(_class2.prototype, "approvedDisplayText"), _class2.prototype)), _class2)) || _class);
  _exports.default = Travelrequest;
});