define("clientui/pods/components/widgets/contact-detail-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y2X0JEm5",
    "block": "{\"symbols\":[],\"statements\":[[11,\"li\"],[4,[38,1],[[32,0],\"edit\",[35,0]],null],[12],[2,\"\\n\"],[6,[37,2],[[35,0,[\"description\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"small\"],[12],[2,\"\\n      \"],[10,\"b\"],[12],[2,\"\\n        \"],[1,[35,0,[\"description\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[1,[35,0,[\"value\"]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"item\",\"action\",\"if\"]}",
    "moduleName": "clientui/pods/components/widgets/contact-detail-item/template.hbs"
  });

  _exports.default = _default;
});