define("clientui/pods/components/layout/page-logos/component", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor"], function (_exports, _applyDecoratedDescriptor2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let PageLogos = (_dec = Ember.computed, classic(_class = (_class2 = class PageLogos extends Ember.Component {
    get isTopWindow() {
      return window.top === window.self;
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isTopWindow", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "isTopWindow"), _class2.prototype)), _class2)) || _class);
  _exports.default = PageLogos;
});