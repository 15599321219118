define("clientui/pods/components/pages/page-customeraccounts/page-customeraccounts-clientinvoices-search-form/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "moment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  let PagesPageCustomeraccountsPageCustomeraccountsClientinvoicesSearchFormComponent = (_dec = Ember.inject.service('system'), _dec2 = Ember.inject.service('form-date-conversion'), _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class PagesPageCustomeraccountsPageCustomeraccountsClientinvoicesSearchFormComponent extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "dateConversion", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "paramsForDisplay", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "searchClosed", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "selectedAccounts", _descriptor5, this);
      this.selectedAccounts = this.getFromListByIds(this.args.formParams.accountid, this.args.accounts);
      this.displayParams = JSON.parse(JSON.stringify(this.args.formParams));

      if (!Ember.isEmpty(this.args.clientInvoices)) {
        this.addToDisplayParams();
        this.searchClosed = true;
      }
    }

    getFromListByIds(id, list) {
      let result = [];

      if (id) {
        let ids = id.split(',');
        ids.forEach(itemId => {
          if (itemId) {
            if (list && list.length) {
              result.push(list.findBy('id', itemId));
            }
          }
        });
      } else {
        return null;
      }

      return result;
    }

    getFromListById(id, list, cachedItem) {
      let result = null;

      if (id) {
        if (cachedItem && cachedItem.id == id) {
          result = cachedItem;
        } else {
          if (list && list.length) {
            result = list.findBy('id', id);
          }
        }
      }

      return result;
    }

    get momentfrom() {
      let moment = null;

      if (this.args.formParams.get('datefrom')) {
        moment = new _moment.default(this.args.formParams.get('datefrom'), this.system.isoDateFormat);
      }

      return moment;
    }

    get momentto() {
      let moment = null;

      if (this.args.formParams.get('dateto')) {
        moment = new _moment.default(this.args.formParams.get('dateto'), this.system.isoDateFormat);
      }

      return moment;
    }

    setAccount(selected) {
      this.selectedAccounts = selected;
      Ember.set(this.args.formParams, 'accountid', selected ? selected.id : null);
    }

    setAccounts(selected) {
      this.selectedAccounts = selected;

      if (selected !== null) {
        if (Ember.isArray(selected)) {
          let arrayOfIds = selected.map(function (a) {
            return a.id;
          });
          this.args.formParams.set('accountid', arrayOfIds.join(","));
        } else {
          this.args.formParams.set('accountid', selected.id);
        }
      } else {
        this.args.formParams.set('accountid', null);
      }
    }

    setDateFrom(momentDate) {
      this.args.formParams.set('datefrom', this.dateConversion.getIsoDate(momentDate));
    }

    setDateTo(momentDate) {
      this.args.formParams.set('dateto', this.dateConversion.getIsoDate(momentDate));
    }

    formSubmit() {
      this.displayParams = this.args.formParams;
      this.args.onSubmit(this.args.formParams);
      this.addToDisplayParams();
      this.searchClosed = true;
    }

    clearForm() {
      this.selectedAccounts = null;
      this.displayParams = {};
      this.args.onClearForm();
    }

    addAccountNamesToDisplayParams() {
      if (this.getFromListByIds(this.displayParams.accountid, this.args.accounts) != null) {
        let arrayOfNames = this.getFromListByIds(this.displayParams.accountid, this.args.accounts).map(function (a) {
          return a.name;
        });
        return arrayOfNames.join(", ");
      } else {
        return null;
      }
    }

    addToDisplayParams() {
      let params = [{
        name: 'accountid',
        displayName: 'Account(s)',
        value: this.addAccountNamesToDisplayParams(),
        type: 'strings'
      }, {
        name: 'bookingid',
        displayName: 'Booking',
        value: this.displayParams.bookingid,
        type: 'number'
      }, {
        name: 'datefrom',
        displayName: 'Issue Date from',
        value: this.displayParams.datefrom,
        type: 'date'
      }, {
        name: 'dateto',
        displayName: 'Issue Date to',
        value: this.displayParams.dateto,
        type: 'date'
      }, {
        name: 'hasoutstandingbalance',
        displayName: 'Has Outstanding Balance',
        value: this.displayParams.hasoutstandingbalance,
        type: 'boolean'
      }, {
        name: 'id',
        displayName: 'Client Invoice',
        value: this.displayParams.id,
        type: 'number'
      }, {
        name: 'identifier',
        displayName: 'Client Invoice',
        value: this.displayParams.identifier,
        type: 'number'
      }];
      this.paramsForDisplay = params;
    }

    toggleSearch() {
      if (this.searchClosed) {
        this.searchClosed = false;
      } else {
        this.addToDisplayParams();
        this.searchClosed = true;
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dateConversion", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "paramsForDisplay", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "searchClosed", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedAccounts", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setAccount", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setAccount"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setAccounts", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setAccounts"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setDateFrom", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "setDateFrom"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setDateTo", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "setDateTo"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "formSubmit", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "formSubmit"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "clearForm", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "clearForm"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleSearch", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "toggleSearch"), _class.prototype)), _class));
  _exports.default = PagesPageCustomeraccountsPageCustomeraccountsClientinvoicesSearchFormComponent;
});