define("clientui/pods/components/dialogs/form-profile-edit-contact-detail/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-validators"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberValidators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let FormProfileEditContactDetail = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('system'), _dec3 = Ember.computed('client.contactDetails.{[],@each.deleted}', 'category'), _dec4 = Ember.computed.sort('contactDetails', 'sortDefinition'), _dec5 = Ember.computed.sort('countries', 'dialcodeSortDefinition'), _dec6 = Ember.computed('clientportal.homeCountryCode'), _dec7 = Ember.computed('category'), _dec8 = Ember.computed('category'), _dec9 = Ember.computed('category'), _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, classic(_class = (_class2 = class FormProfileEditContactDetail extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
      (0, _defineProperty2.default)(this, "sortDefinition", null);
      (0, _initializerDefineProperty2.default)(this, "sortedContactDetails", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "sortedDialCodes", _descriptor4, this);
    }

    init() {
      super.init(...arguments); //console.log('Init Create Profile Wizard');

      this.set('sortDefinition', ['defaultContactDetail:desc', 'description', 'value']);
    }

    didReceiveAttrs() {// if (this.get('category') !== 'EMAIL') {
      //   this.set('validCountries', this.get('countries').filter((item) => (isPresent(item.get('dialCode')))));
      // }
    }

    get contactDetails() {
      return this.get('client.contactDetails').filter(item => item.get('contactDetailType') === this.category && !item.get('deleted'));
    }

    get dialcodeSortDefinition() {
      return ['priority' + this.get('clientportal.homeCountryCode'), 'dialCode'];
    }

    get isEmail() {
      return this.category === 'EMAIL' || this.category === 'APPROV';
    }

    get hidePrefix() {
      return this.category === 'MOBILE';
    }

    get helpkey() {
      if (this.category === 'MOBILE') {
        return 'mobile';
      } else if (this.category === 'PHONE') {
        return 'phone';
      } else {
        return null;
      }
    }

    validate(item) {
      let result = true;

      if (Ember.isBlank(item.get('newDescription'))) {
        item.set('newDescriptionError', 'Description cannot be blank');
        result = false;
      } else {
        item.set('newDescriptionError', null);
      }

      let vResult = null;

      if (this.isEmail) {
        vResult = (0, _emberValidators.validate)('format', item.get('newValue'), {
          'type': 'email'
        });
      } else {
        vResult = (0, _emberValidators.validate)('format', item.get('newValue'), {
          'regex': /(\+\d+-|0)?(\d+-)?\d+/
        });
      } //vResult is either boolean 'true' or an object.


      if (vResult && typeof vResult === 'object') {
        result = false;

        if (vResult.message) {
          item.set('newValueError', vResult.message);
        } else {
          item.set('newValueError', 'Invalid Value');
        }
      } else {
        item.set('newValueError', null);
      }

      return result;
    }

    validateNew() {
      let result = true;

      if (Ember.isBlank(this.newItemDescription)) {
        this.set('newItemDescriptionError', 'Description cannot be blank');
        result = false;
      } else {
        this.set('newItemDescriptionError', null);
      }

      let vResult = null;
      this.set('newItemValue', this.get('client.newItemValue')); //pull new phone number out of client changeset

      if (this.isEmail) {
        //console.log('validating new email');
        vResult = (0, _emberValidators.validate)('format', this.newItemValue, {
          'type': 'email'
        }); //console.log(vResult);
        //vResult is either boolean 'true' or an object.
      } else {
        //console.log('validating new phone');
        vResult = (0, _emberValidators.validate)('format', this.newItemValue, {
          'regex': /(\+\d+-|0)\d+-\d+/
        });
      }

      if (vResult && typeof vResult === 'object') {
        result = false;

        if (vResult.message) {
          this.set('newItemValueError', vResult.message);
        } else {
          this.set('newItemValueError', 'Invalid Value');
        }
      } else {
        this.set('newItemValueError', null);
      }

      return result;
    }

    edit(item) {
      if (!this.pageActive) {
        if (typeof item.get('editing') === 'undefined') {
          item.set('originalDescription', item.get('description'));
          item.set('originalValue', item.get('value'));
        }

        item.set('newDescription', item.get('description'));
        item.set('newDescriptionEditable', !['BUSINESS', 'PERSONAL'].includes(item.get('description').toUpperCase()));
        item.set('newValue', item.get('value'));
        this.onActivate(true);
        item.set('editing', true);
        item.set('deleting', false);
        this.set('hideAdd', true); //component-global flag
      }
    }

    delete(item) {
      if (!this.pageActive) {
        this.onActivate(true);
        item.set('editing', false);
        item.set('deleting', true);
        this.set('hideAdd', true); //component-global flag
      }
    }

    addNew() {
      if (!this.pageActive) {
        this.set('adding', true);
        this.onActivate(true);
      }
    }

    back(item) {
      this.onActivate(false);
      item.set('editing', false);
      item.set('deleting', false);
      this.set('hideAdd', false); //component-global flag
    }

    backAdd() {
      this.set('adding', false);
      this.$('#addNew').collapse('hide');
      this.onActivate(false);
    }

    add() {
      if (this.validateNew()) {
        let newItem = this.store.createRecord('contact-detail');
        newItem.set('contactDetailType', this.category);
        newItem.set('description', this.newItemDescription);
        newItem.set('value', this.newItemValue);
        this.get('client.contactDetails').pushObject(newItem);
        this.set('newItemDescription', null);
        this.set('newItemValue', null);
        this.$('#addNew').collapse('hide');
        this.set('adding', false);
        this.onActivate(false);
      }
    }

    apply(item) {
      let success = true;

      if (item.get('editing') === true) {
        if (this.validate(item)) {
          item.set('description', item.get('newDescription'));
          item.set('value', item.get('newValue'));
        } else {
          success = false;
        }
      } else if (item.get('deleting') === true) {
        item.set('deleted', true);
      } else {
        console.log("nothing to do");
      }

      if (success) {
        this.onActivate(false);
        item.set('editing', false);
        item.set('deleting', false);
        this.set('hideAdd', false); //component-global flag
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "contactDetails", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "contactDetails"), _class2.prototype), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sortedContactDetails", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sortedDialCodes", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "dialcodeSortDefinition", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "dialcodeSortDefinition"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isEmail", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "isEmail"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "hidePrefix", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "hidePrefix"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "helpkey", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "helpkey"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "edit", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "edit"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "delete", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "delete"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "addNew", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "addNew"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "back", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "back"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "backAdd", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "backAdd"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "add", [_dec15], Object.getOwnPropertyDescriptor(_class2.prototype, "add"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apply", [_dec16], Object.getOwnPropertyDescriptor(_class2.prototype, "apply"), _class2.prototype)), _class2)) || _class);
  _exports.default = FormProfileEditContactDetail;
});