define("clientui/pods/components/ember-model-table/page-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rVSk+fcI",
    "block": "{\"symbols\":[\"opt\",\"&default\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"options\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"option\"],[15,2,[32,1,[\"value\"]]],[15,\"selected\",[30,[36,0],[[32,0,[\"value\"]],[32,1,[\"value\"]],[32,0,[\"type\"]]],null]],[12],[2,\"\\n    \"],[1,[32,1,[\"label\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[18,2,null]],\"hasEval\":false,\"upvars\":[\"is-equal\",\"-track-array\",\"each\"]}",
    "moduleName": "clientui/pods/components/ember-model-table/page-select/template.hbs"
  });

  _exports.default = _default;
});