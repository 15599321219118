define("clientui/models/bookingreference", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  let bookingreference = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('boolean'), _dec5 = (0, _model.attr)('string'), (_class = class bookingreference extends _model.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "caption", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "displayCaption", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "displayDescription", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "manual", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "value", _descriptor5, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "caption", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "displayCaption", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "displayDescription", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "manual", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "value", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = bookingreference;
});