define("clientui/pods/contact/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NVCrY6PN",
    "block": "{\"symbols\":[],\"statements\":[[8,\"layout/page-header\",[],[[\"@clientportal\",\"@client\",\"@showBanner\",\"@pageTitle\"],[[32,0,[\"clientportal\"]],[32,0,[\"client\"]],false,\"Contact Us\"]],null],[2,\"\\n\"],[10,\"section\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[14,1,\"content\"],[12],[2,\"\\n\"],[2,\"\\n    \"],[8,\"pages/page-contact\",[],[[\"@client\",\"@dateFormat\",\"@clientportal\",\"@accountmanager\",\"@company\",\"@hideHeadOfContract\",\"@hideOnlineHelpdesk\",\"@showSecureOnlineHelpdesk\"],[[32,0,[\"client\"]],[32,0,[\"system\",\"dateFormat\"]],[32,0,[\"clientportal\"]],[32,0,[\"accountmanager\"]],[32,0,[\"company\"]],[32,0,[\"hideHeadOfContract\"]],[32,0,[\"hideOnlineHelpdesk\"]],[32,0,[\"showSecureOnlineHelpdesk\"]]]],null],[2,\"\\n\\n\"],[2,\"\\n\"],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "clientui/pods/contact/template.hbs"
  });

  _exports.default = _default;
});