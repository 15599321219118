define("clientui/pods/components/ember-model-table/cell-date/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kFYH0gxK",
    "block": "{\"symbols\":[\"@column\",\"@record\"],\"statements\":[[1,[30,[36,2],[[30,[36,0],[[32,2],[32,1,[\"propertyName\"]]],null],[30,[36,1],[[30,[36,0],[[32,2],[32,1,[\"propertyName\"]]],null],[32,0,[\"system\",\"dateFormat\"]]],null],\"\"],null]]],\"hasEval\":false,\"upvars\":[\"get\",\"moment-format\",\"if\"]}",
    "moduleName": "clientui/pods/components/ember-model-table/cell-date/template.hbs"
  });

  _exports.default = _default;
});