define("clientui/pods/components/dialogs/form-profile-delete/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  let FormClientDelete = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('system'), _dec3 = Ember._action, _dec4 = Ember._action, (_class = class FormClientDelete extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
      (0, _defineProperty2.default)(this, "modelName", 'client');
      (0, _defineProperty2.default)(this, "modelDescription", 'Client');
      (0, _defineProperty2.default)(this, "enableDebugLogging", true);
      this.log('Constructor ' + this.modelDescription + ' Delete/Undelete Form');
    }

    log(s) {
      if (this.enableDebugLogging) {
        console.log(s);
      }
    }

    delete() {
      this.args.onDelete(Ember.get(this.args, this.modelName)).then(() => {
        // Promise
        this.log(this.modelDescription + ' Deletion/Undeletion Saved Successfully in Component');
        this.args.onClose(); //this.args.onRefreshRoute();
      }, errors => {
        this.log(this.modelDescription + ' Deletion/Undeletion FAILED to Save in Component');
        this.log(errors);
        this.servererrors = errors;
      });
    }

    back() {
      //Close dialog
      this.args.onClose();
      this.args.onRollback(); //this.args.onRefreshRoute();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "delete", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "back", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "back"), _class.prototype)), _class));
  _exports.default = FormClientDelete;
});