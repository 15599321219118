define("clientui/validators/presence-if-required", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validatePresenceIfRequired;

  function validatePresenceIfRequired() {
    let requiresFlagKey = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return (key, newValue, oldValue, changes, content) => {
      let requiresFlag = Ember.get(changes, requiresFlagKey);

      if (requiresFlag === undefined) {
        requiresFlag = Ember.get(content, requiresFlagKey);
      }

      if (requiresFlag === true) {
        if (Ember.isBlank(newValue)) {
          return key + " cannot be blank";
        }
      }

      return true;
    };
  }
});