define("clientui/pods/components/pages/page-customeraccounts/page-customeraccounts-statements-landingpage/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZNNkdbiq",
    "block": "{\"symbols\":[\"option\",\"@accounts\"],\"statements\":[[10,\"section\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"mb-4\"],[12],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[14,1,\"account\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"label\"],[14,\"for\",\"account\"],[14,0,\"col-3 col-form-label\"],[12],[2,\"\\n        Select Account\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-5\"],[12],[2,\"\\n        \"],[8,\"power-select\",[],[[\"@id\",\"@options\",\"@selected\",\"@allowClear\",\"@disabled\",\"@onChange\"],[\"account\",[32,2],[32,0,[\"selectedAccount\"]],false,[32,0,[\"onlyOneAccount\"]],[32,0,[\"setAccount\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[32,1,[\"name\"]]],[2,\"\\n        \"]],\"parameters\":[1]}]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-4\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn w-100 btn-purple btn-rounded\"],[24,4,\"button\"],[4,[38,0],[\"click\",[32,0,[\"goToAccountStatement\"]]],null],[12],[2,\"\\n          Go To Statement\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"on\"]}",
    "moduleName": "clientui/pods/components/pages/page-customeraccounts/page-customeraccounts-statements-landingpage/template.hbs"
  });

  _exports.default = _default;
});