define("clientui/pods/downloadabledocuments/search/controller", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let SearchController = (_dec = Ember.inject.service, _dec2 = Ember.computed('id', 'accountid', 'documenttype', 'description', 'datefrom', 'dateto', 'read', 'trigger'), _dec3 = Ember._action, _dec4 = Ember._action, classic(_class = (_class2 = class SearchController extends Ember.Controller {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor, this);
      (0, _defineProperty2.default)(this, "queryParams", ['id', 'accountid', 'documenttype', 'description', 'datefrom', 'dateto', 'read', 'archived', 'pageno', 'psz', 'sort', 'sortdir']);
      (0, _defineProperty2.default)(this, "id", null);
      (0, _defineProperty2.default)(this, "accountid", null);
      (0, _defineProperty2.default)(this, "documenttype", null);
      (0, _defineProperty2.default)(this, "description", null);
      (0, _defineProperty2.default)(this, "datefrom", null);
      (0, _defineProperty2.default)(this, "dateto", null);
      (0, _defineProperty2.default)(this, "read", false);
      (0, _defineProperty2.default)(this, "archived", false);
      (0, _defineProperty2.default)(this, "pageno", 1);
      (0, _defineProperty2.default)(this, "psz", 10);
      (0, _defineProperty2.default)(this, "sort", null);
      (0, _defineProperty2.default)(this, "sortdir", null);
      (0, _defineProperty2.default)(this, "trigger", 0);
      (0, _defineProperty2.default)(this, "noneFound", false);
    }

    get formParams() {
      let f = Ember.Object.create();
      ['id', 'accountid', 'documenttype', 'description', 'datefrom', 'dateto', 'read', 'archived'].forEach(s => {
        f.set(s, this.get(s));
      });
      return f;
    }

    clearForm() {
      this.set('id', null);
      this.set('accountid', null);
      this.set('documenttype', null);
      this.set('description', null);
      this.set('datefrom', null);
      this.set('dateto', null);
      this.set('read', false);
      this.set('archived', false);
      this.incrementProperty('trigger');
    }

    dataNavigation(navData) {
      //Update page level variables to match whatever has happened in the table.
      this.set('pageno', navData.currentPageNumber);
      this.set('psz', navData.pageSize);
      this.set('sort', navData.sortField);
      this.set('sortdir', navData.sortDir);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "formParams", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "formParams"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "clearForm", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "clearForm"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "dataNavigation", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "dataNavigation"), _class2.prototype)), _class2)) || _class);
  _exports.default = SearchController;
});