define("clientui/serializers/pageablelist", ["exports", "clientui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let Pageablelist = classic(_class = class Pageablelist extends _application.default {
    /*
    This serializer is designed to allow the server to return Meta information over and above the data content of the response
    Such Meta data includes pagination information
    We can get Java to produce JSON in the form (the payload):
     {
      "pageablelist":[
        {"id":1,...},
        {"id":2,...},
        ...
      ],
      "meta":{
        "totalRecords":0,
        "pageSize":10,
        "pageNumber":0,
        "totalPages":0,
      }
    }
    In order for the data to be parsed properly, the payload should be of the form
    [
     {"id":1,...},
     {"id":2,...},
     ...
    ]
     To achieve this, we subclass the serializer and override the normalizeResponse method.
    We first extract the meta data, if present, using the built-in routines.
    We then send just the pageablelist to be normalised by the build in code
    Finally, the metaData is re-joined to the response in the same place where JSON-API would put it.
    */
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      let meta = this.extractMeta(store, primaryModelClass, payload);

      if (payload && payload['pageablelist'] !== undefined) {
        payload = payload['pageablelist'];
      }

      let documentHash = super.normalizeResponse(store, primaryModelClass, payload, id, requestType);

      if (meta) {
        documentHash.meta = meta;
      }

      return documentHash;
    }

  }) || _class;

  _exports.default = Pageablelist;
});