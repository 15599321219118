define("clientui/pods/components/dialogs/form-profile-edit-subscriptions/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let FormProfileEditSubscriptions = (_dec = Ember.inject.service('system'), _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, classic(_class = (_class2 = class FormProfileEditSubscriptions extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _defineProperty2.default)(this, "selectedMarketingGroups", null);
    }

    init() {
      super.init(...arguments);
      console.log('Init Client Subscriptions Form');
    }

    didReceiveAttrs() {
      this.set('selectedMarketingGroups', {});
      this.get('client.marketingGroups').forEach(mg => {
        this.selectedMarketingGroups[mg.get('id')] = true;
      });
      this.system.set('saving', false);
    }

    save() {
      this.system.set('saving', true);
      let client = this.client;
      client.validate().then(() => {
        if (client.get("isValid")) {
          // let fieldName = null;
          // let selectionId = 0;
          // let model = null;
          let promises = {}; // if (isPresent(this.get('client.genderSelection'))) {
          //   fieldName = 'gender';
          //   model = 'gender';
          //   selectionId = this.get('client.genderSelection');
          //   promises[fieldName] = this.get('store').peekRecord(model, selectionId);
          // }

          client.get('marketingGroups').clear();
          Object.keys(this.selectedMarketingGroups).forEach(key => {
            if (this.selectedMarketingGroups[key] === true) {
              let mg = this.store.peekRecord('marketinggroup', key);
              client.get('marketingGroups').pushObject(mg);
            }
          });
          Ember.RSVP.hash(promises).then(item => {
            Object.keys(item).forEach(key => {
              client.set(key, item[key]);
            });
            this.onSave(client).then(() => {
              // Promise
              console.log('clientSubscriptions Promise Saved Successfully in Component');
              this.onClose(); //this.onRefreshRoute();

              this.system.set('saving', false);
            }, errors => {
              console.log('clientSubscriptions Promise Failed to Save in Component');
              console.log(errors);
              this.set('servererrors', errors);
              this.system.set('saving', false);
            });
          }, errors => {
            console.log('clientSubscriptions Promise Failed to Save in Component - unable to find one of gender/paxType');
            console.log(errors);
            this.set('servererrors', errors);
            this.system.set('saving', false);
          });
        } else {
          this.system.set('saving', false);
        }
      }, () => {
        this.system.set('saving', false);
      });
    }

    back() {
      //Rollback any changes
      this.client.rollback();

      this.client._content._internalModel._record.rollbackAttributes(); //Close dialog


      this.onClose();
      this.onRollback();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "save", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "back", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "back"), _class2.prototype)), _class2)) || _class);
  _exports.default = FormProfileEditSubscriptions;
});