define("clientui/pods/components/layout/content-heading/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let ContentHeading = classic(_class = class ContentHeading extends Ember.Component {}) || _class;

  _exports.default = ContentHeading;
});