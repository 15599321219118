define("clientui/pods/travelrequests/requestnew/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "clientui/superclasses/this-route-new", "ember-changeset-validations", "ember-changeset", "clientui/validations/enquirycontainer"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _thisRouteNew, _emberChangesetValidations, _emberChangeset, _enquirycontainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let RequestnewRoute = (_dec = Ember.inject.service('keycloak-session'), _dec2 = Ember.inject.service('system'), classic(_class = (_class2 = class RequestnewRoute extends _thisRouteNew.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
      (0, _defineProperty2.default)(this, "routeLeaf", 'newrequest');
      (0, _defineProperty2.default)(this, "dialogTitle", 'New Travel Request');
      (0, _defineProperty2.default)(this, "enableDebugLogging", true);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
    }

    model() {
      let promises = [];
      promises['client'] = this.modelFor('travelrequests'); //contains client

      promises['managedclientlist'] = this.store.query("shortclient", {
        'incguest': true,
        'pno': -1
      });
      return Ember.RSVP.hash(promises);
    }

    setupController(controller, promises) {
      controller.set('model', undefined); //Reset form

      let client = promises['client'];

      if (client) {
        controller.set('client', client);
      }

      console.log('****************** Client **********************');
      console.log(client);
      controller.set('clientportal', this.system.clientportal);
      controller.set('system', this.system);
      let managedclientlist = promises['managedclientlist'];

      if (managedclientlist) {
        console.log(managedclientlist);
        controller.set('managedclientlist', managedclientlist);
      }

      let enquiryContainer = new _emberChangeset.default(this.store.createRecord('enquirycontainer'), (0, _emberChangesetValidations.default)(_enquirycontainer.default), _enquirycontainer.default);
      enquiryContainer.set('account', client.get('clientProfile.defaultAccount'));
      enquiryContainer.set('travelArrangerId', client.get('dbId'));
      enquiryContainer.set('tradeClientProfileId', this.system.clientportal.get('tradeClientProfileId'));
      enquiryContainer.set('travelRequests', Ember.A([]));
      enquiryContainer.set('quoteClients', Ember.A([]));
      controller.set('enquiryContainer', enquiryContainer);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = RequestnewRoute;
});