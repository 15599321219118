define("clientui/pods/components/pages/faq-category-accordion/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  let PagesFaqCategoryAccordionComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class PagesFaqCategoryAccordionComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "expanded", _descriptor, this);
    }

    expand() {
      this.expanded = !this.expanded;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "expanded", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "expand", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "expand"), _class.prototype)), _class));
  _exports.default = PagesFaqCategoryAccordionComponent;
});