define("clientui/services/emtsp-helper", ["exports", "clientui/services/emt-helper-base"], function (_exports, _emtHelperBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let EmtspHelperService =
  /*
    This version is suitable for use on server-paginated tables
  */
  classic(_class = class EmtspHelperService extends _emtHelperBase.default {
    _buildColInternal(propertyName, title, filterPropertyName, className, componentName, defaultOptions, userOptions) {
      return super._buildColInternal(propertyName, title, filterPropertyName, className, componentName, defaultOptions, userOptions);
    }

  }) || _class;

  _exports.default = EmtspHelperService;
});