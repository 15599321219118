define("clientui/pods/components/ember-model-table/cell-actions/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember-decorators/component"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let CellActions = (_dec = (0, _component.tagName)(''), _dec2 = Ember._action, _dec3 = Ember._action, classic(_class = _dec(_class = (_class2 = class CellActions extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "show", "");
    }

    handleMouseEnter() {
      this.set('show', 'in');
    }

    handleMouseLeave() {
      this.set('show', '');
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleMouseEnter", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "handleMouseEnter"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleMouseLeave", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "handleMouseLeave"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = CellActions;
});