define("clientui/pods/components/widgets/display-page/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let DisplayPage = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('system'), _dec3 = Ember.computed('link.pageHtml'), classic(_class = (_class2 = class DisplayPage extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
      (0, _defineProperty2.default)(this, "elementId", 'pagehtml');
    }

    didReceiveAttrs() {
      console.log("Starting display-page component");
    }

    get safeHtml() {
      let pageHtml = null;

      if (!this.link) {
        console.log("display-page: link is null");
      }

      pageHtml = this.get('link.pageHtml');

      if (!pageHtml) {
        console.log("display-page: link.pageHtml is null");
      } else {
        pageHtml = this.system.replacePlaceholderUrls(pageHtml);
      } //console.log("starting conditional inclusion");


      let regex = /<!-- #if (.+?) -->([\W\w]*?)<!-- #endif -->/g;
      let matchArray;
      let result = "";
      let startIndex = 0;

      while ((matchArray = regex.exec(pageHtml)) !== null) {
        if (startIndex < matchArray.index) {
          //copy prefix block to result
          result += pageHtml.substring(startIndex, matchArray.index);
        } //console.log(`Found ${matchArray[0]}. Next starts at ${regex.lastIndex}.`);


        let condition = matchArray[1];
        let block = matchArray[2];

        if (this.evaluateCondition(condition)) {
          result += block;
        }

        startIndex = regex.lastIndex;
      } //Copy block after last #endif to result


      if (startIndex < pageHtml.length) {
        result += pageHtml.substring(startIndex);
      } //Replace class bg-purpleROW with bg-purple1, bg-purple2 etc.


      let rowNumber = 1; //Always start with 1

      result = result.replace(/(class="[^"]*?)(bg-purpleROW)([^"]*?")/g, function (match, p1, p2, p3
      /*, offset, string */
      ) {
        //console.log(match);
        //console.log(rowNumber);
        let newClass = 'row' + rowNumber; //console.log(newClass);

        let result = [p1, newClass, p3].join('');
        rowNumber++; //console.log(result);

        return result;
      });
      return Ember.String.htmlSafe(result);
    }

    evaluateCondition(condition) {
      let result = false;
      let negative = condition.startsWith('!');

      if (negative) {
        result = !this.get(condition.substring(1));
      } else {
        result = !!this.get(condition);
      }

      console.log("evaluating condition " + condition + ": result is " + result);
      return result;
    }
    /*
      Capture the click event anywhere on the displayed page and if it is a link to the current site, then
      convert it into a transition. Non-anchor and external-site links are unaffected
    */


    click(event) {
      let el = event.target || event.srcElement;
      let anchor = null;

      while (el) {
        if ((el.nodeName || el.tagName).toLowerCase() === 'a') {
          anchor = el;
          break;
        }

        el = el.parentNode;
      } //var target = event.target;


      if (anchor) {
        let pathsWithParam = this.system.pathsWithParam;
        let pathsWithoutParams = this.system.pathsWithoutParams;

        if (anchor.hostname === window.location.hostname) {
          //console.log(anchor.pathname);
          if (anchor.pathname) {
            let foundPath = pathsWithParam.find(path => {
              return anchor.pathname.startsWith(path);
            });
            let foundPath2 = pathsWithoutParams.find(path => {
              return anchor.pathname.startsWith(path);
            });

            if (anchor.pathname === '/') {
              event.preventDefault();
              this.router.transitionTo('application');
            } else if (foundPath) {
              // console.log("FoundPath: " + foundPath);
              let param = anchor.pathname.substring(foundPath.length);

              if (param) {
                event.preventDefault();
                let route = foundPath.substr(1, foundPath.length - 2).replace("/", "."); //truncate leading and trailing / and replace internal / with .
                // console.log("Transitioning to route " + route + " with param " + param);

                this.router.transitionTo(route, param);
              }
            } else if (foundPath2) {
              // console.log("Found " + anchor.pathname + " in pathsWithoutParams");
              event.preventDefault();
              let route = anchor.pathname.substr(1, anchor.pathname.length - 1).replace("/", "."); //truncate leading / and replace internal / with .
              // console.log("Transitioning to route " + route);

              this.router.transitionTo(route);
            }
          }
        }
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "safeHtml", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "safeHtml"), _class2.prototype)), _class2)) || _class);
  _exports.default = DisplayPage;
});