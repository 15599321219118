define("clientui/pods/components/widgets/external-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hPcb+g/e",
    "block": "{\"symbols\":[\"&default\",\"&attrs\"],\"statements\":[[11,\"a\"],[16,\"rel\",[32,0,[\"rel\"]]],[16,\"target\",[32,0,[\"target\"]]],[16,6,[32,0,[\"href\"]]],[16,\"aria-label\",[32,0,[\"ariaLabel\"]]],[17,2],[12],[2,\"\\n\"],[6,[37,0],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[32,0,[\"href\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "clientui/pods/components/widgets/external-link/template.hbs"
  });

  _exports.default = _default;
});