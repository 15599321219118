define("clientui/pods/components/layout/content-heading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rs2FCex1",
    "block": "{\"symbols\":[\"@tradeclient\",\"@position\",\"@email\",\"@surname\",\"@firstName\",\"@portalDisplayName\"],\"statements\":[[10,\"div\"],[14,0,\"row pb-2 mb-4 border-bottom\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-auto mr-auto\"],[12],[2,\"\\n    \"],[10,\"h5\"],[14,0,\"m-0\"],[12],[2,\"\\n\"],[6,[37,0],[[32,6]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[32,6]],[2,\":\\n\"]],\"parameters\":[]}]]],[6,[37,0],[[32,5]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[32,5]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,0],[[32,4]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[32,4]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,0],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"small\"],[12],[2,\"\\n          (\"],[1,[32,3]],[2,\")\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-auto\"],[12],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"small text-right align-bottom m-0\"],[12],[2,\"\\n\"],[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[32,2]],[2,\" at\\n        \"],[10,\"br\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "clientui/pods/components/layout/content-heading/template.hbs"
  });

  _exports.default = _default;
});