define("clientui/pods/application-error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XuQXYz2E",
    "block": "{\"symbols\":[],\"statements\":[[8,\"layout/page-header\",[],[[\"@clientportal\",\"@client\",\"@showBanner\"],[[34,0],[34,1],true]],null],[2,\"\\n\"],[10,\"section\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[14,1,\"content\"],[12],[2,\"\\n    \"],[8,\"layout/content-heading\",[],[[\"@portalDisplayName\",\"@firstName\",\"@surname\",\"@email\"],[[34,0,[\"portalDisplayName\"]],[34,2],[34,3],[34,4]]],null],[2,\"\\n    \"],[10,\"h3\"],[12],[2,\"\\n      Application Error\\n    \"],[13],[2,\"\\n\"],[6,[37,5],[[32,0,[\"appErrString\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"pre\"],[12],[2,\"        \"],[1,[32,0,[\"appErrString\"]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[1,[30,[36,7],[[30,[36,6],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"clientportal\",\"client\",\"firstName\",\"surname\",\"email\",\"if\",\"-outlet\",\"component\"]}",
    "moduleName": "clientui/pods/application-error/template.hbs"
  });

  _exports.default = _default;
});