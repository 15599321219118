define("clientui/pods/components/widgets/form-save-buttons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OvvV987Z",
    "block": "{\"symbols\":[\"&attrs\",\"@onBack\",\"@onSubmit\"],\"statements\":[[11,\"div\"],[17,1],[12],[2,\"\\n  \"],[10,\"br\"],[12],[13],[2,\"\\n\"],[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[24,0,\"btn btn-secondary\"],[16,\"disabled\",[32,0,[\"internalbackdisabled\"]]],[24,4,\"button\"],[4,[38,1],[\"click\",[32,0,[\"clickBack\"]]],null],[12],[2,\"\\n      \"],[1,[32,0,[\"backbuttonlabel\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,0],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[16,0,[31,[\"btn btn-secondary ld-ext-right \",[30,[36,0],[[32,0,[\"system\",\"saving\"]],\"running\"],null]]]],[16,\"disabled\",[32,0,[\"internalsavedisabled\"]]],[24,4,\"button\"],[4,[38,1],[\"click\",[32,0,[\"clickSubmit\"]]],null],[12],[2,\"\\n      \"],[1,[32,0,[\"savebuttonlabel\"]]],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ld ld-ring ld-spin-fast\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "moduleName": "clientui/pods/components/widgets/form-save-buttons/template.hbs"
  });

  _exports.default = _default;
});