define("clientui/pods/myprofile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WJHoT9jb",
    "block": "{\"symbols\":[],\"statements\":[[8,\"layout/page-header\",[],[[\"@clientportal\",\"@client\",\"@showBanner\",\"@pageTitle\"],[[32,0,[\"clientportal\"]],[32,0,[\"client\"]],false,\"Selective Profile Suite\"]],null],[2,\"\\n\"],[10,\"section\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[14,1,\"content\"],[12],[2,\"\\n    \"],[8,\"layout/content-heading\",[],[[\"@portalDisplayName\",\"@firstName\",\"@surname\",\"@email\",\"@position\",\"@tradeclient\"],[[32,0,[\"clientportal\",\"portalDisplayName\"]],[32,0,[\"client\",\"firstName\"]],[32,0,[\"client\",\"surname\"]],[32,0,[\"client\",\"mainEmail\"]],[32,0,[\"client\",\"position\"]],[32,0,[\"client\",\"owner\",\"selectDisplayText\"]]]],null],[2,\"\\n\\n    \"],[8,\"pages/page-myprofile-new\",[],[[\"@client\",\"@dateFormat\",\"@dateTimeFormat\",\"@clientportal\",\"@loggedinclient\",\"@inverse\",\"@onEditPersonal\",\"@onEditContact\",\"@onEditProfessional\",\"@onEditDocuments\",\"@onEditProfileNotes\",\"@onEditSubscriptions\",\"@onEditPreferences\",\"@onEditPermissions\",\"@onManagedProfiles\",\"@onCoordinateProfiles\",\"@onDelete\",\"@onUndelete\"],[[32,0,[\"client\"]],[32,0,[\"system\",\"dateFormat\"]],[32,0,[\"system\",\"dateTimeFormat\"]],[32,0,[\"clientportal\"]],[32,0,[\"client\"]],false,[30,[36,0],[\"editPersonal\"],null],[30,[36,0],[\"editContact\"],null],[30,[36,0],[\"editProfessional\"],null],[30,[36,0],[\"editDocuments\"],null],[30,[36,0],[\"editProfileNotes\"],null],[30,[36,0],[\"editSubscriptions\"],null],[30,[36,0],[\"editPreferences\"],null],[30,[36,0],[\"editPermissions\"],null],[30,[36,0],[\"managedProfiles\"],null],[30,[36,0],[\"coordinateProfiles\"],null],[30,[36,0],[\"delete\"],null],[30,[36,0],[\"undelete\"],null]]],null],[2,\"\\n    \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"route-action\",\"-outlet\",\"component\"]}",
    "moduleName": "clientui/pods/myprofile/template.hbs"
  });

  _exports.default = _default;
});