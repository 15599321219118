define("clientui/pods/myprofile/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MyprofilesController extends Ember.Controller {
    get adminSettings() {
      return {
        routePlural: '',
        routeSingular: 'myprofile',
        parentModelName: 'client'
      };
    }

  }

  _exports.default = MyprofilesController;

  window.__CLASSIC_OWN_CLASSES__.set(MyprofilesController, true);
});