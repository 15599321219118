define("clientui/pods/components/widgets/form-wizard-buttons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U4AsB7ZG",
    "block": "{\"symbols\":[],\"statements\":[[11,\"button\"],[24,0,\"btn btn-secondary\"],[16,\"disabled\",[34,3]],[4,[38,1],[[32,0],\"Back\"],null],[12],[2,\"\\n  Cancel\\n\"],[13],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[35,4],1],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"button\"],[24,0,\"btn btn-outline-secondary\"],[16,\"disabled\",[34,0]],[4,[38,1],[[32,0],\"Prev\"],null],[12],[2,\"\\n    Previous\\n  \"],[13],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-outline-secondary\"],[16,\"disabled\",[34,2]],[4,[38,1],[[32,0],\"Next\"],null],[12],[2,\"\\n    Next\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[11,\"button\"],[16,0,[31,[\"btn\\n    \",[30,[36,9],[[35,8],\"btn-primary\",\"btn-info\"],null],\"\\n     ld-ext-right\\n    \",[30,[36,6],[[35,7,[\"saving\"]],\"running\",\"\"],null]]]],[16,\"disabled\",[34,3]],[4,[38,1],[[32,0],\"Submit\"],null],[12],[2,\"\\n  \"],[1,[34,10]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ld ld-ring ld-spin-fast\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"prevdisabled\",\"action\",\"nextdisabled\",\"disabled\",\"lastPage\",\"gt\",\"if\",\"system\",\"inverse\",\"unless\",\"savebuttonlabel\"]}",
    "moduleName": "clientui/pods/components/widgets/form-wizard-buttons/template.hbs"
  });

  _exports.default = _default;
});