define("clientui/pods/components/widgets/technology-item/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TechnologyItem extends _component.default {
    get internalLinkRoute() {
      if (this.args.internalLink) {
        return this.args.internalLink;
      } else if (this.args.externalLink === "<DEMO>") {
        switch (this.args.identifier) {
          case "MIS":
            return "technology.demo-mis";

          case "TRACKER":
            return "technology.demo-tracker";

          default:
            return null;
        }
      } else {
        return null;
      }
    }

    get internalRoute() {
      return !Ember.isBlank(this.internalLinkRoute);
    }

    get externalHRef() {
      if (this.args.externalLink === "<DEMO>") {
        return null;
      } else {
        return this.args.externalLink;
      }
    }

  }

  _exports.default = TechnologyItem;

  window.__CLASSIC_OWN_CLASSES__.set(TechnologyItem, true);
});