define("clientui/pods/components/widgets/cookie-consent/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let CookieConsent = ( //import { computed } from '@ember/object';
  _dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, classic(_class = _dec(_class = (_class2 = class CookieConsent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
      (0, _defineProperty2.default)(this, "consentStatus", null);
      (0, _defineProperty2.default)(this, "isShowingDialog", false);
      (0, _defineProperty2.default)(this, "showConsentBar", true);
    }

    didReceiveAttrs() {
      console.log("Starting Cookie-Consent component");
      let status = this.system.cookieConsentStatus();
      this.set('consentStatus', status);
      this.set('showConsentBar', !status.dialogDismissed);
    }

    allowall() {
      let status = this.system.allowAllCookies();
      this.set('consentStatus', status);
      this.set('showConsentBar', !status.dialogDismissed);
      this.set('isShowingDialog', false);
    }

    managecookies() {
      this.set('isShowingDialog', true);
    }

    savechanges(consentStatus) {
      // console.log("savechanges", consentStatus);
      let status = this.system.updateCookieConsentStatus(consentStatus);
      this.set('consentStatus', status);
      this.set('showConsentBar', !status.dialogDismissed);
      this.set('isShowingDialog', false);
    }

    showExplorerMsg() {
      this.system.explorerMessageShown = false;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "allowall", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "allowall"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "managecookies", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "managecookies"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "savechanges", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "savechanges"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "showExplorerMsg", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "showExplorerMsg"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = CookieConsent;
});