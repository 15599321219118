define("clientui/pods/travelrequests/requestchange/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c0FnS0Fe",
    "block": "{\"symbols\":[],\"statements\":[[8,\"widgets/card-basic\",[],[[\"@title\",\"@inverse\",\"@historyBack\"],[[34,0],true,false]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"wizards/wizard-create-request\",[],[[\"@client\",\"@enquiryContainer\",\"@clientportal\",\"@managedclientlist\",\"@inverse\",\"@changeRequest\",\"@onSave\"],[[34,1],[30,[36,4],[[35,3],[35,2]],null],[34,5],[34,6],true,true,[30,[36,7],[\"save\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"title\",\"client\",\"validation\",\"enquiryContainer\",\"changeset\",\"clientportal\",\"managedclientlist\",\"route-action\"]}",
    "moduleName": "clientui/pods/travelrequests/requestchange/template.hbs"
  });

  _exports.default = _default;
});