define("clientui/pods/technology/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vPEw2gR8",
    "block": "{\"symbols\":[],\"statements\":[[8,\"layout/page-header\",[],[[\"@clientportal\",\"@client\",\"@showBanner\"],[[32,0,[\"clientportal\"]],[32,0,[\"client\"]],true]],null],[2,\"\\n\"],[10,\"section\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[14,1,\"content\"],[12],[2,\"\\n    \"],[8,\"pages/page-technology\",[],[[\"@clientportal\",\"@client\"],[[32,0,[\"clientportal\"]],[32,0,[\"client\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "clientui/pods/technology/index/template.hbs"
  });

  _exports.default = _default;
});