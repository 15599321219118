define("clientui/models/clientportallink", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let Clientportallink = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('number'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('boolean'), _dec15 = Ember.computed('externalHRef', 'internalRoute', 'pageHtml', 'imageUrl'), _dec16 = Ember.computed('externalHRef', 'internalRoute', 'pageHtml', 'imageUrl'), classic(_class = (_class2 = class Clientportallink extends _model.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "caption", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "helpText", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "iconName", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "tileSize", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "externalHRef", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "internalRoute", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "pageHtml", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "imageUrl", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "role1", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "role2", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "linkName", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "dynamicDisplayModel", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "dynamicDisplayModelParams", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "authRequired", _descriptor14, this);
    }

    get linkTypeDescription() {
      if (!Ember.isEmpty(this.externalHRef)) {
        return "External Link";
      } else if (!Ember.isEmpty(this.internalRoute)) {
        return "Internal Link";
      } else if (!Ember.isEmpty(this.pageHtml)) {
        return "HTML Page";
      } else if (!Ember.isEmpty(this.imageUrl)) {
        return "Image Only";
      } else {
        return "Unknown";
      }
    }

    get linkType() {
      if (!Ember.isEmpty(this.externalHRef)) {
        return "EXTERNAL";
      } else if (!Ember.isEmpty(this.internalRoute)) {
        return "INTERNAL";
      } else if (!Ember.isEmpty(this.pageHtml)) {
        return "PAGE";
      } else if (!Ember.isEmpty(this.imageUrl)) {
        return "IMAGE";
      } else {
        return "UNKNOWN";
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "caption", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "helpText", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "iconName", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "tileSize", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "externalHRef", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "internalRoute", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "pageHtml", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "imageUrl", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "role1", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "role2", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "linkName", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "dynamicDisplayModel", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "dynamicDisplayModelParams", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "authRequired", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "linkTypeDescription", [_dec15], Object.getOwnPropertyDescriptor(_class2.prototype, "linkTypeDescription"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "linkType", [_dec16], Object.getOwnPropertyDescriptor(_class2.prototype, "linkType"), _class2.prototype)), _class2)) || _class);
  _exports.default = Clientportallink;
});