define("clientui/pods/components/widgets/address-line-item-value/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sDc0u91R",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0,[\"line1\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"card-text mb-0\"],[12],[2,\"\\n    \"],[1,[35,0,[\"line1\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[35,0,[\"line2\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"card-text mb-0\"],[12],[2,\"\\n    \"],[1,[35,0,[\"line2\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[35,0,[\"line3\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"card-text mb-0\"],[12],[2,\"\\n    \"],[1,[35,0,[\"line3\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[35,0,[\"city\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"card-text mb-0\"],[12],[2,\"\\n    \"],[1,[35,0,[\"city\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[35,0,[\"county\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"card-text mb-0\"],[12],[2,\"\\n    \"],[1,[35,0,[\"county\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[35,0,[\"country\",\"id\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"card-text mb-0\"],[12],[2,\"\\n    \"],[1,[35,0,[\"country\",\"name\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[35,0,[\"postCode\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"card-text mb-0\"],[12],[2,\"\\n    \"],[1,[35,0,[\"postCode\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"address\",\"if\"]}",
    "moduleName": "clientui/pods/components/widgets/address-line-item-value/template.hbs"
  });

  _exports.default = _default;
});