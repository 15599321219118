define("clientui/pods/components/ember-model-table/models-table-simple/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-models-table/components/models-table"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _modelsTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ModelsTableSimple = (_dec = Ember.inject.service('emt-component-helper'), classic(_class = (_class2 = class ModelsTableSimple extends _modelsTable.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "emtcomponenthelper", _descriptor, this);
      (0, _defineProperty2.default)(this, "pageSize", 9999);
      (0, _defineProperty2.default)(this, "currentPageNumber", 1);
      (0, _defineProperty2.default)(this, "pageSizeValues", null);
      (0, _defineProperty2.default)(this, "filterQueryParameters", null);
      (0, _defineProperty2.default)(this, "multipleColumnsSorting", false);
      (0, _defineProperty2.default)(this, "showComponentFooter", false);
      (0, _defineProperty2.default)(this, "showCurrentPageNumberSelect", true);
      (0, _defineProperty2.default)(this, "useNumericPagination", false);
      (0, _defineProperty2.default)(this, "showPageSize", true);
      (0, _defineProperty2.default)(this, "showColumnsDropdown", false);
      (0, _defineProperty2.default)(this, "useFilteringByColumns", false);
      (0, _defineProperty2.default)(this, "filteringIgnoreCase", true);
      (0, _defineProperty2.default)(this, "doFilteringByHiddenColumns", false);
      (0, _defineProperty2.default)(this, "showGlobalFilter", false);
      (0, _defineProperty2.default)(this, "filterString", '');
      (0, _defineProperty2.default)(this, "focusGlobalFilter", false);
      (0, _defineProperty2.default)(this, "selectRowOnClick", true);
      (0, _defineProperty2.default)(this, "multipleSelect", false);
      (0, _defineProperty2.default)(this, "data", null);
      (0, _defineProperty2.default)(this, "columns", null);
      (0, _defineProperty2.default)(this, "groupedHeaders", null);
      (0, _defineProperty2.default)(this, "displayDataChangedAction", null);
      (0, _defineProperty2.default)(this, "selectAction", null);
      (0, _defineProperty2.default)(this, "rowDoubleClickAction", null);
      (0, _defineProperty2.default)(this, "rowHoverAction", null);
      (0, _defineProperty2.default)(this, "rowOutAction", null);
      (0, _defineProperty2.default)(this, "_prevNav", null);
      (0, _defineProperty2.default)(this, "_prevCols", null);
    }

    //stores previous column settings so that the event only fires if there is a change
    init() {
      //Set default values
      this.set('pageSizeValues', [10, 25, 50, 100]);
      super.init(...arguments);
    }
    /**
     * Component init
     *
     * Set visibility and filtering attributes for each column
     * This is called from within super.init(...arguments) called above
     * This is a direct copy from ember-models-table except that there is a check for null/undefined on recordsCount
     * This throws an error on initialisation using the original code
     *
     * @method setup
     * @protected
     */


    setup() {
      this.emtcomponenthelper._setup(this);
    }

    userInteractionObserverOnce() {
      super.userInteractionObserverOnce(...arguments); //do regular stuff first

      this.emtcomponenthelper.sendSingleSelectAction(this); //send selectAction if one defined (from mixin)
    } //override EMT updateColumns so that _setupColumns is only called if the columns have changed
    //otherwise, filters/sorting etc are lost at inappropriate times


    updateColumns() {
      this.emtcomponenthelper._setupColumns(this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "emtcomponenthelper", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = ModelsTableSimple;
});