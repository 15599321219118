define("clientui/pods/components/pages/downloadabledocuments/page-downloadabledocuments-archive/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UxPyoTP/",
    "block": "{\"symbols\":[\"&attrs\",\"@downloadableDocument\",\"@onRollback\"],\"statements\":[[11,\"div\"],[17,1],[12],[2,\"\\n  \"],[10,\"form\"],[14,0,\"form\"],[12],[2,\"\\n    \"],[10,\"fieldset\"],[12],[2,\"\\n      \"],[8,\"widgets/list-item\",[],[[\"@label\",\"@item\",\"@col1Width\"],[\"Document Description\",[32,2,[\"fileName\"]],\"4\"]],null],[2,\"\\n      \"],[8,\"widgets/list-item\",[],[[\"@label\",\"@item\",\"@col1Width\"],[\"Creation Date\",[30,[36,0],[[32,2,[\"createdOn\"]]],null],\"4\"]],null],[2,\"\\n      \"],[10,\"div\"],[14,0,\"form-row forcewide\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-4\"],[12],[2,\"\\n          \"],[8,\"widgets/form-save-buttons\",[],[[\"@onSubmit\",\"@savebuttonlabel\",\"@onBack\"],[[32,0,[\"save\"]],[32,0,[\"savebuttonlabel\"]],[32,3]]],null],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n\"],[6,[37,1],[[32,2,[\"errors\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"validation\"],[12],[2,\"\\n            There is an error on the form. Please fix this error before continuing. The error will be marked in red.\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[8,\"widgets/server-errors\",[],[[\"@servererrors\"],[[32,0,[\"servererrors\"]]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"boss-date\",\"if\"]}",
    "moduleName": "clientui/pods/components/pages/downloadabledocuments/page-downloadabledocuments-archive/template.hbs"
  });

  _exports.default = _default;
});