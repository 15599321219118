define("clientui/pods/customeraccounts/receipts/search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jUFyRhxt",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"Search Receipts\"],null]],[2,\"\\n\"],[8,\"pages/page-customeraccounts/page-customeraccounts-receipt-search-list\",[],[[\"@accounts\",\"@receipts\",\"@receiptTypes\",\"@currencies\",\"@formParams\",\"@queryParams\",\"@onSubmit\",\"@onClearForm\"],[[32,0,[\"accounts\"]],[32,0,[\"receipts\"]],[32,0,[\"receiptTypes\"]],[32,0,[\"currencies\"]],[32,0,[\"formParams\"]],[32,0,[\"queryParams\"]],[30,[36,1],[\"formSubmit\"],null],[30,[36,2],[[32,0,[\"clearForm\"]]],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"page-title\",\"route-action\",\"fn\"]}",
    "moduleName": "clientui/pods/customeraccounts/receipts/search/template.hbs"
  });

  _exports.default = _default;
});