define("clientui/pods/components/pages/page-customeraccounts/page-customeraccounts-booking-search-list/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "moment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  let PageCustomeraccountsBooking = (_dec = Ember.inject.service('system'), _dec2 = Ember.inject.service('emtsp-helper'), _dec3 = Ember.inject.service('form-date-conversion'), _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, (_class = class PageCustomeraccountsBooking extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "emthelper", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "dateConversion", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "selectedAccounts", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "paramsForDisplay", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "searchClosed", _descriptor7, this);
      (0, _defineProperty2.default)(this, "actions", [this.emthelper.buildAction("view", "view", "View")]);
      this.selectedAccounts = this.getFromListByIds(this.args.formParams.accountid, this.args.accounts);
      this.displayParams = JSON.parse(JSON.stringify(this.args.formParams));

      if (!Ember.isEmpty(this.args.bookings)) {
        this.addToDisplayParams();
        this.searchClosed = true;
      }
    }

    getFromListByIds(id, list) {
      let result = [];

      if (id) {
        let ids = id.split(',');
        ids.forEach(itemId => {
          if (itemId) {
            if (list && list.length) {
              result.push(list.findBy('id', itemId));
            }
          }
        });
      } else {
        return null;
      }

      return result;
    }

    getFromListById(id, list, cachedItem) {
      let result = null;

      if (id) {
        if (cachedItem && cachedItem.id == id) {
          result = cachedItem;
        } else {
          if (list && list.length) {
            result = list.findBy('id', id);
          }
        }
      }

      return result;
    }

    setAccount(selected) {
      this.selectedAccounts = selected;
      Ember.set(this.args.formParams, 'accountid', selected ? selected.id : null);
    }

    setAccounts(selected) {
      this.selectedAccounts = selected;

      if (selected !== null) {
        if (Ember.isArray(selected)) {
          let arrayOfIds = selected.map(function (a) {
            return a.id;
          });
          this.args.formParams.set('accountid', arrayOfIds.join(","));
        } else {
          this.args.formParams.set('accountid', selected.id);
        }
      } else {
        this.args.formParams.set('accountid', null);
      }
    }

    get momentDotFrom() {
      let moment = null;

      if (this.args.formParams.get('dotdatefrom')) {
        moment = new _moment.default(this.args.formParams.get('dotdatefrom'), this.system.isoDateFormat);
      }

      return moment;
    }

    get momentDotTo() {
      let moment = null;

      if (this.args.formParams.get('dotdateto')) {
        moment = new _moment.default(this.args.formParams.get('dotdateto'), this.system.isoDateFormat);
      }

      return moment;
    }

    get momentCreatedFrom() {
      let moment = null;

      if (this.args.formParams.get('createddatefrom')) {
        moment = new _moment.default(this.args.formParams.get('createddatefrom'), this.system.isoDateFormat);
      }

      return moment;
    }

    get momentCreatedTo() {
      let moment = null;

      if (this.args.formParams.get('createddateto')) {
        moment = new _moment.default(this.args.formParams.get('createddateto'), this.system.isoDateFormat);
      }

      return moment;
    }

    setDotDateFrom(momentDate) {
      this.args.formParams.set('dotdatefrom', this.dateConversion.getIsoDate(momentDate));
    }

    setDotDateTo(momentDate) {
      this.args.formParams.set('dotdateto', this.dateConversion.getIsoDate(momentDate));
    }

    setCreatedDateFrom(momentDate) {
      this.args.formParams.set('createddatefrom', this.dateConversion.getIsoDate(momentDate));
    }

    setCreatedDateTo(momentDate) {
      this.args.formParams.set('createddateto', this.dateConversion.getIsoDate(momentDate));
    }

    setBookingId(bookingId) {
      this.args.formParams.id = bookingId;
    }

    formSubmit() {
      this.displayParams = this.args.formParams;
      this.args.onSubmit(this.args.formParams);
      this.addToDisplayParams();
    }

    clearForm() {
      this.selectedAccounts = null;
      this.displayParams = {};
      this.args.onClearForm();
    }

    addAccountNamesToDisplayParams() {
      if (this.getFromListByIds(this.displayParams.accountid, this.args.accounts) != null) {
        let arrayOfNames = this.getFromListByIds(this.displayParams.accountid, this.args.accounts).map(function (a) {
          return a.name;
        });
        return arrayOfNames.join(", ");
      } else {
        return null;
      }
    }

    addToDisplayParams() {
      let params = [{
        name: 'accountid',
        displayName: 'Account(s)',
        value: this.addAccountNamesToDisplayParams(),
        type: 'strings'
      }, {
        name: 'clientref',
        displayName: 'Client Reference',
        value: this.displayParams.clientref,
        type: 'string'
      }, {
        name: 'reference',
        displayName: 'Booking Reference',
        value: this.displayParams.reference,
        type: 'string'
      }, {
        name: 'dotdatefrom',
        displayName: 'Date of Travel from',
        value: this.displayParams.dotdatefrom,
        type: 'date'
      }, {
        name: 'dotdateto',
        displayName: 'Date of Travel to',
        value: this.displayParams.dotdateto,
        type: 'date'
      }, {
        name: 'createddatefrom',
        displayName: 'Activation Date from',
        value: this.displayParams.createddatefrom,
        type: 'date'
      }, {
        name: 'createddateto',
        displayName: 'Activation Date to',
        value: this.displayParams.createddateto,
        type: 'date'
      }, {
        name: 'surname',
        displayName: 'Surname',
        value: this.displayParams.surname,
        type: 'string'
      }, {
        name: 'id',
        displayName: 'booking',
        value: this.displayParams.id,
        type: 'number'
      }];
      this.paramsForDisplay = params;
      this.searchClosed = true;
    }

    toggleSearch() {
      if (this.searchClosed) {
        this.searchClosed = false;
      } else {
        this.addToDisplayParams();
        this.searchClosed = true;
      }
    }

    // get bookingColumns() {
    //   return [
    //     this.emthelper.buildStringCol("id", "Booking ID"),
    //     this.emthelper.buildStringCol("accountName", "Account"),
    //     this.emthelper.buildStringCol("clientRef", "Client Ref"),
    //     this.emthelper.buildStringCol("leadName", "Lead Name"),
    //     // this.emthelper.buildDateCol("dateOfTravel", "Date of Travel"),
    //     this.emthelper.buildMoneyIncZeroCol("totalFcNetInvoice", "Amount", null, null, null, { "currencyProperty": "currencyCode" }),
    //     this.emthelper.buildDateCol("createdOn", "Activation Date"),
    //     this.emthelper.buildActionCol("", this.actions)
    //   ]
    // }
    get bookingColumns() {
      return [this.emthelper.buildStringCol("id", "Booking ID"), this.emthelper.buildStringCol("accountName", "Account"), this.emthelper.buildDateCol("activationDate", "Activation Date"), this.emthelper.buildStringCol("clientRef", "Client Ref"), this.emthelper.buildStringCol("leadName", "Lead Name"), this.emthelper.buildDateCol("dateOfTravel", "Date of Travel"), //this.emthelper.buildStringCol("billingStatusLabel", "Billing Status", null, null, null, {sortedBy: 'billingStatus'}),
      this.emthelper.buildMoneyIncZeroCol("totalFcNetInvoice", "Amount", null, null, null, {
        "currencyProperty": "currencyCode",
        sortedBy: "amount"
      }), this.emthelper.buildMoneyIncZeroCol("outstandingBalance", "Balance", null, null, null, {
        "currencyProperty": "currencyCode"
      }), this.emthelper.buildActionCol("Actions", this.actions)];
    }

    selectAction(itemId) {
      if (itemId > 0) {
        this.router.transitionTo('customeraccounts.bookings.booking', itemId);
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "system", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "emthelper", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dateConversion", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedAccounts", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "paramsForDisplay", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "searchClosed", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setAccount", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "setAccount"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setAccounts", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "setAccounts"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setDotDateFrom", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "setDotDateFrom"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setDotDateTo", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "setDotDateTo"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setCreatedDateFrom", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "setCreatedDateFrom"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setCreatedDateTo", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "setCreatedDateTo"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setBookingId", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "setBookingId"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "formSubmit", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "formSubmit"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "clearForm", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "clearForm"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleSearch", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "toggleSearch"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectAction", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "selectAction"), _class.prototype)), _class));
  _exports.default = PageCustomeraccountsBooking;
});