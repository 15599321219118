define("clientui/models/transaction", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18;

  let Transaction = (_dec = (0, _model.attr)('number'), _dec2 = (0, _model.attr)('date'), _dec3 = (0, _model.belongsTo)('transtype'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.belongsTo)('currency'), _dec6 = (0, _model.attr)('number'), _dec7 = (0, _model.attr)('number'), _dec8 = (0, _model.attr)('number'), _dec9 = (0, _model.attr)('date'), _dec10 = (0, _model.attr)('date'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('number'), _dec14 = (0, _model.attr)('number'), _dec15 = (0, _model.attr)('boolean'), _dec16 = (0, _model.attr)('date'), _dec17 = (0, _model.attr)('string'), _dec18 = (0, _model.attr)('number'), (_class = class Transaction extends _model.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "bookingId", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "transDate", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "transType", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "details", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "currency", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "fcAmount", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "revenueAmount", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "costAmount", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "createdOn", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "modifiedOn", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "modifiedBy", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "notes", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "costItemId", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "invoiceItemId", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "cancelled", _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, "cancelledOn", _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, "cancellationBy", _descriptor17, this);
      (0, _initializerDefineProperty2.default)(this, "receiptId", _descriptor18, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "bookingId", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "transDate", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "transType", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "details", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "currency", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fcAmount", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "revenueAmount", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "costAmount", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "createdOn", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "modifiedOn", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "modifiedBy", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "notes", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "costItemId", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "invoiceItemId", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cancelled", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cancelledOn", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cancellationBy", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "receiptId", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Transaction;
});