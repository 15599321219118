define("clientui/pods/travelalerts/controller", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let TravelalertsController = (_dec = Ember.computed('data'), classic(_class = (_class2 = class TravelalertsController extends Ember.Controller {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "test", "Testing");
    }

    get feedModel() {
      let xml = this.data; //Months[0] is none so 1 = Jan, 2=Feb etc for easy lookup

      let months = ['None', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      let result = {};
      result['title'] = xml.getElementsByTagName("title")[0].childNodes[0].data;
      let updated = xml.getElementsByTagName("updated")[0].childNodes[0].data;
      result['updated'] = updated;
      result['updated2'] = updated.substring(8, 10) + " " + months[Number(updated.substring(5, 7))] + " " + updated.substring(0, 4) + " at " + updated.substring(11, 16);
      result['entries'] = []; //xml.getElementsByTagName("entry").forEach((xmlEntry) => {

      for (var i = 0; i < xml.getElementsByTagName("entry").length; i++) {
        let xmlEntry = xml.getElementsByTagName("entry")[i];
        let entry = {};
        let nodes = xmlEntry.getElementsByTagName("title");

        if (nodes && nodes.length > 0) {
          entry['title'] = nodes[0].childNodes[0].data;
        }

        nodes = xmlEntry.getElementsByTagName("updated");

        if (nodes && nodes.length > 0) {
          let sUpdated = nodes[0].childNodes[0].data;
          entry['updatedDay'] = sUpdated.substring(8, 10);
          entry['updatedMonth'] = months[Number(sUpdated.substring(5, 7))];
          entry['updatedYear'] = sUpdated.substring(0, 4);
        }

        nodes = xmlEntry.getElementsByTagName("summary");

        if (nodes && nodes.length > 0) {
          let divNodes = nodes[0].getElementsByTagName("div");

          if (divNodes && divNodes.length > 0) {
            let pNodes = nodes[0].getElementsByTagName("p");

            if (pNodes && pNodes.length > 0) {
              let summary = pNodes[0].childNodes[0].data;
              entry['summary'] = summary;
            }
          }
        } // xmlEntry.getElementsByTagName("link").forEach((link) => {


        nodes = xmlEntry.getElementsByTagName("link");

        for (var j = 0; j < nodes.length; j++) {
          let link = nodes[j];

          if (link.getAttribute("type") === "text/html") {
            entry['link'] = link.getAttribute("href");
            break;
          }
        } // console.log(entry);


        result['entries'].push(entry);
      } // console.log(result);


      return result;
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "feedModel", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "feedModel"), _class2.prototype)), _class2)) || _class);
  _exports.default = TravelalertsController;
});