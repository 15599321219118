define("clientui/mixins/parent-client-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    routePrefix: '',
    routePlural: '',
    routeSingular: 'myprofile',
    parentModelName: 'client',
    routeSingularRequiresId: false
  });

  _exports.default = _default;
});