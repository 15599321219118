define("clientui/pods/components/ember-model-table/cell-string/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hvvVyWri",
    "block": "{\"symbols\":[\"@column\",\"@record\",\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"\"],[17,3],[12],[2,\"\\n  \"],[1,[30,[36,0],[[32,2],[32,1,[\"propertyName\"]]],null]],[2,\"\\n\"],[6,[37,1],[[32,1,[\"tooltipPropertyName\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"attach-tooltip\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[[32,2],[32,1,[\"tooltipPropertyName\"]]],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"get\",\"if\"]}",
    "moduleName": "clientui/pods/components/ember-model-table/cell-string/template.hbs"
  });

  _exports.default = _default;
});