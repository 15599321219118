define("clientui/pods/customeraccounts/receipts/receipt/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+4JXxWAy",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[[32,0,[\"receipt\",\"id\"]]],null]],[2,\"\\n\"],[8,\"pages/page-customeraccounts/page-customeraccounts-receipt\",[],[[\"@receipt\",\"@downloadReceipt\"],[[32,0,[\"receipt\"]],[30,[36,1],[\"downloadReceipt\"],null]]],null],[2,\"\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"page-title\",\"route-action\",\"-outlet\",\"component\"]}",
    "moduleName": "clientui/pods/customeraccounts/receipts/receipt/template.hbs"
  });

  _exports.default = _default;
});