define("clientui/pods/components/pages/page-application-index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PiTxSKJ7",
    "block": "{\"symbols\":[\"link\",\"@filteredLinks\"],\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"widgets/link-item\",[],[[\"@href\",\"@caption\",\"@img-url\",\"@internalRoute\",\"@linkId\"],[[32,1,[\"externalHRef\"]],[32,1,[\"caption\"]],[32,1,[\"imageUrl\"]],[32,1,[\"internalRoute\"]],[32,1,[\"id\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "moduleName": "clientui/pods/components/pages/page-application-index/template.hbs"
  });

  _exports.default = _default;
});