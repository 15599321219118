define("clientui/pods/travelrequests/travelrequest/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let TravelrequestRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, classic(_class = (_class2 = class TravelrequestRoute extends Ember.Route {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "fetch", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "system", _descriptor2, this);
      (0, _defineProperty2.default)(this, "routeLeaf", 'new');
      (0, _defineProperty2.default)(this, "dialogTitle", 'New Traveller Profile');
      (0, _defineProperty2.default)(this, "enableDebugLogging", true);
    }

    model(params) {
      let promises = [];
      promises['client'] = this.modelFor('travelrequests'); // Give me whatever model is returned from the travelrequests route

      promises['enquirycontainer'] = this.store.findRecord('enquirycontainer', params.travelrequest_id);
      return Ember.RSVP.hash(promises);
    }

    setupController(controller, promises) {
      let client = promises['client'];

      if (client) {
        controller.set('client', client);
      }

      controller.set('clientportal', this.system.clientportal);
      let enquirycontainer = promises['enquirycontainer'];

      if (enquirycontainer) {
        controller.set('enquirycontainer', enquirycontainer);
      }
    }

    save(enquiryId, approved, comment) {
      console.log('Saving Approve/Decline In Route');
      let model = {
        'approved': approved,
        'comment': comment
      };
      return this.fetch.fetch('post', 'cors', 'enquirycontainers/process/' + enquiryId, model).then(response => {
        this.transitionTo('travelrequests.manage');
        return response;
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "system", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "save", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype)), _class2)) || _class);
  _exports.default = TravelrequestRoute;
});